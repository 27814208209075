import _ from 'lodash'
import Vue from 'vue'
import { appStore } from '@/store'
import InnerTabs from '@/views/dashboard/inner-tabs.vue'

export default Vue.extend({
  computed: {
    url() {
      return _.get(appStore.state.data, this.name)
    }
  },

  mounted() {
    window.addEventListener('message', this.onMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },

  methods: {
    onMessage({ data }) {
      console.debug(data)
      try {
        const message = JSON.parse(data)
        if (message.height) {
          this.setHeight(message.height)
        }
      } catch (e) {}
    },

    setHeight(h) {
      const iframe = this.$el.querySelector('iframe')
      iframe.style.height = `${parseInt(h) + 50}px`
      iframe.style.overflowY = 'hidden'
    }
  },

  render(h) {
    const { isCXApp } = appStore.state.app
    const tab = isCXApp && this.name === 'zhuyun_page.padbookbr' && (
      <page-header breadcrumb={['阅读本借还统计']}>
        <inner-tabs />
      </page-header>
    )

    const style = isCXApp &&
      this.name === 'zhuyun_page.padbookbr' && {
        marginTop: '-58px'
      }

    const iframe = h('iframe', {
      attrs: {
        src: this.url,
        ref: 'iframe',
        frameborder: 'no'
      },
      style: {
        width: '100%',
        height: 'calc(100vh - 64px - 60px - 20px)',
        ...style
      }
    })
    return h('div', [tab, iframe])
  }
})
