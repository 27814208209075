<template>
  <page-layout>
    <page-header :breadcrumb="['实时推送']" />
    <page-main>
      <page-action-bar>
        <el-button type="primary" size="small" @click="onAddClick">
          新增推送
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items">
        <el-table-column type="index" width="50" :index="indexMethod" />

        <el-table-column label="时间">
          <template slot-scope="{ row }">
            {{ row.created_at | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="summary" show-overflow-tooltip />
        <el-table-column label="图书数" prop="book_count" align="right" />
        <el-table-column width="50px" />
        <el-table-column label="操作" width="180px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="onEditClick(row)">
              查看配置
            </el-button>
            <el-button type="text" @click="onPushClick(row)">
              再次推送
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <preset-book-send-add />
    <preset-book-send-edit />
  </page-layout>
</template>

<script lang="ts">
import store from './preset-book-send.store'
import PresetBookSendAdd from './preset-book-send-add.component.vue'
import PresetBookSendEdit from './preset-book-send-edit.component.vue'

export default {
  components: {
    PresetBookSendAdd,
    PresetBookSendEdit
  },

  data() {
    return {
      list: store.list
    }
  },

  mounted() {
    store.fetch(true)
  },

  methods: {
    async handleCurrentChange(index) {
      this.list.index = index
      await store.fetch()
    },
    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },

    onAddClick() {
      store.onAdd()
    },
    onEditClick(item) {
      store.onEdit(item)
    },

    onPushClick(item) {
      store.onPush(item)
    }
  }
}
</script>
