<template>
  <div class="app-nav" :class="{ collapsed: collapseState }">
    <div class="logo">
      <img src="~@/admin-shared-modules/theme/image/logo.png" alt="" />
      <span v-show="!collapseState">{{ ui.appName || '超星阅读本' }}</span>
    </div>
    <el-menu
      :router="true"
      :default-active="$route.name"
      :unique-opened="true"
      :collapse="collapseState"
      :collapse-transition="false"
      background-color="#001529"
      text-color="#cccccc"
      active-text-color="#ffffff"
    >
      <template v-if="!isCXApp">
        <el-submenu index="group-dashboard">
          <template slot="title">
            <i class="el-icon-odometer" />
            <span>统计报表</span>
          </template>

          <template v-if="lib.hasDeviceReader">
            <el-menu-item
              :route="{ name: 'dashboardReader' }"
              index="dashboardReader"
            >
              综合分析
            </el-menu-item>
          </template>

          <template v-if="lib.lendOutWithShelf || lib.lendOutWithManual">
            <el-menu-item
              :route="{ name: 'zcloudPadbookBR' }"
              index="zcloudPadbookBR"
            >
              阅读本借还统计
            </el-menu-item>
          </template>

          <el-menu-item :route="{ name: 'bookSendTops' }" index="bookSendTops">
            图书借阅统计
          </el-menu-item>

          <el-menu-item
            :route="{ name: 'readingReport' }"
            index="readingReport"
          >
            读者阅读统计
          </el-menu-item>
          <el-menu-item :route="{ name: 'user' }" index="user">
            读者行为分析
          </el-menu-item>
          <el-menu-item :route="{ name: 'userReadTop' }" index="userReadTop">
            读者排行
          </el-menu-item>

          <template v-if="lib.hasDeviceKindle">
            <el-menu-item
              :route="{ name: 'dashboardKindle' }"
              index="dashboardKindle"
            >
              Kindle阅读本报表
            </el-menu-item>
          </template>
          <template v-if="lib.hasDeviceKindle && lib.data.show_org_statistic">
            <el-menu-item
              :route="{ name: 'dashboardKindleReading' }"
              index="dashboardKindleReading"
            >
              Kindle阅读报表
            </el-menu-item>
          </template>

          <el-menu-item
            :route="{ name: 'dashboardResReport' }"
            index="dashboardResReport"
          >
            资源统计
          </el-menu-item>

          <el-menu-item
            :route="{ name: 'dashboardBookRelease' }"
            index="dashboardBookRelease"
          >
            图书更新统计
          </el-menu-item>
        </el-submenu>

        <el-submenu index="group-book">
          <template slot="title">
            <i class="el-icon-collection" />
            <span>图书资源管理</span>
          </template>
          <el-menu-item :route="{ name: 'tag' }" index="tag">
            图书分类
          </el-menu-item>
          <el-menu-item :route="{ name: 'book' }" index="book">
            图书管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'topic' }" index="topic">
            书单管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'courseToEpub' }" index="courseToEpub">
            云舟专题导入
          </el-menu-item>
        </el-submenu>

        <el-submenu
          index="group-deposit"
          v-if="lib.hasDeviceReader && lib.lendOutWithShelf"
        >
          <template slot="title">
            <i class="el-icon-bank-card" />
            <span>财务管理</span>
          </template>
          <el-menu-item
            :route="{ name: 'zcloudDepositReport' }"
            index="zcloudDepositReport"
          >
            押金统计
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudDeposit' }"
            index="zcloudDeposit"
          >
            财务对账
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudOverdueOrder' }"
            index="zcloudOverdueOrder"
          >
            违约管理
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudOverdueOrderDeposit' }"
            index="zcloudOverdueOrderDeposit"
          >
            滞纳金管理
          </el-menu-item>
        </el-submenu>

        <el-submenu index="group-custom" v-if="lib.hasDeviceReader">
          <template slot="title">
            <i class="el-icon-star-off" />
            <span>书城定制</span>
          </template>
          <el-menu-item
            :route="{ name: 'customHomeSection' }"
            index="customHomeSection"
          >
            定制书城首页
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'magazineRecommend' }"
            index="magazineRecommend"
          >
            期刊推荐
          </el-menu-item>
        </el-submenu>

        <el-submenu index="group-device-custom" v-if="lib.deviceAppDashboard">
          <template slot="title">
            <i class="el-icon-set-up"></i>
            <span>首页管理</span>
          </template>
          <el-menu-item
            :route="{ name: 'deviceCustomIntro' }"
            index="deviceCustomIntro"
          >
            首页配置
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'deviceCustomIntroApps' }"
            index="deviceCustomIntroApps"
          >
            其他应用管理
          </el-menu-item>
        </el-submenu>

        <el-submenu index="group-device-custom-ui" v-if="lib.deviceCustomUI">
          <template slot="title">
            <i class="el-icon-set-up"></i>
            <span>开关机界面配置</span>
          </template>
          <!-- <el-menu-item
            :route="{ name: 'deviceCustomSetupImage' }"
            index="deviceCustomSetupImage"
          >
            开机界面配置
          </el-menu-item> -->
          <el-menu-item
            :route="{ name: 'deviceCustomGuideImage' }"
            index="deviceCustomGuideImage"
          >
            开机界面配置
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'deviceCustomShutdownImage' }"
            index="deviceCustomShutdownImage"
          >
            关机界面配置
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'deviceCustomLockImage' }"
            index="deviceCustomLockImage"
          >
            锁屏界面配置
          </el-menu-item>
        </el-submenu>

        <el-submenu
          index="group-device"
          v-if="
            lib.hasDeviceReader &&
            (lib.lendOutWithShelf || lib.lendOutWithManual)
          "
        >
          <template slot="title">
            <i class="el-icon-mobile-phone" />
            <span>阅读本借阅</span>
          </template>

          <template v-if="lib.lendOutWithShelf && lib.lendOutWithManual">
            <el-menu-item
              :route="{ name: 'zcloudInventory' }"
              index="zcloudInventory"
            >
              阅读本盘点
            </el-menu-item>
            <el-menu-item
              v-if="lib.data.is_show_borrow"
              :route="{ name: 'zcloudBorrower' }"
              index="zcloudBorrower"
            >
              借阅名单管理
            </el-menu-item>
            <el-menu-item :route="{ name: 'borrowing' }" index="borrowing">
              手动借阅
            </el-menu-item>
            <!--
            <el-menu-item :route="{ name: 'zcloudError' }" index="zcloudError">
              异常事件show_lend_info
            </el-menu-item>
            -->
            <el-menu-item
              :route="{ name: 'zcloudBlockAccount' }"
              index="zcloudBlockAccount"
            >
              黑名单管理
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'zcloudDefineDamage' }"
              index="zcloudDefineDamage"
            >
              人工定损
            </el-menu-item>
          </template>

          <template v-else-if="lib.lendOutWithShelf">
            <el-menu-item
              :route="{ name: 'zcloudInventory' }"
              index="zcloudInventory"
            >
              阅读本盘点
            </el-menu-item>
            <el-menu-item
              v-if="lib.data.is_show_borrow"
              :route="{ name: 'zcloudBorrower' }"
              index="zcloudBorrower"
            >
              借阅名单管理
            </el-menu-item>
            <el-menu-item :route="{ name: 'zcloudError' }" index="zcloudError">
              异常事件
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'zcloudBlockAccount' }"
              index="zcloudBlockAccount"
            >
              黑名单管理
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'zcloudDefineDamage' }"
              index="zcloudDefineDamage"
            >
              人工定损
            </el-menu-item>
          </template>

          <template v-else-if="lib.lendOutWithManual">
            <el-menu-item :route="{ name: 'borrowing' }" index="borrowing">
              手动借阅
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'zcloudPadbookBR' }"
              index="zcloudPadbookBR"
            >
              借还统计
            </el-menu-item>
            <!--            <el-menu-item :route="{ name: 'device' }" index="device">-->
            <!--              库存管理-->
            <!--            </el-menu-item>-->
          </template>
        </el-submenu>

        <el-submenu index="group-push" v-if="lib.hasDeviceReader">
          <template slot="title">
            <i class="el-icon-news" />
            <span>图书推送分发</span>
          </template>
          <el-menu-item :route="{ name: 'presetBook' }" index="presetBook">
            预置图书
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'presetBookSend' }"
            index="presetBookSend"
          >
            实时推送
          </el-menu-item>
        </el-submenu>

        <el-submenu
          index="group-credit"
          v-if="lib.data && lib.data.open_credit_book"
        >
          <template slot="title">
            <i class="el-icon-medal-1" />
            阅读学分
          </template>
          <el-submenu index="group-credit-student">
            <template slot="title"> 学生报名 </template>
            <el-menu-item
              :route="{ name: 'creditRegister' }"
              index="creditRegister"
            >
              报名审核
            </el-menu-item>
          </el-submenu>
          <el-submenu index="group-credit-res">
            <template slot="title"> 资源管理 </template>
            <el-menu-item
              :route="{ name: 'creditBookPro' }"
              index="creditBookPro"
            >
              专业图书管理
            </el-menu-item>
            <el-menu-item :route="{ name: 'creditBook' }" index="creditBook">
              通识书管理
            </el-menu-item>
            <el-menu-item :route="{ name: 'creditTopic' }" index="creditTopic">
              图书推荐
            </el-menu-item>
          </el-submenu>
          <el-submenu index="group-credit-note-review">
            <template slot="title"> 读书笔记评定 </template>
            <el-menu-item
              :route="{ name: 'creditNoteReview' }"
              index="creditNoteReview"
            >
              笔记评分
            </el-menu-item>
          </el-submenu>
          <el-submenu index="group-credit-point-review">
            <template slot="title"> 学分认定 </template>
            <el-menu-item
              :route="{ name: 'creditPointReview' }"
              index="creditPointReview"
            >
              学分评定
            </el-menu-item>
          </el-submenu>
          <el-submenu index="group-credit-report">
            <template slot="title"> 数据分析 </template>
            <el-menu-item
              :route="{ name: 'creditReport' }"
              index="creditReport"
            >
              开展情况
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'creditDailyTask' }"
              index="creditDailyTask"
            >
              学习轨迹
            </el-menu-item>
          </el-submenu>
          <el-submenu index="group-credit-setting">
            <template slot="title"> 设置 </template>
            <el-menu-item :route="{ name: 'creditIntro' }" index="creditIntro">
              学分介绍
            </el-menu-item>
            <el-menu-item :route="{ name: 'creditRules' }" index="creditRules">
              规则设置
            </el-menu-item>
            <el-menu-item
              :route="{ name: 'creditSetting' }"
              index="creditSetting"
            >
              阅读测评设置
            </el-menu-item>
          </el-submenu>
        </el-submenu>

        <el-submenu index="group-setting">
          <template slot="title">
            <i class="el-icon-setting" />
            <span>设置</span>
          </template>
          <el-menu-item :route="{ name: 'account' }" index="account">
            后台管理员设置
          </el-menu-item>
          <el-menu-item :route="{ name: 'lib' }" index="lib">
            机构信息
          </el-menu-item>
          <template v-if="lib.hasDeviceReader">
            <el-menu-item :route="{ name: 'device' }" index="device">
              设备查询
            </el-menu-item>
          </template>
          <template v-if="lib.hasDeviceKindle">
            <el-menu-item
              :route="{ name: 'deviceKindle' }"
              index="deviceKindle"
            >
              Kindle设备管理
            </el-menu-item>
          </template>
          <template v-if="lib.lendOutWithShelf">
            <el-menu-item
              :route="{ name: 'zcloudAdPublish' }"
              index="zcloudAdPublish"
            >
              借还柜信息发布
            </el-menu-item>
          </template>
          <!--
          <template v-if="lib.lendOutWithShelf">
            <el-menu-item :route="{ name: 'zcloudSiteSetting' }" index="zcloudSiteSetting">
              自助借还设置
            </el-menu-item>
          </template>
          -->
          <!--          <template v-if="lib.hasDeviceReader">-->
          <!--            <el-menu-item :route="{ name: 'appAlertMessage' }" index="appAlertMessage">-->
          <!--              移动端信息发布-->
          <!--            </el-menu-item>-->
          <!--          </template>-->
        </el-submenu>
      </template>

      <!-- CXApp -->
      <template v-if="isCXApp && !isCXCreditApp">
        <el-submenu index="group-book">
          <template slot="title">
            <i class="el-icon-collection" />
            <span>图书资源管理</span>
          </template>
          <el-menu-item :route="{ name: 'tag' }" index="tag">
            本馆图书分类
          </el-menu-item>
          <el-menu-item :route="{ name: 'book' }" index="book">
            本馆图书管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'topic' }" index="topic">
            书单管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'courseToEpub' }" index="courseToEpub">
            云舟专题导入
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-custom">
          <template slot="title">
            <i class="el-icon-star-off" />
            <span>书城定制</span>
          </template>
          <el-menu-item
            :route="{ name: 'customHomeSection' }"
            index="customHomeSection"
          >
            定制书城首页
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'magazineRecommend' }"
            index="magazineRecommend"
          >
            期刊推荐
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-setting">
          <template slot="title">
            <i class="el-icon-setting" />
            <span>阅读本借阅</span>
          </template>
          <el-menu-item
            :route="{ name: 'zcloudInventory' }"
            index="zcloudInventory"
          >
            阅读本盘点
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudBlockAccount' }"
            index="zcloudBlockAccount"
          >
            黑名单管理
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudDefineDamage' }"
            index="zcloudDefineDamage"
          >
            人工定损
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudAdPublish' }"
            index="zcloudAdPublish"
          >
            借还柜信息发布
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-deposit">
          <template slot="title">
            <i class="el-icon-bank-card" />
            <span>财务管理</span>
          </template>
          <el-menu-item
            :route="{ name: 'zcloudDepositReport' }"
            index="zcloudDepositReport"
          >
            押金统计
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudDeposit' }"
            index="zcloudDeposit"
          >
            财务对账
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudOverdueOrder' }"
            index="zcloudOverdueOrder"
          >
            违约管理
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'zcloudOverdueOrderDeposit' }"
            index="zcloudOverdueOrderDeposit"
          >
            滞纳金管理
          </el-menu-item>
        </el-submenu>
      </template>

      <!-- CXApp & Credit -->
      <template v-if="isCXApp && isCXCreditApp">
        <el-submenu index="group-credit-student">
          <template slot="title">
            <i class="el-icon-receiving" />
            学生报名
          </template>
          <el-menu-item
            :route="{ name: 'creditRegister' }"
            index="creditRegister"
          >
            报名审核
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-credit-res">
          <template slot="title">
            <i class="el-icon-notebook-1" />
            资源管理
          </template>
          <el-menu-item
            :route="{ name: 'creditBookPro' }"
            index="creditBookPro"
          >
            专业图书管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'creditBook' }" index="creditBook">
            通识书管理
          </el-menu-item>
          <el-menu-item :route="{ name: 'creditTopic' }" index="creditTopic">
            图书推荐
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-credit-note-review">
          <template slot="title">
            <i class="el-icon-edit-outline" />
            读书笔记评定
          </template>
          <el-menu-item
            :route="{ name: 'creditNoteReview' }"
            index="creditNoteReview"
          >
            笔记评分
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-credit-point-review">
          <template slot="title">
            <i class="el-icon-medal-1" />
            学分认定
          </template>
          <el-menu-item
            :route="{ name: 'creditPointReview' }"
            index="creditPointReview"
          >
            学分评定
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-credit-report">
          <template slot="title">
            <i class="el-icon-pie-chart" />
            数据分析
          </template>
          <el-menu-item :route="{ name: 'creditReport' }" index="creditReport">
            开展情况
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'creditDailyTask' }"
            index="creditDailyTask"
          >
            学习轨迹
          </el-menu-item>
        </el-submenu>
        <el-submenu index="group-credit-setting">
          <template slot="title">
            <i class="el-icon-setting" />
            设置
          </template>
          <el-menu-item :route="{ name: 'creditIntro' }" index="creditIntro">
            学分介绍
          </el-menu-item>
          <el-menu-item :route="{ name: 'creditRules' }" index="creditRules">
            规则设置
          </el-menu-item>
          <el-menu-item
            :route="{ name: 'creditSetting' }"
            index="creditSetting"
          >
            阅读测评设置
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script lang="ts">
import { AppStateMixin } from '@/core/appStateMixin'
import { appStore } from '@/store'

export default {
  mixins: [AppStateMixin],

  computed: {
    collapseState: {
      get() {
        return !appStore.state.ui.openNav
      },
      set(val) {
        appStore.state.ui.openNav = !val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-nav {
  flex: 0 0 240px;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #001529;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  z-index: 5;
  &.collapsed {
    flex: 0 0 64px;
  }
}
.logo {
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 14px;
  line-height: 64px;
  background: #002140;
  color: #fff;
  font-size: 18px;
  img {
    margin-right: 8px;
    width: 38px;
  }
}

::v-deep .el-menu {
  border-right: 0;
  .el-menu--inline > .el-menu-item {
    background-color: #000 !important;
  }
  .el-menu-item.is-active {
    background-color: #409eff !important;
  }
  .el-submenu.is-opened .el-submenu__title,
  .el-submenu.is-active .el-submenu__title {
    color: #fff !important;
    i {
      color: #fff !important;
    }
  }
}
</style>
