import { GET } from '@/admin-shared-modules/utils/ajax'

class Store {
  state = {
    visible: true,
    list: {
      items: [],
      index: 1,
      size: 10,
      total: 0,
      loading: false
    },
    search: {
      data: {
        scope: 'all' as 'all' | 'public' | 'private',
        title_like: ''
      }
    }
  }

  async fetch(reset = false) {
    if (reset) this.state.list.index = 1
    this.state.list.loading = true
    this.state.list.items = []
    const search: any = { ...this.state.search.data }
    if (this.state.search.data.scope === 'public') {
      search.public = true
    }
    if (this.state.search.data.scope === 'private') {
      search.private = true
    }

    try {
      const res = await GET('projects', {
        data: {
          pageIndex: this.state.list.index - 1,
          pageSize: this.state.list.size,
          ...search
        }
      })
      this.state.list.items = res.data.items
      this.state.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.state.list.loading = false
    }
  }
}

export default new Store()
