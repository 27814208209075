<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    append-to-body
    width="400px"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="150px"
      style="width: 400px"
      @submit="onSubmit"
    >
      <el-form-item label="分类名称" prop="name">
        <el-input v-model.trim="edit.data.name" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        name: [
          { required: true, message: '请填写' },
          { min: 2, message: '长度需大于 2' }
        ]
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        store.edit.onEditSubmit()
      })
    }
  }
}
</script>
