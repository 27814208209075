import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'
import { onMounted, ref } from 'vue'

export type Data = {
  name: string
  sections: Section[]
}

export type Section = BannerSection | AppSection

export type BannerSection = {
  type: 'banner'
  images: string[]
  duration: number
}

export type AppSection = {
  type: 'apps'
  name: string
  columns: number
  data: App[]
}

export type App = {
  type: 'libApp' | 'libResource' | 'apk' | 'web'
  libResourceKey?: string
  libAppKey?: number

  name: string
  alias?: string
  icon?: string
  url?: string
  note?: string
  zoom?: number
  showControl?: boolean
}

function createData(): Data {
  return {
    name: '',
    sections: [
      {
        type: 'banner',
        images: ['//placehold.it/200x200', '//placehold.it/200x200'],
        duration: 10
      }
      //   {
      //     type: 'apps',
      //     name: '1',
      //     columns: 2,
      //     data: [
      //       {
      //         type: 'buildIn',
      //         name: '书城',
      //         icon: '//placehold.it/200x200'
      //       },
      //       {
      //         type: 'web',
      //         name: 'web',
      //         icon: '//placehold.it/200x200',
      //         url: 'https://www.baidu.com'
      //       },
      //       {
      //         type: 'apk',
      //         icon: '//placehold.it/200x200',
      //         name: 'apk',
      //         url: 'https://www.baidu.com'
      //       }
      //     ]
      //   },
      //   {
      //     type: 'apps',
      //     name: '阅读评价',
      //     columns: 1,
      //     data: [
      //       {
      //         type: 'web',
      //         icon: '//placehold.it/200x200',
      //         name: '2',
      //         url: 'https://www.baidu.com'
      //       },
      //       {
      //         type: 'web',
      //         icon: '//placehold.it/200x200',
      //         name: '2',
      //         url: 'https://www.baidu.com'
      //       },
      //       {
      //         type: 'web',
      //         icon: '//placehold.it/200x200',
      //         name: '2',
      //         url: 'https://www.baidu.com'
      //       }
      //     ]
      //   },
      //   {
      //     type: 'apps',
      //     name: '3',
      //     columns: 1,
      //     data: []
      //   },
      //   {
      //     type: 'apps',
      //     name: '4',
      //     columns: 2,
      //     data: []
      //   }
    ]
  }
}

export const useStore = () => {
  const data = ref<Data>(createData())
  const loading = ref(true)

  async function fetch() {
    const res = await GET('device/config/home', {})

    if (_.has(res.data.config, 'sections')) {
      data.value = res.data.config
    }

    loading.value = false
  }

  onMounted(fetch)

  async function submit(data) {
    await POST('device/config/home', {
      data: {
        config: data
      }
    })
  }

  return {
    loading,
    data,
    submit
  }
}
