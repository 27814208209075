<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="'编辑'"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-form
      v-if="edit.visible"
      :model="edit.data"
      ref="form"
      label-width="80px"
    >
      <el-form-item
        label="标题"
        prop="name"
        :rules="{ required: true, message: '请填写' }"
      >
        <el-input v-model="edit.data.name" />
        <div class="help">
          <template v-if="edit.data.config.type === 'project'">
            {{ edit.data.is_private ? '本馆' : '公共' }}书单:
            {{ edit.data.original_name || edit.data.name }}
          </template>
          <template v-else-if="edit.data.config.type === 'tag'">
            {{ edit.data.is_private ? '本馆' : '公共' }}分类:
            {{ edit.data.original_name || edit.data.name }}
          </template>
          <template v-else-if="edit.data.config.group">
            推荐: <b>{{ edit.data.config.name }}</b>
          </template>
          <template v-else>
            {{ edit.data.config.desc }}
          </template>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from './home-section.store'
export default {
  data() {
    return {
      edit: store.edit
    }
  },

  methods: {
    onSubmit() {
      store.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss" src="./home-section.style.scss"></style>
