import _ from 'lodash'
import { Tag } from '../../admin-shared-modules/typings'
import { GET } from '../../admin-shared-modules/utils/ajax'
import { listToTree } from '../../admin-shared-modules/utils/tree'

class BookTagStore {
  state = {
    flattenTags: [] as Tag[],
    items: [] as Tag[],
    loading: false
  }

  async fetch() {
    if (this.state.items.length) return
    this.state.loading = true

    try {
      const res = await GET(`tag`, {})
      this.state.flattenTags = res.data.items
      this.state.items = listToTree(
        this.state.flattenTags as any,
        true
      ) as Tag[]
    } catch (e) {
      console.error(e)
    } finally {
      this.state.loading = false
    }
  }

  getTagById(id: number) {
    return _.clone(_.find(this.state.flattenTags, { id }))
  }
}

export default new BookTagStore()
