import _ from 'lodash'
import {
  Book,
  BookBindTag,
  EditState,
  ListState,
  SearchState
} from '@/admin-shared-modules/typings'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { getFullPathOfTag, parseTagsToGroup } from '@/components/book/utils'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import bookTagStore from '../../components/book/book-tag.store'
import { Tag } from '@/admin-shared-modules/typings'
import { listToTree } from '@/admin-shared-modules/utils/tree'

export interface BookFormData extends Book {
  authorStr?: string
  tagsGroup?: any[]
  file_size?: number
}

export interface TopicTransformRecord {
  id: number
  course_id: string
  title: string
  created_at: string
  status: 'READY' | 'SUCCESS' | 'PENDING' | 'FAILED'
  is_release: boolean
  tags: BookBindTag[]
  property: any
}

type TopicSearchFromData = Partial<TopicTransformRecord> & {
  status: 1 | 0 | 'PENDING' | 'FAILED'
  tag: number[]
  un_bind: 0 | 1
}

class Store {
  list: ListState<TopicTransformRecord> = {
    items: [],
    index: 1,
    size: 50,
    total: 0,
    loading: true
  }
  search: SearchState<TopicSearchFromData> = {
    data: {
      course_id: null,
      title: null,
      tag: null,
      status: null,
      un_bind: null,
      property: '1'
    }
  }
  add = {
    data: '',
    property: 1,
    loading: false
  }
  edit: EditState<BookFormData> = {
    visible: false,
    isEdit: false,
    params: null as TopicTransformRecord,
    data: {} as BookFormData,
    loading: false
  }

  async fetchTag(type?: any) {
    try {
      const res = await GET(`lms_course/tag`, {
        data: {
          type: type
        }
      })
      bookTagStore.state.flattenTags = _.map(res.data, (item, index) => {
        return { ...item, index }
      })
      bookTagStore.state.items = listToTree(
        bookTagStore.state.flattenTags as any,
        true
      ) as Tag[]
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.list.loading = false
    }
  }

  async fetch(reset = false) {
    this.fetchTag(this.search.data.property)
    if (reset) this.list.index = 1
    this.list.loading = true
    try {
      const res = await GET('lms_course', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          ...this.search.data,
          tag: _.size(this.search.data.tag)
            ? _.last(this.search.data.tag)
            : null
        }
      })
      this.list.items = _.map<any, TopicTransformRecord>(
        res.data.items,
        item => {
          return {
            ...item,
            fullTags: _.map(item.tags, getFullPathOfTag)
          }
        }
      )
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  async onAdd(data: string[], property?) {
    LoadingService.create()
    try {
      await POST('lms_course/create', {
        data: {
          course_ids: data,
          property
        }
      })
      this.fetch(true)
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
      this.add.data = ''
    }
  }

  async onEdit(item) {
    this.edit.data = {} as BookFormData
    this.edit.params = item
    this.edit.loading = true
    this.edit.visible = true
    try {
      const res = await GET(`lms_course/${item.course_id}`, {})
      this.edit.data = this.parseItemToFormData(_.cloneDeep(res.data))
    } catch (e) {
      this.edit.visible = false
      defaultErrorHandler(e)
    } finally {
      this.edit.loading = false
    }
  }
  private parseItemToFormData(item: Book): BookFormData {
    return {
      ...item,
      image: _.get(item, 'images.large', item.image),
      authorStr: _.join(item.author, ', '),
      tagsGroup: parseTagsToGroup(item.tags)
    }
  }

  async onEditSubmit() {
    const requestMethod = PUT
    const data = _.cloneDeep(this.edit.data)
    const url = `lms_course/${this.edit.params.course_id}`

    data.author = _.split(data.authorStr, /,|，/)
    data.tags = _.reduce(
      data.tagsGroup,
      (result, tag) => {
        result.push({ id: _.last(tag.items) })
        return _.uniqBy(result, 'id')
      },
      []
    )
    data.is_release = (data.is_release ? 1 : 0) as any

    const partialFormData = _.omit(data, ['tagsGroup', 'authorStr', 'fullTags'])

    LoadingService.create('保存中...')
    try {
      //submit book
      const res = await requestMethod(url, { data: partialFormData })
      //submit book tags
      await POST('lms_course/tag', {
        data: {
          course_id: this.edit.params.course_id,
          tag_ids: _.map(data.tags, 'id')
        }
      })

      MessageService.open({ message: '保存成功' })
      this.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  async onRemove(data: TopicTransformRecord) {
    LoadingService.create()
    try {
      await DELETE(`lms_course/${data.course_id}`, {})
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }
}

export default new Store()
