import {
  EditState,
  ListState,
  SearchState,
  Topic
} from '@/admin-shared-modules/typings'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils/index'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'

export interface TopicSearchFromData {
  scope: 'all' | 'public' | 'private'
}

export type TopicFromData = Topic & {
  AddBySSID?: boolean
  ssids?: ''
}

class Store {
  list: ListState<Topic> = {
    items: [],
    index: 1,
    size: 50,
    total: 0,
    loading: true
  }
  search: SearchState<TopicSearchFromData> = {
    data: {
      scope: 'all'
    }
  }

  edit: EditState<TopicFromData> = {
    visible: false,
    isEdit: false,
    data: {} as TopicFromData,
    loading: false
  }

  detail = {
    visible: false,
    loading: false,
    // data: {} as Topic,
    items: {} as Topic
  }

  async fetch(reset = false) {
    const searchData = {}
    if (reset) this.list.index = 1
    if (this.search.data.scope == 'public') {
      searchData['public'] = 1
    } else if (this.search.data.scope == 'private') {
      searchData['private'] = 1
    }
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('projects', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: 100,
          ...searchData
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  onAdd(typeName?: string) {
    this.edit.data = {
      AddBySSID: typeName === 'AddBySSID',
      books: [],
      ssids: ''
    } as TopicFromData
    this.edit.isEdit = false
    this.edit.visible = true
  }
  async onShowDetail(item: Topic) {
    this.detail.loading = true
    const res = await GET(`projects/${item.id}/books`, {})
    this.detail.items = _.cloneDeep({
      ...item,
      books: res.data
    })
    this.detail.visible = true
    this.detail.loading = false
  }
  async onEdit(item: Topic) {
    this.edit.loading = true
    const res = await GET(`projects/${item.id}/books`, {})
    this.edit.data = _.cloneDeep({
      ...item,
      books: res.data
    })
    this.edit.isEdit = true
    this.edit.loading = false
    this.edit.visible = true
  }
  async onRemove(item: Topic) {
    try {
      await DELETE(`projects/${item.id}`, {})
      MessageService.open({ message: '删除成功' })
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    }
  }

  async onEditSubmit() {
    const data = _.cloneDeep(this.edit.data)
    data.books = _.reduce(
      data.books,
      (result, item) => {
        result.push(
          _.pick(item, ['book_id', 'section_title', 'section_summary'])
        )
        return result
      },
      []
    )

    //
    const addBySSID = data.AddBySSID
    if (data.AddBySSID) {
      const ssidArr = _.map(data.ssids?.split('\n'), _.trim).filter(Boolean)
      if (!ssidArr.length) {
        MessageService.open({ message: '请填写SSID' })
        return
      }
      data.AddBySSID = undefined
      data.ssids = ssidArr as any
    }

    this.edit.loading = true
    try {
      if (this.edit.isEdit) {
        const res = await PUT(`projects/${data.id}`, {
          data
        })
        const res2 = await POST(`projects/${data.id}/books`, {
          data: {
            books: data.books
          }
        })
      } else {
        const res = await POST(
          addBySSID ? 'projects/createBySsid' : `projects`,
          {
            data
          }
        )
        // await POST(`projects/${res.data.id}/books`, {
        //   data: {
        //     books: data.books
        //   }
        // })
      }

      this.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.edit.loading = false
    }
  }
  async onSortSubmit() {
    try {
      await POST(`projects/setAllProjectOrder`, {
        data: {
          sorted_ids: _.map(this.list.items, 'id')
        }
      })
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
}

export default new Store()
