<template>
  <el-dialog :visible.sync="detail.visible" title="个人信息" width="800px">
    <div v-loading="detail.loading">
      <user-banner :data="userInfo" />
    </div>

    <div class="panel" v-show="detail.data.BorrowingRecords">
      <div class="panel__header">
        <div class="title">最近借还</div>
        <el-button type="text" @click="onBorrowHistoryClick">
          更多借还记录 <i class="el-icon-arrow-right" />
        </el-button>
      </div>
      <div class="panel__body">
        <borrow-card
          v-if="detail.data.BorrowingRecords"
          :data="detail.data.BorrowingRecords"
          :adapt-for-detail-recode="true"
        />
      </div>
    </div>

    <div class="footer" style="margin-top: 32px">
      <el-button type="primary" size="small" @click="onUserReportClick">
        查看阅读报告
      </el-button>
    </div>

    <borrow-history-dialog ref="borrowHistoryDialog" />

    <user-reporting-dialog ref="userReportDialog" />
  </el-dialog>
</template>

<script lang="ts">
import UserBanner from './shared/user-banner.vue'
import BorrowCard from './shared/borrow-card.vue'
import BorrowHistoryDialog from './borrow-history/borrow-history.vue'
import UserReportingDialog from '@/views/user/user-reporting/user-reporting.component.vue'
import store from './borrower.store'

export default {
  components: {
    UserBanner,
    BorrowCard,
    BorrowHistoryDialog,
    UserReportingDialog
  },

  data() {
    return {
      detail: store.detail
    }
  },
  computed: {
    userInfo() {
      return {
        borrowerBrTimes: this.detail.data.borrowerBrTimes,
        borrowerExpireTimes: this.detail.data.borrowerExpireTimes,
        ...this.detail.data.borrowerDetails
      }
    }
  },

  methods: {
    onBorrowHistoryClick() {
      this.$refs.borrowHistoryDialog.open({
        ...this.detail.data,
        ...this.userInfo
      })
    },
    onUserReportClick() {
      this.$refs.userReportDialog.open(store.detail.params, {
        isBorrower: true
      })
    }
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
