import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { API_HOST } from '@/config'
import router from '@/router'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'

interface ajaxOptions {
  baseURL?: string
  version?: string
  headers?: object
  method?: string
  data?: any
  timeout?: number
  ignoreError?: boolean
  cancelToken?: any
  responseType?: AxiosRequestConfig['responseType']
}

export interface ServerResponseData {
  code: number
  data: any
  total?: number
  message: string
}

export interface AJAXErrorResult extends ServerResponseData {
  handled: boolean
}

export const ajax = (url: string, options: ajaxOptions) => {
  const axiosOptions: AxiosRequestConfig = {}
  if (options.cancelToken) {
    axiosOptions.cancelToken = options.cancelToken.token
  }

  axiosOptions.baseURL = options.baseURL || API_HOST
  axiosOptions.headers = {
    ...options.headers
  }
  axiosOptions.method = options.method || 'get'
  axiosOptions.timeout = options.timeout || 15 * 1000
  axiosOptions.responseType = options.responseType || 'json'

  if (options.data && _.includes(['get', 'delete'], options.method))
    axiosOptions.params = options.data
  else axiosOptions.data = options.data

  return new Promise<ServerResponseData>((resolve, reject) => {
    axios(url, axiosOptions)
      .then(async (res: AxiosResponse<ServerResponseData>) => {
        if (axiosOptions.responseType === 'blob') {
          resolve(res as any)
          return
        }
        resolve(res.data)
      })
      .catch(async (err: AxiosError) => {
        console.error(err)
        if (options.ignoreError) {
          reject({ ...err, handled: false })
          return
        }
        if (!err.response) {
          defaultErrorHandler()
          reject({ ...err, handled: true })
          return
        }

        if (err.response.status == 401) {
          reject({ handled: true })
          router.replace({ name: 'signIn' })
        } else if (err.response.status == 400) {
          reject({
            data: err.response.data,
            message: _.get(err.response.data, 'msg'),
            handled: false
          })
        } else {
          defaultErrorHandler()
          reject({ ...err, handled: true })
        }
      })
  })
}

const defaultErrorHandler = () => {
  MessageBoxService.alert({ type: 'error', message: '服务繁忙，请稍后重试' })
}

export const GET = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'get'
  })
export const PUT = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'put'
  })
export const POST = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'post'
  })
export const DELETE = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'delete'
  })
