<template>
  <div class="date-range-select">
    <span
      v-for="(item, index) in items"
      :key="index"
      class="item"
      :class="{ active: item.type === value }"
      @click="onClick(item)"
    >
      {{ item.label }}
    </span>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { DateRangeMap } from './consts'

export default {
  props: {
    value: { required: true, type: String }
  },
  data() {
    return {
      items: _.map(DateRangeMap, (label, type) => {
        return { label, type }
      })
    }
  },

  methods: {
    onClick(item) {
      this.$emit('input', item.type)
      this.$emit('change', item.type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/admin-shared-modules/theme/var';

.date-range-select {
  .item {
    padding: 0 10px;
    color: $--color-text-regular;
    border-right: 1px solid $--border-color-light;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
    &.active {
      color: #409eff;
    }
  }
}
</style>
