import _ from 'lodash'
import { GET, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { Book } from '@/admin-shared-modules/typings'
import { MessageBox } from 'element-ui'

interface UpdateFolder {
  type?: 'create' | 'edit' | 'delete' | 'add' | 'sort'
}

interface AddBook {
  from?: 'origin' | 'detail'
}

interface PresetBook {
  id: string | number
  name: string
  books: Book[]
  book_ids: number[]
  created_at?: string | Date
  updated_at?: string | Date
}

class Store {
  state = {
    isUsb: true,
    isWifi: true,
    loading: false
  }

  folder = {
    data: [] as PresetBook[]
  }

  books = {
    //根目录图书
    data: [] as Book[],
    book_ids: []
  }

  folderEdit = {
    //文件夹新建或编辑
    visible: false,
    data: {} as PresetBook,
    isEdit: false
  }

  detail = {
    current: {} as PresetBook
  }

  async fetch() {
    this.state.loading = true
    try {
      const res = await GET(`library_publish`, {})
      this.state.isUsb = res.data.shelf_push
      this.state.isWifi = res.data.wifi_push
      this.folder.data = res.data.folders
      this.books.data = res.data.books
    } catch (e) {
      console.log(e)
    } finally {
      this.state.loading = false
    }
  }

  async updateSetting() {
    try {
      const res = await PUT(`library_publish/setting`, {
        data: {
          wifi_push: this.state.isWifi, // 是否开启wifi传书
          shelf_push: this.state.isUsb
        }
      })
    } catch (e) {
      if (e.handled) return
      MessageService.open({ message: e.message, type: 'error' })
    }
  }

  setCureetFolder(folderId: string | number) {
    //console.log(folderId,this.folder.data)
    const data = _.find(this.folder.data, o => {
      return o.id == folderId
    })
    this.detail.current = data
    //console.log(this.detail.current)
    //console.log(data)
  }

  addFolder() {
    //console.log('addFolder')
    this.folderEdit.data = {} as PresetBook
    this.folderEdit.isEdit = false
    this.folderEdit.visible = true
  }

  editFolder(item: PresetBook) {
    //console.log(this.folder.data)
    this.folderEdit.data = _.cloneDeep(item)
    this.folderEdit.isEdit = true
    this.folderEdit.visible = true
    this.detail.current = _.cloneDeep(item)
  }

  async editFolderSubmit() {
    if (!this.folderEdit.isEdit) {
      //新增文件夹
      if (this.folder.data.length == 0) {
        this.folder.data.push(this.folderEdit.data)
        await this.updateFolders({ type: 'create' })
        this.folderEdit.visible = false
      } else {
        let isRepetition = false
        _.forEach(this.folder.data, item => {
          if (item.name == this.folderEdit.data.name) {
            isRepetition = true
          }
        })
        if (isRepetition) {
          MessageService.open({
            message: `与已有文件夹名重复,请重新填写`,
            type: 'error'
          })
        } else {
          this.folder.data.push(this.folderEdit.data)
          await this.updateFolders({ type: 'create' })
          this.folderEdit.visible = false
        }
      }

      //this.folder.data.push(this.folderEdit.data)
      //await this.updateFolders({ type: 'create' })
    } else {
      _.map(this.folder.data, item => {
        if (item.id == this.folderEdit.data.id) {
          item.name = this.folderEdit.data.name
        }
      })
      await this.updateFolders({ type: 'edit' })
      this.folderEdit.visible = false
    }
    //this.folderEdit.visible = false
  }

  async removeFolder(item: PresetBook) {
    //删除文件夹
    this.folderEdit.data = item
    _.remove(this.folder.data, o => {
      return o.id == item.id
    })
    //console.log(this.folder.data)
    await this.updateFolders({ type: 'delete' })
  }

  //#region book

  async addBook(bookIds: number[], fromType: AddBook) {
    //添加图书
    let uncommonBook = [],
      commonBook = [],
      newBook = [],
      selectBookIds = _.clone(bookIds)
    if (fromType.from == 'detail') {
      console.log(this.detail.current.books)
      const currentBookIds = _.map(this.detail.current.books, 'id')
      ;(uncommonBook = _.difference(currentBookIds, selectBookIds)),
        (commonBook = _.difference(currentBookIds, uncommonBook)),
        (newBook = _.difference(selectBookIds, commonBook))
      this.detail.current.book_ids = _.clone(bookIds)
      //console.log(currentBookIds,selectBookIds,uncommonBook,commonBook,newBook)
    } else if (fromType.from == 'origin') {
      const originBookIds = _.map(this.books.data, 'id')
      ;(uncommonBook = _.difference(originBookIds, selectBookIds)),
        (commonBook = _.difference(originBookIds, uncommonBook)),
        (newBook = _.difference(selectBookIds, commonBook))
      this.books.book_ids = bookIds
    }

    await this.updateFolders({ type: 'add' })

    if (newBook.length == 0) {
      MessageBox.alert(
        `您选择的${selectBookIds.length}本图书已在此文件夹中`,
        '提示',
        {
          type: 'warning',
          confirmButtonText: '知道了',
          callback: () => {}
        }
      )
      // MessageService.open({
      //   message: `您选择的${selectBookIds.length}本图书已在此文件夹中`,
      //   type: 'warning'
      // });
      return
    }
    if (commonBook.length > 0) {
      //有重复的图书
      MessageBox.alert(
        `本次添加新图书${newBook.length}本 ，有${commonBook.length}本已在此文件夹中`,
        '添加成功',
        {
          confirmButtonText: '知道了'
        }
      )
    } else {
      MessageService.open({
        message: `添加成功，本次添加新图书${newBook.length}本 `
      })
    }
    if (fromType.from == 'origin') {
      return
    }
    this.setCureetFolder(this.detail.current.id)
  }

  async removeBook(item: PresetBook, fromType: AddBook) {
    if (fromType.from == 'detail') {
      console.log(this.detail.current.id)
      const currentFolder = _.find(this.folder.data, o => {
        return o.id === this.detail.current.id
      })
      _.remove(currentFolder.books, o => {
        return o.id == item.id
      })
    } else if (fromType.from == 'origin') {
      _.remove(this.books.data, o => {
        return o.id == item.id
      })
    }

    await this.updateFolders({ type: 'delete' })
    this.setCureetFolder(this.detail.current.id)
  }

  async setSorting() {
    //保存排序
    //console.log(this.folder.data)
    _.map(this.folder.data, item => {
      if (this.detail.current.id == item.id) {
        item.books = this.detail.current.books
      }
      return item
    })
    await this.updateFolders({ type: 'sort' })
  }

  //#endregion

  async updateFolders(typeConfig: UpdateFolder) {
    const foldersData = this.folder.data
    const addData = _.reduce(
      foldersData,
      (result, item) => {
        if (!!item.id == false) {
          result.push({
            name: item.name,
            book_ids: []
          })
        }
        return result
      },
      []
    )
    const editData = _.reduce(
      foldersData,
      (result, item) => {
        if (item.id) {
          let bookIds: number[] = []
          if (item.book_ids && typeConfig.type != 'delete')
            bookIds = _.union(_.map(item.books, 'id'), item.book_ids)
          else bookIds = _.map(item.books, 'id')
          result.push({
            name: item.name,
            book_ids: bookIds,
            id: item.id,
            updated_at: item.updated_at,
            created_at: item.created_at
          })
        }
        return result
      },
      []
    )
    let folders = editData,
      message = '',
      books = _.map(this.books.data, 'id')
    if (!!typeConfig.type && typeConfig.type == 'create') {
      message = '文件夹创建成功'
      folders = [...addData, ...editData]
    } else if (typeConfig.type == 'edit') {
      message = '文件夹名已更新成功'
    } else if (typeConfig.type == 'delete') {
      message = '删除成功'
    } else if (typeConfig.type == 'add') {
      books = _.union(books, this.books.book_ids)
    }
    try {
      const res = await PUT(`library_publish/folders`, {
        data: {
          folders: folders,
          books: books
        }
      })
      //this.folder.data = res.data.folders
      await this.fetch()
      //this.setCureetFolder(this.detail.current.id)
      if (typeConfig.type == 'add' || typeConfig.type == 'sort') {
        return
      }
      MessageService.open({ message: message })
    } catch (e) {
      await this.fetch()
      if (e.handled) return
      MessageService.open({ message: e.message, type: 'error' })
    }
  }
}

export default new Store()
