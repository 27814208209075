<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="书名">
      <el-input v-model="search.data.title" clearable />
    </el-form-item>
    <el-form-item label="ISBN">
      <el-input v-model="search.data.isbn" clearable />
    </el-form-item>
    <el-form-item label="图书类型">
      <el-select v-model="search.data.is_professional" clearable>
        <el-option label="通识" :value="0" />
        <el-option label="专业" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item label="评测时间">
      <el-date-picker
        v-model="search.data.dateRange"
        type="daterange"
        size="small"
        clearable
        style="width: 260px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { store } from './store'

export default {
  components: {},

  data() {
    return {
      store,
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
