<template>
  <el-dialog
    :visible.sync="store.visible"
    :title="`《${list.book.title}》 试题管理`"
    width="80vw"
    top="4vh"
    :close-on-click-modal="false"
  >
    <page-action-bar style="margin-top: -10px">
      <el-radio-group
        v-model="search.data.quest_type"
        size="small"
        @change="() => search.onSubmit()"
      >
        <el-radio-button :label="''">
          全部 ({{ list.summary.count }})
        </el-radio-button>
        <el-radio-button :label="0">
          单选题 ({{ list.summary.type_0_count || 0 }})
        </el-radio-button>
        <el-radio-button :label="1">
          多选题 ({{ list.summary.type_1_count || 0 }})
        </el-radio-button>
        <el-radio-button :label="2">
          判断题 ({{ list.summary.type_2_count || 0 }})
        </el-radio-button>
      </el-radio-group>
      <template slot="right">
        <el-input
          v-model="search.data.quest_content_like"
          size="small"
          placeholder="输入题干搜索"
          clearable
          style="width: 200px; margin-right: 12px"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="() => search.onSubmit()"
          />
        </el-input>
        <el-button type="primary" size="small"> 试题导入 </el-button>
        <el-button
          size="small"
          :disabled="!selectedItems.length"
          @click="onRemoveClick()"
        >
          批量删除
        </el-button>
      </template>
    </page-action-bar>

    <el-alert
      v-if="selectedItems.length"
      type="success"
      :title="`已选中 ${selectedItems.length} 条记录`"
      close-text="清空选择"
      @close="cleanSelectedItems"
    />

    <el-table
      ref="table"
      :data="list.items"
      size="small"
      v-loading="list.loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        type="index"
        width="50"
        :index="i => list.indexMethod(i)"
      />
      <el-table-column label="题型" prop="quest_type" width="100px">
        <template slot-scope="{ row }">
          {{ QuestType[row.quest_type] }}
        </template>
      </el-table-column>
      <el-table-column
        label="题干"
        prop="quest_content"
        show-overflow-tooltip
      />
      <el-table-column label="导入时间" prop="created_at" width="100px">
        <template slot-scope="{ row }">
          {{ row.created_at | date }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="onEditClick(row)"> 预览 </el-button>
          <el-button type="text" @click="onRemoveClick(row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <edit />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { QuestType, store } from './store'
import Edit from './edit.vue'

export default {
  components: {
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search,
      edit: store.edit,
      QuestType,
      selectedItems: []
    }
  },

  methods: {
    onEditClick(item) {
      this.edit.onEdit(item)
    },
    async onRemoveClick(item?) {
      await this.edit.onRemove(item ? [item] : this.selectedItems)
      this.selectedItems = []
    },

    handleSelectionChange(items) {
      const unSelectedItems = _.differenceBy(this.list.items, items, 'id')
      this.selectedItems = _.chain(this.selectedItems)
        .unionBy(items, 'id')
        .differenceBy(unSelectedItems, 'id')
        .value()
    },
    setTableCurrentSelection() {
      const selectedRow = _.intersectionBy(
        this.list.items,
        this.selectedItems,
        'id'
      )
      _.forEach(selectedRow, row =>
        this.$refs.table.toggleRowSelection(row, true)
      )
    },
    cleanSelectedItems() {
      this.selectedItems = []
      this.$refs.table.clearSelection()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
