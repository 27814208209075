<template>
  <div ref="chart" style="height: 160px" />
</template>

<script>
import G2 from '@/admin-shared-modules/utils/g2'
import numeral from 'numeral'

export default {
  props: {
    data: { type: Array, default: () => [] }
  },

  mounted() {
    const chart = (this.chartInstance = new G2.Chart({
      container: this.$refs.chart,
      height: this.$refs.chart.offsetHeight,
      forceFit: true,
      padding: ['15%', 'auto', '15%', 'auto']
    }))
    chart.tooltip(false)
    chart.axis('y', false)
    chart.source(this.data, {
      y: {
        alias: '人数'
      }
    })
    chart.interval().position('x*y').label('y')
    chart.render()
  }
}
</script>

<style scoped></style>
