<template>
  <el-dialog
    title="评分等级配置"
    :visible.sync="store.visible"
    append-to-body
    :close-on-click-modal="false"
    width="700px"
  >
    <page-action-bar>
      <el-button type="primary" size="small" @click="onAddClick">
        添加等级
      </el-button>
    </page-action-bar>
    <el-table :data="list.items">
      <el-table-column type="index" width="50" />
      <el-table-column prop="level" label="等级" />
      <el-table-column label="分数">
        <template slot-scope="{ row }">
          {{ row.min_score }}-{{ row.max_score }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="onEditClick(row)"> 编辑 </el-button>
          <el-button type="text" @click="onRemoveClick(row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-form
      ref="form"
      :model="list.data"
      size="small"
      style="margin-top: 32px"
    >
      <el-form-item
        label="合格标准:"
        prop="note_pass_score"
        :rules="{ type: 'number', required: true, message: '请填写' }"
      >
        <el-input-number
          v-model="list.data.note_pass_score"
          :controls="false"
          style="width: 80px"
        />
        分及以上为合格笔记
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="store.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import store from './store'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { PUT } from '@/admin-shared-modules/utils/ajax'

export default {
  data() {
    return {
      store,
      list: store.list,
      saving: false
    }
  },

  methods: {
    onAddClick() {
      store.edit.onAdd()
    },
    onEditClick(row) {
      store.edit.onEdit(row)
    },
    async onRemoveClick(row) {
      store.edit.onRemove(row)
    },

    async onSubmit() {
      await this.$refs.form.validate()
      this.submit()
    },
    async submit() {
      this.saving = true
      try {
        const data = {
          ...this.list.data,
          review_level: this.list.items
        }
        await PUT('credit/note/review/config', {
          data
        })
        MessageService.open({ type: 'success', message: '保存成功' })
        this.store.visible = false
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style scoped></style>
