<template>
  <div class="intro-container">
    <vue-particles
      class="backdrop"
      :particles-number="50"
      :hover-effect="false"
      :click-effect="false"
    />

    <div class="page-sign-in">
      <div class="header">
        <h1 class="title">超星阅读本</h1>
        <p class="desc">管理平台</p>
      </div>

      <div class="tabs" v-if="false">
        <div
          class="item"
          :class="{ active: type === 'scan' }"
          @click="
            type = 'scan'
            scanInit()
          "
        >
          扫码登录
        </div>
        <div
          class="item"
          :class="{ active: type === 'account' }"
          @click="
            type = 'account'
            scanCancel()
          "
        >
          手机号登录
        </div>
      </div>

      <form v-if="type === 'account'" class="form" @submit.prevent="submit()">
        <div class="form-field" v-if="ajaxErrorMessage">
          <el-alert
            :title="ajaxErrorMessage"
            type="warning"
            show-icon
            :closable="false"
          />
        </div>

        <div class="form-field">
          <el-input
            ref="inputPhone"
            v-model.trim="form.phone"
            placeholder="手机号"
            :autofocus="true"
          />
        </div>
        <div class="form-field-sms">
          <el-input
            style="width: 160px"
            ref="inputCode"
            type="code"
            v-model="form.code"
            placeholder="验证码"
          />
          <el-button
            :disabled="isDisabled"
            style="margin-left: 10px; width: 130px"
            @click="sendMsg"
          >
            {{ buttonName }}
          </el-button>
        </div>
        <div class="form-field-sms" v-if="isDisabled">
          <span class="el-icon-circle-check-outline" style="color: #00cc00" />
          <span style="font-size: 12px; color: #b0b0b0"
            >验证码已发送至您的手机，5分钟内输入有效</span
          >
        </div>
        <div class="form-field">
          <el-button
            type="primary"
            native-type="submit"
            :loading="loading"
            class="block-btn"
          >
            登 录
          </el-button>
        </div>
      </form>

      <div class="scan-result-container" v-if="status === 'scan'">
        <div class="img">
          <img src="~@/admin-shared-modules/theme/image/u75.png" />
        </div>
        <div class="hd">扫码成功</div>
        <div class="desc">请在学习通上确认登录</div>
        <div class="action">
          <el-button @click="scanReturn()" type="text"> 返回登录 </el-button>
        </div>
      </div>

      <div class="scan-container" v-if="type === 'scan' && status !== 'scan'">
        <qr-code-image :text="pic_url_code" :size="512" />
        <div class="desc">
          打开 <span class="primary">学习通APP</span> 扫一扫登录
        </div>
      </div>
    </div>
    <footer class="footer">Copyright &copy; 2018 - {{ year }} 超星 V1.0</footer>

    <el-dialog
      :visible.sync="librarySelector.visible"
      title="请选择图书馆"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="margin-top: -14px">
        <el-button
          type="primary"
          v-for="item in librarySelector.items"
          :key="item.id"
          @click="loginWithLibrary(item)"
          style="margin-bottom: 10px"
        >
          {{ item.name }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { AJAXErrorResult, POST, GET } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import QrCodeImage from '@/admin-shared-modules/components/qr-code.vue'
import { Scan } from './sign-in.store'
import queryString from 'query-string'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import LoadingService from '@/admin-shared-modules/utils/loading.service'

export default {
  components: { QrCodeImage },

  data() {
    return {
      ssoMode: false,

      form: {
        phone: '',
        code: ''
      },
      type: 'scan', // scan account
      loading: false,
      ajaxErrorMessage: null,

      librarySelector: {
        visible: false,
        currentSelectedId: null,
        items: []
      },
      pic_url_code: '',
      status: '',
      scan: {},
      isDisabled: false,
      buttonName: '获取验证码',
      time: 60,

      year: new Date().getFullYear()
    }
  },

  created() {
    const { sso_err_msg, sso_library_data } = queryString.parse(location.search)
    if (sso_err_msg) {
      MessageBoxService.alert({ type: 'error', message: sso_err_msg })
      return
    }
    if (sso_library_data) {
      this.ssoMode = true
      try {
        this.librarySelector.items = _.map(
          JSON.parse(sso_library_data),
          lib => {
            return {
              id: lib.admin_id,
              name: lib.library_name
            }
          }
        )
        this.librarySelector.visible = true
      } catch (e) {
        defaultErrorHandler(e)
      }
    }
  },

  mounted() {
    if (this.ssoMode) {
      return
    }
    if (this.type === 'scan') {
      this.scan = new Scan()
      this.scan.on('code-update', val => {
        this.pic_url_code = val
      })
      this.scan.on('code-status', val => {
        this.status = val
      })

      this.scan.init()
    }
  },

  methods: {
    scanInit() {
      this.scan = new Scan()
      this.type = 'scan'
      this.scan.on('code-update', val => {
        this.pic_url_code = val
      })
      this.scan.on('code-status', val => {
        this.status = val
      })
      this.scan.init()
    },
    scanCancel() {
      this.scan.isDestory = true
      this.scan.source.cancel()
    },
    async scanReturn() {
      const uuid = this.scan.uuid
      const res = await GET('account/scan/cancel', {
        data: {
          uuid: uuid
        }
      })
      this.type = 'scan'
      this.scan.on('code-update', val => {
        this.pic_url_code = val
      })
      this.scan.on('code-status', val => {
        this.status = val
      })
    },

    async sendMsg() {
      if (!this.form.phone) {
        MessageService.open({ type: 'error', message: '请输入手机号' })
        return
      }
      try {
        const res = await POST('account/sms/send', {
          data: {
            phone: this.form.phone
          }
        })
        this.$refs.inputCode.focus()
        this.isDisabled = true
        const interval = window.setInterval(() => {
          this.buttonName = `${this.time}秒后可重发`
          --this.time
          if (this.time < 0) {
            this.buttonName = '重新获取'
            this.time = 60
            this.isDisabled = false
            window.clearInterval(interval)
          }
        }, 1000)
      } catch (e) {
        console.error(e)
        const ex: AJAXErrorResult = e
        if (ex.handled) return
        // this.ajaxErrorMessage = ex.message
        MessageService.open({ type: 'error', message: e.message })
      } finally {
      }
    },

    async submit() {
      if (!this.validate()) return
      this.ajaxErrorMessage = null
      this.loading = true
      try {
        const data = {
          ...this.form,
          library_id: this.librarySelector.currentSelectedId
            ? this.librarySelector.currentSelectedId
            : undefined
        }
        const res = await POST(`account/sms/login`, {
          data
        })

        if (res.code === 1001) {
          this.librarySelector.items = res.data
          this.librarySelector.visible = true
        } else {
          setTimeout(() => {
            this.$router.replace({ name: 'home' })
          }, 500)
        }
      } catch (e) {
        console.error(e)
        this.loading = false
        const ex: AJAXErrorResult = e
        if (ex.handled) return
        // this.ajaxErrorMessage = ex.message
        MessageService.open({ type: 'error', message: e.message })
      } finally {
        this.librarySelector.currentSelectedId = null
      }
    },

    loginWithLibrary(item) {
      this.librarySelector.currentSelectedId = item.id
      // this.librarySelector.visible = false
      if (this.ssoMode) {
        this.ssoLogin()
      } else {
        this.submit()
      }
    },

    validate() {
      const inputPhone = this.$refs['inputPhone']
      const inputCode = this.$refs['inputCode']
      if (!this.form.phone) {
        MessageService.open({ type: 'error', message: '请输入手机号' })
        inputPhone.focus()
        return
      } else if (!this.form.code) {
        MessageService.open({ type: 'error', message: '请输入手机验证码' })
        inputCode.focus()
        return
      }
      return true
    },

    async ssoLogin() {
      LoadingService.create()
      const { redirect } = queryString.parse(location.search)
      try {
        await POST('sso/login', {
          data: { admin_id: this.librarySelector.currentSelectedId }
        })
        location.href = redirect
          ? `${location.protocol}//${location.host}${redirect}`
          : `${location.protocol}//${location.host}`
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        LoadingService.close()
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./sign-in.style.scss">
.logo {
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 14px;
  line-height: 64px;
  background: #002140;
  color: #fff;
  font-size: 18px;

  img {
    margin-right: 8px;
    width: 38px;
  }
}
</style>
