import { GET, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

class Store {
  visible = false
  data = {}
  loading = true
  saving = false

  async fetch() {
    this.loading = true
    const res = await GET('credit/note/review/config', {})
    this.data = res.data
    this.loading = false
  }

  async submit(data?) {
    this.saving = true
    try {
      await PUT('credit/note/review/config', {
        data: {
          ...this.data,
          ...data
        }
      })
      this.fetch()
      MessageService.open({ type: 'success', message: '保存成功' })
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.saving = false
    }
  }

  open() {
    this.visible = true
    this.fetch()
  }
}

export const store = new Store()
