<template>
  <div ref="chart" style="height: 90px" />
</template>

<script>
import G2 from '@/admin-shared-modules/utils/g2'
import numeral from 'numeral'

export default {
  props: {
    percent: { type: Number, default: 0 }
  },

  mounted() {
    const chart = (this.chartInstance = new G2.Chart({
      container: this.$refs.chart,
      height: this.$refs.chart.offsetHeight,
      forceFit: true,
      padding: 'auto'
    }))
    chart.source([
      { name: '1', value: this.percent },
      { name: '2', value: 1 - this.percent }
    ])
    chart.legend(false)
    chart.tooltip(false)

    chart.coord('theta', {
      radius: 0.9,
      innerRadius: 0.7
    })
    chart
      .intervalStack()
      .position('value')
      .color('name', ['#409EFF', '#eeeeee'])
    chart.render()
  }
}
</script>

<style scoped></style>
