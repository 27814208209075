import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'
import moment from 'moment'
import { dateFormat } from '@/admin-shared-modules/utils/format'

class List extends AbstractListService<Store> {
  size = 50
  getFetchURL(): string {
    return 'credit/teacher/statistic'
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData() {
    return {
      college_id: null,
      profession_id: null,
      class_id: null,
      dateRange: [moment().subtract(30, 'day').toDate(), moment().toDate()]
    }
  }
  getSearchParams() {
    return this.omitEmptyString({
      ..._.omit(this.data, 'dateRange'),
      begin_date: dateFormat(this.data.dateRange[0]),
      end_date: dateFormat(this.data.dateRange[1])
    })
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class Store {
  list: List
  search: Search

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
  }
}

export const store = new Store()
