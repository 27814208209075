<template>
  <page-layout>
    <page-header :breadcrumb="['Kindle阅读报表']" />
    <div class="page-dashboard">
      <div class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">学院借阅分类统计</div>
        </div>
        <div class="panel__body" v-loading="state.lendBookData.loading">
          <el-form class="page-search" :inline="true">
            <el-form-item label="学院">
              <user-scope-selector
                v-model="state.lendBookData.search.userScope"
              />
            </el-form-item>
            <el-form-item label="统计日期">
              <el-date-picker
                v-model="state.lendBookData.search.dateRange"
                type="daterange"
                size="small"
                style="width: 260px"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onCateSubmit">
                查询
              </el-button>
            </el-form-item>
          </el-form>

          <el-row :gutter="24" type="flex" class="stretch-items">
            <el-col :span="12">
              <div class="panel panel--no-border panel--stretch">
                <div class="panel__inner-header">
                  <div class="title title-summary">
                    <i class="el-icon-info" /> 共借阅图书
                    {{ state.lendBookData.lendCount | number }} 次
                  </div>
                </div>
                <div class="panel-stretch-body">
                  <div class="chart-container chart-container--stretch">
                    <div
                      v-show="
                        !state.lendBookData.loading &&
                        !state.lendBookData.trendData.length
                      "
                      class="chart-empty-message"
                    >
                      暂无数据
                    </div>
                    <div
                      v-show="state.lendBookData.trendData.length"
                      class="chart"
                      ref="lendDataChart"
                    />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="panel__inner-header">
                <div class="title title-summary">
                  <i class="el-icon-info" /> 共借阅
                  {{ state.lendBookData.cateData.length }} 个分类
                </div>
              </div>

              <div
                v-if="
                  !state.lendBookData.loading &&
                  !state.lendBookData.cateData.length
                "
                class="chart-empty-message"
              >
                暂无数据
              </div>

              <div class="cate-list" v-else>
                <div
                  class="item"
                  v-for="item in state.lendBookData.cateData"
                  :key="item.tag_id"
                >
                  <div class="label">
                    {{ item.tag_name }}
                  </div>
                  <div class="content">
                    <div class="bar">
                      <div class="inner" :style="getBarWidth(item)" />
                      {{ item.count | number }}次
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">学院借阅报表</div>
        </div>
        <div class="panel__body" v-loading="state.orgLendData.loading">
          <el-form class="page-search" :inline="true">
            <el-form-item label="学院">
              <user-scope-selector
                v-model="state.orgLendData.search.userScope"
              />
            </el-form-item>
            <el-form-item label="统计日期">
              <el-date-picker
                v-model="state.orgLendData.search.dateRange"
                type="daterange"
                size="small"
                style="width: 260px"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onOrgSubmit">
                查询
              </el-button>
            </el-form-item>
          </el-form>

          <div class="panel__inner-header">
            <div class="title title-summary">
              <i class="el-icon-info" /> 共借阅图书
              {{ state.orgLendData.lendCount | number }} 次
            </div>
          </div>

          <el-table :data="state.orgLendData.lendList.items">
            <el-table-column
              type="index"
              label="排名"
              align="center"
              width="90px"
            />
            <el-table-column label="学院" prop="org_name" />
            <el-table-column label="使用人数" prop="use_count" align="right" />
            <el-table-column
              label="推送图书(次)"
              prop="push_count"
              align="right"
            />
            <el-table-column width="100" />

            <el-table-column label="各专业报表" width="120">
              <template slot-scope="{ row }">
                <el-button type="text" size="small" @click="openOrgDetail(row)">
                  查看详细
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 50px;
          margin-bottom: 50px;
        "
      >
        <el-button type="primary" @click="state.export.visible = true">
          借阅数据导出
        </el-button>
      </div>

      <!-- org lend detail -->
      <el-dialog
        :visible.sync="state.orgDetail.visible"
        :title="state.orgDetail.params.org_name"
        width="700px"
      >
        <div class="page-search" style="margin-top: -16px; margin-bottom: 20px">
          <div class="btn-group">
            <el-button
              size="small"
              :type="state.orgDetail.search.yearScope ? 'plain' : 'primary'"
              @click="state.orgDetail.search.yearScope = null"
            >
              全部
            </el-button>
            <el-button
              v-for="(item, index) in state.gradeData.items"
              :key="index"
              :type="
                state.orgDetail.search.yearScope === item ? 'primary' : 'plain'
              "
              size="small"
              @click="state.orgDetail.search.yearScope = item"
            >
              {{ item }}级
            </el-button>
          </div>
        </div>

        <el-table :data="state.orgDetail.items" show-summary>
          <el-table-column
            type="index"
            label="排名"
            align="center"
            width="90px"
          />
          <el-table-column label="部门/专业" prop="profession" />
          <el-table-column label="使用人数" prop="use_count" align="right" />
          <el-table-column
            label="推送图书(次)"
            prop="push_count"
            align="right"
          />
        </el-table>
      </el-dialog>

      <!-- export -->
      <el-dialog :visible.sync="state.export.visible" title="借阅数据导出">
        <el-form class="page-search" :inline="true">
          <el-form-item label="身份">
            <el-select v-model="state.export.data.userType" size="small">
              <el-option
                v-for="(item, index) in state.userType.items"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="年级"
            v-if="state.export.data.userType !== '教工'"
          >
            <el-select v-model="state.export.data.grade" size="small">
              <el-option
                v-for="(item, index) in state.gradeData.items"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-form>

        <div style="text-align: center">
          <a
            :href="exportURL"
            class="el-button el-button--primary"
            target="_blank"
            @click="onExportClick"
            >导出</a
          >
        </div>
      </el-dialog>
    </div>
  </page-layout>
</template>

<script lang="ts">
import UserScopeSelector from './shared/user-scope-selector.vue'
import { store } from './dashboard.store'
import { LendTrendChart } from '@/views/dashboard/kindle-reading/chart/LendTrendChart'
import { API_HOST } from '@/config'
import queryString from 'query-string'
import { Message } from 'element-ui'

export default {
  components: { UserScopeSelector },

  data() {
    return {
      state: store
    }
  },
  computed: {
    exportURL() {
      const query = queryString.stringify({
        grade: this.state.export.data.grade,
        user_type: this.state.export.data.userType,
        _t: +new Date()
      })
      return `${API_HOST}/org/push/export?${query}`
    }
  },
  watch: {
    'state.orgDetail.search.yearScope'() {
      store.fetchOrgLendDetailData()
    }
  },

  async mounted() {
    store.fetchInitialData()
    await store.fetchLendBookData()
    await store.fetchOrgLendData()

    LendTrendChart.render(
      this.$refs.lendDataChart,
      this.state.lendBookData.trendData
    )
  },

  methods: {
    async onCateSubmit() {
      await store.fetchLendBookData()
      LendTrendChart.render(
        this.$refs.lendDataChart,
        this.state.lendBookData.trendData
      )
    },
    async onOrgSubmit() {
      await store.fetchOrgLendData()
    },
    openOrgDetail(params) {
      store.openOrgDetail(params)
    },

    onExportClick(e) {
      if (!this.state.export.data.userType) {
        e.preventDefault()
        Message.warning('请选择身份')
      }
      if (
        !this.state.export.data.grade &&
        this.state.export.data.userType !== '教工'
      ) {
        e.preventDefault()
        Message.warning('请选择年级')
      }
    },

    // ui
    getBarWidth(item) {
      return {
        flex: `0 0 ${item.percent - 20}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped src="../dashboard.style.scss"></style>
<style lang="scss" scoped>
@import '~@/admin-shared-modules/theme/var';

.cate-list {
  overflow-y: auto;
  max-height: 360px;

  .item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: $--font-size-small;
  }
  .label {
    margin-right: 16px;
    width: 4em;
    max-width: 4em;
    text-align: right;
  }
  .content {
    flex: auto;
  }
  .bar {
    display: flex;
    align-items: center;
    .inner {
      height: 24px;
      min-width: 4px;
      margin-right: 8px;
      background-color: $--color-primary;
    }
  }
}
.chart-empty-message {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $--color-text-secondary;
  background-color: #f7f7f7;
}
</style>
