import { GET } from '@/admin-shared-modules/utils/ajax'

class Store {
  list = {
    items: [],
    index: 1,
    size: 50,
    total: 0,
    loading: true
  }
  search = {
    data: {
      dsn: null,
      amazon_register_mail: null
    }
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('device', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          ...this.search.data
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }
}

export default new Store()
