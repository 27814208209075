<template>
  <el-cascader
    v-model="currentValue"
    :options="items"
    :props="{
      label: 'name',
      value: 'tag_id',
      lazy: true,
      lazyLoad: load
    }"
    :change-on-select="selectParent"
    :clearable="clearable"
    placeholder="请选择"
    :size="size"
    :style="{ width }"
    @change="onChange"
  />
</template>
<script lang="ts">
import store from './book-cate.store'

export default {
  props: {
    value: { type: Array },
    selectParent: { default: false },
    filterable: { default: false },
    clearable: { default: false },
    size: { default: '' },
    width: { type: [Number, String] }
  },

  data() {
    return {
      currentValue: this.value,
      state: store.state
    }
  },
  computed: {
    items() {
      return this.state.items
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    },
    currentValue() {
      this.$emit('input', this.currentValue)
    }
  },

  mounted() {
    store.fetch()
  },

  methods: {
    onChange(val) {
      this.currentValue = val
    },
    async load(node, resolve) {
      console.log(node)
      if (node.children?.length) {
        resolve([])
      }
      resolve(await store.fetchSubCates(node.value))
    }
  }
}
</script>
<style lang="scss"></style>
