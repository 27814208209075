<template>
  <el-form class="page-search" :inline="true">
    <el-form-item label="姓名">
      <el-input v-model="search.data.userName" size="small" clearable />
    </el-form-item>
    <el-form-item label="手机号">
      <el-input v-model="search.data.phone" size="small" clearable />
    </el-form-item>
    <el-form-item label="借阅证号">
      <el-input v-model="search.data.cardNum" size="small" clearable />
    </el-form-item>
    <el-form-item label="身份证号" v-show="isMoreShow">
      <el-input v-model="search.data.certCode" size="small" clearable />
    </el-form-item>
    <el-form-item label="借阅状态" v-show="isMoreShow">
      <el-select v-model="search.data.status" size="small" clearable>
        <el-option label="在借中" value="1" />
        <el-option label="已超期" value="3" />
        <el-option label="已归还" value="2" />
        <el-option label="未借过" value="4" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="选择日期"
      v-show="isMoreShow"
      style="margin-bottom: 16px"
    >
      <el-date-picker
        v-model="search.data.date_range"
        type="daterange"
        size="small"
        clearable
        style="width: 240px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
      <el-button size="small" @click="onReset"> 重置 </el-button>
      <el-button
        size="small"
        type="text"
        @click="toggleOpen"
        style="margin-left: 2em"
      >
        <span v-if="!isMoreShow">展开 <i class="el-icon-arrow-down" /></span>
        <span v-else>收起 <i class="el-icon-arrow-up" /></span>
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './borrower.store'

export default {
  data() {
    return {
      search: store.search
    }
  },

  computed: {
    isMoreShow: {
      get() {
        return this.search.isMoreShow
      },
      set(val) {
        this.search.isMoreShow = val
      }
    }
  },

  methods: {
    toggleOpen() {
      this.isMoreShow = !this.isMoreShow
      if (!this.isMoreShow) {
        this.search.data.certCode = ''
        this.search.data.status = ''
        this.search.data.date_range = []
      }
    },
    onReset() {
      this.search.data = {}
      store.fetch(true)
    },
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
