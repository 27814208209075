<template>
  <div>
    <page-header :breadcrumb="['读书笔记评定', '笔记评分']" />

    <page-main>
      <search />
    </page-main>

    <page-main>
      <page-action-bar>
        <el-radio-group
          v-model="search.data.note_status"
          size="small"
          @change="list.fetch({ reset: true })"
        >
          <el-radio-button :label="1"> 待审核 </el-radio-button>
          <el-radio-button :label="2"> 已审核 </el-radio-button>
        </el-radio-group>

        <div slot="right">
          <el-button size="small" @click="onReviewSettingClick">
            审核须知
          </el-button>
          <el-button v-if="isAdmin" size="small" @click="onAccountSettingClick">
            审核账号设置
          </el-button>
        </div>
      </page-action-bar>

      <template v-if="!list.loading && list.items.length">
        <el-table ref="table" :data="list.items" size="small">
          <el-table-column label="姓名" prop="user_name" />
          <el-table-column label="学号" prop="card_num" />
          <el-table-column label="专业" prop="profession" />
          <el-table-column label="入学年份" prop="year" />
          <el-table-column label="书名">
            <template slot-scope="{ row }">
              <div>{{ row.title }}</div>
              <div style="color: #999">
                {{ row.isbn_13 }}
              </div>
            </template>
          </el-table-column>
          <template v-if="search.data.note_status === 2">
            <el-table-column label="得分" prop="note_score" align="right" />
            <el-table-column width="30" />
            <el-table-column label="等级">
              <template slot-scope="{ row }">
                <el-tag
                  v-if="row.isPass"
                  type="success"
                  size="small"
                  disable-transitions
                >
                  {{ row.level }}
                </el-tag>
                <el-tag v-else type="danger" size="small" disable-transitions>
                  {{ row.level }}
                </el-tag>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" width="120px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="onEditClick(row)">
                笔记评分
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        还没有待审核的读书笔记
      </div>
    </page-main>

    <edit />
    <review-setting-dialog />
    <account-setting-dialog />
  </div>
</template>

<script lang="ts">
import ReviewSettingDialog from '../credit-note-review-setting-dialog/index.vue'
import AccountSettingDialog from '../credit-note-review-account/index.vue'
import { store } from './store'
import { store as reviewSettingStore } from '../credit-note-review-setting-dialog/store'
import { store as accountSettingStore } from '../credit-note-review-account/store'
import { AppStateMixin } from '@/core/appStateMixin'
import Search from './seach.vue'
import Edit from './edit.vue'

export default {
  mixins: [AppStateMixin],
  components: {
    ReviewSettingDialog,
    AccountSettingDialog,
    Search,
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search,
      reviewSettingStore
    }
  },
  watch: {
    async 'reviewSettingStore.visible'(v) {
      if (!v) {
        await store.fetchScoreLevel()
        store.list.fetch({ reset: true })
      }
    }
  },

  async mounted() {
    await store.fetchScoreLevel()
    store.list.fetch({ reset: true })
  },

  methods: {
    onEditClick(item) {
      store.edit.onEdit(item)
    },

    onReviewSettingClick() {
      reviewSettingStore.open()
    },
    onAccountSettingClick() {
      accountSettingStore.open()
    }
  }
}
</script>

<style scoped></style>
