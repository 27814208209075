<template>
  <el-form class="page-search" :inline="true">
    <el-form-item label="分类">
      <el-select
        v-model="search.data.fid"
        size="small"
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="(item, index) in state.cate"
          :key="index"
          :label="item.fenleiname"
          :value="item.fenleiid"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="关键词">
      <el-input
        v-model="search.data.key_word"
        size="small"
        clearable
        placeholder="期刊名称/主办单位/ISSN"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './store'

export default {
  data() {
    return {
      search: store.state.search,
      state: store.state
    }
  },

  mounted() {
    store.fetchCate()
  },

  methods: {
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
