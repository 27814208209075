import Vue from 'vue'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import _ from 'lodash'

class List extends AbstractListService<Store> {
  checkedRow = []
  getFetchURL(): string {
    return 'credit/book'
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData(): Partial<any> {
    return { is_professional: 1 }
  }
  getSearchParams(options): any {
    const data = {
      ..._.omit(this.data, 'tags'),
      tag_id: this.data.tags && this.data.tags[0]
    }
    return this.omitEmptyString(data)
  }
  onSubmit() {
    this.context.list.checkedRow = []
    this.context.list.fetch({ reset: true })
  }
}

class Store {
  list: List
  search: Search
  visible = false
  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
  }
  initialize() {
    this.list.checkedRow = []
    this.search.reset()
    this.visible = true
  }
}

export const store = new Store()

import Dialog from './index.vue'
export const CreditBookSelectorDialog = {
  el: null,
  vm: null,
  open() {
    return new Promise(resolve => {
      this.el = document.createElement('div')
      document.body.appendChild(this.el)
      const props = {
        on: {
          confirm: data => {
            resolve(data)
            store.visible = false
          },
          cancel: () => this.destroy()
        }
      }
      this.vm = new Vue({
        el: this.el,
        render: h => h(Dialog, props)
      })
      store.initialize()
    })
  },
  destroy() {
    if (this.vm && this.el.parentNode) {
      this.vm.$destroy()
      this.el.parentNode.removeChild(this.el)
    }
  }
}
