<template>
  <el-dialog
    :visible.sync="store.visible"
    title="审核须知"
    :close-on-click-modal="false"
  >
    <h2>你好，XXX，请认真阅读审核须知</h2>

    <div class="panel">
      <div class="panel__header">
        <div class="title">审核规则</div>
        <el-button type="text" @click="$refs.summarySetting.edit()">
          编辑
        </el-button>
      </div>
      <div class="panel__body">
        <summary-setting ref="summarySetting" />
      </div>
    </div>

    <div class="panel">
      <div class="panel__header">
        <div class="title">等级与分数对照表</div>
        <el-button type="text" @click="$refs.rulesSetting.edit()">
          编辑
        </el-button>
      </div>
      <div class="panel__body">
        <div style="margin-bottom: 16px">
          {{ store.data.note_pass_score }}分及以上为合格笔记
        </div>
        <rules-setting ref="rulesSetting" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import SummarySetting from './summary'
import RulesSetting from './rules/index'
import { store } from './store'
import rulesStore from './rules/store'

export default {
  components: {
    SummarySetting,
    RulesSetting
  },

  data() {
    return {
      store,
      rulesStore
    }
  },

  watch: {
    'rulesStore.visible'(visible) {
      if (!visible) {
        store.fetch()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.panel {
  margin-bottom: 32px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .title {
      margin-right: 16px;
      font-size: 18px;
    }
  }
  &__body {
  }
}
</style>
