<template>
  <page-layout>
    <page-header :breadcrumb="['图书借阅记录']">
      <inner-tabs v-if="isCXApp" />
    </page-header>

    <page-main v-loading="list.loading">
      <search />

      <page-action-bar>
        <div style="flex: 1" />
        <el-button size="small" @click="onExportClick">
          <i class="el-icon-printer" /> 列表导出
        </el-button>
      </page-action-bar>

      <div>共 {{ list.total }} 条记录</div>

      <el-table ref="table" :data="list.items" size="small">
        <el-table-column
          type="index"
          width="50"
          :index="index => list.indexMethod(index)"
        />

        <el-table-column label="书名/期刊名">
          <template slot-scope="{ row }">
            {{ row.title }}
          </template>
        </el-table-column>
        <el-table-column label="作者">
          <template slot-scope="{ row }">
            {{ row.author | join }}
          </template>
        </el-table-column>
        <el-table-column label="出版社/主办单位">
          <template slot-scope="{ row }">
            {{ row.publisher }}
          </template>
        </el-table-column>
        <el-table-column label="读者证号">
          <template slot-scope="{ row }">
            {{ row.lend_card_num || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="{ row }">
            {{ row.lend_user_name }}
          </template>
        </el-table-column>
        <el-table-column label="登录账号">
          <template slot-scope="{ row }">
            {{ row.cx_uname }}
          </template>
        </el-table-column>
        <el-table-column label="借阅时间">
          <template slot-scope="{ row }">
            {{ row.begin_time | date('YYYY.MM.DD HH:mm') }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <el-dialog
      title="导出借阅记录"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </page-layout>
</template>

<script lang="ts">
import store from './store'
import Search from './search.vue'
import { API_HOST } from '@/config'
import queryString from 'querystring'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: {
    Search
  },

  data() {
    return {
      list: store.list,
      exportVisible: false,
      exportURL: ''
    }
  },

  mounted() {
    this.list.fetch({ reset: true })
  },

  methods: {
    onExportClick() {
      const qs = queryString.stringify({
        ...store.search.getSearchParams(),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/report/book/lend/export?${qs}`
      this.exportVisible = true
    }
  }
}
</script>
