<template>
  <div v-loading="store.loading">
    <div v-if="!isEdit" v-html="summary" class="text" />

    <div v-else>
      <quill-editor
        v-model="content"
        :options="{
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', { color: [] }],
              [{ indent: '-1' }, { indent: '+1' }, { align: [] }]
            ]
          }
        }"
      />
      <div style="margin-top: 16px">
        <el-button @click="isEdit = false"> 取消 </el-button>
        <el-button :loading="store.saving" type="primary" @click="submit">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from './store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { MessageService } from '../../admin-shared-modules/utils/message.service'

export default {
  components: { quillEditor },

  data() {
    return {
      store,
      isEdit: false,
      content: ''
    }
  },
  computed: {
    summary() {
      return store.data.review_rule
    }
  },

  methods: {
    edit() {
      this.content = this.summary
      this.isEdit = true
    },

    async submit() {
      if (!this.content) {
        MessageService.open({ type: 'warning', message: '请填写内容' })
        return
      }
      this.store.data.review_rule = this.content
      this.isEdit = false
      store.submit()
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  padding: 12px 8px;
  max-height: 200px;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 2px rgba(#000, 0.1);
  border-radius: 4px;
}
</style>
