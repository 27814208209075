import _ from 'lodash'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { POST } from '@/admin-shared-modules/utils/ajax'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/book/package'
  }
  async onSortSubmit() {
    try {
      await POST('credit/book/package/sort', {
        data: {
          credit_book_package_sort: _.map(this.items, 'id')
        }
      })
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
}

class Edit extends AbstractEditService<Store> {
  selectRows = []

  getFetchURL(): string | boolean {
    return `credit/book/package/${this.params.id}`
  }
  getRemoveURL(): string {
    return `credit/book/package/${this.params.id}`
  }
  getSubmitURL(): string {
    return this.isEdit
      ? `credit/book/package/${this.params.id}`
      : 'credit/book/package'
  }
  getDefaultFormData() {
    return { lib_credit_books: [] }
  }

  getSubmitData(): any {
    return {
      name: this.data.name,
      lib_credit_book_ids: _.map(this.data.lib_credit_books, 'id')
    }
  }
  requestListReload(option?): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit

  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
