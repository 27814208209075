import { render, staticRenderFns } from "./user-scope-selector.vue?vue&type=template&id=2702ca08&scoped=true"
import script from "./user-scope-selector.vue?vue&type=script&lang=ts"
export * from "./user-scope-selector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2702ca08",
  null
  
)

export default component.exports