import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { DELETE } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/tag'
  }
  parseFetchedItems(data) {
    return data
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return false
  }
  getSubmitURL(): string {
    return this.isEdit ? `credit/tag/${this.params.id}` : 'credit/tag'
  }
  getRemoveURL(): string {
    return `credit/tag/${this.params.id}`
  }
  async onRemove(item: any, force = false) {
    await MessageBoxService.confirm({
      message: force ? '此分类下已有图书，确定删除' : '确认删除'
    })
    this.params = item as any
    try {
      await DELETE(this.getRemoveURL(), {
        data: { force_delete: force ? 1 : 0 }
      })
      this.requestListReload({ fixIndex: true })
      MessageService.open({ message: '删除成功' })
    } catch (e) {
      if (_.get(e, 'data.code') === 4001) {
        await this.onRemove(item, true)
      } else {
        defaultErrorHandler(e)
      }
    }
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit

  visible = false

  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }

  open() {
    this.visible = true
  }
}

export const store = new Store()
