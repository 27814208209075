import { appStore } from '@/store'

export const AppStateMixin = {
  data() {
    return {
      app: appStore.state.app,
      user: appStore.state.user.data,
      lib: appStore.state.lib,
      ui: appStore.state.ui
    }
  },
  computed: {
    isCXApp() {
      return this.app.isCXApp
    },
    isCXCreditApp() {
      return this.app.isCXCreditApp
    },
    isAdmin() {
      return this.app.isAdmin
    }
  }
}
