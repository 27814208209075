<template>
  <el-dialog
    class="edit-dialog"
    :visible="visible"
    width="840px"
    top="6vh"
    :close-on-click-modal="false"
    append-to-body
    @close="cancel"
  >
    <div slot="title" class="el-dialog__title">
      书库选书
      <span class="blue" style="font-size: 14px; margin-left: 20px">
        请选择要添加进{{ state.isPro ? '专业图书' : '通识图书' }}的书目,
        并点击"立即导入"
      </span>
    </div>
    <div>
      <el-radio-group
        v-model="state.isPro"
        size="small"
        @change="onProTypeChange"
        style="margin-bottom: 20px; margin-top: -20px"
      >
        <el-radio-button :label="false"> 通识书单 </el-radio-button>
        <el-radio-button :label="true"> 专业书单 </el-radio-button>
      </el-radio-group>

      <search />

      <hr />

      <el-radio-group
        v-model="state.search.data.tab"
        size="small"
        @change="onImportStateFilterChange"
        style="margin-top: 8px"
      >
        <el-radio-button :label="1"> 未导入 </el-radio-button>
        <el-radio-button :label="2"> 已导入 </el-radio-button>
      </el-radio-group>

      <el-table
        ref="table"
        :data="state.list.items"
        size="small"
        @selection-change="onSelectChange"
        v-loading="state.list.loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="book-detail-expand">
              <div class="book-detail-expand__left">
                <img class="cover-img" :src="props.row.image" alt="" />
              </div>
              <div class="book-detail-expand__body">
                <p><span>ISBN:</span> {{ props.row.isbn_13 }}</p>
                <p>
                  <span>图书名称:</span> {{ props.row.title }}
                  {{ props.row.subtitle }}
                </p>
                <p><span>作者:</span> {{ props.row.author | join }}</p>
                <p><span>出版社:</span> {{ props.row.publisher }}</p>
                <p><span>出版时间:</span> {{ props.row.pub_date | date }}</p>
                <p>
                  <span>数据更新时间:</span> {{ props.row.updated_at | date }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="selection" width="55" />

        <el-table-column label="图书名称">
          <template slot-scope="scope">
            <div class="title" style="font-size: 14px">
              {{ scope.row.title }}
            </div>
            <small class="desc" style="color: #999">{{
              scope.row.author | join
            }}</small>
          </template>
        </el-table-column>

        <el-table-column label="分类" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.tags | join('name') }}
          </template>
        </el-table-column>

        <el-table-column
          prop="publisher"
          label="出版社"
          width="160"
          show-overflow-tooltip
        />
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="state.list.index"
        :total="state.list.total"
        :page-size="state.list.size"
        layout="total, prev, pager, next"
        background
      />
    </div>

    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" class="selectNum">
        <span
          >已选中&nbsp;<span class="blue">{{ selectedItems.length }}</span
          >&nbsp;本</span
        >
      </el-col>
      <el-col :span="12" class="btn">
        <el-button @click="cancel"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 立即导入 </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './store'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import Search from './search.vue'

export default {
  components: {
    Search
  },

  props: {
    visible: { type: Boolean, default: false }
  },
  data() {
    return {
      store,
      state: store.state,

      selectedItems: []
    }
  },

  mounted() {
    this.state.isPro = false
    this.state.search.data.tab = 1
    store.fetch(true)
  },

  methods: {
    onProTypeChange() {
      this.state.search.data.tags = []
      store.fetch(true)
    },
    onImportStateFilterChange() {
      store.fetch(true)
    },
    async handleCurrentChange(index) {
      this.state.list.index = index
      await store.fetch()
      this.setTableCurrentSelection()
    },

    onSelectChange(items) {
      const unSelectedItems = _.differenceBy(this.state.list.items, items, 'id')
      this.selectedItems = _.chain(this.selectedItems)
        .unionBy(items, 'id')
        .differenceBy(unSelectedItems, 'id')
        .value()
    },
    setTableCurrentSelection() {
      const selectedRow = _.intersectionBy(
        this.state.list.items,
        this.selectedItems,
        'id'
      )
      _.forEach(selectedRow, row =>
        this.$refs.table.toggleRowSelection(row, true)
      )
    },

    async cancel() {
      this.$emit('update:visible', false)
    },
    async confirm() {
      if (!this.selectedItems.length) {
        MessageService.open({ message: '请选择图书', type: 'warning' })
        return
      }
      this.$emit(
        'confirm',
        _.cloneDeep(this.selectedItems),
        _.map(this.selectedItems, 'id')
      )
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-footer {
  .selectNum {
    text-align: left !important;
    line-height: 40px;
    font-size: 15px;
    .blue {
      color: #409eff;
    }
  }
  .btn {
    text-align: right;
  }
}
.book-detail-expand {
  display: flex;
  &__left {
    width: 150px;
    margin-right: 16px;
  }
}
</style>
