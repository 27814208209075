<template>
  <page-layout>
    <page-header :breadcrumb="['云舟专题导入']" />

    <page-main>
      <el-form
        ref="addForm"
        :model="add"
        label-width="100px"
        v-loading="add.loading"
      >
        <el-form-item label="专题链接/ID" style="margin-bottom: 0">
          <el-input
            v-model.trim="add.data"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            placeholder="每行输入一条记录"
          />
          <div class="help">共{{ addRecordRowCount }}行</div>
        </el-form-item>
        <el-form-item style="margin-bottom: 0">
          <el-button type="primary" size="small" @click="onAddClick">
            专题导入
          </el-button>
        </el-form-item>
      </el-form>
    </page-main>

    <page-main v-loading="list.loading">
      <search />

      <el-table ref="table" :data="list.items">
        <el-table-column type="index" width="50" :index="indexMethod" />

        <el-table-column label="专题ID">
          <template slot-scope="scope">
            {{ scope.row.course_id }}
          </template>
        </el-table-column>
        <el-table-column label="名称">
          <template slot-scope="{ row }">
            {{ row.title }}
          </template>
        </el-table-column>
        <el-table-column label="系统分类">
          <template slot-scope="{ row }">
            <el-tag
              v-for="(tags, index) in row.fullTags"
              :key="`${row.id}_${index}`"
              size="mini"
              disable-transitions
              style="margin-bottom: 4px; margin-right: 8px"
            >
              {{ tags | join('name', '>') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <el-tag
              size="mini"
              :type="getRecordTagStatus(row)"
              disable-transitions
            >
              {{ row.status | statusName(row.is_release) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="onPreviewClick(row)">
              在线浏览
            </el-button>
            <el-button
              v-if="row.status === 'SUCCESS'"
              type="text"
              @click="onEditClick(row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="row.status === 'SUCCESS'"
              type="text"
              @click="onRemoveClick(row)"
            >
              删除
            </el-button>
            <el-button
              v-if="row.status === 'FAILED'"
              type="text"
              @click="onRebuildClick(row)"
            >
              重新生成
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import store, { TopicTransformRecord } from './store'
import bookTagStore from '../../components/book/book-tag.store'
import Search from './search.vue'
import Edit from './edit.vue'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { appStore } from '@/store'

const ADD_RECORD_URL_REGEX = /(?:course|tocourse|zt)\/([^\.|^\?]+)(?:\.html)?/i
const ADD_RECORD_ID_REGEX = /^[\w]+$/

@Component({
  components: { Search, Edit },
  filters: {
    statusName(status, is_release) {
      switch (status) {
        case 'SUCCESS':
          return is_release ? '可用' : '下架'
        case 'READY':
          return '生成中'
        case 'PENDING':
          return '生成中'
        case 'FAILED':
          return '生成失败'
      }
    }
  }
})
export default class TopicTransform extends Vue {
  list = store.list
  add = store.add

  get isCXApp() {
    return appStore.state.app.isCXApp
  }

  async mounted() {
    await bookTagStore.fetch()
    store.fetch(true)
  }

  async handleCurrentChange(index) {
    this.list.index = index
    await store.fetch()
  }
  indexMethod(index) {
    return index + 1 + (this.list.index - 1) * this.list.size
  }
  getRecordTagStatus(item: TopicTransformRecord) {
    switch (item.status) {
      case 'PENDING':
        return 'warning'
      case 'SUCCESS':
        return item.is_release ? 'success' : 'info'
      case 'FAILED':
        return 'danger'
    }
  }

  get addRecordRowData() {
    return _.chain(this.add.data)
      .split('\n')
      .map(_.trim)
      .filter(_.identity)
      .value()
  }

  get addRecordRowCount() {
    return this.addRecordRowData.length
  }

  onAddClick() {
    ;(this.$refs.addForm as any).validate(v => {
      if (!v) {
        MessageService.open({ message: '请输入专题链接/ID', type: 'warning' })
        return
      }

      const { error, data } = this.validateFormData()
      if (error) {
        MessageBoxService.alert({
          type: 'warning',
          message: error.message,
          dangerouslyUseHTMLString: true
        })
      } else {
        store.onAdd(data, this.add.property)
        MessageService.open({ type: 'success', message: 'epub生成中' })
      }
    })
  }
  private validateFormData() {
    const invalidateItems = []
    const result = []
    _.forEach(this.addRecordRowData, str => {
      const matched = str.match(ADD_RECORD_URL_REGEX)
      if (matched && matched[1]) {
        result.push(matched[1])
      } else if (ADD_RECORD_ID_REGEX.test(str)) {
        result.push(str)
      } else {
        invalidateItems.push(str)
      }
    })
    return {
      error: invalidateItems.length
        ? {
            message: `无法识别以下记录:<br> <div style="word-break: break-all;">${invalidateItems.join(
              '<br>'
            )}</div>`
          }
        : null,
      data: result
    }
  }

  onPreviewClick(item) {
    window.open(
      `https://special.zhexuezj.cn/mobile/mooc/tocourse/${item.course_id}`
    )
  }
  onEditClick(item) {
    store.onEdit(item)
  }
  async onRemoveClick(item) {
    await MessageBoxService.confirm({ message: '确认删除' })
    await store.onRemove(item)
    MessageService.open({ message: '删除成功' })
  }
  async onRebuildClick(item) {
    await store.onAdd([item.course_id])
  }
}
</script>
