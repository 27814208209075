<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item :label="store.state.isPro ? '专业分类' : '通识书单'">
      <book-cate-selector
        v-model="search.data.tags"
        clearable
        form-los
        :is-pro="store.state.isPro"
        style="width: 300px"
      />
    </el-form-item>
    <br />
    <el-form-item label="书名">
      <el-input v-model="search.data.title" clearable style="width: 120px" />
    </el-form-item>
    <el-form-item label="作者">
      <el-input v-model="search.data.author" clearable style="width: 120px" />
    </el-form-item>
    <el-form-item label="出版社">
      <el-input
        v-model="search.data.publisher"
        clearable
        style="width: 120px"
      />
    </el-form-item>
    <el-form-item label="ISBN">
      <el-input v-model="search.data.isbn_13" clearable style="width: 120px" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './store'
import BookCateSelector from '../book-cate-selector.vue'

export default {
  components: {
    BookCateSelector
  },

  data() {
    return {
      store,
      search: store.state.search
    }
  },

  methods: {
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
