import _ from 'lodash'
import moment from 'moment'
import { GET } from '@/admin-shared-modules/utils/ajax'

class Store {
  list = {
    items: [],
    summary: {},
    index: 1,
    size: 20,
    total: 0,
    loading: true
  }

  search = {
    data: {
      user_name_like: '',
      cx_uname_like: '',
      first_created_range: ''
      // createdAtRange: null
    }
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    this.list.summary = {}
    try {
      let res = await GET('user', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          ...this.getSearchData()
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount

      res = await GET('user/online_statistic', {
        data: {
          ...this.getSearchData()
        }
      })
      this.list.summary = res.data
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  indexMethod(index) {
    return index + 1 + (this.list.index - 1) * this.list.size
  }

  getSearchData() {
    const data: any = _.cloneDeep(this.search.data)
    if (_.size(data.first_created_range)) {
      data.first_login_begin = moment(data.first_created_range[0]).format(
        'YYYY-MM-DD'
      )
      data.first_login_end = moment(data.first_created_range[1]).format(
        'YYYY-MM-DD'
      )
      data.first_created_range = null
    }
    return _.omitBy(data, val => val === '')
  }
}

export default new Store()
