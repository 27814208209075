<template>
  <el-form class="page-search" size="small" label-width="6em">
    <el-form-item label="考核周期">
      <el-radio-group v-model="search.data.termIndex">
        <template v-for="(t, i) in termList">
          <el-radio-button v-if="t.is_open" :key="i" :label="t.index">
            {{ t._name }}
            <span v-if="t.is_current">(当前)</span>
          </el-radio-button>
        </template>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="审核状态">
      <el-radio-group v-model="search.data.status">
        <el-radio-button :label="0">
          待审核 ({{ year.authState.status_0_count || 0 }})
        </el-radio-button>
        <el-radio-button :label="1">
          未通过 ({{ year.authState.status_1_count || 0 }})
        </el-radio-button>
        <el-radio-button :label="2">
          已通过 ({{ year.authState.status_2_count || 0 }})
        </el-radio-button>
        <el-radio-button :label="3">
          未申请 ({{ year.authState.status_3_count || 0 }})
        </el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="学生信息">
      <el-row>
        <el-col :span="8">
          <el-form-item label="学号" label-width="3em">
            <el-input v-model="search.data.title" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="姓名" label-width="3em">
            <el-input v-model="search.data.title" clearable />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import _ from 'lodash'
import { store } from './store'
import { GradeNames, TermName } from '@/consts'
export default {
  data() {
    return {
      search: store.search,
      year: store.year
    }
  },
  computed: {
    type() {
      return _.get(store.year, 'yearConfig.score_config_type')
    },
    configs() {
      return _.get(store.year, 'yearConfig.config', [])
    },
    termList() {
      return _.map(this.configs, c => {
        if (this.type === 1) {
          return { ...c, _name: TermName[c.index - 1] }
        } else if (this.type === 2) {
          return { ...c, _name: GradeNames[c.index - 1] }
        } else {
          return { ...c, name: '大一到大四' }
        }
      })
    }
  },
  methods: {
    onSubmit() {
      this.search.onSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
.page-search {
  margin-bottom: 16px;
  padding: 16px 16px 8px;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 4px;
}
::v-deep .el-form-item {
  margin-bottom: 16px;
  .el-form-item__label {
    margin-right: 16px;
  }
  .el-form-item > .el-form-item__label {
    margin-right: 0;
    width: auto !important;
  }
}
</style>
