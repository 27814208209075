<template>
  <el-dialog
    :visible.sync="edit.visible"
    title="批量导入"
    class="edit-dialog"
    :close-on-click-modal="false"
    @open="onDialogOpen"
  >
    <div class="drop-uploader" v-if="step === 0">
      <div class="file-example">
        <a
          class="el-button el-button--primary el-button--small"
          :href="`${baseUrl}files/users_inport.xlsx`"
          target="_blank"
          >下载导入模板</a
        >
      </div>

      <el-upload
        action="/api/v1/admin/borrower/import"
        drag
        :show-file-list="isUploading"
        with-credentials
        :before-upload="beforeFileUpload"
        :on-success="onFileUploadSuccess"
        :on-error="onFileUploadError"
        v-loading="isUploading"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传 Excel 文件</div>
      </el-upload>
    </div>

    <div class="message" v-if="step === 1">
      <div class="icon">
        <i class="el-icon-success" />
      </div>
      <div class="text">导入成功</div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './borrower.store'
import { MessageService } from '../../../admin-shared-modules/utils/message.service'
import { MessageBoxService } from '../../../admin-shared-modules/utils/message-box.service'

export default {
  data() {
    return {
      edit: store.importer,
      step: 0,
      isUploading: false,
      baseUrl: process.env.BASE_URL
    }
  },

  methods: {
    onDialogOpen() {
      this.step = 0
      this.isUploading = false
    },

    //#region upload
    beforeFileUpload(file: File) {
      if (!/^application\/vnd\.openxmlformats/.test(file.type)) {
        MessageService.open({ type: 'error', message: '文件格式无效' })
        return false
      }
      this.isUploading = true
      return true
    },
    onFileUploadError(err, file: File) {
      this.isUploading = false
      try {
        const error = JSON.parse(err.message)

        let title = error.msg
        const data = error.data
        let desc = []
        if (data.card_nums.length) {
          desc.push(`卡号: ` + _.join(data.card_nums, ', '))
        }
        if (data.card_nums.length) {
          desc.push(`身份证: ` + _.join(data.id_card, ', '))
        }
        if (data.card_nums.length) {
          desc.push(`电话: ` + _.join(data.phones, ', '))
        }

        MessageBoxService.alert({
          type: 'error',
          title: title,
          message: _.join(desc, '<br>'),
          dangerouslyUseHTMLString: true
        })
      } catch (e) {
        console.error(e)
        MessageBoxService.alert({ type: 'error', message: err.message })
      }
    },
    onFileUploadSuccess(res, file: File) {
      this.isUploading = false
      this.step = 1
      store.fetch(true)
    }
    //#endregion
  }
}
</script>

<style scoped lang="scss">
.drop-uploader {
  ::v-deep .el-upload {
    display: block;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 220px;
  }
}
.file-example {
  margin-bottom: 20px;
  text-align: right;
}
</style>
