import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { ListFetchOptions } from '@/core/typing'
import { AbstractEditService } from '@/core/services/edit.service'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { PUT } from '@/admin-shared-modules/utils/ajax'

class List extends AbstractListService<Store> {
  data = {} as any
  getFetchURL(): string {
    return 'credit/note/review/config'
  }
  parseFetchedItems(data): any[] {
    this.data = data
    return data.review_level
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return false
  }
  getDefaultFormData(params: Partial<any>): Partial<any> {
    return {
      scoreRange: {
        min_score: undefined,
        max_score: undefined
      }
    }
  }
  parseFetchedFormData(data: any): any {
    return {
      ...data,
      scoreRange: {
        min_score: data.min_score,
        max_score: data.max_score
      }
    }
  }
  getRemoveURL() {
    return ''
  }
  getSubmitURL() {
    return ''
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch()
  }

  async onEditSubmit() {
    if (this.saving) {
      return
    }
    this.saving = true
    const item = {
      level: this.data.level,
      min_score: this.data.scoreRange.min_score,
      max_score: this.data.scoreRange.max_score
    }
    if (this.isEdit) {
      const index = _.findIndex(this.context.list.items, {
        level: this.params.level
      })
      this.context.list.items.splice(index, 1, item)
    } else {
      this.context.list.items.push(item)
    }
    // @ts-ignore
    this.context.list.items = _.orderBy(
      this.context.list.items,
      ['min_score'],
      ['desc']
    )

    this.visible = false
    this.saving = false
  }

  async onRemove(row) {
    await MessageBoxService.confirm({ message: '确认删除' })
    const index = _.findIndex(this.context.list.items, {
      level: row.level
    })
    this.context.list.items.splice(index, 1)
  }
}

class Store {
  list: List
  edit: Edit

  visible = false

  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export default new Store()
