<template>
  <el-select
    v-model="currentValue"
    filterable
    remote
    :remote-method="remoteMethod"
    :loading="loading"
    style="width: 100%"
  >
    <el-option
      v-for="item in list.items"
      :key="item.padbookId"
      :label="item.padbookCode"
      :value="item.padbookId"
    />
  </el-select>
</template>

<script lang="ts">
import store from './device-selector.store'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    deviceType: {
      type: String,
      default: 'all' //lend store
    }
  },
  data() {
    return {
      list: store.list,
      currentValue: this.value,
      loading: false
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    },
    currentValue() {
      this.$emit('input', this.currentValue)
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch(deviceCode) {
      this.loading = true
      await store.fetch(this.deviceType, deviceCode)
      this.loading = false
    },

    remoteMethod(query) {
      this.fetch(query)
    }
  }
}
</script>

<style scoped></style>
