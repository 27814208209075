import { Tag } from '@/admin-shared-modules/typings'
import _ from 'lodash'
import bookTagStore from './book-tag.store'

export const getFullPathOfTag = (tag: Tag) => {
  const path = _.split(tag.path, ',')
  if (!_.size(path)) return []

  return _.reduce<string, Tag[]>(
    path,
    (result, p) => {
      if (!p) return result

      tag = bookTagStore.getTagById(_.parseInt(p))
      result.push(tag)
      return result
    },
    []
  )
}

export const parseTagsToGroup = items => {
  const res = _.reduce(
    items,
    (result, item) => {
      result.push({
        items: _.chain(item.path)
          .split(',')
          .filter(v => !!v)
          .map(_.parseInt)
          .value()
      })
      return result
    },
    []
  )
  return res.length ? res : [{ items: [] }]
}
