import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import moment from 'moment'

class List extends AbstractListService<Store> {
  summary = {}
  getFetchURL(): string {
    return 'credit/test/book'
  }
  getSearchParams() {
    const data = this.context.search.getSearchParams()
    return {
      credit_user_id: this.context.userId,
      ...data
    }
  }
}

class Search extends AbstractSearchService<Store> {
  getSearchParams() {
    return this.omitEmptyString({
      ...this.data,
      begin_date: _.size(this.data.dateRange)
        ? moment(this.data.dateRange[0]).format('YYYY-MM-DD')
        : null,
      end_date: _.size(this.data.dateRange)
        ? moment(this.data.dateRange[1]).format('YYYY-MM-DD')
        : null
    })
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class Store {
  list: List
  search: Search
  userId: string
  visible = false

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
  }

  open({ userId, begin_date, end_date }) {
    this.userId = userId
    this.visible = true
    this.list.fetch({ reset: true })
  }
}

export const store = new Store()
