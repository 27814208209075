import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { POST } from '@/admin-shared-modules/utils/ajax'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/book/intro'
  }
  parseFetchedItems(data) {
    return _.map(data, (d, index) => {
      return { ...d, _index: index }
    })
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return false
  }
  getRemoveURL(): string {
    return 'credit/book/intro'
  }
  getSubmitURL(): string {
    return 'credit/book/intro'
  }
  getSubmitMethod() {
    return POST
  }
  getSubmitData(): any {
    if (this.isEdit) {
      this.context.list.items.splice(this.params._index, 1, this.data)
    } else {
      this.context.list.items.push(this.data)
    }
    return {
      intro_list: this.context.list.items
    }
  }
  getRemoveParams() {
    this.context.list.items.splice(this.params._index, 1)
    return {
      intro_list: this.context.list.items
    }
  }
  getRemoveMethod() {
    return POST
  }

  requestListReload(option?): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit
  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
