<template>
  <page-layout>
    <page-header :breadcrumb="['首页管理', '应用管理']" />

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-button type="primary" size="small" @click="e => store.edit.onAdd()">
          添加
        </el-button>
      </page-action-bar>
      <el-table :data="list.items">
        <el-table-column label="图标" prop="icon">
          <template v-slot="{ row }">
            <img :src="row.icon" style="width: 40px; height: 40px" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" />
        <el-table-column label="类型" prop="type">
          <template v-slot="{ row }">
            <span>{{
              {
                web: '链接应用',
                apk: 'apk应用'
              }[row.type]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template v-slot="{ row }">
            <el-button type="text" @click="e => store.edit.onEdit(row)">
              编辑
            </el-button>
            <el-button type="text" @click="e => store.edit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import edit from './edit.vue'
import { store } from './store'

export default {
  components: {
    edit
  },
  setup() {
    onMounted(() => {
      store.list.fetch()
    })

    return {
      store,
      list: store.list,
      edit: store.edit
    }
  }
}
</script>
