<template>
  <el-dialog
    :title="edit.isEdit ? '编辑学期' : '新增学期'"
    :visible.sync="edit.visible"
    append-to-body
    width="300px"
  >
    <el-form
      ref="form"
      v-if="edit.visible"
      :model="edit.data"
      :rules="rules"
      label-width="120px"
      @submit="onSubmit"
    >
      <el-form-item label="学年" prop="year">
        <el-select v-model="edit.data.year" placeholder="请选择">
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="学期" prop="term">
        <el-radio-group v-model="edit.data.term">
          <el-radio :label="0"> 上学期 </el-radio>
          <el-radio :label="1"> 下学期 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="统计截止日期" prop="end_date">
        <el-date-picker
          v-model="edit.data.end_date"
          type="date"
          placeholder="请选择"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { termStore } from './store'

export default {
  data() {
    return {
      edit: termStore.termEdit,
      rules: {
        year: { required: true, message: '请填写' },
        term: { type: 'number', required: true, message: '请填写' },
        end_date: { type: 'date', required: true, message: '请填写' }
      },
      yearOptions: _.range(2016, 2026).map(value => {
        return {
          value,
          label: `${value}-${value + 1}学年`
        }
      })
    }
  },

  methods: {
    onSubmit() {
      this.$refs['form'].validate(async v => {
        if (!v) return
        await termStore.termEdit.onEditSubmit()
      })
    }
  }
}
</script>
