<template>
  <div class="page-user">
    <div :loading="list.loading">
      <search />

      <page-action-bar style="margin-top: 16px">
        <div style="font-size: 15px; color: #409eff">
          <i class="el-icon-info" />
          借阅读者<b>{{ list.summary.total || 0 }}</b
          >人, 有卡<b>{{ list.summary.haveCards || 0 }}</b
          >人, 无卡<b>{{ list.summary.notCards || 0 }}</b
          >人, 共<b>{{ list.summary.borrowedNum || 0 }}</b
          >人借过阅读本
        </div>
        <div slot="right">
          <el-button size="small" @click="onExportClick">
            <i class="el-icon-printer" /> 列表导出
          </el-button>
        </div>
      </page-action-bar>

      <el-table :data="list.items">
        <el-table-column
          type="index"
          width="50"
          :index="i => store.indexMethod(i)"
        />
        <el-table-column label="读者信息">
          <template slot-scope="{ row }">
            <div class="table__user-info">
              <div class="name">
                {{ row.userName || '-' }}
                <el-tag
                  v-if="row.status === '1'"
                  type="success"
                  size="mini"
                  disable-transitions
                >
                  在借中
                </el-tag>
                <el-tag
                  v-if="row.status === '3'"
                  type="danger"
                  size="mini"
                  disable-transitions
                >
                  超期{{ Math.abs(row.expireDays) }}天
                </el-tag>
              </div>
              <div v-if="row.cardNum" class="desc" style="white-space: nowrap">
                借阅证: {{ row.cardNum }}
              </div>
              <div v-else class="desc">暂未办理借阅证</div>
              <div class="desc">手机号: {{ row.phone || '-' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="借阅次数" align="right">
          <template slot-scope="{ row }">
            <span v-if="row.status === '4'">-</span>
            <span v-else>{{ row.borrowingTimes }} 次</span>
          </template>
        </el-table-column>
        <el-table-column label="累计借阅天数" align="right">
          <template slot-scope="{ row }">
            <span v-if="row.status === '4'">-</span>
            <span v-else>{{ row.borrowingDays }} 天</span>
          </template>
        </el-table-column>
        <el-table-column label="" width="30px" />
        <el-table-column label="最近借还">
          <template slot-scope="{ row }">
            <span v-if="row.status === '4'">-</span>
            <el-tag
              v-else-if="row.status === '2'"
              type="danger"
              size="mini"
              disable-transition
            >
              还
            </el-tag>
            <el-tag v-else type="success" size="mini" disable-transition>
              借
            </el-tag>
            {{ row.createDate | date('YYYY.MM.DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button type="text" @click="onDetailClick(scope.row)">
              查看详情
            </el-button>
            <el-button type="text" @click="onReadReportClick(scope.row)">
              阅读报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handlePageChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </div>

    <detail />
    <importer />

    <user-reporting ref="userReportDialog" :is-borrower="true" />

    <el-dialog
      title="导出借阅读者"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </div>
</template>

<script lang="ts">
import queryString from 'querystring'
import Search from './borrower-search.component.vue'
import Detail from '@/views/user/borrower/borrower-detail.component.vue'
import UserReporting from '@/views/user/user-reporting/user-reporting.component.vue'
import Importer from './borrower-import.component.vue'
import store from './borrower.store'
import { API_HOST } from '@/config'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: {
    Search,
    Detail,
    UserReporting,
    Importer
  },

  data() {
    return {
      store,
      list: store.list,
      exportVisible: false,
      exportURL: ''
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    fetch() {
      store.fetch()
    },
    handlePageChange(index: number) {
      this.list.index = index
      this.fetch()
    },
    onImportClick() {
      store.onImport()
    },

    onDetailClick(data) {
      store.onDetail(data)
    },

    onReadReportClick(data) {
      this.$refs.userReportDialog.open(data, { isBorrower: true })
    },

    onExportClick() {
      this.exportVisible = true
      const query = queryString.stringify({
        ...store.getSearchParams(),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/reader/lend_users/export?${query}`
    }
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
