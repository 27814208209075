<template>
  <el-dialog
    :visible.sync="detail.visible"
    title="详情"
    class="topic-edit-dialog"
    width="700px"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="topic-detail" v-loading="detail.loading">
      <el-form
        ref="form"
        v-if="detail.visible"
        label-width="6em"
        v-loading="detail.loading"
      >
        <div class="topic-banner">
          <img :src="detail.items.banner" alt="" />
          <div class="title">
            {{ detail.items.title }}
          </div>
        </div>

        <el-form-item label="书单标题:" label-width="6em">
          <span>{{ detail.items.title }}</span>
        </el-form-item>
        <el-form-item label="书单介绍:" label-width="6em">
          <span>{{ detail.items.summary }}</span>
        </el-form-item>

        <div class="book-section-group">
          <div
            class="book-section"
            v-for="(section, index) in detail.items.books"
            :key="index"
          >
            <div class="book-section__header">
              <div class="title">书单{{ index + 1 }}</div>
            </div>

            <div class="book-section__content">
              <div class="thumb">
                <img class="cover-img" :src="section.book.image" />
              </div>
              <div class="content">
                <el-form-item label="标题:">
                  <span>{{ section.book.title }}</span>
                </el-form-item>
                <el-form-item label="出版社:">
                  <span>{{ section.book.publisher }}</span>
                </el-form-item>
                <el-form-item label="介绍:" v-if="section.section_summary">
                  <span>{{ section.section_summary }}</span>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>

    <div slot="footer">
      <el-button type="primary" @click="detail.visible = false">
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from '../topic.store'

export default {
  data() {
    return {
      detail: store.detail
    }
  }
}
</script>

<style scoped lang="scss">
.topic-banner {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  margin-bottom: 10px;
  .title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1.4;
    padding: 0 30px;
    color: #fff;
  }
}
.book-section {
  margin-top: 30px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 10px;
    height: 40px;
    background-color: #eee;
    .title {
      flex: 1;
    }
  }
  &__content {
    display: flex;
    .thumb {
      img {
        width: 120px;
        min-height: 167px;
      }
    }
    .content {
      flex: 1;
    }
  }
}
.topic-edit-dialog {
  ::v-deep .el-form-item {
    margin-bottom: 0;
    .el-form-item__content {
      padding-top: 10px;
      line-height: 1.6;
    }
  }
  .topic-detail {
    width: 550px;
    margin: 0 auto;
  }
}
</style>
