import { MessageBox } from 'element-ui'

export interface MessageBoxServiceOptions {
  title?: string
  message: string
  type?: 'success' | 'warning' | 'info' | 'error'
  dangerouslyUseHTMLString?: boolean
}

export class MessageBoxService {
  static alert(options: MessageBoxServiceOptions) {
    MessageBox.alert(options.message, options.title || '提示', options)
  }
  static async confirm(options: MessageBoxServiceOptions) {
    if (!options.type) {
      options.type = 'warning'
    }
    await MessageBox.confirm(options.message, options.title || '提示', options)
  }
}
