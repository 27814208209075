<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="'题目预览'"
    append-to-body
    width="600px"
  >
    <div class="header">
      <el-tag>{{ QuestType[edit.data.quest_type] }}</el-tag>
    </div>

    <div class="content">
      <div>
        {{ edit.data.quest_content }}
      </div>
      <div class="options">
        <div v-for="(item, index) in edit.data.options" :key="index">
          <span>{{ item.index }}</span> {{ item.value }}
        </div>
      </div>
    </div>

    <div class="answer">
      <span>正确答案:</span>
      <el-tag>
        {{ edit.data.answer }}
      </el-tag>
    </div>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { QuestType, store } from './store'

export default {
  data() {
    return {
      edit: store.edit,
      QuestType
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  margin-top: -10px;
  margin-bottom: 16px;
}
.content {
  margin-bottom: 16px;
  padding: 8px;
  background-color: #f7f7f7;
  .options {
    margin-top: 16px;
    > div {
      line-height: 1.6;
      span {
        margin-right: 0.5em;
      }
    }
  }
}
.answer {
  display: flex;
  align-items: center;
  span {
    margin-right: 1em;
  }
}
</style>
