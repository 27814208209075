<template>
  <el-form class="page-search" :inline="true">
    <el-form-item label="读者姓名">
      <el-input v-model="search.data.user_name_like" size="small" clearable />
    </el-form-item>
    <el-form-item label="账号">
      <el-input v-model="search.data.cx_uname_like" size="small" clearable />
    </el-form-item>
    <el-form-item label="首次登录时间">
      <el-date-picker
        v-model="search.data.first_created_range"
        type="daterange"
        size="small"
        clearable
        style="width: 240px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
      <el-button size="small" @click="onReset"> 重置 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './user.store'

export default {
  data() {
    return {
      search: store.search
    }
  },

  methods: {
    onReset() {
      this.search.data = {}
      store.fetch(true)
    },
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
