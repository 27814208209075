<template>
  <div v-loading="loading">
    <div v-if="!isEdit" v-html="summary" class="text" />

    <div v-else>
      <quill-editor
        v-model="content"
        :options="{
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', { color: [] }],
              [{ indent: '-1' }, { indent: '+1' }, { align: [] }]
            ]
          }
        }"
      />
      <div style="margin-top: 16px">
        <el-button @click="isEdit = false"> 取消 </el-button>
        <el-button :loading="saving" type="primary" @click="submit">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from './store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import { POST } from '../../admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '../../admin-shared-modules/utils'

export default {
  components: { quillEditor },

  data() {
    return {
      store,
      loading: true,
      isEdit: false,
      content: '',
      saving: false
    }
  },
  computed: {
    summary() {
      return store.summary
    }
  },

  async mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      this.loading = true
      await store.fetchSummary()
      this.loading = false
    },
    edit() {
      this.content = this.summary
      this.isEdit = true
    },

    async submit() {
      if (!this.content) {
        MessageService.open({ type: 'warning', message: '请填写内容' })
        return
      }
      this.saving = true
      try {
        await POST('credit/rule/intro', {
          data: { rule_intro: this.content }
        })
        this.isEdit = false
        this.fetch()
        MessageService.open({ type: 'success', message: '保存成功' })
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  padding: 16px;
  max-height: 200px;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 2px rgba(#000, 0.1);
  border-radius: 4px;
}
</style>
