<template>
  <el-dialog
    class="edit-dialog"
    title="添加期刊"
    :visible.sync="currentVisible"
    width="750px"
    top="6vh"
    :close-on-click-modal="false"
  >
    <div>
      <search v-if="currentVisible" />

      <el-alert v-if="selectedItems.length" type="success">
        共选中 {{ selectedItems.length }} 个期刊
      </el-alert>

      <el-table
        ref="table"
        :data="state.list.items"
        size="small"
        @selection-change="onSelectChange"
        v-loading="state.list.loading"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column label="图书名称">
          <template slot-scope="{ row }">
            <div class="book-list__item">
              <div class="thumb thumb--small">
                <img :src="row.coverurl" alt="" />
              </div>
              <div class="content">
                <div class="title">
                  {{ row.magname }}
                </div>
                <div class="desc">
                  {{ row.unit }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="分类" prop="fenleiname" />

        <el-table-column label="ISSN" prop="issn" width="120px" />
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="state.list.index"
        :total="state.list.total"
        :page-size="state.list.size"
        layout="total, prev, pager, next"
        background
      />
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="currentVisible = false"> 取消 </el-button>
      <el-button type="primary" @click="onConfirm"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import store from './store'
import Search from './magazine-select-search.component'

export default {
  components: { Search },

  props: {
    visible: { type: Boolean }
  },
  data() {
    return {
      state: store.state,
      currentVisible: false,
      selectedItems: []
    }
  },
  watch: {
    visible(v) {
      this.currentVisible = v
    },
    currentVisible(v) {
      if (v) {
        store.fetch()
      }
      this.$emit('update:visible', v)
    }
  },

  methods: {
    async handleCurrentChange(index) {
      this.state.list.index = index
      await store.fetch()
      await this.$nextTick()
      this.setTableCurrentSelection()
    },

    onSelectChange(items) {
      const unSelectedItems = _.differenceBy(this.state.list.items, items, 'id')
      this.selectedItems = _.chain(this.selectedItems)
        .unionBy(items, 'id')
        .differenceBy(unSelectedItems, 'id')
        .value()
    },
    setTableCurrentSelection() {
      const selectedRow = _.intersectionBy(
        this.state.list.items,
        this.selectedItems,
        'id'
      )
      _.forEach(selectedRow, row =>
        this.$refs.table.toggleRowSelection(row, true)
      )
    },

    onConfirm() {
      this.$emit('confirm', _.cloneDeep(this.selectedItems))
      this.selectedItems = []
      this.currentVisible = false
    }
  }
}
</script>
