<template>
  <page-layout>
    <page-header :breadcrumb="['设备查询']" />

    <page-main v-loading="list.loading">
      <search />

      <page-action-bar>
        <div style="flex: 1">
          <el-button
            v-if="false"
            type="primary"
            size="small"
            @click="onAddClick"
          >
            新增设备
          </el-button>
        </div>
        <el-button v-if="false" size="small" @click="onImportClick">
          <i class="el-icon-document" /> 批量导入
        </el-button>
        <el-button size="small" @click="onExportClick"> 导出 </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items" size="small">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="device-detail-expand">
              <div class="device-detail-expand__body">
                <p><span>系统版本:</span> {{ props.row.padbookVersion }}</p>
                <p><span>存储容量:</span> {{ props.row.padbookCapacity }}</p>
                <p><span>区域:</span> {{ props.row.areaName }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="index" width="50" :index="indexMethod" />

        <el-table-column label="设备型号">
          <template slot-scope="scope">
            {{ scope.row.padbookType }}
          </template>
        </el-table-column>
        <el-table-column label="设备编号">
          <template slot-scope="scope">
            {{ scope.row.padbookCode }}
          </template>
        </el-table-column>
        <el-table-column label="MAC地址">
          <template slot-scope="scope">
            {{ scope.row.padbookMac }}
          </template>
        </el-table-column>
        <el-table-column label="导入时间">
          <template slot-scope="scope">
            {{ scope.row.createDate | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column v-if="false" label="操作" width="100px">
          <template slot-scope="scope">
            <el-button type="text" @click="onRemoveClick(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
    <importer />
  </page-layout>
</template>

<script lang="ts">
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET } from '@/admin-shared-modules/utils/ajax'
import { AxiosResponse } from 'axios'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import Edit from './device-edit.componet.vue'
import Importer from './device-import.component.vue'
import Search from './device-search.component.vue'
import store from './device.store'

export default {
  components: { Search, Edit, Importer },

  data() {
    return {
      list: store.list,
      search: store.search
    }
  },

  mounted() {
    store.fetch(true)
  },

  methods: {
    //#region ui
    async handleCurrentChange(index) {
      this.list.index = index
      await store.fetch()
    },
    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },
    //#endregion ui

    onAddClick() {
      store.onAdd()
    },
    async onRemoveClick(data) {
      await MessageBoxService.confirm({ message: '确认删除' })
      await store.onRemove(data)
      MessageService.open({ message: '删除成功' })
      store.fetch()
    },

    onImportClick() {
      store.onImport()
    },

    async onExportClick() {
      this.$message({
        message: '正在导出',
        duration: 0
      })
      try {
        const res = (await GET('reader_device/export', {
          data: {
            code: this.search.data.code
          },
          responseType: 'blob'
        })) as unknown as AxiosResponse<Blob>
        const data = res.data as Blob
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = decodeURIComponent(
          res.headers['content-disposition'].split('filename=')[1]
        )
        link.click()
        window.URL.revokeObjectURL(link.href)
      } catch (error) {
        defaultErrorHandler(error)
      } finally {
        setTimeout(() => {
          this.$message.close()
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.device-detail-expand {
  font-size: 14px;
}
</style>
