import { render, staticRenderFns } from "./topic.component.vue?vue&type=template&id=e1ff1724&scoped=true"
import script from "./topic.component.vue?vue&type=script&lang=ts"
export * from "./topic.component.vue?vue&type=script&lang=ts"
import style0 from "./topic.style.scss?vue&type=style&index=0&id=e1ff1724&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1ff1724",
  null
  
)

export default component.exports