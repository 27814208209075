<template>
  <div>
    <page-header :breadcrumb="['数据分析', '开展情况']" />

    <div class="page-dashboard">
      <h4>阅读学分开展情况</h4>
      <el-row :gutter="24" type="flex" class="page-section meta-group">
        <el-col :span="6">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="meta-content">
                <div class="label">学分获得人数</div>
                <div class="content">
                  <span class="num">{{ 2888 | number }}</span>
                </div>
                <div class="desc">占总人数 <span class="blue">66%</span></div>
              </div>
              <div class="chart-right">
                <pie-chart :percent="0.23" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="meta-content">
                <div class="label">总阅读量</div>
                <div class="content">
                  <span class="num">{{ 2888 | number }}</span>
                </div>
                <div class="desc">平台借阅 <span class="blue">66%</span></div>
              </div>
              <div class="chart-right">
                <pie-chart :percent="0.23" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="meta-content">
                <div class="label">通过测评(次)</div>
                <div class="content">
                  <span class="num">{{ 2888 | number }}</span>
                </div>
                <div class="desc">
                  总提交测评(次) <span class="blue">{{ 1991 | number }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="meta-content">
                <div class="label">提交笔记(篇)</div>
                <div class="content">
                  <span class="num">{{ 2888 | number }}</span>
                </div>
                <div class="desc">
                  已评分(篇) <span class="blue">{{ 1991 | number }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="24" type="flex" class="page-section point-group">
        <el-col :span="8">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">近一年累计获得学分人数</div>
              </div>
              <div class="panel__inner-header-desc">
                年增长: {{ 1534 | number }}人
              </div>
              <div ref="lastYearCreditByPeople" style="height: 200px" />
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">每学期获得获得学分人数</div>
              </div>
              <div class="panel__inner-header-desc">
                获得学分人数最多: 2019-2020学年 上学期
              </div>
              <div ref="lastYearCreditByPeople" style="height: 200px" />
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">学分获得率</div>
              </div>
              <div class="panel__inner-header-desc">&nbsp;</div>
              <div ref="lastYearCreditByPeople" style="height: 200px" />
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="24" type="flex" class="page-section lend-group">
        <el-col :span="16">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">近30天借阅量</div>
              </div>
              <div class="panel__inner-header-desc">
                借阅量日最高: 333,333 &nbsp;&nbsp;&nbsp;&nbsp; 借阅量日均值:
                222,222
              </div>
              <div ref="Last30DaysLendOut" style="height: 200px" />
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">近一年借阅量</div>
              </div>
              <div class="panel__inner-header-desc">
                借阅量月均值: 1,333,333
              </div>
              <div ref="lastYearLendCount" style="height: 200px" />
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="24" type="flex" class="page-section testing-group">
        <el-col :span="16">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">测评统计</div>
              </div>
              <el-row>
                <el-col :span="12">
                  测评成绩分布
                  <div ref="testingScoreRange" style="height: 200px" />
                </el-col>
                <el-col :span="12">
                  每学期通过评测数
                  <div ref="testingPassByYear" style="height: 200px" />
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="panel panel--stretch">
            <div class="panel__body">
              <div class="panel__inner-header">
                <div class="title b">笔记得分</div>
              </div>
              <div class="panel__inner-header-desc">
                合格笔记(中及以上)：333,333篇
              </div>
              <div ref="noteScoreRange" style="height: 200px" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import PieChart from './pie-chart.vue'
import BarChart from './bar-chart.vue'
import { store } from './store'

const creditRulesData = [
  { type: 1, name: '借阅图书', a: 2888, b: 9668 },
  { type: 2, name: '借阅专业书', a: 2888, b: 9668 },
  { type: 3, name: '考核图书', a: 2888, b: 9668 },
  { type: 4, name: '考核类别', a: 2888, b: 9668 }
]
const creditUserData = [
  { x: '0-16', y: 3333 },
  { x: '17-32', y: 1633 },
  { x: '33-48', y: 1033 },
  { x: '49-64', y: 1033 },
  { x: '65-80', y: 1033 }
]
const creditUserData2 = [
  { x: '1', y: 3333 },
  { x: '2', y: 1633 },
  { x: '3', y: 1033 },
  { x: '4', y: 1033 },
  { x: '5', y: 1033 }
]

export default {
  components: {
    PieChart,
    BarChart
  },

  data() {
    return {
      state: store.state,
      creditRulesData,
      creditUserData,
      creditUserData2
    }
  }
}
</script>

<style scoped lang="scss" src="../dashboard/dashboard.style.scss"></style>
<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.meta-group {
  .panel__body {
    flex: auto;
    display: flex;
  }
  .chart-right {
    flex: 0 1 90px;
  }
  .meta-content {
    flex: auto;
    display: flex;
    flex-direction: column;
    .label {
      margin-bottom: 8px;
    }
    .content {
      flex: auto;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    .num {
      font-size: 24px;
    }
    .desc {
      color: #777;
    }
  }
}

.chart-name {
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
  color: $--color-text-regular;
}
</style>
