var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-alert',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"info","show-icon":"","closable":false,"title":"可拖拽图片进行排序"}}),_c('div',[_c('draggable',{staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"wrap"},attrs:{"list":_vm.images,"filter":".non-draggable","move":_vm.checkMove}},[_vm._l((_vm.images),function(img,i){return _c('div',{key:i,staticStyle:{"margin-right":"20px","margin-bottom":"20px"},style:({
          width: `${_vm.size.width}px`
        })},[_c('div',{staticStyle:{"background-size":"cover","background-position":"center"},style:({
            width: `${_vm.size.width}px`,
            height: `${_vm.size.height}px`,
            backgroundImage: `url(${img})`
          })}),_c('div',{staticStyle:{"text-align":"center","margin-top":"8px","font-size":"14px","position":"relative"}},[_c('div',[_vm._v("第"+_vm._s(i + 1)+"张")]),_c('div',{staticStyle:{"position":"absolute","right":"0","top":"0"}},[_c('a',{staticStyle:{"color":"#f33","margin-left":"0.8em","cursor":"pointer"},on:{"click":() => {
                  _vm.images.splice(i, 1)
                }}},[_vm._v("删除")])])])])}),_c('el-upload',_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploadProps.uploading.value),expression:"uploadProps.uploading.value"}],staticClass:"upload non-draggable",staticStyle:{"border":"1px dashed #aaa","border-radius":"4px","display":"flex","align-items":"center","justify-content":"center"},style:({
          width: `${_vm.size.width}px`,
          height: `${_vm.size.height}px`
        }),attrs:{"action":"/api/v1/admin/upload/image","show-file-list":false}},'el-upload',_vm.uploadProps,false),[_c('i',{staticClass:"el-icon-upload",staticStyle:{"font-size":"30px"}}),_c('div',[_vm._v("将图片拖到此处，或"),_c('em',[_vm._v("点击上传")])]),_c('div',{staticStyle:{"margin-top":"8px"}},[_vm._v("建议尺寸 1404*1640")])])],2)],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }