import G2 from '@/admin-shared-modules/utils/g2'

export class BookCateChart {
  static chartInstance: any

  static render(el: HTMLDivElement, data: any) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const itemProp = 'tag_name'
    const valueProp = 'count'

    const chart = (this.chartInstance = new G2.Chart({
      container: el,
      height: el.offsetHeight,
      forceFit: true,
      padding: 'auto'
    }))
    chart.source(data, {
      [itemProp]: {},
      [valueProp]: {
        alias: '借阅次数'
      }
    })

    chart.coord('polar', {
      radius: 0.8
    })

    chart.axis(itemProp, {
      line: null,
      tickLine: null
    })
    chart.axis(valueProp, {
      line: null,
      tickLine: null,
      grid: {
        type: 'polygon',
        lineStyle: {
          lineDash: null
        }
      }
    })

    chart.line().position(`${itemProp}*${valueProp}`)
    chart.area().position(`${itemProp}*${valueProp}`)
    chart.render()
  }
}
