import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { GradeNames, TermName } from '@/consts'
import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import _ from 'lodash'

class YearList extends AbstractListService<Store> {
  currentYearId: number
  yearConfig: any = {}
  authState = {}

  getFetchURL(): string {
    return 'credit/year/config'
  }
  parseFetchedItems(data) {
    this.currentYearId = _.get(data, '[0].id')
    return data
  }
  async fetchCurrentYearConfig() {
    this.loading = true
    try {
      const { data } = await GET(`credit/schedule`, {
        data: { year_config_id: this.currentYearId }
      })
      this.yearConfig = data
      this.context.search.data.termIndex =
        _.chain(this.yearConfig.config)
          .find('is_current')
          // @ts-ignore
          .get('index')
          .value() || 0
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.loading = false
    }
  }
  async fetchCurrentYearAuthState() {
    const { data } = await GET('credit/score/status', {
      data: {
        // @ts-ignore
        year: _.chain(this.items)
          .find({ id: this.currentYearId })
          // @ts-ignore
          .get('year')
          .value(),
        score_config_type: _.get(this.yearConfig, 'score_config_type'),
        index: this.context.search.data.termIndex
      }
    })
    this.authState = data
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData(): Partial<any> {
    return {
      termIndex: 0,
      status: 0
    }
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class List extends AbstractListService<Store> {
  showReviewBtn = true
  termName = ''
  getFetchURL(): string {
    return 'credit/score/apply'
  }
  getSearchParams(): any {
    this.showReviewBtn = this.context.search.data.status !== 3
    this.termName = this.getTermName()
    return {
      // @ts-ignore
      year: _.chain(this.context.year.items)
        .find({ id: this.context.year.currentYearId })
        // @ts-ignore
        .get('year')
        .value(),
      score_config_type: _.get(
        this.context.year.yearConfig,
        'score_config_type'
      ),
      index: this.context.search.data.termIndex,
      ...this.context.search.getSearchParams()
    }
  }
  getTermName() {
    const type: number = _.get(
      this.context.year,
      'yearConfig.score_config_type'
    )
    if (type === 1) {
      return TermName[this.context.search.data.termIndex - 1]
    } else if (type === 2) {
      return GradeNames[this.context.search.data.termIndex - 1]
    } else {
      return '大一到大四'
    }
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return 'credit/score/apply/detail'
  }
  getFetchParams(): any {
    // @ts-ignore
    return {
      ..._.chain(this.context.year.yearConfig)
        .get('config')
        // @ts-ignore
        .find({ index: this.context.search.data.termIndex })
        .pick(['begin_date', 'end_date'])
        .value(),
      credit_user_id: this.params.id,
      index: this.context.search.data.termIndex
    }
  }
  parseFetchedFormData(data: any): any {
    return {
      ...data,
      ..._.pick(data.apply, ['status', 'msg', 'score', 'attach_require'])
    }
  }
  getRemoveURL(): string {
    return ''
  }
  getSubmitURL(): string {
    return 'credit/score/apply/review'
  }
  getSubmitMethod() {
    return POST
  }
  getSubmitData(): any {
    return {
      apply_id: _.get(this.data.apply, 'id'),
      ..._.pick(this.data, ['status', 'msg', 'score', 'attach_require'])
    }
  }

  requestListReload(option?): void {
    this.context.year.fetchCurrentYearAuthState()
    this.context.list.fetch()
  }
}

class Store {
  year: YearList
  search: Search
  list: List
  edit: Edit
  constructor() {
    this.year = new YearList(this)
    this.search = new Search(this)
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
