import _ from 'lodash'

export const LibType = {
  1: '公图',
  2: '教图',
  3: '中小学',
  4: '党建'
}

export const GradeNames = ['大一', '大二', '大三', '大四']

export const TermName = _.reduce(
  GradeNames,
  (result, g) => {
    result.push(`${g}上学期`)
    result.push(`${g}下学期`)
    return result
  },
  []
)
