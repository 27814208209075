import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'
import { AbstractSearchService } from '@/core/services/search.service'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { POST } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

export const QuestType = { 0: '单选题', 1: '多选题', 2: '判断题' }

class List extends AbstractListService<Store> {
  book = {} as any
  summary = {}
  size = 50

  getFetchURL(): string {
    return 'credit/book/quest'
  }
  getSearchParams(): any {
    return {
      ...this.context.search.getSearchParams(),
      credit_book_id: this.book.credit_book_id
    }
  }
  parseFetchedItems(data) {
    this.summary = data.statistic
    return data.items
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData() {
    return {
      quest_type: ''
    }
  }
  onSubmit() {
    this.context.list.fetch({ reset: true })
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return `credit/book/quest/${this.params.id}`
  }
  getSubmitURL(): string {
    return ''
  }
  getRemoveURL(): string {
    return `credit/book/quest/remove`
  }
  async onRemove(items) {
    await MessageBoxService.confirm({ message: '确认删除' })
    try {
      await POST(this.getRemoveURL(), {
        data: {
          credit_book_id: this.context.list.book.credit_book_id,
          quest_ids: _.map(items, 'id')
        }
      })
      this.requestListReload({ fixIndex: true })
      MessageService.open({ message: '删除成功' })
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  search: Search
  edit: Edit

  visible = false

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
    this.edit = new Edit(this)
  }

  open(book) {
    this.list.book = book
    this.visible = true
    this.search.data = {}
    this.list.fetch({ reset: true })
  }
}

export const store = new Store()
