<template>
  <div id="presetBookInit">
    <h1 class="t-sec1">
      <b>使用说明</b>
    </h1>
    <div class="t-sec2">
      <h3><b>1.预置图书是什么？</b></h3>
      <div class="content">
        <ul class="list">
          <li>
            您可以选择预置图书，系统将自动传输到阅读本上，读者拿到阅读本时即可阅读。
          </li>
        </ul>
      </div>
    </div>
    <div class="t-sec3">
      <h3><b>2.怎样添加预置图书？</b></h3>
      <div class="content">
        <ul class="list">
          <li>您可以将读书放在文件夹内、或直接添加图书</li>
        </ul>
        <div class="btnArea">
          <el-button type="primary" disabled size="mini" icon="el-icon-plus">
            新建文件夹
          </el-button>
          <el-button type="primary" disabled size="mini"> 添加图书 </el-button>
        </div>
      </div>
    </div>
    <div class="t-sec4">
      <h3><b>3.怎样将预置图书资源传输到阅读本上？</b></h3>
      <div class="content">阅读本连接WiFi后，可自动接收预置图书。</div>
    </div>
    <!-- <div class="intfooter" v-if="!!isShow">
      <div>
        <i class="el-icon-question question"></i>
        <span class="marLeft1">点击此按钮即可查看使用须知</span>
      </div>
      <div class="newfileArea">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="createNewFolder">新建文件夹</el-button>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import store from './preset-book.store'
export default {
  name: 'PresetBookInit',
  data() {
    return {}
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    createNewFolder() {
      store.addFolder()
    }
  }
}
</script>

<style lang="scss" scoped>
#presetBookInit {
  background: #fff;
  padding: 5px;
  h3 {
    margin-top: 20px;
    line-height: 2.5;
    letter-spacing: 1px;
  }
  .content {
    padding-left: 45px;
    font-size: 15px;
    line-height: 1.8;
    padding-right: 20px;
    .list {
      padding: 0;
      margin: 0;
      .emphasize {
        font-weight: 600;
      }
    }
    .btnArea {
      margin-top: 10px;
    }
  }
  .intfooter {
    padding: 40px 20px 10px;
    .newfileArea {
      margin-top: 30px;
      text-align: center;
    }
  }
}
</style>
