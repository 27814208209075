<template>
  <el-dialog
    title="读者详情"
    :visible.sync="visible"
    width="650px"
    @open="onDialogOpen"
    :append-to-body="true"
  >
    <div style="margin-bottom: 8px; margin-top: -16px">
      <h1 style="font-size: 18px; font-weight: bold">
        {{ list.data.user_name }}
      </h1>
    </div>
    <div style="margin-bottom: 16px">
      <span v-if="list.data.cx_uname" style="margin-right: 3em">
        学习通账号: {{ list.data.cx_uname }}
      </span>
      <span>首次登录时间: {{ list.data.first_login_time | date }}</span>
    </div>
    <div style="border-bottom: 1px dashed #e0e0e0; margin: 24px 0 16px" />

    <div class="header" style="display: flex; align-items: center">
      <span style="flex: auto; font-weight: bold; font-size: 16px"
        >最近借过的书</span
      >
      <el-button type="text" @click="onPushCountClick(list.data)">
        共借过{{ list.data.lend_count }}本书<i class="el-icon-arrow-right" />
      </el-button>
    </div>

    <el-table
      style="margin-bottom: 24px; border: 1px solid #e0e0e0"
      :data="list.data.latest_lend_books"
      size="small"
      v-loading="list.data.loading"
    >
      <el-table-column label="书名" :width="300">
        <template slot-scope="scope">
          <div
            class="title"
            style="margin-top: 10px; font-size: 14px; color: #333"
          >
            {{ scope.row.title }}
          </div>
          <small class="desc">{{ scope.row.author | join }}</small>
        </template>
      </el-table-column>
      <el-table-column
        style="margin-bottom: 24px"
        property="publisher"
        label="出版社"
      />
      <el-table-column property="created_at" label="借阅日期" />
    </el-table>

    <el-button
      type="primary"
      size="small"
      @click="onReadReportClick(list.data)"
    >
      查看阅读报告
    </el-button>

    <borrower-push-log ref="userPushLogDialog" />
    <borrower-user-reporting ref="userReportDialog" />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './push-log.store'
import BorrowerPushLog from './push-log.component.vue'
import BorrowerUserReporting from '@/views/user/user-reporting/user-reporting.component.vue'
import { LibraryMember, DeviceUser } from '@/admin-shared-modules/typings'

export default {
  components: { BorrowerPushLog, BorrowerUserReporting },

  data() {
    return {
      visible: false,
      list: store.list
    }
  },

  methods: {
    open(params: LibraryMember | DeviceUser) {
      this.list.params = params
      this.visible = true
    },
    onDialogOpen() {
      store.fetch()
    },
    onPushCountClick(user) {
      this.$refs.userPushLogDialog.open(user)
    },
    onReadReportClick(user) {
      this.$refs.userReportDialog.open(user)
    }
  }
}
</script>
