<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { appStore } from './store'

export default {
  data() {
    return {
      state: appStore.state
    }
  }
}
</script>

<style lang="scss">
@import 'admin-shared-modules/theme/app.scss';
</style>
