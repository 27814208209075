<template>
  <page-layout>
    <page-header
      :breadcrumb="['资源管理', store.isPro ? '专业图书管理' : '通识图书管理']"
    />

    <page-main>
      <search style="margin-bottom: -8px" />
    </page-main>

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-radio-group
          v-model="search.data.tab"
          size="small"
          @change="list.fetch({ reset: true })"
        >
          <el-radio-button :label="0">
            全部 ({{ list.summary.count }})
          </el-radio-button>
          <el-radio-button :label="1">
            电子阅读 ({{ list.summary.tab_1_count || 0 }})
          </el-radio-button>
          <el-radio-button :label="2">
            纸本阅读 ({{ list.summary.tab_2_count || 0 }})
          </el-radio-button>
        </el-radio-group>
        <template slot="right">
          <el-button type="primary" size="small" @click="onAddClick('upload')">
            上传图书
          </el-button>
          <el-button type="primary" size="small" @click="onAddClick('ref')">
            书库选书
          </el-button>

          <el-dropdown @command="onMultiTagClick" style="margin: 0 10px">
            <el-button size="small" :disabled="!selectedItems.length">
              批量操作
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1" :disabled="!selectedItems.length">
                加入分类
              </el-dropdown-item>
              <el-dropdown-item :command="2" :disabled="!selectedItems.length">
                移出分类
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button v-if="store.isPro" size="small" @click="openTagDialog">
            分类管理
          </el-button>
          <el-button size="small" @click="openQuestImport">
            试题导入
          </el-button>
        </template>
      </page-action-bar>

      <el-alert
        v-if="selectedItems.length"
        type="success"
        :title="`已选中 ${selectedItems.length} 条记录`"
        close-text="清空选择"
        @close="cleanSelectedItems"
      />

      <template v-if="!list.loading && list.items.length">
        <el-table
          ref="table"
          :data="list.items"
          size="small"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="封面">
            <template slot-scope="{ row }">
              <img
                :src="row.image"
                alt="封面"
                class="cover-img"
                style="width: 60px"
              />
            </template>
          </el-table-column>
          <el-table-column label="书名" prop="title">
            <template slot-scope="{ row }">
              <div style="font-size: 14px">
                {{ row.title }}
              </div>
              <div style="color: #777">
                {{ row.author | join }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="出版社" prop="publisher" />
          <el-table-column v-if="store.isPro" label="分类">
            <template slot-scope="{ row }">
              <el-tag
                v-for="(tag, index) in row.tags"
                :key="index"
                size="small"
                disable-transitions
                style="margin: 0 4px 4px 0"
              >
                {{ tag.name }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="题库题数" prop="quest_count" align="right" />
          <el-table-column width="20" />
          <el-table-column label="阅读类型">
            <template slot-scope="{ row }">
              <el-tag
                :type="row.is_paper ? 'success' : 'warning'"
                size="small"
                disable-transitions
              >
                {{ row.is_paper ? '纸本' : '电子' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="onEditClick(row)">
                图书编辑
              </el-button>
              <el-button type="text" @click="onQuestDetailClick(row)">
                试题管理
              </el-button>
              <el-button type="text" @click="onRemoveClick(row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        还没有阅读学分图书哦<br />可以上传图书或从书库选书
      </div>
    </page-main>

    <book-selector-dialog
      v-if="bookSelectorVisible"
      :visible.sync="bookSelectorVisible"
      @confirm="onBindBookConfirm"
    />
    <edit />
    <tag-dialog />
    <multi-tag-edit />
    <quest-import />
    <quest-list />

    <el-dialog
      :visible.sync="proTagSelectorVisible"
      title="请选择图书分类"
      class="pro-tag-selector"
    >
      <div class="title">请选择要添加图书的分类</div>
      <el-radio-group v-model="proTagSelectorValue" class="tag-radio-list">
        <el-radio
          v-for="tag in tagStore.list.items"
          :key="tag.id"
          :label="tag.id"
          border
        >
          {{ tag.name }}
        </el-radio>
      </el-radio-group>

      <el-button
        type="text"
        class="tip"
        style="margin-top: 40px"
        @click="closeAndOpenTagDialog"
      >
        以上都不是? 点此进行分类管理
      </el-button>

      <div slot="footer">
        <el-button @click="proTagSelectorVisible = false"> 取消 </el-button>
        <el-button
          type="primary"
          :disabled="!proTagSelectorValue"
          @click="onAddWithTag"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import Search from './seach.vue'
import Edit from './edit.vue'
import TagDialog from './tag/index.vue'
import MultiTagEdit from './multi-tag-edit.vue'
import QuestImport from './quest-import.vue'
import QuestList from './quest/index.vue'
import BookSelectorDialog from './shared/book-selector/index.vue'
import { store as tagStore } from './tag/store'
import { store as questStore } from './quest/store'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export default {
  components: {
    Search,
    TagDialog,
    BookSelectorDialog,
    Edit,
    MultiTagEdit,
    QuestImport,
    QuestList
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search,
      edit: store.edit,
      multiTagEdit: store.multiTagEdit,
      selectedItems: [],

      tagStore,
      questStore,

      proTagSelectorVisible: false,
      proTagSelectorValue: null,
      bookSelectorVisible: false
    }
  },
  watch: {
    async 'list.items'() {
      await this.$nextTick()
      this.setTableCurrentSelection()
    },
    'tagStore.visible'(v) {
      if (!v) {
        store.list.fetch()
      }
    },
    'questStore.visible'(v) {
      if (!v) {
        store.list.fetch()
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    store.isPro = to.name === 'creditBookPro'
    store.list.fetch({ reset: true })
    next()
  },

  methods: {
    openTagDialog() {
      tagStore.open()
    },
    openQuestImport() {
      store.questImport.open()
    },

    onAddClick(type: 'ref' | 'upload') {
      if (type === 'ref') {
        if (store.isPro) {
          this.proTagSelectorVisible = true
          return
        }
        this.bookSelectorVisible = true
      } else {
        this.edit.onAdd({ type })
      }
    },
    onAddWithTag() {
      this.proTagSelectorVisible = false
      this.bookSelectorVisible = true
    },

    onEditClick(item) {
      this.edit.onEdit(item)
    },
    onRemoveClick(item) {
      this.edit.onRemove(item)
    },
    onQuestDetailClick(item) {
      questStore.open(item)
    },

    async onBindBookConfirm(items, ids) {
      await store.edit.bindSelectedBook(ids, this.proTagSelectorValue)
      this.proTagSelectorValue = null
    },

    //#region selection
    handleSelectionChange(items) {
      const key = 'credit_book_id'
      const unSelectedItems = _.differenceBy(this.list.items, items, key)
      this.selectedItems = _.chain(this.selectedItems)
        .unionBy(items, key)
        .differenceBy(unSelectedItems, key)
        .value()
    },
    setTableCurrentSelection() {
      const selectedRow = _.intersectionBy(
        this.list.items,
        this.selectedItems,
        'credit_book_id'
      )
      _.forEach(selectedRow, row =>
        this.$refs.table.toggleRowSelection(row, true)
      )
    },
    cleanSelectedItems() {
      this.selectedItems = []
      this.$refs.table.clearSelection()
    },

    onMultiTagClick(val) {
      this.multiTagEdit.bookIds = _.map(this.selectedItems, 'credit_book_id')
      val === 1 ? this.onAadTagClick() : this.onRemoveTagClick()
    },
    async onAadTagClick() {
      await tagStore.list.fetch()
      if (!tagStore.list.items.length) {
        await MessageBoxService.confirm({
          message: '还未创建学分图书分类，立即为这些书创建一个新分类?'
        })
        tagStore.open()
        return
      }
      this.multiTagEdit.onAdd()
    },
    onRemoveTagClick() {
      const tags = _.chain(this.selectedItems)
        .map('tags')
        .flatten()
        .filter(t => !!t)
        .uniqBy('id')
        .value()
      if (!tags.length) {
        MessageBoxService.alert({
          type: 'warning',
          message: '选中的图书都还没有分类哦'
        })
        return
      }

      this.multiTagEdit.onEdit({
        tags,
        checkedItems: []
      })
    },
    //#endregion

    closeAndOpenTagDialog() {
      this.proTagSelectorVisible = false
      this.openTagDialog()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.pro-tag-selector {
  .title {
    margin-top: -16px;
    margin-bottom: 8px;
  }
  .tag-radio-list {
    display: flex;
    flex-wrap: wrap;
    ::v-deep .el-radio {
      margin: 8px 16px 0 0;
      &__input {
        display: none;
      }
    }
  }
}
</style>
