<template>
  <el-dialog
    :visible.sync="store.visible"
    title="学期配置"
    :close-on-click-modal="false"
    :show-close="!store.isInitial"
  >
    <el-steps
      v-if="store.isInitial"
      :active="store.step"
      align-center
      style="margin-top: -16px; margin-bottom: 40px"
    >
      <el-step title="添加入学年份" />
      <el-step title="学期配置" />
      <el-step title="学分获得规则设置" />
    </el-steps>

    <template v-if="store.step === 0">
      <page-action-bar>
        <div class="title">
          <i class="el-icon-info" />
          以下年份入学的学生可参加阅读学分
        </div>
        <el-button
          slot="right"
          type="primary"
          size="small"
          @click="onAddYearClick"
        >
          添加
        </el-button>
      </page-action-bar>
      <el-table v-loading="store.yearList.loading" :data="store.yearList.items">
        <el-table-column prop="year" label="入学年份" />
        <el-table-column label="操作" width="120px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="onRemoveYearClick(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <edit-year />
      <div slot="footer">
        <el-button type="primary" @click="onNextStep"> 下一步 </el-button>
      </div>
    </template>

    <template v-if="store.step === 1">
      <page-action-bar>
        <el-button
          slot="right"
          type="primary"
          size="small"
          @click="onAddTermClick"
        >
          添加学期
        </el-button>
      </page-action-bar>
      <el-table v-loading="store.termList.loading" :data="store.termList.items">
        <el-table-column prop="year" label="学年" />
        <el-table-column prop="term" label="学期">
          <template slot-scope="{ row }">
            {{ row.term === 0 ? '上学期' : '下学期' }}
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="统计截止日期">
          <template slot-scope="{ row }">
            {{ row.end_date }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="onEditTermClick(row)">
              编辑
            </el-button>
            <el-button type="text" @click="onRemoveTermClick(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <edit-term />
      <div v-if="store.isInitial" slot="footer">
        <el-button @click="store.step = 0"> 上一步 </el-button>
        <el-button type="primary" @click="onNextStep"> 下一步 </el-button>
      </div>
    </template>

    <template v-if="store.step === 2">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 0;
        "
      >
        <div style="font-size: 20px; margin-bottom: 16px">
          可以开始配置学分规则了
        </div>
        <el-button type="primary" @click="onNextStep"> 知道了 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { termStore } from './store'
import EditYear from './edit-year'
import EditTerm from './edit-term'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  components: {
    EditYear,
    EditTerm
  },

  data() {
    return {
      store: termStore
    }
  },

  methods: {
    onAddYearClick() {
      this.store.yearEdit.onAdd()
    },
    onRemoveYearClick(item) {
      this.store.yearEdit.onRemove(item)
    },

    onAddTermClick() {
      this.store.termEdit.onAdd()
    },
    onEditTermClick(item) {
      this.store.termEdit.onEdit(item)
    },
    onRemoveTermClick(item) {
      this.store.termEdit.onRemove(item)
    },

    onNextStep() {
      if (this.store.step === 0) {
        if (!this.store.yearList.items.length) {
          MessageService.open({ type: 'warning', message: '请添加入学年份' })
          return
        }
        this.store.step = 1
        this.store.termList.fetch()
      } else if (this.store.step === 1) {
        if (!this.store.termList.items.length) {
          MessageService.open({ type: 'warning', message: '请配置学期' })
          return
        }
        this.store.step = 2
      } else if (this.store.step === 2) {
        this.store.isInitial = false
        this.store.visible = false
      }
    }
  }
}
</script>

<style scoped></style>
