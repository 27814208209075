import { Loading } from 'element-ui'

let LoadingInstance

export class LoadingService {
  static create(text = '载入中...') {
    if (LoadingInstance) return

    LoadingInstance = Loading.service({
      fullscreen: true,
      text
    })
    return LoadingInstance
  }

  static close() {
    LoadingInstance && LoadingInstance.close()
    LoadingInstance = null
  }
}

export default LoadingService
