import { render, staticRenderFns } from "./folder-edit.component.vue?vue&type=template&id=aaf1e056&scoped=true"
import script from "./folder-edit.component.vue?vue&type=script&lang=ts"
export * from "./folder-edit.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf1e056",
  null
  
)

export default component.exports