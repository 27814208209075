<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '查看配置' : '新增'"
    width="860px"
    top="8vh"
  >
    <el-form inline label-width="5em">
      <el-form-item label="图书数">
        <el-tag size="small"> {{ edit.data.book_count }}本 </el-tag>
      </el-form-item>
      <el-form-item label="推送时间">
        <el-tag size="small">
          {{ edit.data.push_time }}
        </el-tag>
      </el-form-item>
      <el-form-item label="设备">
        <el-tag size="small"> 本馆所有设备 </el-tag>
      </el-form-item>
      <el-form-item label="推送备注" v-if="edit.data.summary">
        <el-tag size="small">
          {{ edit.data.summary }}
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="book-and-folder-list">
      <div class="col" v-for="item in edit.data.folder" :key="item.id">
        <div class="book-folder__grid-item">
          <div class="thumb-grid">
            <div class="cell" v-for="book in getFolderBookLimit(item.books)">
              <div class="thumb">
                <img :src="book.image" alt="" />
              </div>
            </div>
          </div>
          <div class="content">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="desc">{{ item.books.length }}本图书</div>
            <div class="action">
              <el-button type="text" size="small" @click="openFolderEdit(item)">
                查看
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="col" v-for="item in edit.data.books" :key="item.id">
        <div class="book-list__item">
          <div class="thumb">
            <img :src="item.image" alt="" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.author | join }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <folder-edit v-if="edit.visible" :readonly="true" />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store, { Folder } from './preset-book-send.store'
import BookSelectDialog from '@/components/book-selector/book-selector-dialog.component.vue'
import FolderEdit from './folder/folder-edit.component.vue'

export default {
  components: { BookSelectDialog, FolderEdit },

  data() {
    return {
      edit: store.edit,
      bookSelectVisible: false
    }
  },
  computed: {
    bookCount() {
      return _.reduce(
        this.edit.data.folder,
        (result, f) => {
          return result + f.books.length
        },
        0
      )
    }
  },

  methods: {
    getFolderBookLimit(items) {
      return _.take(items, 4)
    },

    openFolderEdit(item: Folder) {
      store.onFolderEdit(item)
    }
  }
}
</script>
