<template>
  <el-dialog
    :visible.sync="store.visible"
    title="读书笔记"
    width="800px"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    append-to-body
  >
    <div v-loading="list.loading">
      <search style="margin-bottom: 20px" />

      <page-action-bar>
        查询到 {{ list.total }} 条记录, {{ summary }}
      </page-action-bar>

      <template v-if="!list.loading && list.items.length">
        <el-table ref="table" :data="list.items" size="small">
          <el-table-column label="书名" prop="title" />
          <el-table-column label="ISBN" prop="isbn_13" />
          <el-table-column label="得分" prop="note_score" align="right" />
          <el-table-column width="40" />
          <el-table-column label="等级" prop="level" />
          <el-table-column label="提交时间">
            <template slot-scope="{ row }">
              {{ row.last_note_time | date('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="{ row }">
              <el-button type="text" @click="e => edit.onEdit(row)">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        暂无记录
      </div>
    </div>

    <edit />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import Search from './seach.vue'
import Edit from './edit.vue'

export default {
  components: {
    Search,
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search,
      edit: store.edit
    }
  },
  computed: {
    summary() {
      return _.chain(this.list.summary)
        .omit('count')
        .pickBy(_.isNumber)
        .map((val, name) => {
          return `${name} ${val} 条`
        })
        .join(', ')
        .value()
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss"></style>
