<template>
  <el-form class="page-search" inline>
    <el-form-item label="专题ID">
      <el-input v-model="search.data.course_id" size="small" clearable />
    </el-form-item>
    <el-form-item label="名称">
      <el-input v-model="search.data.title" size="small" clearable />
    </el-form-item>

    <el-form-item label="分类">
      <book-tag-selector
        v-model="search.data.tag"
        :clearable="true"
        size="small"
        :disabled="search.data.un_bind === 1"
      />
      <el-checkbox
        v-model="search.data.un_bind"
        :true-label="1"
        :false-label="null"
        style="margin-left: 10px"
      >
        未绑定分类
      </el-checkbox>
    </el-form-item>

    <el-form-item label="状态">
      <el-select v-model="search.data.status" size="small" clearable filterable>
        <el-option
          v-for="item in statusOptions"
          :key="item.key"
          :value="item.key"
          :label="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './store'
import BookTagSelector from './shared/tag-selector.vue'

export default {
  components: {
    BookTagSelector
  },

  data() {
    return {
      search: store.search,
      statusOptions: [
        { key: 1, value: '可用' },
        { key: 0, value: '下架' },
        { key: 'PENDING', value: '生成中' },
        { key: 'FAILED', value: '生成失败' }
      ]
    }
  },
  watch: {
    'search.data.property'(val) {
      store.fetchTag(val)
      store.fetch(true)
    }
  },

  methods: {
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
