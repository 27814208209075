import * as constCollection from '@/admin-shared-modules/consts'
import { filesize } from 'filesize'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export const dateFormat = (
  val: string | Date,
  formatter = 'YYYY-MM-DD'
): string => {
  if (!val || /^0000-00-00/.test(`${val}`)) return ''
  return moment(val).format(formatter)
}

export const join = (val: object[], key: string, separator = ', ') => {
  if (key) {
    return _.chain(val).map(key).join(separator).value()
  }
  return _.join(val, separator)
}

export const getConstValue = (val: string | number, constName: string) => {
  return _.get(constCollection[constName], val)
}

export const splitByLength = (val = '', length = 4) => {
  const reg = new RegExp(`.{1,${length}}`, 'g')
  return `${val}`.match(reg)
}

export const humanizeDuration = (val, unit = 'seconds') => {
  const duration = moment().startOf('day').add(unit, val)
  let formatter = ''
  if (duration.hour() > 0) formatter += 'H小时'
  if (duration.minute() > 0) formatter += 'm分'
  formatter += 's秒'
  return duration.format(formatter)
}

export const fileSize = val => {
  return filesize(val)
}

export const numberFormat = (val, formatter = '0,0') => {
  return numeral(val).format(formatter)
}

export const limitLength = (val: string, length = 12) => {
  return _.truncate(val, { length })
}

export const indexPercentage = (val: number, dividend = 1) => {
  return numeral(val).divide(dividend).multiply(100).value()
}
