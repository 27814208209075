<template>
  <page-layout>
    <page-header :breadcrumb="['读者阅读统计']">
      <inner-tabs v-if="isCXApp" />
    </page-header>

    <div class="page-dashboard">
      <div class="panel">
        <div class="panel__header">
          <el-form inline style="flex: 1">
            <el-form-item label="日期" style="margin-bottom: 0">
              <el-date-picker
                v-model="search.dateRange"
                type="daterange"
                size="small"
                :clearable="false"
                style="width: 260px"
              />
            </el-form-item>
          </el-form>
          <el-button size="small" @click="onExportClick">
            <i class="el-icon-printer" /> 列表导出
          </el-button>
        </div>
        <div class="panel__body">
          <el-row :gutter="48" type="flex" class="stretch-items">
            <el-col :span="12">
              <div class="panel panel--no-border panel--stretch">
                <div class="panel__inner-header">
                  <div class="title title--large">阅读时长</div>
                  <div>共 {{ readTimeTotal }} 小时</div>
                </div>
                <div class="panel-stretch-body" v-loading="loading">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="readTimeChart" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="panel panel--no-border panel--stretch">
                <div class="panel__inner-header">
                  <div class="title title--large">借阅图书数</div>
                  <div>共 {{ readCountTotal }} 本</div>
                </div>
                <div class="panel-stretch-body" v-loading="loading">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="readCountChart" />
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="panel__body">
          <div>共 {{ data.length }} 条记录</div>

          <el-table :data="currentTableItems" size="small" v-loading="loading">
            <el-table-column type="index" label="序号" width="120px" />
            <el-table-column label="日期" prop="dt" />
            <el-table-column label="阅读时长(小时)" align="right">
              <template slot-scope="{ row }">
                {{ row.read_time | number('0,0.00') }}
              </template>
            </el-table-column>
            <el-table-column width="10px" />
            <el-table-column
              label="阅读图书(本)"
              align="right"
              prop="read_count"
            />
          </el-table>

          <el-pagination
            :current-page.sync="pageIndex"
            :page-size="30"
            background
            layout="total, prev, pager, next"
            :total="data.length"
          />
        </div>
      </div>
    </div>

    <el-dialog
      title="导出借阅读者"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </page-layout>
</template>

<script type="ts">
import _ from 'lodash'
import { GET } from '@/admin-shared-modules/utils/ajax'
import moment from 'moment'
import { numberFormat } from '@/admin-shared-modules/utils/format'
import { ReadTimeChart } from '@/views/dashboard/reading-report/ReadTimeChart'
import { ReadCountChart } from '@/views/dashboard/reading-report/ReadCountChart'
import { API_HOST } from '@/config'
import queryString from 'querystring'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],

  data() {
    return {
      loading: true,
      data: [],
      pageIndex: 1,
      search: {
        dateRange: [moment().subtract(1, 'months').toDate(), moment().toDate()]
      },
      exportVisible: false,
      exportURL: ''
    }
  },
  computed: {
    readTimeTotal() {
      return numberFormat(_.sumBy(this.data, 'read_time'))
    },
    readCountTotal() {
      return _.sumBy(this.data, 'read_count')
    },
    currentTableItems() {
      return _.slice(this.data, (this.pageIndex - 1) * 30, this.pageIndex * 30)
    }
  },
  watch: {
    'search.dateRange'() {
      this.fetch()
    }
  },

  async mounted() {
    await this.fetch()
  },

  methods: {
    async fetch() {
      this.loading = true
      const res = await GET('report/user_read', {
        data: {
          begin_date: moment(this.search.dateRange[0]).format('YYYY-MM-DD'),
          end_date: moment(this.search.dateRange[1]).format('YYYY-MM-DD')
        },
        timeout: 30 * 1000
      })
      this.data = _.map(res.data, d => ({ ...d, read_time: d.read_time / 60 / 60 }))
      this.loading = false

      ReadTimeChart.render(this.$refs.readTimeChart, this.data)
      ReadCountChart.render(this.$refs.readCountChart, this.data)
    },

    onExportClick() {
      this.exportVisible = true
      const query = queryString.stringify({
        begin_date: moment(this.search.dateRange[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.dateRange[1]).format('YYYY-MM-DD'),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/report/user_read/export?${query}`
    }
  }
}
</script>

<style scoped lang="scss" src="../dashboard.style.scss"></style>
