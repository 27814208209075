<template>
  <div>
    <el-checkbox-group v-model="currentVal" size="small" @change="onChange">
      <el-checkbox
        class="checkbox__item"
        v-for="(item, index) in currentAllTag"
        :label="item.tag_id"
        border
        :key="index"
      >
        {{ item.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './book-sort-selector.store'

export default {
  data() {
    return {
      currentAllTag: this.allTag, //[{tag_id:"",name:""}]
      currentVal: this.value, //[tag_id]
      state: store.state
    }
  },
  props: {
    value: { type: Array, default: () => [] }, //选中的值
    allTag: { type: Array, default: () => [] } //显示的所有分类
  },
  watch: {
    value() {
      return (this.currentVal = this.value)
    }
  },
  methods: {
    onChange(val) {
      const tags = _.reduce(
        val,
        (result, o) => {
          result.push(
            _.find(this.currentAllTag, item => {
              return item.tag_id == o
            })
          )
          return result
        },
        []
      )
      console.log(val, this.currentVal, tags)
      this.$emit('input', val)
      this.$emit('sortChange', tags)
    },
    async initCheck() {
      if (this.currentAllTag.length == 0) {
        await store.fetch()
        this.currentAllTag = this.state.items
      } else {
        // this.currentAllTag = this.allTag
      }
    }
  },
  mounted() {
    this.initCheck()
    console.log(this.currentVal)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
::v-deep.checkbox__item {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(25% - 10px);
  margin-bottom: 8px;
  margin-right: 0;
  margin-left: 10px !important;
}
</style>
