<template>
  <page-layout>
    <page-header :breadcrumb="['Kindle阅读本报表']" />

    <div class="page-dashboard">
      <el-row :gutter="24" type="flex" class="stretch-items page-section">
        <el-col :span="12">
          <div class="panel panel--stretch">
            <div class="panel__header">
              <div class="title">借阅图书</div>
              <div class="actions">
                <date-range-select
                  v-model="bookSendCountData.dateRange"
                  @change="onBookSendCountDateRangeChange"
                />
              </div>
            </div>
            <div
              class="panel__body panel-stretch-body"
              v-loading="bookSendCountData.loading"
            >
              <div class="summary">
                <div class="summary__item">
                  <div class="summary__label">总借阅书数</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ bookSendCountData.count | number }}
                      <span class="unit">本</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item">
                  <div class="summary__label">
                    {{ bookSendCountData.dateRange | dateRangeLabel }}借阅
                  </div>
                  <div class="summary__data">
                    <span class="num">
                      {{ bookSendCountData.weekCount | number }}
                      <span class="unit">本</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="chart-container chart-container--stretch">
                <div class="chart" ref="bookSendCountDataChart" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="panel">
            <div class="panel__header">
              <div class="title">读者统计</div>
              <div class="actions">
                <date-range-select
                  v-model="userCountData.dateRange"
                  @change="onUserCountDataDateRangeChange"
                />
              </div>
            </div>
            <div class="panel__body" v-loading="userCountData.loading">
              <div class="summary">
                <div class="summary__item">
                  <div class="summary__label">服务读者总数</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ userCountData.count | number }}
                      <span class="unit">人</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item">
                  <div class="summary__label">
                    {{ userCountData.dateRange | dateRangeLabel }}新增
                  </div>
                  <div class="summary__data">
                    <span class="num">
                      {{ userCountData.weekCount | number }}
                      <span class="unit">人</span>
                    </span>
                  </div>
                </div>
              </div>
              <el-table :data="userCountData.items" size="small">
                <el-table-column
                  label="排名"
                  prop="ranking"
                  align="center"
                  width="70px"
                />
                <el-table-column width="50px" />
                <el-table-column label="用户名" align="left">
                  <template slot-scope="{ row }">
                    {{ row.user === '匿名用户' ? '体验读者' : row.user }}
                  </template>
                </el-table-column>
                <el-table-column label="借阅次数" align="right" width="100px">
                  <template slot-scope="{ row }">
                    {{ row.count | number }}
                  </template>
                </el-table-column>
                <el-table-column width="10px" />
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">热门资源</div>
        </div>
        <div class="panel__body">
          <el-row :gutter="24" type="flex" class="stretch-items">
            <el-col :span="12">
              <div class="panel panel--no-border panel--stretch">
                <div class="panel__inner-header">
                  <div class="title">热门分类</div>
                </div>
                <div class="panel-stretch-body">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="bookCateDataChart" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="panel__inner-header">
                <div class="title">热门图书</div>
              </div>
              <el-table :data="topBookData.items">
                <el-table-column
                  label="排名"
                  prop="ranking"
                  align="center"
                  width="50px"
                />
                <el-table-column width="50px" />
                <el-table-column
                  label="书名"
                  prop="title"
                  align="left"
                  show-tooltip-when-overflow
                />
                <el-table-column label="借阅次数" align="right" width="80px">
                  <template slot-scope="{ row }">
                    {{ row.count | number }}
                  </template>
                </el-table-column>
                <el-table-column width="10px" />
              </el-table>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script lang="ts">
import DateRangeSelect from '../shared/date-range-select.vue'
import store from './dashboard.store'
import { DateRangeMap } from '../shared/consts'
import { BookSendCountChart } from './chart/BookSendCountChart'
import { BookCateChart } from './chart/BookCateChart'

export default {
  components: {
    DateRangeSelect
  },
  filters: {
    dateRangeLabel: val => {
      return DateRangeMap[val]
    }
  },

  data() {
    return {
      bookSendCountData: store.bookSendCountData,
      userCountData: store.userCountData,
      bookCateData: store.bookCateData,
      topBookData: store.topBookData
    }
  },

  async mounted() {
    await store.fetchBookSendCountData()
    await store.fetchUserCountData()
    await store.fetchBookData()

    await this.$nextTick()
    this.renderBookSendCountChart()
    this.renderBookCateChart()
  },

  methods: {
    async onBookSendCountDateRangeChange() {
      await store.fetchBookSendCountData()
      await this.$nextTick()

      this.renderBookSendCountChart()
    },
    async onUserCountDataDateRangeChange() {
      await store.fetchUserCountData()
      await this.$nextTick()

      this.renderBookCateChart()
    },

    renderBookSendCountChart() {
      BookSendCountChart.render(
        this.$refs.bookSendCountDataChart,
        this.bookSendCountData.items
      )
    },
    renderBookCateChart() {
      BookCateChart.render(
        this.$refs.bookCateDataChart,
        this.bookCateData.items
      )
    }
  }
}
</script>

<style lang="scss" scoped src="../dashboard.style.scss"></style>
