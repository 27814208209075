<template>
  <page-layout>
    <page-header :breadcrumb="['阅读本', '手动借阅']" />

    <page-main>
      <div class="action-buttons">
        <div class="btn borrowing" @click="onBorrowing">
          <i class="el-icon-ant-upload icon-left" /> 借出
        </div>
        <div class="btn return" @click="onReturn">
          <i class="el-icon-ant-download icon-left" /> 归还
        </div>
      </div>
    </page-main>

    <page-main>
      <div class="header">
        <div class="title">最近借阅记录</div>
      </div>

      <el-table ref="table" :data="list.items" v-loading="list.loading">
        <el-table-column type="index" width="50" />

        <el-table-column label="操作类型">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === '1' ? 'success' : 'warning'">
              {{
                scope.row.status | getConstValue('DeviceBorrowingLogStateNames')
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="设备编号">
          <template slot-scope="scope">
            {{ scope.row.padbookCode }}
          </template>
        </el-table-column>
        <el-table-column label="借阅人">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column label="借阅时间">
          <template slot-scope="scope">
            {{ scope.row.createDate | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <borrowing-edit />
    <return-edit />
  </page-layout>
</template>

<script lang="ts">
import store from './borrowing.store'
import BorrowingEdit from './borrowing-edit.component.vue'
import ReturnEdit from './return-edit.component.vue'
import { DeviceBorrowingLog } from '../../admin-shared-modules/typings'

export default {
  components: {
    BorrowingEdit,
    ReturnEdit
  },
  data() {
    return {
      list: store.borrowingList
    }
  },

  mounted() {
    store.fetchLog()
  },

  methods: {
    onBorrowing() {
      store.onBorrowing()
    },
    onReturn() {
      store.onReturn()
    },

    setTableRowClassName({ row }) {
      const data = row as DeviceBorrowingLog
      if (data.status === '1') return 'warning-row'
      else if (data.status === '2') return 'success-row'
    }
  }
}
</script>

<style scoped lang="scss" src="./borrowing.style.scss"></style>
