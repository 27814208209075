<template>
  <div>
    <div style="display: flex">
      <div>
        <el-upload
          class="upload non-draggable"
          action="/api/v1/admin/upload/image"
          :show-file-list="false"
          style="
            flex: 0 0 auto;
            border: 1px dashed #aaa;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
          "
          :style="{
            width: `${size.width}px`,
            height: `${size.height}px`,
            backgroundImage: `url(${image})`
          }"
          v-bind="uploadProps"
          v-loading="uploadProps.uploading.value"
        >
          <template v-if="!image">
            <i class="el-icon-upload" style="font-size: 30px"></i>
            <div>将图片拖到此处，或<em>点击上传</em></div>
          </template>
        </el-upload>

        <div
          v-if="image"
          style="display: flex; justify-content: flex-end; margin-top: 4px"
        >
          <el-upload
            action="/api/v1/admin/upload/image"
            :show-file-list="false"
            v-bind="uploadProps"
          >
            <a>更改</a>
          </el-upload>
          <a
            style="margin-left: 0.8em; color: #f33; cursor: pointer"
            @click="image = null"
            >删除</a
          >
        </div>
      </div>

      <div style="margin-left: 8px">建议尺寸 1404*1872, 4:3比例</div>
    </div>

    <div style="text-align: right">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useUpload } from '../useUpload'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const image = ref<string>()

    image.value = props.data[props.type].images?.[0]

    const uploadProps = useUpload({
      onUpload(url) {
        image.value = url
      }
    })

    function submit() {
      emit('change', props.type, image.value ? [image.value] : [])
    }

    return {
      size: {
        width: 240,
        height: 240 * 1.333
      },
      image,
      uploadProps,
      submit
    }
  }
}
</script>

<style scoped lang="less">
.upload {
  ::v-deep .el-upload {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
