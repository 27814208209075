<template>
  <div v-loading="state.loading">
    <el-table v-if="!state.loading" :data="rows" border size="small">
      <el-table-column prop="level" label="等级" />
      <el-table-column label="分数">
        <template slot-scope="{ row }">
          {{ row.min_score }}-{{ row.max_score }}
        </template>
      </el-table-column>
      <el-table-column label="通过审核">
        <template slot-scope="{ row }">
          {{ row.min_score >= store.data.note_pass_score ? '是' : '否' }}
        </template>
      </el-table-column>
    </el-table>

    <rules-table />
    <rules-edit />
  </div>
</template>

<script>
import { store } from '../store'
import rulesStore from './store'
import RulesTable from './table'
import RulesEdit from './edit'

export default {
  components: {
    RulesTable,
    RulesEdit
  },

  data() {
    return {
      store,
      rulesStore,
      visible: false
    }
  },
  computed: {
    state() {
      return store.data
    },
    rows() {
      return store.data.review_level
    }
  },

  methods: {
    edit() {
      rulesStore.visible = true
      rulesStore.list.fetch()
    }
  }
}
</script>

<style scoped></style>
