<template>
  <page-layout>
    <page-header :breadcrumb="['图书借阅统计']">
      <inner-tabs v-if="isCXApp" />
    </page-header>

    <page-main>
      <search />

      <page-action-bar>
        <div style="flex: 1" />
        <el-button size="small" @click="onExportClick">
          <i class="el-icon-printer" /> 列表导出
        </el-button>
      </page-action-bar>

      <el-row :gutter="24">
        <el-col :span="12" style="border-right: 1px solid #ddd">
          <div class="panel__inner-header">
            <div class="title">分类借阅排名</div>
          </div>
          <el-table
            ref="table"
            :data="cateList.items"
            size="small"
            v-loading="cateList.loading"
          >
            <el-table-column
              label="排名"
              type="index"
              width="50"
              :index="index => cateList.indexMethod(index)"
            />

            <el-table-column label="分类">
              <template slot-scope="{ row }">
                {{ row.tag_name }}
              </template>
            </el-table-column>
            <el-table-column label="借阅次数" align="right">
              <template slot-scope="{ row }">
                {{ row.count | number }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <div class="panel__inner-header">
            <div class="title">图书借阅排名</div>
          </div>
          <el-table
            ref="table"
            :data="bookList.items"
            size="small"
            v-loading="bookList.loading"
          >
            <el-table-column
              label="排名"
              type="index"
              width="50"
              :index="index => bookList.indexMethod(index)"
            />

            <el-table-column label="图书名称">
              <template slot-scope="{ row }">
                <a style="cursor: pointer" @click="onBookDetailClick(row)">
                  {{ row.title }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="作者" show-overflow-tooltip>
              <template slot-scope="{ row }">
                {{ row.author | join }}
              </template>
            </el-table-column>
            <el-table-column label="出版社" show-overflow-tooltip>
              <template slot-scope="{ row }">
                {{ row.publisher }}
              </template>
            </el-table-column>
            <el-table-column label="所属分类" show-overflow-tooltip>
              <template slot-scope="{ row }">
                {{ row.tags | join('name', '-') }}
              </template>
            </el-table-column>
            <el-table-column label="借阅次数" align="right">
              <template slot-scope="{ row }">
                {{ row.count | number }}
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="i => bookList.handlePageIndexChange(i)"
            :current-page="bookList.index"
            :total="bookList.total"
            :page-size="bookList.size"
            layout="total, prev, pager, next"
            background
          />
        </el-col>
      </el-row>
    </page-main>

    <book-detail-dialog />

    <el-dialog
      title="导出借阅记录"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </page-layout>
</template>

<script lang="ts">
import store from './store'
import Search from './search.vue'
import { API_HOST } from '@/config'
import queryString from 'querystring'
import BookDetailDialog from './book-detail-dialog.vue'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: {
    Search,
    BookDetailDialog
  },

  data() {
    return {
      cateList: store.cateList,
      bookList: store.bookList,
      bookDetailDialog: store.bookDetailDialog,
      exportVisible: false,
      exportURL: ''
    }
  },

  mounted() {
    this.cateList.fetch({ reset: true })
    this.bookList.fetch({ reset: true })
  },

  methods: {
    onExportClick() {
      const qs = queryString.stringify({
        ...store.search.getSearchParams(),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/report/book/tag/range/export?${qs}`
      this.exportVisible = true
    },

    onBookDetailClick(item) {
      this.bookDetailDialog.open(item, store.search.data)
    }
  }
}
</script>

<style scoped lang="scss" src="../dashboard.style.scss"></style>
