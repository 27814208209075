import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/note/account'
  }
  parseFetchedItems(data): any[] {
    return data
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return `credit/note/account/${this.params.id}`
  }
  getRemoveURL(): string {
    return `credit/note/account/${this.params.id}`
  }
  getSubmitURL(): string {
    return this.isEdit
      ? `credit/note/account/${this.params.id}`
      : 'credit/note/account'
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch()
  }
}

class Store {
  list: List
  edit: Edit

  visible = false

  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }

  open() {
    this.list.fetch()
    this.visible = true
  }
}

export const store = new Store()
