//@ts-ignore
const G2 = window.G2Plot

export class LendTrendBookChart {
  static chartInstance: any

  static render(el: HTMLDivElement, data: any) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const scaleXProp = 'day'
    const scaleYProp = 'count'

    this.chartInstance = new G2.Line(el, {
      data,
      xField: scaleXProp,
      yField: scaleYProp,
      meta: {
        [scaleXProp]: {
          type: 'time',
          mask: 'MM-DD'
        },
        [scaleYProp]: {
          min: 0,
          alias: '阅读图书数'
        }
      },
      smooth: true
    })

    this.chartInstance.render()
  }
}
