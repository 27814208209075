<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="700px"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="edit.data" :rules="rules" label-width="6em">
      <el-form-item label="标题" prop="title">
        <el-input v-model="edit.data.title" />
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <quill-editor
          v-model="edit.data.content"
          :options="{
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline', { color: [] }],
                [{ indent: '-1' }, { indent: '+1' }, { align: [] }]
              ]
            }
          }"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: { quillEditor },

  data() {
    return {
      edit: store.edit,
      rules: {
        title: { required: true, message: '请填写' },
        content: { required: true, message: '请填写' }
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

::v-deep {
  .quill-editor {
    line-height: 1.6;
  }
  .quill-editor:not(.bubble) .ql-container,
  .quill-editor:not(.bubble) .ql-container .ql-editor {
    min-height: 10rem;
    padding-bottom: 1rem;
  }
  .quill-editor:not(.bubble) .ql-container .ql-editor {
    font-size: 16px;
  }

  .quill-editor {
    p {
      margin: 0 0 1em;
    }
  }
  @for $i from 1 through 9 {
    .ql-editor .ql-indent-#{$i}:not(.ql-direction-rtl) {
      text-indent: #{$i * 2}em;
      padding-left: 0;
    }
  }
}
</style>
