<template>
  <div>
    <el-table
      v-loading="loading"
      :data="rows"
      border
      :show-header="false"
      style="width: 500px"
    >
      <el-table-column prop="name" width="100" align="right" />
      <el-table-column prop="value">
        <template slot-scope="{ row }">
          <div style="padding-left: 8px" v-html="row.value" />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-if="visible" title="考试设置" :visible.sync="visible">
      <el-form
        ref="form"
        :model="formData"
        label-width="100px"
        :rules="rules"
        :close-on-click-modal="false"
      >
        <el-form-item label="规则"> 所有图书统一配置 </el-form-item>
        <el-form-item label="组卷方式" prop="quest_count">
          从图书的题库中随机抽取
          <el-input-number
            v-model="formData.quest_count"
            :min="1"
            controls-position="right"
            size="small"
          />
          道题组卷
        </el-form-item>
        <el-form-item label="总分" prop="score">
          <el-input-number
            v-model="formData.score"
            :min="1"
            controls-position="right"
            size="small"
          />
          分
        </el-form-item>
        <el-form-item label="题型" prop="type">
          <el-radio-group v-model="formData.type">
            <el-radio :label="0"> 自定义 </el-radio>
            <el-radio :label="1"> 组卷时题型随机 </el-radio>
          </el-radio-group>

          <div
            v-if="formData.type === 1"
            style="
              padding: 0 8px;
              margin-bottom: 8px;
              background-color: #f5f5f5;
            "
          >
            每题 {{ autoConfigScore }} 分
          </div>
        </el-form-item>

        <div
          v-if="formData.type === 0"
          style="
            margin-left: 100px;
            padding: 16px 16px 8px;
            margin-bottom: 8px;
            background-color: #f5f5f5;
          "
        >
          <el-form-item
            label="单选题"
            label-width="70px"
            prop="type_config.0"
            :rules="RuleRequiredQuestGroup"
          >
            随机抽取
            <el-input-number
              v-model="formData.type_config['0'].count"
              :min="1"
              controls-position="right"
              size="small"
            />
            题, 每题
            <el-input-number
              v-model="formData.type_config['0'].score"
              :min="1"
              controls-position="right"
              size="small"
            />
            分
          </el-form-item>

          <el-form-item
            label="多选题"
            label-width="70px"
            prop="type_config.1"
            :rules="RuleRequiredQuestGroup"
          >
            随机抽取
            <el-input-number
              v-model="formData.type_config['1'].count"
              :min="1"
              controls-position="right"
              size="small"
            />
            题, 每题
            <el-input-number
              v-model="formData.type_config['1'].score"
              :min="1"
              controls-position="right"
              size="small"
            />
            分
          </el-form-item>

          <el-form-item
            label="判断题"
            label-width="70px"
            prop="type_config.2"
            :rules="RuleRequiredQuestGroup"
          >
            随机抽取
            <el-input-number
              v-model="formData.type_config['2'].count"
              :min="1"
              controls-position="right"
              size="small"
            />
            题, 每题
            <el-input-number
              v-model="formData.type_config['2'].score"
              :min="1"
              controls-position="right"
              size="small"
            />
            分
          </el-form-item>

          <div class="help" style="font-size: 13px; padding-left: 8px">
            (题型的题数不足时，将随机抽取其它题型补足)
          </div>
        </div>

        <el-form-item label="选项随机" prop="option_random">
          <el-radio-group v-model="formData.option_random">
            <el-radio :label="true"> 是 </el-radio>
            <el-radio :label="false"> 否 </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="通过条件" prop="pass_score">
          >=
          <el-input-number
            v-model="formData.pass_score"
            :min="0"
            controls-position="right"
            size="small"
          />
          分
        </el-form-item>
        <el-form-item label="考试时长" prop="test_time">
          <el-input-number
            v-model="formData.test_time"
            :min="0"
            controls-position="right"
            size="small"
          />
          分钟 <span style="margin-left: 4px">(填0为不限制)</span>
        </el-form-item>
        <el-form-item label="考试次数" prop="test_count">
          <el-input-number
            v-model="formData.test_count"
            :min="0"
            controls-position="right"
            size="small"
          />
          次 <span style="margin-left: 4px">(填0为不限制)</span>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="visible = false"> 取消 </el-button>
        <el-button :disabled="saving" type="primary" @click="onSubmit">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { store } from './store'
import { QuestType } from '../credit-book/quest/store'
import { POST } from '../../admin-shared-modules/utils/ajax'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import { numberFormat } from '../../admin-shared-modules/utils/format'
import { defaultErrorHandler } from '../../admin-shared-modules/utils'

const RuleRequired = { required: true, message: '请填写' }

export default {
  data() {
    return {
      store,
      loading: true,

      visible: false,
      saving: false,
      formData: {},

      RuleRequiredQuestGroup: {
        required: true,
        type: 'object',
        fields: {
          count: { ...RuleRequired, type: 'number' },
          score: { ...RuleRequired, type: 'number' }
        }
      },
      rules: {
        quest_count: RuleRequired,
        score: RuleRequired,
        type: { ...RuleRequired, type: 'number' }
      }
    }
  },
  computed: {
    testing() {
      return store.testing
    },
    rows() {
      return [
        { name: '规则', value: '所有图书统一配置' },
        { name: '组卷方式', value: '由题库随机组题' },
        { name: '题量', value: this.testing.quest_count + '题' },
        { name: '总分', value: this.testing.score + '分' },
        (() => {
          if (this.testing.type === 0) {
            const value = _.chain(this.testing.type_config)
              .map((v, k) => {
                return `${QuestType[k]}${v.count}, 每题${v.score}分`
              })
              .join('<br>')
              .value()
            return { name: '题型', value }
          }
          return { name: '题型', value: `题型随机, 每题2分` }
        })(),
        { name: '通过条件', value: `>=${this.testing.pass_score}分` },
        {
          name: '考试时长',
          value: this.testing.test_time
            ? `${this.testing.test_time}分钟`
            : '不限制'
        },
        {
          name: '考试次数',
          value: this.testing.test_count
            ? `${this.testing.test_count}次`
            : '不限制'
        }
      ]
    },
    autoConfigScore() {
      return numberFormat(
        this.formData.score / this.formData.quest_count,
        '0.[00]'
      )
    }
  },

  async mounted() {
    this.fetch()
  },

  methods: {
    edit() {
      this.formData = _.cloneDeep(this.testing)
      this.visible = true
    },
    async fetch() {
      this.loading = true
      await store.fetchTesting()
      this.loading = false
    },

    async onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        if (this.validate()) {
          this.submit()
        }
      })
    },
    validate() {
      if (this.formData.type !== 0) {
        return true
      }
      const questCount = _.reduce(
        this.formData.type_config,
        (result, c) => {
          return result + c.count
        },
        0
      )
      if (questCount !== this.formData.quest_count) {
        MessageService.open({
          type: 'error',
          message: `自定义的题型的总数与总数不符: ${questCount}/${this.formData.quest_count}`
        })
        return false
      }
      const scoreCount = _.reduce(
        this.formData.type_config,
        (result, c) => {
          return result + c.score * c.count
        },
        0
      )
      if (scoreCount !== this.formData.score) {
        MessageService.open({
          type: 'error',
          message: `自定义的题型的总数分与总分不符: ${scoreCount}/${this.formData.score}`
        })
        return false
      }
      if (this.formData.pass_score > this.formData.score) {
        MessageService.open({ type: 'error', message: `通过条件不能高于总分` })
        return false
      }
      return true
    },
    async submit() {
      try {
        this.saving = true
        const data = {
          ...this.formData,
          test_time: this.formData.test_time * 60
        }
        await POST('credit/test/config', {
          data: { data }
        })
        this.visible = false
        this.fetch()
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style scoped></style>
