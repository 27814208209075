<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="'笔记评分'"
    width="700px"
    :close-on-click-modal="false"
  >
    <div class="user-info">
      <div class="title">图书信息</div>
      <el-row type="flex">
        <el-col :span="8">
          <span class="label">书名:</span> {{ edit.params.title }}年
        </el-col>
        <el-col :span="8">
          <span class="label">ISBN:</span> {{ edit.params.isbn_13 }}
        </el-col>
        <el-col :span="8">
          <span class="label">出版年份:</span> {{ edit.params.year }}年
        </el-col>
      </el-row>
    </div>
    <div class="user-info">
      <div class="title">学生信息</div>
      <el-row type="flex">
        <el-col :span="8">
          <span class="label">姓名:</span> {{ edit.params.user_name }}
        </el-col>
        <el-col :span="8">
          <span class="label">学号:</span> {{ edit.params.card_num }}
        </el-col>
        <el-col :span="8">
          <span class="label">专业:</span> {{ edit.params.profession }}
        </el-col>
      </el-row>
    </div>
    <div class="user-info">
      <div class="title">
        已提交笔记
        <span v-if="edit.data.notes">(共{{ edit.data.notes.length }}篇)</span>
      </div>
      <div class="note-list">
        <div v-for="(note, index) in edit.data.notes" :key="index" class="item">
          <div class="body">
            <div v-if="note.title" class="title">
              {{ note.title }}
            </div>
            <div class="content">
              {{ note.content }}
            </div>
          </div>
          <a :href="note.shareUrl" target="_blank" class="action">查看笔记</a>
        </div>
      </div>
    </div>

    <el-form
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="5em"
      style="margin-top: 16px"
    >
      <el-form-item label="评分" prop="note_score">
        <el-input-number
          v-model="edit.data.note_score"
          controls-position="right"
          style="width: 150px"
        />
        <span v-if="levelName" style="margin-left: 2em">
          等级: {{ levelName }}
        </span>
      </el-form-item>
      <el-form-item label="评分人">
        {{ edit.data.reviewer || user.data.name }}
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        审核评分
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { findLevelConfig, store } from './store'
import { appStore } from '@/store'

export default {
  data() {
    return {
      user: appStore.state.user,
      edit: store.edit,
      rules: {
        note_score: { type: 'number', required: true, message: '请填写' }
      }
    }
  },

  computed: {
    levelName() {
      const levelConfig = findLevelConfig(this.edit.data.note_score)
      return _.get(levelConfig, 'level')
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.user-info {
  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid $--border-color-base;
  border-radius: $--border-radius-base;
  &:first-child {
    margin-top: -20px;
  }
  > .title {
    margin-bottom: 16px;
    font-size: $--font-size-large;
    font-weight: bold;
  }
  .el-row {
    margin-top: 8px;
    font-size: $--font-size-base + 1;
  }
  .label {
    margin-right: 8px;
    color: $--color-text-secondary;
  }
}
.note-list {
  .item {
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    line-height: 1.4;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    .body {
      flex: auto;
      .title {
        margin-bottom: 4px;
        font-size: $--font-size-base + 1;
        font-weight: bold;
      }
      .content {
        color: $--color-text-secondary;
      }
    }
    .action {
      flex: 0 0 auto;
      align-self: center;
      margin-left: 20px;
    }
  }
}
</style>
