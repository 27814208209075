<template>
  <el-dialog
    :title="edit.isEdit ? '编辑' : '新增'"
    :visible.sync="edit.visible"
    append-to-body
    width="300px"
  >
    <el-form
      ref="form"
      v-if="edit.visible"
      :model="edit.data"
      :rules="rules"
      label-width="120px"
      @submit="onSubmit"
    >
      <el-form-item label="入学年份" prop="year">
        <el-input-number v-model="edit.data.year" controls-position="right" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { termStore } from './store'

export default {
  data() {
    return {
      edit: termStore.yearEdit,
      rules: {
        year: [
          { required: true, message: '请填写' },
          { type: 'number', min: 2016, message: '学年需要在2016年之后' }
        ]
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs['form'].validate(async v => {
        if (!v) return
        await termStore.yearEdit.onEditSubmit()
      })
    }
  }
}
</script>
