<template>
  <page-layout>
    <page-header :breadcrumb="['书单管理']" />

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-radio-group
          v-model="search.data.scope"
          @change="scopeChange"
          style="flex: 1"
          size="small"
        >
          <el-radio-button label="all"> 全部 </el-radio-button>
          <el-radio-button label="public"> 公共书单 </el-radio-button>
          <el-radio-button label="private"> 本馆书单 </el-radio-button>
        </el-radio-group>

        <div>
          <el-button
            v-show="!isSorting"
            type="primary"
            size="small"
            @click="onAddClick"
          >
            新增书单
          </el-button>
          <el-button
            v-show="!isSorting"
            type="primary"
            size="small"
            @click="onAddClick('AddBySSID')"
          >
            通过id创建书单
          </el-button>

          <template v-if="!isSorting && isShowSort">
            <el-button
              size="small"
              @click="isSorting = true"
              :disabled="list.items.length == 0"
            >
              <i class="el-icon-sort" />书单排序
            </el-button>
          </template>
          <template v-if="isSorting">
            <el-button size="small" type="primary" @click="onSortSubmit">
              保存
            </el-button>
            <el-button size="small" @click="onSortCancel"> 取消 </el-button>
          </template>
        </div>
      </page-action-bar>

      <div class="table">
        <div class="table__header">
          <div class="index">序号</div>
          <div class="title">书单名称</div>
          <div class="bookNum">图书数量</div>
          <div class="create-at">创建时间</div>
          <div class="operate">操作</div>
        </div>
        <div class="table__content">
          <draggable
            v-model="list.items"
            :options="{ ghostClass: 'sort-placeholder', sort: isSorting }"
          >
            <div
              :class="{ row: true, sorting: isSorting }"
              v-for="(item, index) in list.items"
              :key="index"
            >
              <div class="index">
                {{ index + 1 }}
              </div>
              <div class="title">
                {{ item.title }}
                <el-tag
                  class="tag"
                  size="mini"
                  v-if="!!item.is_private_project"
                >
                  本馆自建
                </el-tag>
                <el-tag
                  type="warning"
                  class="tag"
                  size="mini"
                  v-if="!!item.is_home_page"
                >
                  首页展示
                </el-tag>
                <el-tag
                  type="danger"
                  class="tag"
                  size="mini"
                  v-if="!!item.is_top"
                >
                  置顶推荐
                </el-tag>
              </div>
              <div class="bookNum">{{ item.book_list_count }}本</div>
              <div class="create-at">
                {{ item.created_at | date('YYYY/MM/DD') }}
              </div>
              <div class="operate">
                <el-button type="text" @click="onShowDetail(item)">
                  查看
                </el-button>
                <el-button
                  type="text"
                  v-if="!!item.is_private_project"
                  @click="onEditClick(item)"
                >
                  编辑
                </el-button>
                <el-button type="text" @click="onRemoveClick(item)">
                  删除
                </el-button>
              </div>
            </div>
          </draggable>
          <div
            style="text-align: center; margin-top: 10px"
            v-if="!list.items.length"
          >
            暂无数据
          </div>
        </div>
      </div>
    </page-main>

    <edit />

    <detail />
  </page-layout>
</template>

<script lang="ts">
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import Draggable from 'vuedraggable'
import detail from './shared/topic-detail-dialog.component.vue'
import Edit from './topic-edit.component.vue'
import store from './topic.store'

export default {
  components: { Edit, Draggable, detail },
  data() {
    return {
      list: store.list,
      search: store.search,
      isSorting: false,
      isShowSort: true
    }
  },

  mounted() {
    store.fetch(true)
  },

  methods: {
    scopeChange(val) {
      this.isSorting = false
      this.isShowSort = !(val === 'private' || val === 'public')
      this.handleCurrentChange(1)
    },

    //#region ui
    async handleCurrentChange(index) {
      this.list.index = index
      await store.fetch()
    },
    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },
    //#endregion ui

    onAddClick(type?: string) {
      store.onAdd(type)
    },
    onShowDetail(item) {
      store.onShowDetail(item)
    },
    onEditClick(item) {
      store.onEdit(item)
    },
    async onRemoveClick(item) {
      let message = ''
      if (item.is_home_page && !item.is_top) {
        message = '此书单正在首页展示，请取消推荐后再删除'
      } else if (item.is_home_page && item.is_top) {
        message = '此书单正在置顶推荐和首页展示，请取消推荐后再删除'
      } else if (!item.is_home_page && item.is_top) {
        message = '此书单正在置顶推荐，请取消推荐后再删除'
      }
      if (message) {
        this.$alert(message, '提示', {
          confirmButtonText: '知道了'
        })
        return
      }

      await MessageBoxService.confirm({
        message: '确定删除此书单吗？此操作不可撤销'
      })
      store.onRemove(item)
    },
    onSortSubmit() {
      this.isSorting = !this.isSorting
      store.onSortSubmit()
    },
    onSortCancel() {
      this.isSorting = false
      store.fetch(true)
    }
  }
}
</script>

<style scoped lang="scss" src="./topic.style.scss"></style>
