<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '批量移出分类' : '批量加入分类'"
    width="600px"
  >
    <div style="margin-top: -16px; margin-bottom: 16px">
      请选择要添加的分类（可多选）
    </div>

    <el-checkbox-group
      v-if="edit.visible"
      v-model="edit.data.checkedItems"
      class="tag-list"
    >
      <el-checkbox
        v-for="(item, index) in listItems"
        :key="index"
        :label="item.id"
        border
      >
        {{ item.name }}
      </el-checkbox>
    </el-checkbox-group>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="
          !edit.data.checkedItems ||
          !edit.data.checkedItems.length ||
          edit.loading ||
          edit.saving
        "
        @click="onSubmit"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'
import { store as tagStore } from './tag/store'

export default {
  data() {
    return {
      edit: store.multiTagEdit,
      list: tagStore.list
    }
  },
  computed: {
    listItems() {
      return this.edit.isEdit ? this.edit.params.tags : this.list.items
    }
  },

  methods: {
    onSubmit() {
      this.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  .el-checkbox {
    flex: 0 0 calc(33% - 16px);
    max-width: calc(33% - 16px);
    margin: 0 0 16px 16px !important;
  }
}
</style>
