<template>
  <page-layout>
    <page-header
      :breadcrumb="isCXApp ? ['本馆图书管理'] : ['图书', '图书管理']"
    />

    <page-main v-loading="state.list.loading">
      <el-form class="page-search" :inline="true">
        <el-form-item label="分类">
          <book-cate-selector
            v-model="state.search.data.tags"
            :clearable="true"
            size="small"
          />
        </el-form-item>
        <el-form-item label="图书名称">
          <el-input v-model="state.search.data.title" size="small" clearable />
        </el-form-item>
        <el-form-item label="ISBN">
          <el-input v-model="state.search.data.isbn" size="small" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">
            查询
          </el-button>
          <el-button size="small" @click="onReset"> 重置 </el-button>
        </el-form-item>
      </el-form>

      <div class="border" />

      <page-action-bar>
        <el-radio-group
          v-if="!isCXApp"
          v-model="state.search.data.scope"
          @change="onSubmit"
          size="small"
          style="flex: 1"
        >
          <el-radio-button label="all"> 全部 </el-radio-button>
          <el-radio-button label="public"> 云端书城 </el-radio-button>
          <el-radio-button label="private"> 本馆图书 </el-radio-button>
        </el-radio-group>

        <el-button type="primary" size="small" @click="onAddClick">
          新增图书
        </el-button>
        <el-dropdown
          @command="onBatchCommand"
          v-if="state.search.data.scope === 'private'"
          style="margin-left: 15px"
        >
          <el-button
            type="info"
            size="small"
            v-if="multipleSelection.length === 0"
          >
            批量操作<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-button
            type="primary"
            class="el-dropdown-link"
            size="small"
            :disabled="multipleSelection.length === 0"
            v-else
          >
            批量操作<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="tagCreate"> 新增分类 </el-dropdown-item>
            <el-dropdown-item command="tagRemove"> 移除分类 </el-dropdown-item>
            <el-dropdown-item command="bookBatch"> 删除图书 </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </page-action-bar>

      <el-tag
        v-if="multipleSelection.length"
        disable-transitions
        style="margin-top: 10px"
      >
        共选中<span class="blue"> {{ multipleSelection.length }} </span>本图书
      </el-tag>

      <el-table
        :data="state.list.items"
        size="small"
        class="bookTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          v-if="state.search.data.scope == 'private'"
          key="1"
        />

        <el-table-column label="图书信息" key="2">
          <template slot-scope="scope">
            <div class="book-dec">
              <div class="book-dec__left">
                <img :src="scope.row.image" alt="封面" class="cover-img" />
              </div>
              <div class="book-dec__right">
                <div class="common title">
                  {{ scope.row.title }} {{ scope.row.subtitle }}
                </div>
                <div class="common">
                  {{ scope.row.author | join }}
                </div>
                <div class="common">
                  {{ scope.row.publisher }}
                </div>
                <div class="common">出版时间: {{ scope.row.pub_date }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="category" label="分类" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ tagsFilter(scope.row.tags) }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="120px"
          v-if="state.search.data.scope !== 'public'"
          key="4"
        >
          <template slot-scope="scope" v-if="!!scope.row">
            <el-button
              v-if="scope.row.library_id"
              type="text"
              @click="onEditClick(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="scope.row.library_id"
              type="text"
              @click="onRemoveClick(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        width="40%"
        :title="state.batchsort.isAdd ? '新增分类' : '移除分类'"
        :visible.sync="state.batchsort.visible"
        :before-close="cancelFix"
        class="batchSortDialog"
      >
        <div class="book-sort-step" v-if="state.batchsort.visible">
          <div class="book-sort-step__section1">
            <div class="section1__select-tag">
              <div v-if="state.batchsort.isAdd">
                为选中的
                <span style="color: #409eff">{{
                  multipleSelection.length
                }}</span>
                本本馆图书新增分类如下：
              </div>
              <div v-else>
                为选中的
                <span style="color: #409eff">{{
                  multipleSelection.length
                }}</span>
                本本馆图书从以下分类中移出：
              </div>
              <div class="tag">
                <el-tag
                  v-for="tag in tags"
                  :key="tag.tag_id"
                  closable
                  @close="onRemoveTag(tag)"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </div>
          </div>
          <div class="book-sort-step__section2">
            <book-sort-selector
              v-model="state.batchsort.tagIds"
              @sortChange="sortChangeSelect"
              v-if="state.batchsort.isAdd"
            />
            <book-sort-selector
              v-model="state.batchsort.tagIds"
              :all-tag="state.batchsort.tagsData"
              @sortChange="sortChangeSelect"
              v-else
            />
          </div>
        </div>
        <div slot="footer">
          <el-button @click="cancelFix"> 取消 </el-button>
          <el-button type="primary" @click="onChangeSelectTagSubmit">
            保存
          </el-button>
        </div>
      </el-dialog>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="state.list.index"
        :total="state.list.total"
        :page-size="state.list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { AppStateMixin } from '@/core/appStateMixin'
import _ from 'lodash'
import Edit from './book-edit.component.vue'
import store from './book.store'
import BookCateSelector from './shared/book-cate-selector.vue'
import BookSortSelector from './shared/book-sort-selector.vue'

export default {
  mixins: [AppStateMixin],
  components: { BookCateSelector, Edit, BookSortSelector },

  data() {
    return {
      state: store.state,
      multipleSelection: [],
      tags: []
    }
  },

  mounted() {
    if (this.isCXApp) {
      this.state.search.data.scope = 'private'
    }
    this.state.list.index = 1
    store.fetch()
  },

  methods: {
    onReset() {
      store.restSearch()
    },
    onSubmit() {
      this.state.list.index = 1
      store.fetch()
    },
    handleCurrentChange(index) {
      this.state.list.index = index
      store.fetch()
    },

    tagsFilter(tags) {
      tags = _.map(tags, tag => tag.name)
      return _.join(tags, ',')
    },

    onAddClick() {
      this.state.search.data.tags = []
      store.onAdd()
    },
    onEditClick(item) {
      store.onEdit(item)
    },
    async onRemoveClick(data) {
      await MessageBoxService.confirm({ message: '确认删除' })
      await store.onRemove([data])
      MessageService.open({ message: '删除成功' })
      store.fetch()
    },
    async onBatchCommand(command) {
      //批量操作
      if (this.multipleSelection.length == 0) {
        this.$alert('请选择图书', '提示', {
          confirmButtonText: '知道了',
          type: 'warning',
          callback: action => {}
        })
        return
      }
      switch (command) {
        case 'tagCreate':
          store.tagCreateBatch(this.multipleSelection)
          break
        case 'tagRemove':
          store.tagRemoveBatch(this.multipleSelection)
          break
        case 'bookBatch':
          await MessageBoxService.confirm({
            message: `确认删除这${this.multipleSelection.length}本图书吗？`
          })
          await store.bookBatchRemove(this.multipleSelection)
          MessageService.open({ message: '删除成功' })
          break
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    sortChangeSelect(tags) {
      this.tags = tags
    },
    onRemoveTag(tag) {
      this.changeSelectTag.splice(
        this.tags.indexOf(
          _.find(this.tags, item => {
            return item.tag_id == tag.tag_id
          })
        ),
        1
      )
    },
    cancelFix() {
      this.state.batchsort.visible = false
      this.state.batchsort.tagIds = []
      this.tags = []
    },
    async onChangeSelectTagSubmit() {
      this.state.batchsort.tagIds = _.map(this.tags, 'tag_id')
      await store.soreOperationSubmit()
      this.tags = []
      console.log(this.state.batchsort.tagsData)
    }
  }
}
</script>

<style scoped lang="scss" src="./book.style.scss"></style>
