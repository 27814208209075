<template>
  <page-layout id="presetBookManage" v-loading="state.loading">
    <page-header :breadcrumb="['图书', '预置图书', '图书管理']" />

    <page-main class="head">
      <div class="head-sec1 clearfix">
        <span class="title" v-if="folder.current.name"
          >名称:&nbsp;{{ folder.current.name }}</span
        >
        <el-button type="text" class="button" @click="editFolder">
          编辑
        </el-button>
        <el-button
          type="text"
          class="button"
          @click="goback"
          style="float: right"
        >
          返回列表
        </el-button>
      </div>
      <el-row class="dec">
        <el-col :span="8" v-if="!!folder.current.books">
          图书数量: {{ folder.current.books.length }}
        </el-col>
        <el-col :span="8"> 创建时间: {{ folder.current.created_at }} </el-col>
        <el-col :span="8"> 更新时间: {{ folder.current.updated_at }} </el-col>
      </el-row>
    </page-main>

    <el-card class="bookManageArea" shadow="never">
      <div slot="header" class="m-head">
        <el-row>
          <el-col :span="12" style="line-height: 2">
            <b><big>图书管理</big></b>
          </el-col>
          <el-col :span="12" class="btn">
            <template v-if="!sorting">
              <el-button type="primary" size="small" @click="isVisibleAddBook">
                <i class="el-icon-plus" size="small" />添加图书
              </el-button>
              <el-button size="small" @click="onSorting">
                <i class="el-icon-sort" />图书排序
              </el-button>
            </template>
            <template v-else>
              <el-button type="primary" size="small" @click="onSorting">
                <i class="el-icon-edit" /> 保存排序
              </el-button>
              <el-button size="small" @click="cancelSorting"> 取消 </el-button>
            </template>
          </el-col>
        </el-row>
      </div>

      <div class="item">
        <draggable
          class="preset-grid flex"
          v-model="folder.current.books"
          :options="{ ghostClass: 'sort-placehoder', sort: sorting }"
        >
          <div
            :class="{ 'preset-grid__item': true, 'is-draggable': sorting }"
            v-for="(item, index) in folder.current.books"
            :key="index"
          >
            <el-card
              class="preset-card"
              shadow="hover"
              :body-style="{ display: 'flex', padding: '15px' }"
            >
              <span class="index" v-show="sorting">{{ index + 1 }}</span>
              <div class="img">
                <img :src="item.image" alt="缩略图" />
              </div>
              <div class="right">
                <div class="title clamp2">
                  {{ item.title }}
                </div>
                <div class="author clamp2" v-if="!!item.author">
                  <span v-for="(a, b) in item.author" :key="b"
                    >{{ a
                    }}<span v-if="b < item.author.length - 1">,</span></span
                  >
                </div>
                <div class="clamp2">
                  {{ item.publisher }}
                </div>
                <div class="actions" :style="{ opacity: sorting ? 0 : 1 }">
                  <el-button
                    type="text"
                    size="small"
                    @click.stop="onRemoveHandler(item)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </el-card>
          </div>
        </draggable>
        <div
          v-if="!!folder.current.books && folder.current.books.length == 0"
          style="text-align: center; line-height: 3"
        >
          暂无图书
        </div>
      </div>
    </el-card>

    <folder-dialog />

    <add-book :visible.sync="isVisible" @confirm="addBookSubmit" />
  </page-layout>
</template>

//
<script lang="ts">
import _ from 'lodash'
import store from '../preset-book.store'
import { MessageBoxService } from '../../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../../admin-shared-modules/utils/message.service'
import Draggable from 'vuedraggable'
import folderDialog from '../preset-book-edit.component.vue'
import addBook from '@/components/book-selector/book-selector-dialog.component.vue'

export default {
  name: 'PresetBookDetail',
  data() {
    return {
      isVisible: false,
      bookData: {
        isClearSelection: false
      },
      state: store.state,
      folders: store.folder,
      folder: store.detail,
      sorting: false
    }
  },
  components: {
    Draggable,
    folderDialog,
    addBook
  },
  mounted() {
    this.checkInit()
  },
  methods: {
    goback() {
      this.$router.push({ name: 'presetBook' })
    },
    async checkInit() {
      const id = this.$route.query.id
      if (this.folders.data.length == 0) {
        await store.fetch()
        //console.log('check')
      }
      store.setCureetFolder(id)
      //console.log(store.detail.current)
      //console.log(this.folder)
    },
    async cancelSorting() {
      //取消排序
      this.sorting = false
      await store.fetch()
      this.checkInit()
    },
    async onSorting() {
      if (this.sorting) {
        //正在排序并保存，需更新排序结果
        await store.setSorting()
        store.setCureetFolder(this.folder.current.id)
        MessageService.open({ message: '排序保存成功' })
        // console.log(this.init.updateFolder)
      }
      this.sorting = !this.sorting
    },
    async editFolder() {
      await store.editFolder(this.folder.current)
      this.checkInit()
    },
    isVisibleAddBook() {
      this.isVisible = !this.isVisible
    },
    // async addBookInit() {//添加图书
    //   this.isVisibleAddBook()
    // },
    async addBookSubmit(books, ids) {
      await store.addBook(ids, { from: 'detail' })
      store.setCureetFolder(this.folder.current.id)
    },
    async onRemoveHandler(data) {
      //删除图书
      await MessageBoxService.confirm({
        type: 'warning',
        message: '确定删除该图书？删除后不可恢复！'
      })
      await store.removeBook(data, { from: 'detail' })
    }
  }
}
</script>

<style lang="scss" src="./preset-book-detail.style.scss"></style>
