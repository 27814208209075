<template>
  <el-dialog
    :visible.sync="store.visible"
    title="借阅历史"
    width="800px"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    append-to-body
  >
    <div v-loading="list.loading">
      <search />

      <page-action-bar>
        <el-radio-group
          v-model="search.data.tab"
          size="small"
          @change="list.fetch({ reset: true })"
        >
          <el-radio-button :label="0">
            全部 ({{ list.summary.count }})
          </el-radio-button>
          <el-radio-button :label="1">
            电子阅读 ({{ list.summary.plat_lend_count || 0 }})
          </el-radio-button>
          <el-radio-button :label="2">
            纸本阅读 ({{ list.summary.paper_lend_count || 0 }})
          </el-radio-button>
        </el-radio-group>
      </page-action-bar>

      <template v-if="!list.loading && list.items.length">
        <el-table ref="table" :data="list.items" size="small">
          <el-table-column label="书名" prop="title" />
          <el-table-column label="ISBN" prop="isbn_13" />
          <el-table-column label="作者">
            <template slot-scope="{ row }">
              {{ row.author | join }}
            </template>
          </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="{ row }">
              <template v-if="row.is_professional">
                <el-tag
                  v-for="(tag, index) in row.tags"
                  :key="index"
                  size="small"
                  disable-transitions
                  style="margin: 0 4px 4px 0"
                >
                  {{ tag.name }}
                </el-tag>
              </template>
              <el-tag v-else size="small" disable-transitions>
                通识书单
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="借阅时间">
            <template slot-scope="{ row }">
              {{ row.borrow_time | date('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        暂无记录
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'
import Search from './seach.vue'

export default {
  components: {
    Search
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss"></style>
