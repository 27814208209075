<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="姓名">
      <el-input v-model="search.data.user_name_like" clearable />
    </el-form-item>
    <el-form-item label="学号">
      <el-input v-model="search.data.card_num_like" clearable />
    </el-form-item>
    <el-form-item label="学院">
      <el-input v-model="search.data.college_like" clearable />
    </el-form-item>
    <el-form-item label="专业">
      <el-input v-model="search.data.profession_like" clearable />
    </el-form-item>
    <el-form-item label="入学年份">
      <year-selector v-model="search.data.year" clearable />
    </el-form-item>
    <el-form-item label="手机号">
      <el-input v-model="search.data.phone_like" clearable />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { store } from './store'
import YearSelector from '@/components/credit-year-selector.vue'

export default {
  components: {
    YearSelector
  },

  data() {
    return {
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
