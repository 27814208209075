import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'

class YearList extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/year/config'
  }
  parseFetchedItems(data) {
    return data
  }
}

class YearEdit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return false
  }
  getRemoveURL(): string {
    return `credit/year/config/${this.params.id}`
  }
  getSubmitURL(): string {
    return 'credit/year/config'
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.yearList.fetch()
    if (this.params && _.isFunction(this.params.success)) {
      this.params.success()
    }
  }
}

class TermList extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'credit/term'
  }
  parseFetchedItems(data) {
    return data
  }
}

class TermEdit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return false
  }
  getRemoveURL(): string {
    return `credit/term/${this.params.id}`
  }
  getSubmitURL(): string {
    return this.isEdit ? `credit/term/${this.params.id}` : 'credit/term'
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.termList.fetch()
  }
}

class Store {
  yearList: YearList
  yearEdit: YearEdit
  termList: TermList
  termEdit: TermEdit

  isInitial = false
  visible = false
  step = 0

  constructor() {
    this.yearList = new YearList(this)
    this.yearEdit = new YearEdit(this)
    this.termList = new TermList(this)
    this.termEdit = new TermEdit(this)
  }

  open({ isInitial = false }) {
    this.isInitial = isInitial
    this.step = this.isInitial ? 0 : 1
    this.visible = true
    if (this.isInitial) {
      this.yearList.fetch()
    } else {
      this.termList.fetch()
    }
  }
}

export const termStore = new Store()
