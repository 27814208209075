import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import { GET } from '@/admin-shared-modules/utils/ajax'
import { appStore } from '@/store'
import queryString from 'query-string'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

import SignIn from '../views/passport/sign-in.component.vue'
import BaseLayout from '../layouts/base-layout.vue'
import { asyncRouters } from '@/router/config'

const router = new Router({
  routes: [
    {
      path: '/sign-in',
      name: 'signIn',
      component: SignIn
    },
    {
      path: '/u',
      name: 'home',
      component: BaseLayout,
      children: asyncRouters
    },
    { path: '', redirect: { name: 'home' } }
  ]
})

router.beforeEach(async (to, from, next) => {
  const {
    cx_app: isCXApp,
    credit,
    redirect,
    sso_err_msg
  } = queryString.parse(location.search)
  if (isCXApp) {
    appStore.state.app.isCXApp = true
    appStore.state.app.isCXCreditApp = !!credit
    document.title = '超星阅读学分'
    appStore.state.ui.appName = '超星阅读学分'
  }

  const isToSignIn = to.name === 'signIn'

  if (isToSignIn) {
    next()
    return
  }

  let isSignIn = !!appStore.state.user.data
  if (!isSignIn) {
    const res = await GET(`account/current`, {})
    appStore.state.data = res.data
    appStore.setUser(res.data.user_info, res.data.role)
    appStore.setLib({
      ...res.data.library,
      isDepositOpen: !!res.data.deposit_status
    })
    isSignIn = true
  }

  if (sso_err_msg) {
    MessageBoxService.alert({ type: 'error', message: sso_err_msg })
    return
  }
  if (redirect && isSignIn) {
    location.href = `${location.protocol}//${location.host}${redirect}`
    return
  }

  if (!isSignIn) {
    next({ name: 'signIn' })
    return
  }

  if (to.name === 'home') {
    if (appStore.state.lib.hasDeviceReader) {
      next({ name: 'dashboardReader' })
    } else if (appStore.state.lib.hasDeviceKindle) {
      next({ name: 'dashboardKindle' })
    } else {
      next({ name: 'book' })
    }
    return
  }

  next()
})

export default router
