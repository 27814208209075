import { Book } from '@/admin-shared-modules/typings'
import { GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { dateFormat } from '@/admin-shared-modules/utils/format'
import { defaultErrorHandler } from '@/admin-shared-modules/utils/index'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'
import moment from 'moment'

export interface BookFormData extends Book {
  authorStr?: string
  file_size?: number
  epub_file_size?: number
  tagIdArray?: any
}

export interface UploadResponse {
  file: string
  metadata: {
    publisher: string
    title: string
    description: string
    contributor: string
    creator: string
    date: string
    identifier: {
      uuid: string
      isbn: string
    }
  }
  error: string
}

interface Sort {
  book_ids: number[]
  tags: number[]
}

class Store {
  state = {
    list: {
      items: [],
      index: 1,
      size: 20,
      total: 0,
      loading: true
    },
    search: {
      data: {
        tags: [],
        title: '',
        isbn: '',
        scope: 'all'
      }
    },
    edit: {
      visible: false,
      step: 0,
      isEdit: false,
      data: {} as BookFormData,
      tags: []
    },
    batchsort: {
      visible: false,
      isAdd: false,
      tagsData: [], //显示所有分类
      tagIds: [],
      data: {} as Sort
    }
  }

  async fetch() {
    this.state.list.loading = true
    this.state.list.items = []
    try {
      const res = await GET('book', {
        data: {
          pageIndex: this.state.list.index - 1,
          ...this.state.search.data,
          tag: this.state.search.data.tags[1]
        }
      })
      this.state.list.items = res.data.items
      this.state.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.state.list.loading = false
    }
  }

  async fetchBookInfo(isbn?: string) {
    try {
      const res = await GET(
        `douban/${isbn || this.state.edit.data.sub_title}`,
        {}
      )
      const bookInfo: Book = res.data as any
      this.state.edit.data = {
        ...this.state.edit.data,
        ..._.pick(bookInfo, [
          'author',
          'author_intro',
          'catalog',
          'image',
          'isbn_13',
          'pages',
          'price',
          'pub_date',
          'publisher',
          'sub_title',
          'summary',
          'title',
          'translator'
        ])
      }
    } catch (e) {
      throw e
    }
  }

  async setBookInfoFromUploadResponse(data: UploadResponse) {
    this.state.edit.data.epub_file = data.file
    if (data.metadata) {
      this.state.edit.data.authorStr = data.metadata.creator
      this.state.edit.data.pub_date = moment(data.metadata.date).toDate()
      this.state.edit.data.publisher = data.metadata.publisher
      this.state.edit.data.title = data.metadata.title
    }
  }

  getDefaultBookFormData(): BookFormData {
    return {
      tags: [],
      pub_date: new Date(),
      author: [],
      translator: [],
      title: '',
      sub_title: '',
      isbn_13: '',
      publisher: '',
      image: '',
      pages: '',
      catalog: '',
      author_intro: '',
      summary: '',
      price: '',
      epub_file: '',
      tagIdArray: []
    }
  }

  onAdd() {
    this.state.edit.step = 0
    this.state.edit.isEdit = false
    this.state.edit.visible = true
    this.state.edit.data = this.getDefaultBookFormData()
  }

  onEdit(item: Book) {
    this.state.edit.step = 2
    this.state.edit.isEdit = true
    this.state.edit.data = this.parseItemToFormData(_.cloneDeep(item))
    this.state.edit.visible = true
    this.state.edit.tags = item.tags
    _.forEach(this.state.edit.tags, o => {
      o.tag_id = o.id
    })
    //console.log(this.state.edit.tags)
  }

  parseItemToFormData(item: Book): BookFormData {
    //console.log(item)
    return {
      ...item,
      image: _.get(item, 'images.large', item.image),
      authorStr: _.join(item.author, ', '),
      tagIdArray: _.map(item.tags, 'id')
    }
  }

  async onEditSubmit() {
    const requestMethod = this.state.edit.isEdit ? PUT : POST
    const data = _.cloneDeep(this.state.edit.data)
    const url = this.state.edit.isEdit ? `book/${data.id}` : 'book'

    data.author = _.split(data.authorStr, /,|，/)
    data.tags = _.cloneDeep(data.tagIdArray)

    data.epub_file = data.epub_file
    data.epub_file_size = data.file_size
    data.pub_date = dateFormat(data.pub_date)

    const partialFormData = _.omit(data, [
      'tagIdArray',
      'authorStr',
      'file',
      'file_size'
    ])
    LoadingService.create('保存中...')
    try {
      const res = await requestMethod(url, { data: partialFormData })
      MessageService.open({ message: '保存成功' })
      this.state.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  async onRemove(data: Book[]) {
    //LoadingService.create()
    try {
      await POST(`book/batch/delete`, {
        data: {
          book_ids: _.map(data, 'id')
        }
      })
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      // LoadingService.close()
    }
  }

  restSearch() {
    //重置搜索
    this.state.search.data.tags = []
    this.state.search.data.title = ''
    this.state.search.data.isbn = ''
  }

  // region batch
  getBatchTag = items => {
    let tagsData = _.reduce(
      items,
      (result, o) => {
        result.push(...o.tags)
        return result
      },
      []
    )
    tagsData = _.uniqBy(tagsData, 'id')
    return tagsData
  }
  tagCreateBatch(items: Book[]) {
    this.state.batchsort.visible = true
    this.state.batchsort.isAdd = true
    this.state.batchsort.tagsData = this.getBatchTag(items)
    this.state.batchsort.tagIds = []
    this.state.batchsort.data.book_ids = _.map(items, 'id')
  }
  tagRemoveBatch(items: Book[]) {
    this.state.batchsort.visible = true
    this.state.batchsort.isAdd = false
    this.state.batchsort.tagsData = this.getBatchTag(items)
    _.forEach(this.state.batchsort.tagsData, o => {
      o.tag_id = o.id
    })
    this.state.batchsort.tagIds = []
    this.state.batchsort.data.book_ids = _.map(_.cloneDeep(items), 'id')
    //this.state.batchsort.tagIds = _.map(this.state.batchsort.tagsData, 'id')
  }
  bookBatchRemove(items: Book[]) {
    this.onRemove(items)
  }
  async soreOperationSubmit() {
    this.state.batchsort.data.tags = this.state.batchsort.tagIds
    const url = this.state.batchsort.isAdd
      ? `book/tag/create`
      : `book/tag/remove`
    try {
      const res = await POST(url, {
        data: this.state.batchsort.data
      })
      if (!this.state.batchsort.isAdd) {
        MessageService.open({ message: '移除成功' })
      } else {
        MessageService.open({ message: '新增成功' })
      }
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.state.batchsort.visible = false
    }
  }
}

export default new Store()
