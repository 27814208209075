import Account from '@/views/account/account.component.vue'
import Book from '@/views/book/book.component.vue'
import Borrowing from '@/views/borrowing/borrowing.component.vue'
import CourseToEpub from '@/views/course-to-epub/index.vue'
import DashboardBookRelease from '@/views/dashboard/book-release/index.vue'
import DeviceCustomIntroApps from '@/views/device-customs/apps/index.vue'
import DeviceCustomIntro from '@/views/device-customs/intro/index.vue'
import DeviceKindle from '@/views/device-kindle/device-kindle.component.vue'
import Device from '@/views/device/device.component.vue'
import CustomHomeSection from '@/views/home-section/home-section.component.vue'
import Lib from '@/views/lib/lib.component.vue'
import MagazineRecommend from '@/views/magazine-recommend/magazine-recommend.component.vue'
import PresetBookSend from '@/views/preset-book-send/preset-book-send.component.vue'
import presetBookDetail from '@/views/preset-book/detail/preset-book-detail.component.vue'
import presetBook from '@/views/preset-book/preset-book.component.vue'
import Tag from '@/views/tag/tag.component.vue'
import Topic from '@/views/topic/topic.component.vue'
import User from '@/views/user/index.vue'
import { RouteConfig } from 'vue-router'
import BookSendLog from '../views/dashboard/book-send-log/index.vue'
import BookSendTops from '../views/dashboard/book-send-tops/index.vue'
import DashboardKindleReading from '../views/dashboard/kindle-reading/dashboard.compoment.vue'
import DashboardKindle from '../views/dashboard/kindle/dashboard.compoment.vue'
import DashboardReader from '../views/dashboard/reader/dashboard.compoment.vue'
import ReadingReport from '../views/dashboard/reading-report/index.vue'
import DashboardResReport from '../views/dashboard/res/index.vue'
import UserReadTop from '../views/dashboard/user-read-top/index.vue'
// import AppAlertMessage from '@/views/app-alert-message/index.vue'
import {
  default as CreditBook,
  default as CreditBookPro
} from '@/views/credit-book/index.vue'
import CreditDailyTask from '@/views/credit-daily-task/index.vue'
import CreditIntro from '@/views/credit-intro/index.vue'
import CreditNoteReview from '@/views/credit-note-review/index.vue'
import CreditPointReview from '@/views/credit-point-review/index.vue'
import CreditRegister from '@/views/credit-register/index.vue'
import CreditReport from '@/views/credit-report/index.vue'
import CreditRules from '@/views/credit-rules/index.vue'
import CreditSetting from '@/views/credit-setting/index.vue'
import CreditTopic from '@/views/credit-topic/index.vue'
import DeviceCustomUI from '@/views/device-customs/ui/index.vue'
import {
  ZcloudAdPublish,
  ZcloudBlockAccount,
  ZcloudCabinet,
  ZcloudDefineDamage,
  ZcloudDeposit,
  ZcloudDepositReport,
  ZcloudError,
  ZcloudInventory,
  ZcloudOverdueOrder,
  ZcloudOverdueOrderDeposit,
  ZcloudPadbookBR,
  ZcloudSiteSetting,
  zcloudBorrower
} from '@/views/zcloud'

export const asyncRouters: RouteConfig[] = [
  {
    path: 'dashboard-reader',
    name: 'dashboardReader',
    component: DashboardReader
  },
  {
    path: 'dashboard-kindle',
    name: 'dashboardKindle',
    component: DashboardKindle
  },
  {
    path: 'dashboard-kindle-reading',
    name: 'dashboardKindleReading',
    component: DashboardKindleReading
  },
  { path: 'reading-report', name: 'readingReport', component: ReadingReport },
  { path: 'user-read-top', name: 'userReadTop', component: UserReadTop },
  { path: 'book-send-log', name: 'bookSendLog', component: BookSendLog },
  { path: 'book-send-tops', name: 'bookSendTops', component: BookSendTops },
  {
    path: 'dashboard-res',
    name: 'dashboardResReport',
    component: DashboardResReport
  },
  {
    path: 'dashboard-book-release',
    name: 'dashboardBookRelease',
    component: DashboardBookRelease
  },
  { path: 'tag', name: 'tag', component: Tag },
  { path: 'book', name: 'book', component: Book },
  { path: 'topic', name: 'topic', component: Topic },
  {
    path: 'magazine-recommend',
    name: 'magazineRecommend',
    component: MagazineRecommend
  },
  {
    path: 'home-section',
    name: 'customHomeSection',
    component: CustomHomeSection
  },
  { path: 'course-to-epub', name: 'courseToEpub', component: CourseToEpub },

  { path: 'borrowing', name: 'borrowing', component: Borrowing },
  { path: 'device', name: 'device', component: Device },
  { path: 'device-kindle', name: 'deviceKindle', component: DeviceKindle },

  { path: 'user', name: 'user', component: User },
  { path: 'account', name: 'account', component: Account },
  { path: 'lib', name: 'lib', component: Lib },
  // { path: 'app-alert-message', name: 'appAlertMessage', component: AppAlertMessage },

  { path: 'preset-book', name: 'presetBook', component: presetBook },
  {
    path: 'preset-detail',
    name: 'presetBookDetail',
    component: presetBookDetail
  },
  {
    path: 'preset-book-send',
    name: 'presetBookSend',
    component: PresetBookSend
  },

  { path: 'credit-intro', name: 'creditIntro', component: CreditIntro },
  { path: 'credit-book', name: 'creditBook', component: CreditBook },
  { path: 'credit-book-pro', name: 'creditBookPro', component: CreditBookPro },
  { path: 'credit-topic', name: 'creditTopic', component: CreditTopic },
  { path: 'credit-report', name: 'creditReport', component: CreditReport },
  { path: 'credit-rules', name: 'creditRules', component: CreditRules },
  { path: 'credit-setting', name: 'creditSetting', component: CreditSetting },
  {
    path: 'credit-note-review',
    name: 'creditNoteReview',
    component: CreditNoteReview
  },
  {
    path: 'credit-point-review',
    name: 'creditPointReview',
    component: CreditPointReview
  },
  {
    path: 'credit-register',
    name: 'creditRegister',
    component: CreditRegister
  },
  {
    path: 'credit-daily-task',
    name: 'creditDailyTask',
    component: CreditDailyTask
  },

  { path: 'zcloud-cabinet', name: 'zcloudCabinet', component: ZcloudCabinet },
  {
    path: 'zcloud-ad-publish',
    name: 'zcloudAdPublish',
    component: ZcloudAdPublish
  },
  { path: 'zcloud-error', name: 'zcloudError', component: ZcloudError },
  {
    path: 'zcloud-site-setting',
    name: 'zcloudSiteSetting',
    component: ZcloudSiteSetting
  },
  {
    path: 'zcloud-padbookbr',
    name: 'zcloudPadbookBR',
    component: ZcloudPadbookBR
  },
  {
    path: 'zcloud-inventory',
    name: 'zcloudInventory',
    component: ZcloudInventory
  },
  {
    path: 'zcloud-block-account',
    name: 'zcloudBlockAccount',
    component: ZcloudBlockAccount
  },
  {
    path: 'zcloud-define-damage',
    name: 'zcloudDefineDamage',
    component: ZcloudDefineDamage
  },
  {
    path: 'zcloud-deposit-report',
    name: 'zcloudDepositReport',
    component: ZcloudDepositReport
  },
  { path: 'zcloud-deposit', name: 'zcloudDeposit', component: ZcloudDeposit },
  {
    path: 'zcloud-overdue-order',
    name: 'zcloudOverdueOrder',
    component: ZcloudOverdueOrder
  },
  {
    path: 'zcloud-overdue-order-deposit',
    name: 'zcloudOverdueOrderDeposit',
    component: ZcloudOverdueOrderDeposit
  },
  {
    path: 'zcloud-borrower',
    name: 'zcloudBorrower',
    component: zcloudBorrower
  },

  {
    path: 'device-custom-intro',
    name: 'deviceCustomIntro',
    component: DeviceCustomIntro
  },
  {
    path: 'device-custom-apps',
    name: 'deviceCustomIntroApps',
    component: DeviceCustomIntroApps
  },
  {
    path: 'device-custom-ui/setup-image',
    name: 'deviceCustomSetupImage',
    component: DeviceCustomUI
  },
  {
    path: 'device-custom-ui/guide-image',
    name: 'deviceCustomGuideImage',
    component: DeviceCustomUI
  },
  {
    path: 'device-custom-ui/shutdown-image',
    name: 'deviceCustomShutdownImage',
    component: DeviceCustomUI
  },
  {
    path: 'device-custom-ui/lock-image',
    name: 'deviceCustomLockImage',
    component: DeviceCustomUI
  },

  { path: '*', redirect: { name: 'dashboardReader' } }
]
