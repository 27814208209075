import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'
import { POST } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

class List extends AbstractListService<Store> {
  summary = {}
  size = 50

  getFetchURL(): string {
    return 'credit/book'
  }
  parseFetchedItems(data) {
    this.summary = data.statistic
    return super.parseFetchedItems(data)
  }
  getSearchParams() {
    const data = this.context.search.getSearchParams()
    return {
      ..._.omit(data, ['tags', 'emptyTags']),
      tag_id: data.emptyTags ? 0 : _.first(data.tags),
      is_professional: this.context.isPro ? 1 : 0
    }
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData() {
    return {
      tab: 0
    }
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class Edit extends AbstractEditService<Store> {
  step = 0
  isBookRef = false

  onAdd(params?: { type: 'ref' | 'upload'; tag?: number }) {
    this.step = 0
    this.isBookRef = params.type === 'ref'
    super.onAdd(params)
  }
  getDefaultFormData(params: any) {
    return {
      tags: []
    }
  }
  getFetchURL() {
    return `credit/book/${this.params.id}`
  }
  parseFetchedFormData(data: any) {
    return {
      ...data,
      authorStr: _.join(data.author, ', ')
    }
  }
  getSubmitData(): any {
    if (this.isBookRef) {
      return {
        book_id: this.data.book_id,
        tags: _.map(this.data.tags, tag => _.pick(tag, 'id')),
        is_professional: this.context.isPro ? 1 : 0
      }
    } else {
      const data = {
        ...this.data,
        author: _.chain(this.data.authorStr)
          .split(',')
          .map(_.trim)
          .filter(v => !!v)
          .value(),
        tags: _.map(this.data.tags, tag => _.pick(tag, 'id')),
        is_professional: this.context.isPro ? 1 : 0
      }
      return _.omit(data, 'authorStr')
    }
  }
  getSubmitURL(): string {
    if (!this.isEdit && this.isBookRef) {
      return 'credit/book/bind'
    }
    return this.isEdit ? `credit/book/${this.params.id}` : 'credit/book'
  }
  getRemoveURL(): string {
    return `credit/book/${this.params.id}`
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch({ reset: true })
  }

  async bindSelectedBook(bookIds, tagId?) {
    this.saving = true
    try {
      await POST('los/credit/book/bind', {
        data: {
          credit_book_ids: bookIds,
          tags: tagId ? [{ id: tagId }] : null,
          is_professional: this.context.isPro ? 1 : 0
        }
      })
      MessageService.open({ type: 'success', message: '图书导入成功' })
      this.requestListReload({ reset: true })
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.saving = false
    }
  }
}

class MultiTagEdit extends AbstractEditService<Store> {
  bookIds = []
  getFetchURL(): string | boolean {
    return false
  }
  getDefaultFormData(params: any) {
    return { checkedItems: [] }
  }
  getSubmitURL(): string {
    return this.isEdit
      ? 'credit/book/tag/multi/remove'
      : 'credit/book/tag/multi/add'
  }
  getSubmitData(): any {
    return {
      credit_book_ids: this.bookIds,
      credit_tag_ids: this.data.checkedItems
    }
  }
  getSubmitMethod() {
    return POST
  }
  getRemoveURL(): string {
    return ''
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch({ reset: true })
  }
}

class QuestImport<TCT = any> {
  visible = false
  data = {
    credit_book_id: null,
    file: null
  }

  constructor(protected context: TCT) {}

  open() {
    this.data.credit_book_id = null
    this.data.file = null
    this.visible = true
  }
}

class Store {
  list: List
  search: Search
  edit: Edit
  multiTagEdit: MultiTagEdit
  questImport: QuestImport

  isPro = false

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
    this.edit = new Edit(this)
    this.multiTagEdit = new MultiTagEdit(this)
    this.questImport = new QuestImport(this)
  }
}

export const store = new Store()
