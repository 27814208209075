import _ from 'lodash'
import { TreeNode } from '../typings'

export const listToTree = (items: TreeNode[], ignoreChildren = false) => {
  const orderedItem = _.orderBy(items, ['parent_id'])
  return _.reduce(
    orderedItem,
    (result: TreeNode[], item: TreeNode) => {
      if (item.parent_id) {
        const parent = _.find(result, { id: item.parent_id })
        if (parent) {
          parent.children.push(item)
        }
      } else {
        result.push({
          ...item,
          children: []
        })
      }
      return result
    },
    []
  )
}
