<template>
  <transition name="fade">
    <el-dialog
      :title="folderDialog.isEdit ? '编辑' : '新建文件夹'"
      :visible.sync="folderDialog.visible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="beforeClose"
    >
      <el-form
        :model="folderDialog.data"
        :rules="folderRules"
        ref="form"
        label-width="100px"
      >
        <el-form-item label="文件夹名称" prop="name">
          <el-input v-model="folderDialog.data.name" />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </transition>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './preset-book.store'
import { MessageService } from '../../admin-shared-modules/utils/message.service'

export default {
  name: 'FolderNameDialog',
  data() {
    return {
      folderDialog: store.folderEdit,
      folders: store.folder.data,
      folderRules: {
        name: [{ required: true, message: '请输入文件夹名称', trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    beforeClose() {
      this.cancel('form')
    },
    cancel(formName) {
      this.$refs[formName].resetFields()
      this.folderDialog.visible = !this.folderDialog.visible
      this.folderDialog.data.name = store.detail.current.name
      //this.folderDialog.data = store.detail.current
      //store.fetch()
    },
    submit(formName) {
      //新建
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        store.editFolderSubmit()
      })
    }
  }
}
</script>

<style scoped lang="scss">
/*动画*/
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s linear;
  transform: scale(1);
  opacity: 1;
}
.fade-enter,
.fade-leave-active {
  transform: scale(0);
  opacity: 0;
}
</style>
