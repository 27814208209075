import { Tag } from '@/admin-shared-modules/typings'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils/index'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'

interface BindCate {
  id: number
  name?: string
  path?: string[]
}

type CateFormItem = {
  selectedId: number[]
}

const cateCache: { [id: number]: object[] } = {}

class Store {
  list = {
    itemsTreeOrigin: <Tag[]>[],
    items: [] as Tag[],
    tagGroup: {
      currentId: null as any,
      items: <Tag[]>[]
    }
  }

  edit = {
    visible: false,
    isEdit: false,
    data: {} as Tag,
    params: null
  }

  cateEdit = {
    visible: false,
    data: {
      bindCate: [] as CateFormItem[]
    },
    params: null as Tag,
    loading: false
  }

  sortList = []

  //#region tag

  async fetchGroup() {
    this.cateEdit.loading = true

    const res = await GET(`tag/parent`, {})

    const publicTags = res.data as Array<Tag>
    const notClassifyTags = [
      { id: 0, name: '本馆分类', children: [], is_library_private: true }
    ]
    this.list.tagGroup.items = [...publicTags, ...notClassifyTags]

    const defaultId = _.chain(this.list.tagGroup.items)
      .first()
      .get('id')
      .value()
    this.list.tagGroup.currentId = String(defaultId)
    this.cateEdit.loading = false
  }

  async fetch() {
    if (this.list.tagGroup.currentId === null) return
    console.log(this.list.tagGroup.currentId)
    const groupId = +this.list.tagGroup.currentId

    const res = await GET(`tag`, {
      data:
        groupId === 0
          ? {
              public: 0
            }
          : {
              public: 1,
              parent_id: groupId
            }
    })
    this.list.items = _.map(res.data as Tag[], (item, index) => {
      return {
        ...item,
        _index: index
      }
    })
  }

  onAddChild(parent) {
    this.edit.visible = true
    this.edit.isEdit = false
    this.edit.params = parent
    this.edit.data = {
      name: '',
      parent_id: null
    } as Tag
  }
  onAddParent() {
    this.edit.visible = true
    this.edit.isEdit = false
    this.edit.params = null
    this.edit.data = {
      name: ''
    } as Tag
  }
  onEdit(item) {
    this.edit.data = _.cloneDeep(item)
    this.edit.isEdit = true
    this.edit.params = null
    this.edit.visible = true
  }
  async onEditSubmit() {
    const requestMethod = this.edit.isEdit ? PUT : POST
    const data = _.pick(this.edit.data, ['id', 'parent_id', 'name', 'tag_id'])
    if (this.edit.params) {
      data.parent_id = this.edit.params.id
    }
    const url = this.edit.isEdit ? `tag/${data.tag_id}` : 'tag'

    LoadingService.create('保存中...')
    try {
      const res = await requestMethod(url, { data })
      MessageService.open({ message: '保存成功' })
      this.edit.visible = false

      if (this.edit.isEdit) this.updateNode(res.data, data.id)
      else this.appendNode(res.data, data.parent_id)
    } catch (e) {
      console.log(e)
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }
  async onRemove(item) {
    LoadingService.create()
    try {
      await DELETE(`tag/${item.tag_id}`, {})
      this.removeNode(item)
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  //#endregion tag

  //#region cateBind

  onAppendBindCate(owner: Tag) {
    this.cateEdit.visible = true
    this.cateEdit.data.bindCate = [{ selectedId: null }]
    this.cateEdit.params = owner
  }
  async onCateBindSubmit() {
    const bindCateIds = _.map(this.cateEdit.data.bindCate, item => {
      return _.last(item.selectedId)
    })
    this.cateEdit.loading = true
    try {
      const { data } = await POST(`tag/bind`, {
        data: {
          tag_id: this.cateEdit.params.id,
          inner_tag_ids: bindCateIds
        }
      })
      const newBindCate = data.items
      this.cateEdit.params.bind_list = _.concat(
        this.cateEdit.params.bind_list,
        newBindCate
      )
      this.cateEdit.visible = false
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.cateEdit.loading = false
    }
  }

  async onRemoveBindCate(owner: Tag, bind: BindCate) {
    LoadingService.create()
    try {
      await DELETE(`tag/bind`, {
        data: {
          tag_id: owner.id,
          inner_tag_id: bind.id
        }
      })
      const bindIndex = _.findIndex(owner.bind_list, { id: bind.id })
      owner.bind_list.splice(bindIndex, 1)
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  async getCateData(parent = 0): Promise<any[]> {
    console.log('cateCache', cateCache)
    const cache = cateCache[parent]
    if (cache) {
      return cache
    }
    const { data } = await GET(`inner/tags`, {
      data: { parent_id: parent }
    })
    const items = _.map(data.items, item => {
      return { ...item, children: [] }
    })
    cateCache[parent] = items
    return items
  }

  //#endregion cateBind

  private appendNode(node: Tag, parent: number) {
    if (parent) {
      const parentNode = this.findNode(parent)
      if (parentNode) {
        parentNode.children = parentNode.children || []
        parentNode.children.push({
          ...node,
          children: [],
          bind_list: []
        })
      }
    } else {
      this.list.items.push({
        ...node,
        children: [],
        bind_list: []
      })
    }
  }
  private updateNode(data: Tag, id: number) {
    const currentNode = this.findNode(id)
    _.assign(currentNode, data)
  }
  private removeNode(node: Tag) {
    if (node.parent_id) {
      const parentNode = this.findNode(node.parent_id)
      const index = _.findIndex(parentNode.children, { id: node.id })
      parentNode.children.splice(index, 1)
    } else {
      const index = _.findIndex(this.list.items, item => item.id === node.id)
      this.list.items.splice(index, 1)
    }
  }
  private findNode(id: number) {
    let matched: Tag = null
    const find = (items: Tag[]) => {
      const res = _.find(items, item => item.id === id)
      if (!res) {
        _.forEach(items, item => {
          if (item.children) {
            return find(item.children)
          }
        })
      } else {
        matched = res
        return false
      }
    }
    find(this.list.items as Tag[])
    return matched
  }

  async updateSort() {
    try {
      await POST(`tag/setOrder`, {
        data: {
          parent_id: +this.list.tagGroup.currentId,
          sort: _.map(this.list.items, 'tag_id')
        }
      })
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
}

export default new Store()
