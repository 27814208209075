<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="学院">
      <el-select v-model="search.data.college_id" size="small">
        <el-option
          v-for="item in collegeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="专业">
      <el-select v-model="search.data.profession_id" size="small">
        <el-option
          v-for="item in professionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="班级">
      <el-select v-model="search.data.class_id" size="small">
        <el-option
          v-for="item in classOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="时间" style="margin-bottom: 0">
      <el-date-picker
        v-model="search.data.dateRange"
        type="daterange"
        :clearable="false"
      />
    </el-form-item>
    <el-form-item label="学生姓名">
      <el-input v-model="search.data.user_name_like" clearable />
    </el-form-item>
    <el-form-item label="学号">
      <el-input v-model="search.data.card_num" clearable />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { store } from './store'
import { GET } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  data() {
    return {
      search: store.search,

      collegeOptions: [],
      professionOptions: [],
      classOptions: []
    }
  },

  watch: {
    'search.data.college_id'() {
      this.fetchProfession()
      this.search.data.profession_id = null
      this.search.data.class_id = null
    },
    'search.data.profession_id'() {
      this.fetchClass()
      this.search.data.class_id = null
    }
  },

  async mounted() {
    await this.fetchCollege()
  },

  methods: {
    onSubmit() {
      const { college_id, profession_id, class_id } = this.search.data
      if (college_id && profession_id && class_id) {
        store.list.fetch({ reset: true })
      } else if (!college_id) {
        MessageService.open({ message: '请选择学院', type: 'warning' })
      } else if (!profession_id) {
        MessageService.open({ message: '请选择专业', type: 'warning' })
      } else if (!class_id) {
        MessageService.open({ message: '请选择班级', type: 'warning' })
      }
    },

    async fetchCollege() {
      const { data } = await GET('credit/college', {})
      this.collegeOptions = _.map(data.items, d => ({
        label: d.name,
        value: d.id
      }))
    },
    async fetchProfession() {
      const { data } = await GET('credit/profession', {
        data: {
          college_id: this.search.data.college_id
        }
      })
      this.professionOptions = _.map(data.items, d => ({
        label: d.name,
        value: d.id
      }))
    },
    async fetchClass() {
      const { data } = await GET('credit/class', {
        data: {
          profession_id: this.search.data.profession_id
        }
      })
      this.classOptions = _.map(data.items, d => ({
        label: d.name,
        value: d.id
      }))
    }
  }
}
</script>
