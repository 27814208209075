<template>
  <el-dialog
    :visible.sync="store.visible"
    title="图书分类管理"
    :close-on-click-modal="false"
  >
    <page-action-bar>
      <el-button
        type="primary"
        size="small"
        :disabled="isSorting"
        @click="() => store.edit.onAdd()"
      >
        新增分类
      </el-button>
      <template slot="right">
        <el-button
          v-if="!isSorting"
          :disabled="!list.items.length"
          size="small"
          @click="isSorting = true"
        >
          分类排序
        </el-button>
        <template v-else>
          <el-button type="primary" size="small" @click="onSortSubmit">
            保存分类
          </el-button>
          <el-button size="small" @click="onSortCancel"> 取消 </el-button>
        </template>
      </template>
    </page-action-bar>

    <el-table
      v-if="!isSorting"
      :data="list.items"
      size="small"
      v-loading="list.loading"
    >
      <el-table-column type="index" width="50" />
      <el-table-column label="分类" prop="name" />
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="onEditClick(row)"> 编辑 </el-button>
          <el-button type="text" @click="onRemoveClick(row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <draggable
      v-else
      class="list"
      v-model="list.items"
      :options="{ ghostClass: 'sort-placeholder' }"
    >
      <div v-for="(item, index) in list.items" :key="index" class="item">
        <div class="title">
          {{ item.name }}
        </div>
        <div class="action">
          <i class="el-icon-sort" />
        </div>
      </div>
    </draggable>
    <edit />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from '@/views/credit-book/tag/store'
import Edit from './edit.vue'
import Draggable from 'vuedraggable'
import { POST } from '@/admin-shared-modules/utils/ajax'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  components: {
    Edit,
    Draggable
  },

  data() {
    return {
      store,
      list: store.list,
      edit: store.edit,
      isSorting: false
    }
  },

  mounted() {
    store.list.fetch({ reset: true })
  },

  methods: {
    onEditClick(item) {
      this.edit.onEdit(item)
    },
    onRemoveClick(item) {
      this.edit.onRemove(item)
    },

    async onSortSubmit() {
      this.list.loading = true
      await POST('credit/tag/sort', {
        data: { sort: _.map(this.list.items, 'id') }
      })
      MessageService.open({ type: 'success', message: '排序保存成功' })
      this.isSorting = false
      this.list.loading = false
      store.list.fetch({ reset: true })
    },
    onSortCancel() {
      this.isSorting = false
      store.list.fetch({ reset: true })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.list {
  .item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $--border-color-base;
    .title {
      flex: auto;
    }
    .action {
      cursor: move;
    }
  }
}
</style>
