<template>
  <el-dialog
    title="试题导入"
    :visible.sync="state.visible"
    width="500px"
    append-to-body
  >
    <template v-if="!importResult">
      <el-form
        v-if="state.visible"
        ref="form"
        :model="state.data"
        label-width="6em"
        v-loading="isUploading"
      >
        <el-form-item
          label="图书"
          prop="credit_book_id"
          :rules="[baseRequireRule]"
        >
          <book-selector v-model="state.data.credit_book_id" />
        </el-form-item>
        <el-form-item label="试题文件" prop="file" :rules="[baseRequireRule]">
          <div class="upload">
            <input
              ref="file"
              class="file-input"
              type="file"
              @change="onInputChange"
            />
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer" style="display: flex">
        <div style="flex: auto; text-align: left">
          <a
            class="el-button el-button--small"
            target="_blank"
            :href="`${baseUrl}files/试题导入模版.xls`"
            >下载模版</a
          >
        </div>
        <el-button
          type="primary"
          size="small"
          :loading="isUploading"
          @click="submit"
        >
          导入试题
        </el-button>
      </div>
    </template>

    <template v-else>
      <div class="header">
        导入成功 {{ importResult.correct_count }} 题, 导入失败
        {{ importResult.error_count }} 题
      </div>
      <div class="area">
        <div v-for="(item, index) in importResult.error_list" :key="index">
          第{{ item.line }}行: {{ item.msg }}
          <template v-if="item.content">
            {{ item.content }}
          </template>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" size="small" @click="restart">
          继续导入
        </el-button>
        <el-button size="small" @click="state.visible = false">
          关闭
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { store } from './store'
import BookSelector from './shared/book-selector.vue'
import { API_HOST } from '../../config'
import { MessageService } from '../../admin-shared-modules/utils/message.service'

export default {
  components: {
    BookSelector
  },

  data() {
    return {
      state: store.questImport,
      baseRequireRule: { required: true, message: '请填写' },
      baseUrl: process.env.BASE_URL,

      isUploading: false,
      importResult: null
    }
  },
  watch: {
    'state.visible'(visible) {
      if (visible) {
        this.isUploading = false
        this.importResult = null
      }
    }
  },

  methods: {
    onInputChange() {
      const file = this.$refs.file.files[0]
      if (!file) {
        return
      }
      const ext = file.name.split('.').pop()
      // if (!/xlsx?/.test(ext)) {
      //   MessageService.open({ type: 'error', message: `${file.name} ` })
      // }
      this.state.data.file = file
    },

    submit() {
      this.$refs['form'].validate(async v => {
        if (!v) {
          return
        }
        this.isUploading = true
        try {
          this.importResult = await this.upload()
          store.list.fetch({ reset: true })
        } catch (e) {
          MessageService.open({ type: 'error', message: e })
        } finally {
          this.isUploading = false
        }
      })
    },
    restart() {
      this.importResult = null
    },

    upload() {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('credit_book_id', this.state.data.credit_book_id)
        data.append('file', this.state.data.file)
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${API_HOST}/credit/book/quest/import`)
        xhr.onload = e => {
          try {
            const res = JSON.parse(e.target.response) || {}
            if (res.code !== 200) {
              reject(res.msg)
            } else {
              resolve(res.data)
            }
          } catch (e) {
            reject()
          }
        }
        xhr.send(data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
}
.header {
  margin-top: -10px;
  margin-bottom: 16px;
  font-size: 16px;
}
.area {
  padding: 8px;
  line-height: 1.6;
  background-color: #f4f4f4;
  color: #e33;
}
</style>
