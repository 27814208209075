import _ from 'lodash'
import {
  AJAXErrorResult,
  DELETE,
  GET,
  POST,
  PUT
} from '@/admin-shared-modules/utils/ajax'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils/index'

class Store {
  list = {
    items: [],
    index: 1,
    size: 20,
    total: 0,
    loading: true
  }
  search = {
    data: {}
  }
  edit = {
    visible: false,
    isEdit: false,
    data: {
      name: '',
      phone: '',
      password: ''
    }
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('account', {
        data: {
          pageIndex: this.list.index - 1
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
    } finally {
      this.list.loading = false
    }
  }

  onAdd() {
    this.edit.data = {
      name: '',
      phone: '',
      password: ''
    }
    this.edit.isEdit = false
    this.edit.visible = true
  }

  onEdit(data) {
    this.edit.data = _.clone(data)
    this.edit.isEdit = true
    this.edit.visible = true
  }

  async onEditSubmit() {
    const requestMethod = this.edit.isEdit ? PUT : POST
    const data = this.edit.data as any
    const url = this.edit.isEdit ? `account/${data.id}` : 'account'

    if (!data.password) {
      data.password = '123456'
    }

    LoadingService.create('保存中...')
    try {
      const res = await requestMethod(url, { data })
      MessageService.open({ message: '保存成功' })
      this.edit.visible = false
      this.fetch(true)
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    } finally {
      LoadingService.close()
    }
  }

  async onRemove(data) {
    LoadingService.create()
    try {
      await DELETE(`account/${data.id}`, {})
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  async onResetPassword(data) {
    LoadingService.create()
    try {
      await POST(`account/resetPassword`, {
        data: {
          id: data.id,
          new_password: '123456'
        }
      })
      MessageService.open({ message: '密码重置成功' })
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }
}

export default new Store()
