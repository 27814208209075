import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import _ from 'lodash'

export const ModuleConfig = [
  {
    type: 'book-daily',
    group: 'recommend',
    groupName: '推荐',
    name: '天天新书',
    desc: '每日更新'
  },
  {
    type: 'recommend',
    name: '推荐阅读',
    group: 'recommend',
    groupName: '推荐'
  },
  {
    type: 'uniform-textbook-project',
    name: '统编教材推荐书单',
    group: 'recommend',
    groupName: '推荐'
  },
  {
    type: 'k12-reading-project',
    name: '中小学阅读指导书目',
    group: 'recommend',
    groupName: '推荐'
  },
  {
    type: 'school-public-reading-project',
    name: '学校共读书单',
    group: 'recommend',
    groupName: '推荐'
  },
  {
    type: 'mindmap-project',
    name: '心智地图书单',
    group: 'recommend',
    groupName: '推荐'
  },
  {
    type: 'theme-project',
    name: '主题书单',
    group: 'recommend',
    groupName: '推荐'
  },
  { type: 'project', name: '书单' },
  { type: 'label', name: '书单栏目' },
  { type: 'tag', name: '图书分类' }
]
type ModuleConfigItem = (typeof ModuleConfig)[0]
type ModuleType = 'recommend' | 'project' | 'tag' | 'project-tag'

export interface ModuleDto {
  type:
    | 'book-daily'
    | 'book-classic'
    | 'book-hot'
    | 'book-new'
    | 'project'
    | 'tag'
    | 'label'
    | 'tag_group'
    | 'audio_book'
    | 'evaluate_book'
    | 'library_book'
    | 'more_project'
  id?: number
  name?: string
  original_name?: string
  is_private?: boolean
  config?: ModuleConfigItem
}

interface AddFormData {
  type: ModuleType
  selectedItems: ModuleDto[]
}

class Store {
  list = {
    loading: true,
    type: 1,
    items: [] as ModuleDto[]
  }

  add = {
    visible: false,
    data: { selectedItems: [] } as AddFormData
  }

  edit = {
    visible: false,
    loading: false,
    data: {} as ModuleDto,
    params: {}
  }

  async detectType() {
    this.list.loading = true
    const { data } = await GET('shop_type', {})
    this.list.type = data.shop_type
  }

  async fetch() {
    this.list.items = []
    this.list.loading = true
    try {
      const res = await GET(
        this.list.type === 0 ? 'los/shop_models' : 'shop_models',
        {}
      )
      this.list.items = this._parseModuleList(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      this.list.loading = false
    }
  }
  _parseModuleList(list) {
    return _.map(list, item => {
      return {
        ...item,
        config: _.find(ModuleConfig, { type: item.type })
      }
    }).filter(item => !!item.config)
  }

  onAdd() {
    this.add.data = {
      type: 'recommend',
      selectedItems: []
    }
    this.add.visible = true
  }
  async onAddSubmit() {
    await this.onSubmit()
    this.add.visible = false
  }

  onEdit(item) {
    this.edit.data = _.cloneDeep(item)
    this.edit.visible = true
  }
  async onEditSubmit() {
    const item = _.find<ModuleDto>(
      this.list.items,
      _.pick(this.edit.data, ['type', 'id'])
    )
    item.name = this.edit.data.name
    await this.onSubmit()
    this.edit.visible = false
  }

  async onSubmit() {
    LoadingService.create()
    try {
      await POST('shop_models', {
        data: {
          model_settings: [
            ..._.map(this.list.items, item => {
              return _.omit(item, 'config')
            }),
            ...this.add.data.selectedItems
          ]
        }
      })
      this.add.data.selectedItems = []
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      LoadingService.close()
    }
  }

  async onRemove(index) {
    this.list.items.splice(index, 1)
    await this.onSubmit()
  }

  async onSortSubmit() {
    this.onSubmit()
  }
}

// @ts-ignore
export default new Store()
