import { GET } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'

class Store {
  state = {
    visible: true,
    list: {
      items: [],
      index: 1,
      size: 10,
      total: 0,
      loading: false
    },
    search: {
      data: {
        tags: [],
        title: '',
        isbn: '',
        scope: 'all'
      }
    }
  }

  async fetch(reset = false) {
    if (reset) this.state.list.index = 1
    this.state.list.loading = true
    this.state.list.items = []
    try {
      const res = await GET('book', {
        data: {
          pageIndex: this.state.list.index - 1,
          pageSize: this.state.list.size,
          ...this.state.search.data,
          tag: _.last(this.state.search.data.tags)
        }
      })
      this.state.list.items = res.data.items
      this.state.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.state.list.loading = false
    }
  }
}

export default new Store()
