import EE from 'eventemitter3'
import { GET } from '@/admin-shared-modules/utils/ajax'
import router from '@/router'
import axios from 'axios'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export class Scan extends EE {
  uuid = ''
  timestamp = null
  status = ''
  source = null
  isDestory = false

  async init() {
    this.status = null
    this.isDestory = false
    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()
    this.emit('code-status', this.status)
    const uuid = require('node-uuid')
    this.uuid = uuid.v4()
    this.timestamp = Math.round(+new Date() / 1000)
    this.emit(
      'code-update',
      `${location.origin}/scanner.html?uuid=${this.uuid}&timestamp=${this.timestamp}`
    )
    try {
      const res = await GET('account/scan/login', {
        timeout: 60 * 1000,
        ignoreError: true,
        cancelToken: this.source,
        data: {
          uuid: this.uuid,
          timestamp: this.timestamp
        }
      })
      this.status = res.data.status
      this.emit('code-status', this.status)
      if (this.status === 'timeout') {
        this.init()
      }
      if (this.status === 'ready') {
        this.wait()
      }
      if (this.status === 'scan') {
        this.wait()
      }
    } catch (e) {
      await MessageBoxService.alert({
        type: 'error',
        message: e.message || '服务繁忙，请稍后重试'
      })
      if (!this.isDestory) {
        this.init()
      }
    }
  }

  async wait() {
    try {
      const res = await GET('account/scan/login', {
        timeout: 60 * 1000,
        ignoreError: true,
        data: {
          uuid: this.uuid,
          timestamp: this.timestamp
        }
      })
      this.status = res.data.status
      this.emit('code-status', this.status)
      if (
        this.status === 'error' ||
        this.status === 'timeout' ||
        this.status === 'cancel'
      ) {
        this.init()
      }
      if (this.status === 'scan' || this.status === 'ready') {
        this.wait()
      }
      if (this.status === 'success') {
        setTimeout(() => {
          router.replace({ name: 'home' })
        }, 500)
      }
    } catch (e) {
      await MessageBoxService.alert({
        type: 'error',
        message: e.message || '服务繁忙，请稍后重试'
      })
      this.init()
    }
  }
}
