<template>
  <el-dialog :visible.sync="store.visible" title="审核账号">
    <page-action-bar>
      <el-button type="primary" size="small" @click="onAddClick">
        新增
      </el-button>
    </page-action-bar>

    <el-table v-loading="list.loading" :data="list.items">
      <el-table-column type="index" width="50" />
      <el-table-column label="手机号" prop="phone" />
      <el-table-column label="姓名" prop="name" />
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="onEditClick(row)"> 编辑 </el-button>
          <el-button type="text" @click="onRemoveClick(row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <edit />
  </el-dialog>
</template>

<script>
import { store } from './store'
import Edit from './edit'

export default {
  components: {
    Edit
  },

  data() {
    return {
      store,
      list: store.list
    }
  },

  methods: {
    onAddClick() {
      store.edit.onAdd()
    },
    onEditClick(row) {
      store.edit.onEdit(row)
    },
    onRemoveClick(row) {
      store.edit.onRemove(row)
    }
  }
}
</script>

<style scoped></style>
