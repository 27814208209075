<template>
  <page-layout>
    <page-header :breadcrumb="['设置', '阅读测评设置']" />

    <page-main>
      <div class="panel">
        <div class="panel__header">
          <div class="title">考试设置</div>
          <el-button type="text" @click="$refs.testingSetting.edit()">
            编辑
          </el-button>
        </div>
        <div class="panel__body">
          <testing-setting ref="testingSetting" />
        </div>
      </div>
    </page-main>
  </page-layout>
</template>

<script>
import TestingSetting from './testing'
import SummarySetting from './summary'
export default {
  components: {
    SummarySetting,
    TestingSetting
  }
}
</script>

<style scoped lang="scss">
.panel {
  margin-bottom: 32px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .title {
      margin-right: 16px;
      font-size: 18px;
    }
  }
  &__body {
  }
}
</style>
