<template>
  <div v-loading="loading">
    <div class="checkbox-list">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="{ checked: item.selected, disabled: item.disabled }"
        @click="onCheck(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { GET } from '../../../admin-shared-modules/utils/ajax'

export default {
  props: {
    disabledItems: { type: Array, default: () => [] }
  },
  data() {
    return {
      loading: true,
      groups: [],
      items: [],
      currentDisabledItems: []
    }
  },
  computed: {
    currentSelected() {
      return _.filter(this.items, 'selected')
    }
  },
  watch: {
    currentGroupId() {
      this.fetch()
    }
  },

  async mounted() {
    this.currentDisabledItems = this.disabledItems
    this.fetch()
  },

  methods: {
    async fetch() {
      this.loading = true
      const res = await GET('labels', {})
      this.items = _.map(res.data, item => {
        return {
          ...item,
          selected: false,
          disabled: !!_.find(this.currentDisabledItems, { id: item.id })
        }
      })
      this.loading = false
    },
    onCheck(item) {
      if (item.disabled) return
      item.selected = !item.selected
      this.$emit('change', this.currentSelected)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
.search {
  margin-bottom: 16px;
}
.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    flex: 0 0 calc(25% - 32px);
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    line-height: 1.4;
    cursor: pointer;
    &.checked {
      border-color: $--color-primary;
      color: $--color-primary;
    }
    &.disabled {
      background-color: #eee;
      border-color: #ccc;
      color: $--color-text-primary;
      cursor: not-allowed;
    }
  }
}
</style>
