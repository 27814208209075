<template>
  <el-dialog
    :visible.sync="visible"
    :title="'选择书单'"
    :close-on-click-modal="false"
    @open="onOpen"
    width="750px"
    append-to-body
  >
    <div>
      <div class="header">
        <div class="filter">
          <el-radio-group
            v-model="state.search.data.scope"
            @change="onSearchSubmit"
            size="small"
          >
            <el-radio-button label="all"> 全部 </el-radio-button>
            <el-radio-button label="public"> 公共书单 </el-radio-button>
            <el-radio-button label="private"> 本馆书单 </el-radio-button>
          </el-radio-group>
        </div>
        <div class="search-box">
          <el-input
            v-model="state.search.data.title_like"
            clearable
            size="small"
            placeholder="请输入书单名称"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onSearchSubmit"
            />
          </el-input>
        </div>
      </div>

      <el-table
        ref="table"
        :data="state.list.items"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <!--<el-table-column-->
        <!--label="序号"-->
        <!--width="55">-->
        <!--<template slot-scope="scope">{{ scope.row._index }}</template>-->
        <!--</el-table-column>-->
        <el-table-column prop="title" label="书单名称" show-overflow-tooltip />
        <el-table-column label="图书数量" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.book_list_count }}本</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date }}
          </template>
        </el-table-column>
        <el-table-column width="55" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="onDetailShow(scope.row)"
              type="text"
              size="small"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="state.list.index"
        :total="state.list.total"
        :page-size="state.list.size"
        layout="total, prev, pager, next"
        background
      />
    </div>

    <div slot="footer">
      <el-button @click="visible = false" size="small"> 取消 </el-button>
      <el-button type="primary" size="small" @click="onSubmit">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './topic-select-dialog.store'
import { Topic } from '@/admin-shared-modules/typings'

export default {
  components: {},

  props: {
    defaultValues: { type: Array }
  },
  data() {
    return {
      visible: false,
      state: store.state,
      selectedItems: [] as Topic[]
    }
  },
  watch: {
    defaultValues(val) {
      this.selectedItems = val
    }
  },

  methods: {
    async onOpen() {
      this.selectedItems = this.defaultValues || []
      await store.fetch(true)
      await this.$nextTick()
      this.setTableCurrentSelection()
    },

    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },
    onSearchSubmit() {
      store.fetch(true)
    },
    async handleCurrentChange(index: number) {
      this.state.list.index = index
      await store.fetch()
      await this.$nextTick()
      this.setTableCurrentSelection()
    },

    handleSelectionChange(items) {
      const unSelectedItems = _.differenceBy(this.state.list.items, items, 'id')
      this.selectedItems = _.chain(this.selectedItems)
        .unionBy(items, 'id')
        .differenceBy(unSelectedItems, 'id')
        .value()
    },
    setTableCurrentSelection() {
      const selectedRow = _.intersectionBy(
        this.state.list.items,
        this.selectedItems,
        'id'
      )
      _.forEach(selectedRow, row =>
        this.$refs.table.toggleRowSelection(row, true)
      )
    },

    onDetailShow(data) {},

    async onSubmit() {
      this.$emit('change', this.selectedItems)
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  margin-bottom: 16px;
}
.filter {
  flex: auto;
}
.search-box {
  flex: 0 0 40%;
}
</style>
