import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'

class List extends AbstractListService<Store> {
  size = 50
  getFetchURL(): string {
    return 'credit/user'
  }
  getSearchParams(): any {
    return this.context.search.getSearchParams()
  }
}

class Search extends AbstractSearchService<Store> {
  getSearchParams() {
    return this.omitEmptyString({
      ...this.data
    })
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return `credit/user/${this.params.id}`
  }
  getRemoveURL(): string {
    return ''
  }
  getSubmitURL(): string {
    return `credit/user/${this.params.id}`
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  search: Search
  edit: Edit

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
