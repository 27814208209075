<template>
  <el-select
    :value="value"
    size="small"
    clearable
    filterable
    placeholder="请输入关键字或选择"
    @change="onChange"
  >
    <el-option
      v-for="item in list.items"
      :key="item"
      :label="item"
      :value="item"
    />
  </el-select>
</template>

<script lang="ts">
import { store } from '../dashboard.store'

export default {
  props: {
    value: {}
  },

  data() {
    return {
      list: store.userScope
    }
  },

  methods: {
    onChange(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped></style>
