<template>
  <div class="borrow-card">
    <div class="state">
      <el-tag v-if="data.status === '1'" type="success" size="mini">
        在借中
      </el-tag>
      <el-tag v-if="data.status === '2'" size="mini"> 已还 </el-tag>
      <el-tag v-if="data.status === '3'" type="danger" size="mini">
        超期
      </el-tag>
    </div>
    <div class="content">
      <div class="header">
        <div class="title">
          {{ data.padbookCode }}
        </div>
        <div class="meta">
          <span v-if="data.status === '2' || data.status === '3'">
            <el-tag type="danger" size="mini" v-if="expireDays > 0">
              超期 {{ expireDays }} 天<template v-if="data.status === '2'"
                >归还</template
              >
            </el-tag>
          </span>
          <span>
            <el-tag type="success" size="mini" disable-transitions>借出</el-tag>
            {{ borrowDate | date('YYYY-MM-DD HH:mm') }}
          </span>
          <span v-if="data.status === '1'">
            <el-tag size="mini" disable-transitions>到期</el-tag>
            {{ expireDate | date('YYYY-MM-DD HH:mm') }}
          </span>
          <span v-if="data.status === '3'">
            <el-tag type="danger" size="mini" disable-transitions>到期</el-tag>
            {{ expireDate | date('YYYY-MM-DD HH:mm') }}
          </span>
          <span v-if="data.status === '2'">
            <el-tag type="danger" size="mini" disable-transitions>归还</el-tag>
            {{ returnDate | date('YYYY-MM-DD HH:mm') }}
          </span>
        </div>
      </div>

      <div class="desc">
        <span style="margin-right: 2em"
          >借期: {{ data.borrowTerm || data.borrowDayNum }}天</span
        >
        <template v-if="data.status === '2'">
          <span style="margin-right: 2em"
            >实际借阅: {{ actualBorrowDays }}天</span
          >
        </template>

        <span v-if="data.status === '2'"
          >归还地点: {{ data.siteName }} {{ data.equName }}</span
        >
        <span v-else>借出地点: {{ data.siteName }} {{ data.equName }}</span>
      </div>
      <el-button type="text" size="small" @click="showMore = !showMore">
        更多信息
        <i v-if="!showMore" class="el-icon-arrow-down" />
        <i v-else class="el-icon-arrow-up" />
      </el-button>

      <div class="desc-container" v-show="showMore">
        <div class="desc-item">型号: {{ data.padbookType }}</div>
        <div class="desc-item">系统版本: {{ data.padbookVersion }}</div>
        <div class="desc-item">存储容量: {{ data.padbookCapacity }}</div>
        <div class="desc-item">MAC地址: {{ data.padbookMac }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'

export default {
  props: {
    data: { type: Object, default: () => {} },
    adaptForDetailRecode: { type: Boolean, default: false }
  },

  data() {
    return {
      showMore: false
    }
  },

  computed: {
    borrowDate() {
      if (this.adaptForDetailRecode) {
        return this.data.borrowTime
      }
      return this.data.lendDate || this.data.createDate
    },
    expireDate() {
      return this.data.expireDate
    },
    returnDate() {
      if (this.adaptForDetailRecode) {
        return this.data.returnTime
      }
      return this.data.returnDate || this.data.expireDate
    },
    actualBorrowDays() {
      return this.data.actualTerm || this.data.actualBorrowDayNum
    },
    expireDays() {
      return _.parseInt(this.data.expireDays) || 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.borrow-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  padding: 16px 16px 8px;
  border: $--border-base;
  border-radius: $--border-radius-base;
  .state {
    padding-right: 16px;
  }
  .content {
    flex: auto;
    .header {
      display: flex;
      margin-bottom: 16px;
      .title {
        flex: auto;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .meta {
        span {
          margin-left: 1em;
        }
      }
    }
    .desc {
      margin-bottom: 8px;
    }
  }
}

.desc-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 8px;
  border-top: $--border-base;
  .desc-item {
    flex: 0 0 33.33%;
    padding: 8px 0;
  }
}
</style>
