<template>
  <page-layout>
    <page-header :breadcrumb="['读者排行']">
      <inner-tabs v-if="isCXApp" />
    </page-header>

    <page-main>
      <page-action-bar style="margin-top: 0">
        <search />
        <el-button slot="right" size="small" @click="onExportClick">
          <i class="el-icon-printer" /> 列表导出
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items" v-loading="list.loading">
        <el-table-column
          label="排名"
          type="index"
          width="80"
          :index="index => list.indexMethod(index)"
        />
        <el-table-column label="读者姓名" prop="user_name" />
        <el-table-column label="手机号" prop="phone" />
        <el-table-column label="阅读时长" prop="duration">
          <template v-slot="{ row }">
            {{ (row.duration / 60) | number('0[.]0') }}分钟
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <el-dialog
      title="导出读者排行"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </page-layout>
</template>

<script lang="ts">
import store from './store'
import Search from './search.vue'
import { API_HOST } from '@/config'
import queryString from 'querystring'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: {
    Search
  },

  data() {
    return {
      list: store.list,
      exportVisible: false,
      exportURL: ''
    }
  },

  mounted() {
    this.list.fetch({ reset: true })
  },

  methods: {
    onExportClick() {
      const qs = queryString.stringify({
        ...store.search.getSearchParams(),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/bi/reading/range/export?${qs}`
      this.exportVisible = true
    }
  }
}
</script>

<style scoped lang="scss" src="../dashboard.style.scss" />
