<template>
  <el-cascader
    v-model="currentValue"
    :options="items"
    :props="{
      label: 'name',
      value: 'tag_id' //tag_id
    }"
    :filterable="filterable"
    :clearable="clearable"
    placeholder="请选择"
    :size="size"
    :style="{ width }"
    @change="onChange"
    popper-class="cascader-tag-style"
  />
</template>
<script lang="ts">
import store from './tag-selector.store'

export default {
  props: {
    value: { type: Array },
    selectParent: { default: true },
    filterable: { default: false },
    clearable: { default: false },
    size: { default: '' },
    width: { type: [Number, String] }
  },

  data() {
    return {
      currentValue: this.value,
      state: store.state
    }
  },
  computed: {
    items() {
      return this.state.items
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    },
    currentValue() {
      this.$emit('input', this.currentValue)
    }
  },

  mounted() {
    store.fetch()
  },

  methods: {
    onChange(val) {
      this.currentValue = val
    }
  }
}
</script>
<style lang="scss"></style>
