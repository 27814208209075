import { GET } from '@/admin-shared-modules/utils/ajax'
import { Device } from '@/admin-shared-modules/typings'

class Store {
  list = {
    items: <Device[]>[]
  }

  async fetch(type, deviceCode) {
    const res = await GET(`reader/device`, {
      data: {
        type,
        code: deviceCode
      }
    })
    this.list.items = res.data.items
  }
}
export default new Store()
