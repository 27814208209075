<template>
  <page-layout class="presetBook" v-loading="state.loading">
    <page-header :breadcrumb="['图书', '预置图书']" />

    <page-main>
      <div class="headerTool flex">
        <div class="headerTool-left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click="createFolder"
          >
            新建文件夹
          </el-button>
          <el-button type="primary" size="small" @click="isVisibleAddBook">
            添加图书
          </el-button>
        </div>
        <div class="headerTool-right">
          <span class="distributeSwitch marLeft2">
            图书分发
            <el-popover placement="bottom" width="460" trigger="click">
              <init-page :is-show="false" />
              <i slot="reference" class="el-icon-question question" />
            </el-popover>
          </span>
          <el-checkbox
            v-model="state.isUsb"
            class="usbArea marLeft1"
            @change="modifySetting"
          >
            借还柜传书
          </el-checkbox>
          <el-checkbox
            v-model="state.isWifi"
            class="wifiArea marLeft2"
            @change="modifySetting"
          >
            WiFi传书
          </el-checkbox>
        </div>
      </div>
    </page-main>

    <!--创建文件夹数量为0时展示-->
    <page-main
      v-if="!!folder.data && folder.data.length == 0 && books.data.length == 0"
    >
      <init-page />
    </page-main>

    <!-- 有文件夹 -->
    <el-row :gutter="20" v-else class="fileArea">
      <el-col :span="8" v-for="(o, index) in folder.data" :key="index">
        <el-card
          :body-style="{ padding: '12px', display: 'flex' }"
          class="card"
          shadow="hover"
        >
          <el-row class="card-img border">
            <el-col
              :span="12"
              v-for="(r, i) in o.books"
              :key="i"
              v-if="i < 4"
              class="img"
            >
              <img :src="r.image" />
            </el-col>
          </el-row>
          <div class="card-right">
            <div class="title folderTitle clamp2">
              <b>{{ o.name }}</b>
            </div>
            <div class="content">
              <div class="booknum clamp1" v-if="!!o.books">
                {{ o.books.length }}本图书
              </div>
              <div class="time clamp1">
                <time>{{ o.updated_at }}</time>
              </div>
              <div class="btnArea">
                <el-button type="text" class="button" @click="toManage(o)">
                  图书管理
                </el-button>
                <el-button type="text" class="button" @click="editFolder(o)">
                  编辑
                </el-button>
                <el-button type="text" class="button" @click="deleteFolder(o)">
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 根目录书 -->
      <el-col
        :span="8"
        v-for="item in books.data"
        :key="item.name"
        class="card"
      >
        <el-card
          class="preset-card"
          shadow="hover"
          :body-style="{ display: 'flex', padding: '12px' }"
        >
          <div class="card-img">
            <img :src="item.image" />
          </div>
          <div class="card-right">
            <div class="title clamp2">
              {{ item.title }}
            </div>
            <div class="content">
              <div class="author clamp1" v-if="!!item.author">
                <span v-for="(a, b) in item.author" :key="b"
                  >{{ a }}<span v-if="b < item.author.length - 1">,</span></span
                >
              </div>
              <div class="publisher clamp1">
                {{ item.publisher }}
              </div>
              <div class="btnArea actions">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="onRemoveBook(item)"
                >
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <folder-dialog />

    <add-book :visible.sync="isVisible" @confirm="addBookSubmit" />
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './preset-book.store'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import initPage from './preset-book-init.component.vue'
import folderDialog from './preset-book-edit.component.vue'
import addBook from '@/components/book-selector/book-selector-dialog.component.vue'

export default {
  name: 'PresetBook',
  data() {
    return {
      state: store.state,
      folder: store.folder,
      books: store.books,
      isVisible: false
    }
  },
  components: {
    initPage,
    folderDialog,
    addBook
  },
  mounted() {
    store.fetch()
  },
  methods: {
    createFolder() {
      store.addFolder()
    },
    isVisibleAddBook() {
      //显示添加图书
      this.isVisible = !this.isVisible
    },
    async modifySetting() {
      await store.updateSetting()
      if (!this.state.isWifi && !this.state.isUsb) {
        MessageService.open({
          message: `您已关闭所有图书分发模式`,
          type: 'warning'
        })
        return
      }
      const t1 = this.state.isWifi ? 'WiFi传书' : '',
        t2 = this.state.isUsb ? '借还柜传书' : '',
        t3 = !!t1 && !!t2 ? '和' : '',
        type = `${t1} ${t3} ${t2}`
      MessageService.open({ message: `您当前的图书分发模式为${type}` })
    },
    editFolder(data) {
      //编辑
      store.editFolder(data)
    },
    async deleteFolder(data) {
      let message = '',
        length = data.books.length || 0 //根据0文件夹图书数是否是0来显示,0:确定删除文件夹【文件夹名称】吗？删除后不可恢复,>0:【文件夹名称】文件夹内有 10 本图书，确定删除吗？删除后不可恢复
      if (length > 0)
        message = `<<${data.name}>>文件夹内有${length}本图书，确定删除吗？`
      else message = `确定删除文件夹<<${data.name}>>吗？`
      await MessageBoxService.confirm({
        type: 'warning',
        message: message
      })
      await store.removeFolder(data)
      MessageService.open({
        message: `删除成功 `
      })
    },
    toManage(data) {
      this.$router.push({ name: 'presetBookDetail', query: { id: data.id } })
    },
    async addBookSubmit(books, ids) {
      await store.addBook(ids, { from: 'origin' })
    },
    async onRemoveBook(data) {
      await MessageBoxService.confirm({
        type: 'warning',
        message: '确定删除该图书？'
      })
      await store.removeBook(data, { from: 'origin' })
    }
  }
}
</script>

<style lang="scss" scoped src="./preset-book.style.scss"></style>
