<template>
  <div :loading="list.loading">
    <search />

    <div style="margin: 16px 0 8px 0">
      <div style="font-size: 15px; color: #409eff">
        <i class="el-icon-info" />
        学习通用户(使用学习通账号登录过阅读本的读者)
        {{ list.summary.online_user || 0 }} 人， 其中
        {{ list.summary.study_user || 0 }} 个学习通用户,
        {{ list.summary.anonymous_user || 0 }}个匿名用户
      </div>
    </div>

    <el-table :data="list.items">
      <el-table-column
        type="index"
        width="50"
        :index="i => store.indexMethod(i)"
      />
      <el-table-column label="读者信息" width="220">
        <template slot-scope="{ row }">
          <div class="table__user-info">
            <div class="name">
              {{ row.user_name }}
            </div>
          </div>
          <div class="desc" style="white-space: nowrap">
            学习通账号: {{ row.cx_uname || '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="借阅图书数" align="right">
        <template slot-scope="{ row }"> {{ row.lend_count }} 本 </template>
      </el-table-column>
      <el-table-column label="累计阅读时间" align="right">
        <template slot-scope="{ row }"> {{ row.read_time }} 小时 </template>
      </el-table-column>
      <el-table-column label="" width="30px" />
      <el-table-column label="首次登录">
        <template slot-scope="{ row }">
          {{ row.first_login_time | date }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button type="text" @click="onPushCountClick(scope.row)">
            查看详情
          </el-button>
          <el-button type="text" @click="onReadReportClick(scope.row)">
            阅读报告
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handlePageChange"
      :current-page="list.index"
      :total="list.total"
      :page-size="list.size"
      layout="total, prev, pager, next"
      background
    />

    <borrower-details ref="userDetailsDialog" />
    <borrower-user-reporting ref="userReportDialog" />
  </div>
</template>

<script lang="ts">
import Search from './user-search.component.vue'
import BorrowerDetails from '../user-push-log/details.component.vue'
import BorrowerUserReporting from '@/views/user/user-reporting/user-reporting.component.vue'
import store from './user.store'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: { Search, BorrowerDetails, BorrowerUserReporting },

  data() {
    return {
      store,
      list: store.list
    }
  },

  mounted() {
    store.fetch(true)
  },

  methods: {
    handlePageChange(index: number) {
      this.list.index = index
      store.fetch()
    },

    onPushCountClick(user) {
      this.$refs.userDetailsDialog.open(user)
    },

    onReadReportClick(user) {
      this.$refs.userReportDialog.open(user)
    }
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
