<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="860px"
    top="8vh"
    @open="onDialogOpen"
  >
    <el-steps :active="edit.step" style="margin: -16px 24px 24px">
      <el-step title="选择图书" />
      <el-step title="确认推送信息" />
      <el-step title="完成" />
    </el-steps>

    <page-action-bar>
      <el-button type="primary" size="small" @click="openFolderAdd">
        创建文件夹
      </el-button>
      <el-button size="small" @click="bookSelectVisible = true">
        添加图书
      </el-button>
    </page-action-bar>

    <template v-if="edit.step === 0">
      <div
        v-if="!edit.data.folder.length && !edit.data.books.length"
        class="empty-container"
      >
        暂未添加图书或文件夹
      </div>

      <div class="book-and-folder-list">
        <div class="col" v-for="item in edit.data.folder" :key="item.id">
          <div class="book-folder__grid-item">
            <div class="thumb-grid">
              <div class="cell" v-for="book in getFolderBookLimit(item.books)">
                <div class="thumb">
                  <img :src="book.image" alt="" />
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">
                {{ item.name }}
              </div>
              <div class="desc">{{ item.books.length }}本图书</div>
              <div class="action">
                <el-button
                  type="text"
                  size="small"
                  @click="openFolderEdit(item)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="onFolderRemove(item)"
                >
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div class="col" v-for="item in edit.data.books" :key="item.id">
          <div class="book-list__item">
            <div class="thumb">
              <img :src="item.image" alt="" />
            </div>
            <div class="content">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="desc">
                {{ item.author | join }}
              </div>
              <div class="action">
                <el-button type="text" @click="onBookRemove(item)">
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="edit.step === 1">
      <el-form
        v-if="edit.visible"
        :model="edit.data"
        ref="form"
        label-width="6em"
        style="width: 350px; margin: 20px auto 0"
      >
        <el-form-item label="推送内容">
          <span v-if="this.edit.data.folder.length">
            {{ this.edit.data.folder.length }}个文件夹,
          </span>
          <span> 共{{ bookCount }}本图书 </span>
        </el-form-item>
        <el-form-item label="时间"> 立即推送 </el-form-item>
        <el-form-item label="设备"> 本馆所有设备 </el-form-item>
        <el-form-item label="推送备注" prop="summary">
          <el-input v-model="edit.data.summary" />
        </el-form-item>
      </el-form>
    </template>

    <div slot="footer" v-if="edit.step === 0">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="goNext"> 下一步 </el-button>
    </div>
    <div slot="footer" v-if="edit.step === 1">
      <el-button @click="edit.step = 0"> 上一步 </el-button>
      <el-button type="primary" @click="confirm"> 确认 </el-button>
    </div>

    <folder-edit v-if="edit.visible" />

    <book-select-dialog
      :visible.sync="bookSelectVisible"
      @confirm="onAddBookConfirm"
    />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store, { Folder } from './preset-book-send.store'
import BookSelectDialog from '@/components/book-selector/book-selector-dialog.component.vue'
import FolderEdit from './folder/folder-edit.component.vue'
import { Book } from '@/admin-shared-modules/typings'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export default {
  components: { BookSelectDialog, FolderEdit },

  data() {
    return {
      edit: store.add,
      bookSelectVisible: false
    }
  },
  computed: {
    bookCount() {
      return (
        _.reduce(
          this.edit.data.folder,
          (result, f) => {
            return result + f.books.length
          },
          0
        ) + this.edit.data.books.length
      )
    }
  },

  methods: {
    onDialogOpen() {
      this.edit.step = 0
    },

    getFolderBookLimit(items) {
      return _.take(items, 4)
    },

    openFolderAdd() {
      store.onFolderAdd()
    },
    openFolderEdit(item: Folder) {
      store.onFolderEdit(item)
    },
    onAddBookConfirm(books) {
      this.edit.data.books.push(...books)
    },
    onFolderRemove(item: Folder) {
      this.edit.data.folder = _.reject(this.edit.data.folder, f => {
        return f._id === item._id
      })
    },

    onBookRemove(item: Book) {
      this.edit.data.books = _.reject(this.edit.data.books, b => {
        return b.id === item.id
      })
    },

    goNext() {
      if (!this.edit.data.folder.length && !this.edit.data.books.length) {
        MessageBoxService.alert({
          type: 'warning',
          message: '还未添加图书或文件夹'
        })
        return
      }
      if (!store.isFolderNameUnique()) {
        MessageBoxService.alert({
          type: 'warning',
          message: '文件夹重名, 请检查'
        })
        return
      }

      this.edit.step = 1
    },
    confirm() {
      store.onAddSubmit()
    }
  }
}
</script>
