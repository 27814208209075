import _ from 'lodash'
import { GET } from '@/admin-shared-modules/utils/ajax'

export type DateRangeType = 'week' | 'month' | 'three_months'

const getDateRangeForQuery = (type: DateRangeType) => {
  if (type === 'three_months') return null
  return type
}

class Store {
  bookSendCountData = {
    dateRange: 'week' as DateRangeType,
    loading: true,
    items: [],
    count: null,
    weekCount: null
  }

  userCountData = {
    dateRange: 'week' as DateRangeType,
    loading: true,
    items: [],
    count: null,
    weekCount: null
  }

  bookCateData = {
    loading: true,
    items: []
  }

  topBookData = {
    loading: true,
    items: []
  }

  async fetchBookSendCountData() {
    this.bookSendCountData.loading = true
    const res = await GET('kindle/book/statistics', {
      data: {
        read_time: getDateRangeForQuery(this.bookSendCountData.dateRange)
      }
    })
    this.bookSendCountData.items = res.data.reading_trend
    this.bookSendCountData.count = res.data.system_book
    this.bookSendCountData.weekCount = res.data.reading_book_num
    this.bookSendCountData.loading = false
  }

  async fetchUserCountData() {
    this.userCountData.loading = true
    const res = await GET('kindle/reader/statistics', {
      data: {
        read_time: getDateRangeForQuery(this.userCountData.dateRange)
      }
    })
    this.userCountData.items = _.take(res.data.reading_rankings, 10)
    this.userCountData.count = res.data.readership_total
    this.userCountData.weekCount = res.data.icrease_num
    this.userCountData.loading = false
  }
  async fetchBookData() {
    this.bookCateData.loading = true
    this.topBookData.loading = true

    const res = await GET('kindle/tag/statistics', {})
    this.bookCateData.items = _.take(res.data.popular_tag, 10)
    this.topBookData.items = _.take(res.data.popular_book, 10)

    this.bookCateData.loading = false
    this.topBookData.loading = false
  }
}

export default new Store()
