<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    width="860px"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="6em"
      v-loading="edit.loading"
    >
      <el-form-item label="书单名称" prop="name">
        <el-input v-model.trim="edit.data.name" />
      </el-form-item>
      <el-form-item label="书单名称" prop="lib_credit_books">
        <el-button type="primary" size="small" @click="onAddClick">
          新增图书
        </el-button>
      </el-form-item>
    </el-form>

    <core-table
      v-model="edit.selectRows"
      :data="edit.data.lib_credit_books"
      :loading="edit.loading"
      size="small"
      :columns="[
        { type: 'index' },
        { label: '封面', slot: 'cover' },
        { label: '书名', slot: 'title' },
        { label: '出版社', dataKey: 'publisher' },
        { label: 'ISBN', dataKey: 'isbn_13' },
        { label: '专业/通识', slot: 'isPro' },
        { label: '阅读类型', slot: 'isPaper' },
        { label: '操作', slot: 'action', width: 80 }
      ]"
    >
      <template v-slot:cover="{ row }">
        <img
          :src="row.image"
          alt="封面"
          class="cover-img"
          style="width: 60px"
        />
      </template>
      <template v-slot:title="{ row }">
        <div style="font-size: 14px">
          {{ row.title }}
        </div>
        <div style="color: #777">
          {{ row.author | join }}
        </div>
      </template>
      <template v-slot:isPro="{ row }">
        <el-tag
          :type="row.is_professional ? 'success' : 'warning'"
          size="small"
          disable-transitions
        >
          {{ row.is_professional ? '专业' : '通识' }}
        </el-tag>
      </template>
      <template v-slot:isPaper="{ row }">
        <el-tag
          :type="row.is_paper ? 'success' : 'warning'"
          size="small"
          disable-transitions
        >
          {{ row.is_paper ? '纸本' : '电子' }}
        </el-tag>
      </template>
      <template v-slot:action="{ row }">
        <el-button type="text" @click="onRemoveClick(row)"> 删除 </el-button>
      </template>
    </core-table>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" :loading="edit.loading" @click="onSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { CreditBookSelectorDialog } from '@/views/credit-topic/shared/credit-book-selector-dialog/store'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        name: [{ required: true, message: '请填写' }],
        lib_credit_books: [
          { type: 'array', required: true, message: '请选择图书' }
        ]
      }
    }
  },

  methods: {
    async onAddClick() {
      const data = await CreditBookSelectorDialog.open()
      this.edit.data.lib_credit_books = _.chain(this.edit.data.lib_credit_books)
        .concat(data)
        .uniqBy('id')
        .value()
      MessageService.open({ type: 'success', message: '新增成功' })
    },
    async onRemoveClick(item) {
      await MessageBoxService.confirm({ message: '确认删除' })
      const index = _.findIndex(this.edit.data.lib_credit_books, {
        id: item.id
      })
      this.edit.data.lib_credit_books.splice(index, 1)
    },

    async onSubmit() {
      await this.$refs.form.validate()
      this.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>
