<template>
  <el-dialog
    :visible.sync="edit.visible"
    title="审核"
    width="780px"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
    <div class="user-info">
      <el-row type="flex">
        <el-col :span="16">
          <div class="title">
            {{ edit.params.user_name }}
          </div>
          <el-row type="flex">
            <el-col :span="8">
              <span class="label">入学年份:</span> {{ edit.params.year }}年
            </el-col>
            <el-col :span="8">
              <span class="label">当前:</span> {{ list.termName }}
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8">
              <span class="label">学号:</span> {{ edit.params.card_num }}
            </el-col>
            <el-col :span="8">
              <span class="label">手机号:</span> {{ edit.params.phone }}
            </el-col>
          </el-row>
          <div class="action">
            <el-button type="text" @click="openHistory">
              查看借阅历史 >
            </el-button>
            <el-button type="text" @click="openNoteList">
              查看读书笔记 >
            </el-button>
            <el-button type="text" @click="openTestingList">
              查看评测结果 >
            </el-button>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart" ref="reviewChart" style="height: 140px" />
        </el-col>
      </el-row>
    </div>

    <div v-if="edit.visible" class="user-info">
      <el-form
        ref="form"
        :model="edit.data"
        :rules="rules"
        label-width="8em"
        size="small"
      >
        <div class="group">
          <div class="title">考核标准</div>
          <div class="body">
            <el-form-item class="mb0" label="借阅量">
              <div style="display: flex">
                <div class="cell">
                  总借阅量:
                  <span
                    class="num"
                    :class="rcCountValue >= rcCountGoal ? 'blue' : 'red'"
                    >{{ rcCountValue }}</span
                  >/{{ rcCountGoal }}
                </div>
                <div class="cell">
                  通识书:
                  <span
                    class="num"
                    :class="
                      rcNormalCountValue >= rcNormalCountGoal ? 'blue' : 'red'
                    "
                    >{{ rcNormalCountValue }}</span
                  >/{{ rcNormalCountGoal }}
                </div>
                <div class="cell">
                  专业书:
                  <span
                    class="num"
                    :class="rcProCountValue >= rcProCountGoal ? 'blue' : 'red'"
                    >{{ rcProCountValue }}</span
                  >/{{ rcProCountGoal }}
                </div>
                <div class="cell">
                  专业分类:
                  <span
                    class="num"
                    :class="
                      rcProCateCountValue >= rcProCateCountGoal ? 'blue' : 'red'
                    "
                    >{{ rcProCateCountValue }}</span
                  >/{{ rcProCateCountGoal }}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb0" label="读书笔记">
              <div style="display: flex">
                <div class="cell">
                  读书笔记数:
                  <span
                    class="num"
                    :class="noteCount >= noteGoal ? 'blue' : 'red'"
                    >{{ noteCount }}</span
                  >/{{ noteGoal }}
                </div>
                <div class="cell">
                  合格及以上等级:
                  <span
                    class="num"
                    :class="notePassCount >= notePassGoal ? 'blue' : 'red'"
                    >{{ notePassCount }}</span
                  >/{{ notePassGoal }}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb0" label="阅读评测">
              <div style="display: flex">
                <div class="cell">
                  评测通过数:
                  <span
                    class="num"
                    :class="tsCountValue >= tsCountGoal ? 'blue' : 'red'"
                    >{{ tsCountValue }}</span
                  >/{{ tsCountGoal }}
                </div>
                <div class="cell">
                  通识书:
                  <span
                    class="num"
                    :class="
                      tsNormalCountValue >= tsNormalCountGoal ? 'blue' : 'red'
                    "
                    >{{ tsNormalCountValue }}</span
                  >/{{ tsNormalCountGoal }}
                </div>
                <div class="cell">
                  专业书:
                  <span
                    class="num"
                    :class="tsProCountValue >= tsProCountGoal ? 'blue' : 'red'"
                    >{{ tsProCountValue }}</span
                  >/{{ tsProCountGoal }}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb0" label="附加要求" prop="attach_require">
              <el-radio-group v-model="edit.data.attach_require">
                <el-radio :label="1"> 已达到 </el-radio>
                <el-radio :label="2"> 未达到 </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div class="group">
          <div class="title">审核结果</div>
          <div class="body">
            <el-form-item label="本期考核结果" prop="status">
              <el-radio-group v-model="edit.data.status">
                <el-radio :label="2"> 通过 </el-radio>
                <el-radio :label="1"> 不通过 </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="获得学分值" prop="score">
              <el-input-number
                v-model="edit.data.score"
                controls-position="right"
                :min="0"
              />
              分
              <span class="red"> （本学年最多可获得2学分） </span>
            </el-form-item>
            <el-form-item label="审核意见">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="选填"
                v-model="edit.data.msg"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="middle" @click="onSubmit">
                确认
              </el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <read-history />
    <note-list />
    <testing-list />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import G2 from '@/admin-shared-modules/utils/g2'
import ReadHistory from './read-history-dialog/index.vue'
import { store as readHistoryStore } from './read-history-dialog/store'
import NoteList from './note-list-dialog/index.vue'
import { store as noteListStore } from './note-list-dialog/store'
import TestingList from './testing-list-dialog/index.vue'
import { store as testingListStore } from './testing-list-dialog/store'

export default {
  components: {
    ReadHistory,
    NoteList,
    TestingList
  },

  data() {
    return {
      edit: store.edit,
      list: store.list
    }
  },

  computed: {
    config() {
      return this.edit.data.config || {}
    },
    values() {
      return this.edit.data.statistic || {}
    },
    reviewLendPresent() {
      if (_.get(this.config, 'lend.is_check')) {
        const val =
          _.get(this.values, 'read.read_count') /
          _.get(this.config, 'lend.lend_count')
        return Math.min(_.floor(val, 2), 1) || 0
      }
      return 1
    },
    reviewNotePresent() {
      if (_.get(this.config, 'note.is_check')) {
        const val =
          _.get(this.values, 'note.note_pass_count') /
          _.get(this.config, 'note.count')
        return Math.min(_.floor(val, 2), 1) || 0
      }
      return 1
    },
    reviewTestingPresent() {
      if (_.get(this.config, 'read_test.is_check')) {
        const val =
          _.get(this.values, 'test.test_pass_count') /
          _.get(this.config, 'read_test.pass_count')
        return Math.min(_.floor(val, 2), 1) || 0
      }
      return 1
    },
    reviewPresent() {
      const val =
        this.reviewLendPresent +
        this.reviewNotePresent +
        this.reviewTestingPresent
      return _.floor(val / 3, 2) * 100
    },
    rcCountValue() {
      return _.get(this.values, 'read.read_count')
    },
    rcCountGoal() {
      if (!_.get(this.config, 'lend.is_check')) return 0
      return _.get(this.config, 'lend.lend_count', 0)
    },
    rcNormalCountValue() {
      return _.get(this.values, 'read.read_normal_count', 0)
    },
    rcNormalCountGoal() {
      if (!_.get(this.config, 'lend.is_check')) return 0
      return _.get(this.config, 'lend.nomal_count', 0)
    },
    rcProCountValue() {
      return _.get(this.values, 'read.read_professional_count') || 0
    },
    rcProCountGoal() {
      if (!_.get(this.config, 'lend.is_check')) return 0
      return _.get(this.config, 'lend.professional_count', 0)
    },
    rcProCateCountValue() {
      return _.get(this.values, 'read.read_tags_count', 0)
    },
    rcProCateCountGoal() {
      if (!_.get(this.config, 'lend.is_check')) return 0
      return _.get(this.config, 'lend.professional_tag_count', 0)
    },
    noteCount() {
      return _.get(this.values, 'note.note_count')
    },
    noteGoal() {
      return _.get(this.config, 'note.count', '-')
    },
    notePassCount() {
      return _.get(this.values, 'note.note_pass_count')
    },
    notePassGoal() {
      return _.get(this.config, 'note.pass_count', '-')
    },
    tsCountValue() {
      return _.get(this.values, 'test.test_pass_count') || 0
    },
    tsCountGoal() {
      return _.get(this.config, 'read_test.pass_count', 1)
    },
    tsNormalCountValue() {
      return _.get(this.values, 'test.test_nomal_count') || 0
    },
    tsNormalCountGoal() {
      return _.get(this.config, 'read_test.nomal_count', 1)
    },
    tsProCountValue() {
      return _.get(this.values, 'test.test_professional_count') || 0
    },
    tsProCountGoal() {
      return _.get(this.config, 'read_test.professional_count', 1)
    },

    rules() {
      const baseRequireRule = { required: true, message: '请填写' }
      return {
        attach_require: [baseRequireRule],
        status: [baseRequireRule],
        score: [
          baseRequireRule,
          {
            type: 'number',
            max: this.config.score || 999,
            message: `不能超过最大值`
          }
        ]
      }
    }
  },
  watch: {
    async 'edit.visible'() {
      await this.$nextTick()
      this.renderReviewChart()
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    },

    renderReviewChart() {
      const TypeKey = 'type'
      const ValueKey = 'value'
      const data = [
        { type: '借阅量', value: this.reviewLendPresent },
        { type: '读书笔记', value: this.reviewNotePresent },
        { type: '阅读评测', value: this.reviewTestingPresent }
      ]
      const chart = new G2.Chart({
        container: this.$refs.reviewChart,
        height: this.$refs.reviewChart.offsetHeight,
        forceFit: true,
        padding: 'auto'
      })
      chart.coord('polar')
      chart.legend(false)
      chart.tooltip(false)
      chart.source(data, {
        [ValueKey]: {
          min: 0,
          max: 1,
          nice: false
        }
      })
      chart.axis(ValueKey, {
        label: null,
        line: null,
        tickLine: null,
        grid: {
          type: 'polygon',
          lineStyle: {
            lineDash: null
          }
        }
      })
      chart.axis(TypeKey, {
        line: null,
        tickLine: null,
        label: {
          formatter(text: string, item: any, index: number): string {
            const percent = `(${data[index][ValueKey] * 100}%)`
            let t1 = text
            if (text === '借阅量') {
              t1 = `${text} ${percent}`
            }
            if (text === '阅读评测') {
              t1 = `${text}\n${percent}   `
            }
            if (text === '读书笔记') {
              t1 = `${text}\n    ${percent}`
            }
            return t1
          }
        }
      })
      chart.area().position(`${TypeKey}*${ValueKey}`)
      chart.line().position(`${TypeKey}*${ValueKey}`)
      chart.point().position(`${TypeKey}*${ValueKey}`).style({
        stroke: '#fff',
        lineWidth: 1
      })
      chart.render()
    },

    openHistory() {
      readHistoryStore.open({
        userId: this.edit.params.id,
        begin_date: 1,
        end_date: 2
      })
    },
    openNoteList() {
      noteListStore.open({
        userId: this.edit.params.id,
        begin_date: 1,
        end_date: 2
      })
    },
    openTestingList() {
      testingListStore.open({
        userId: this.edit.params.id,
        begin_date: 1,
        end_date: 2
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.user-info {
  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid $--border-color-base;
  &:first-child {
    margin-top: -20px;
  }
  .title {
    margin-bottom: 8px;
    font-size: $--font-size-large;
    font-weight: bold;
  }
  .el-row {
    margin-top: 8px;
    font-size: $--font-size-base + 1;
  }
  .label {
    margin-right: 8px;
    color: $--color-text-secondary;
  }
  .action {
    margin-top: 16px;
    button {
      margin-right: 16px;
    }
  }
}
.mb0 {
  //margin-bottom: 0;
}
.group {
  margin-bottom: 30px;
  .title {
    font-size: $--font-size-large;
  }
}
.el-form-item__content {
  .cell {
    margin-right: 40px;
    .num {
      font-size: $--font-size-large;
    }
  }
}
</style>
