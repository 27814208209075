<template>
  <el-dialog
    :visible.sync="edit.visible"
    title="编辑"
    class="edit-dialog"
    top="6vh"
    width="700px"
    :close-on-click-modal="false"
  >
    <el-form
      v-loading="edit.loading"
      ref="form"
      v-if="edit.visible"
      :model="edit.data"
      :show-message="false"
      label-width="100px"
    >
      <div class="form-row">
        <div class="right">
          <el-form-item label="ISBN" prop="isbn_13">
            <el-input
              v-model="edit.data.isbn_13"
              maxlength="13"
              :disabled="!edit.data.can_update"
            />
          </el-form-item>
          <el-form-item
            v-for="(tag, $index) in edit.data.tagsGroup"
            :key="$index"
            :label="$index === 0 ? '分类' : `分类${$index + 1}`"
            :prop="`tagsGroup.${$index}.items`"
            :rules="[
              { required: $index === 0, trigger: 'change' },
              { type: 'array' }
            ]"
          >
            <div style="display: flex; margin-right: 10px">
              <book-tag-selector v-model="tag.items" style="flex: 1" />
              <el-button
                v-if="$index !== 0"
                type="text"
                style="margin-left: 10px"
                @click="onTagRemoveClick($index)"
              >
                删除
              </el-button>
            </div>
          </el-form-item>
          <el-form-item style="margin-top: -10px">
            <el-button size="small" @click="onTagAddClick()">
              新增分类
            </el-button>
          </el-form-item>

          <el-form-item label="书名" prop="title" :rules="[{ required: true }]">
            <el-input
              v-model="edit.data.title"
              :disabled="!edit.data.can_update"
            />
          </el-form-item>
          <el-form-item
            label="作者"
            prop="authorStr"
            :rules="[{ required: true }]"
          >
            <el-input
              v-model="edit.data.authorStr"
              :disabled="!edit.data.can_update"
            />
            <div class="el-upload__tip">多个作者使用逗号分隔</div>
          </el-form-item>
          <el-form-item
            label="出版社"
            prop="publisher"
            :rules="[{ required: false }]"
          >
            <el-input
              v-model="edit.data.publisher"
              :disabled="!edit.data.can_update"
            />
          </el-form-item>
          <el-form-item label="出版时间" prop="pub_date">
            <el-date-picker
              v-model="edit.data.pub_date"
              type="date"
              :editable="false"
              :clearable="false"
              :disabled="!edit.data.can_update"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="页数" prop="pages">
            <el-input
              v-model="edit.data.pages"
              :disabled="!edit.data.can_update"
            />
          </el-form-item>
        </div>

        <div class="left" style="margin-left: 24px">
          <div class="book-thumb">
            <el-upload
              class="image-uploader"
              :class="{ 'cover-uploader--bordered': !edit.data.image }"
              action="/api/v1/admin/upload/image"
              accept="image/*"
              :show-file-list="false"
              :before-upload="beforeCoverUpload"
              :on-success="onCoverUploadSuccess"
              :on-error="onCoverUploadError"
            >
              <img v-if="edit.data.image" :src="edit.data.image" />
              <i v-else class="el-icon-plus uploader-icon" />
            </el-upload>
            <div class="el-upload__tip" slot="tip">
              只能上传图片文件，建议尺寸180*240，且不超过500kb
            </div>
          </div>
        </div>
      </div>

      <el-form-item label="简介" prop="summary">
        <el-input
          v-model="edit.data.summary"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10 }"
          :disabled="!edit.data.can_update"
        />
      </el-form-item>
      <el-form-item label="目录" prop="catalog">
        <el-input
          v-model="edit.data.catalog"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10 }"
          :disabled="!edit.data.can_update"
        />
      </el-form-item>

      <el-form-item label="电子书文件" prop="file" :rule="[{ required: true }]">
        <div
          v-if="edit.data.epub_file"
          style="display: flex; align-items: center"
        >
          <el-tag
            :disable-transitions="true"
            :closable="false"
            style="margin-right: 10px"
          >
            {{ edit.data.epub_file }}
          </el-tag>
          <a :href="`/api/book/file/${edit.data.id}`" target="_blank">下载</a>
        </div>
        <div v-else>生成中</div>
      </el-form-item>

      <el-form-item label="状态">
        <el-select
          v-model="edit.data.is_release"
          :disabled="!edit.data.can_update"
        >
          <el-option label="可用" :value="true" />
          <el-option label="下架" :value="false" />
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './store'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import BookTagSelector from './shared/tag-selector.vue'

export default {
  components: { BookTagSelector },

  data() {
    return {
      edit: store.edit,
      isUploading: false,
      rules: {}
    }
  },
  watch: {
    'edit.data.property'(val) {
      store.fetchTag(val)
    }
  },
  computed: {
    coverImageUrl() {
      return this.edit.data.image
    }
  },

  methods: {
    //#region cover upload
    beforeCoverUpload(file: File) {
      if (!/^image\//.test(file.type)) {
        MessageService.open({ type: 'error', message: '图片格式无效' })
        return false
      }
      if (file.size / 1024 > 500) {
        MessageService.open({ type: 'error', message: '图片大小不能超过500kb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onCoverUploadError(err, file: File) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    },
    onCoverUploadSuccess(res, file: File) {
      this.isUploading = false
      this.edit.data.image = _.get(res, 'data.url')
    },

    //#region tags
    onTagAddClick() {
      this.edit.data.tagsGroup.push({
        items: []
      })
    },
    onTagRemoveClick(index) {
      if (this.edit.data.tagsGroup.length > 1) {
        this.edit.data.tagsGroup.splice(index, 1)
      }
    },
    //#endregion

    onSubmit() {
      this.$refs['form'].validate(async v => {
        if (!v) {
          MessageService.open({ type: 'error', message: '请检查填写内容' })
          return
        }
        store.onEditSubmit()
      })
    }
  }
}
</script>

<style scoped lang="scss" src="../book/book.style.scss"></style>
