import { render, staticRenderFns } from "./dashboard.compoment.vue?vue&type=template&id=c497a114&scoped=true"
import script from "./dashboard.compoment.vue?vue&type=script&lang=ts"
export * from "./dashboard.compoment.vue?vue&type=script&lang=ts"
import style0 from "../dashboard.style.scss?vue&type=style&index=0&id=c497a114&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c497a114",
  null
  
)

export default component.exports