<template>
  <page-layout id="dashbord">
    <page-header v-if="!isCXApp" :breadcrumb="['综合分析']" />

    <div class="page-dashboard">
      <el-row :gutter="24" type="flex" class="stretch-items page-section">
        <el-col :span="12">
          <div class="panel summary-card" v-loading="summary.loading">
            <div class="panel__body">
              <div class="summary-card__header">阅读本设备</div>
              <div class="summary-card__body">
                <div class="summary-card__main">
                  共 <span class="num">{{ summary.device.count }}</span> 台
                </div>
                <el-row type="flex" justify="space-between">
                  <el-col
                    >借出 <b>{{ summary.device.lend }}</b> 台</el-col
                  >
                  <el-col style="text-align: right">
                    在馆 <b>{{ summary.device.inStore }}</b> 台
                  </el-col>
                </el-row>
                <el-progress
                  class="summary-card__progress-bar"
                  :percentage="summary.device.lendPercent"
                  :stroke-width="8"
                  :show-text="false"
                />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="panel summary-card" v-loading="summary.loading">
            <div class="panel__body">
              <div class="summary-card__header">共借阅图书</div>
              <div class="summary-card__body">
                <div class="summary-card__main">
                  <span class="num">{{ summary.bookLendCount | number }}</span>
                  本
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="panel summary-card" v-loading="summary.loading">
            <div class="panel__body">
              <div class="summary-card__header">总阅读时长</div>
              <div class="summary-card__body">
                <div class="summary-card__main">
                  <span class="num">{{
                    summary.bookReadTimeCount | number
                  }}</span>
                  小时
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div v-if="false" class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">热门资源</div>
        </div>
        <div class="panel__body">
          <el-row :gutter="24" type="flex" class="stretch-items">
            <el-col :span="12">
              <div class="panel panel--no-border panel--stretch">
                <div class="panel__inner-header">
                  <div class="title">热门分类</div>
                </div>
                <div class="panel-stretch-body" v-loading="topBookData.loading">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="topCateDataChart" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="panel__inner-header">
                <div class="title">热门图书</div>
              </div>
              <el-table
                :data="topBookData.items"
                size="small"
                v-loading="topBookData.loading"
              >
                <el-table-column
                  type="index"
                  label="排名"
                  align="center"
                  width="90px"
                />
                <el-table-column width="50px" />
                <el-table-column
                  label="书名"
                  prop="book_title"
                  align="left"
                  show-tooltip-when-overflow
                />
                <el-table-column label="借阅次数" align="right" width="100px">
                  <template slot-scope="{ row }">
                    {{ row.read_count | number }}
                  </template>
                </el-table-column>
                <el-table-column width="10px" />
              </el-table>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">图书分类分布</div>
        </div>
        <div class="panel__body">
          <el-row :gutter="24" type="flex" class="stretch-items">
            <el-col :span="12">
              <div
                class="panel panel--no-border panel--stretch"
                v-loading="bookCateData.loading"
              >
                <div class="panel__inner-header">
                  <div class="title">图书分布</div>
                </div>
                <div class="panel-stretch-body">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="bookCateDataChart" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <el-table
                :data="bookCateData.items.slice(0, 10)"
                size="small"
                v-loading="bookCateData.loading"
              >
                <el-table-column
                  type="index"
                  label="排名"
                  align="center"
                  width="90px"
                />
                <el-table-column width="50px" />
                <el-table-column
                  label="分类"
                  prop="tag_name"
                  align="left"
                  show-tooltip-when-overflow
                />
                <el-table-column label="总数 (本)" align="right" width="100px">
                  <template slot-scope="{ row }">
                    {{ row.book_count | number }}
                  </template>
                </el-table-column>
                <el-table-column width="10px" />
              </el-table>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="panel page-section">
        <div class="panel__header">
          <div class="title title--large">阅读趋势</div>
          <date-range-select
            v-model="readingTrendData.dateRange"
            @change="onReadingTrendDateRangeChange"
          />
        </div>
        <div class="panel__body">
          <el-row :gutter="24" type="flex" class="stretch-items">
            <el-col :span="12">
              <div
                class="panel panel--no-border panel--stretch"
                v-loading="readingTrendData.loading"
              >
                <div class="panel__inner-header">
                  <div class="title">阅读时间</div>
                  <div>
                    共 {{ readingTrendData.readTimeCountInHours | number }} 小时
                  </div>
                </div>
                <div class="panel-stretch-body">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="readingTrendTimeData" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div
                class="panel panel--no-border panel--stretch"
                v-loading="readingTrendData.loading"
              >
                <div class="panel__inner-header">
                  <div class="title">阅读图书</div>
                  <div>共 {{ readingTrendData.readBookCount | number }} 本</div>
                </div>
                <div class="panel-stretch-body">
                  <div class="chart-container chart-container--stretch">
                    <div class="chart" ref="readingTrendBookData" />
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-row :gutter="24" type="flex" class="stretch-items page-section">
        <el-col :span="12">
          <div class="panel panel--stretch" style="min-height: 609px">
            <div class="panel__header">
              <div class="title">借还趋势</div>
              <div class="actions">
                <date-range-select
                  v-model.sync="lendTrendData.dateRange"
                  @change="onLendTrendDateRangeChange"
                />
              </div>
            </div>
            <div
              class="panel__body panel-stretch-body"
              v-loading="lendTrendData.loading"
            >
              <div class="summary">
                <div class="summary__item">
                  <div class="summary__label">借出</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ lendTrendData.lendCount | number }}
                      <span class="unit">台</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item">
                  <div class="summary__label">归还</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ lendTrendData.returnCount | number }}
                      <span class="unit">台</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item">
                  <div class="summary__label">累计</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ lendTrendData.total | number }}
                      <span class="unit">次</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="chart-container chart-container--stretch">
                <div class="chart" ref="lendTrendDataChart" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="panel panel--stretch">
            <div class="panel__header">
              <div class="title">读者统计</div>
              <div class="actions">
                <date-range-select
                  v-model.sync="userCountData.dateRange"
                  @change="onUserCountDateRangeChange"
                />
              </div>
            </div>
            <div class="panel__body" v-loading="userCountData.loading">
              <div class="summary">
                <div class="summary__item">
                  <div class="summary__label">服务读者总数</div>
                  <div class="summary__data">
                    <span class="num">
                      {{ userCountData.count | number }}
                      <span class="unit">人</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item">
                  <div class="summary__label">
                    {{ userCountData.dateRange | dateRangeLabel }}新增
                  </div>
                  <div class="summary__data">
                    <span class="num">
                      {{ userCountData.weekCount | number }}
                      <span class="unit">人</span>
                    </span>
                  </div>
                </div>
              </div>
              <el-table :data="userCountData.items" size="small">
                <el-table-column
                  type="index"
                  label="排名"
                  align="center"
                  width="90px"
                />
                <el-table-column width="50px" />
                <el-table-column label="用户名" align="left">
                  <template slot-scope="{ row }">
                    {{ row.user === '匿名用户' ? '未登录体验读者' : row.user }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="阅读时长 (小时)"
                  align="right"
                  width="120px"
                >
                  <template slot-scope="{ row }">
                    {{ row.reading_time_in_hours | number }}
                  </template>
                </el-table-column>
                <el-table-column width="10px" />
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </page-layout>
</template>

<script lang="ts">
import { AppStateMixin } from '@/core/appStateMixin'
import { DateRangeMap } from '../shared/consts'
import DateRangeSelect from '../shared/date-range-select.vue'
import { BookCateChart } from './chart/BookCateChart'
import { LendTrendBookChart } from './chart/LendTrendBookChart'
import { LendTrendChart } from './chart/LendTrendChart'
import { LendTrendTimeChart } from './chart/LendTrendTimeChart'
import store from './dashboard.store'

export default {
  mixins: [AppStateMixin],
  components: {
    DateRangeSelect
  },
  filters: {
    dateRangeLabel: val => {
      return DateRangeMap[val]
    }
  },

  data() {
    return {
      summary: store.summary,
      topCateData: store.topCateData,
      topBookData: store.topBookData,
      bookCateData: store.bookCateData,
      readingTrendData: store.readingTrendData,
      lendTrendData: store.lendTrendData,
      userCountData: store.userCountData
    }
  },

  async mounted() {
    await store.fetchSummary()
    // await store.fetchTopCateData()
    // await store.fetchTopBookData()
    await store.fetchBookCateData()
    await store.fetchReadingTrendData()
    await store.fetchLendTrendData()
    await store.fetchUserCountData()

    await this.$nextTick()

    // TopCateChart.render(
    //   this.$refs.topCateDataChart,
    //   this.topCateData.items
    // )
    BookCateChart.render(this.$refs.bookCateDataChart, this.bookCateData.items)
    LendTrendTimeChart.render(
      this.$refs.readingTrendTimeData,
      this.readingTrendData.items
    )
    LendTrendBookChart.render(
      this.$refs.readingTrendBookData,
      this.readingTrendData.items
    )
    LendTrendChart.render(
      this.$refs.lendTrendDataChart,
      this.lendTrendData.items
    )
  },

  methods: {
    async onReadingTrendDateRangeChange() {
      await store.fetchReadingTrendData()
      await this.$nextTick()

      LendTrendTimeChart.render(
        this.$refs.readingTrendTimeData,
        this.readingTrendData.items
      )
      LendTrendBookChart.render(
        this.$refs.readingTrendBookData,
        this.readingTrendData.items
      )
    },
    async onLendTrendDateRangeChange() {
      await store.fetchLendTrendData()
      await this.$nextTick()

      LendTrendChart.render(
        this.$refs.lendTrendDataChart,
        this.lendTrendData.items
      )
    },
    async onUserCountDateRangeChange() {
      await store.fetchUserCountData()
    }
  }
}
</script>

<style scoped lang="scss" src="../dashboard.style.scss"></style>
