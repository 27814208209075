import Base from './base'

export const ZcloudCabinet = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.cabinet'
    }
  }
})

export const ZcloudAdPublish = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.ad-publish'
    }
  }
})

export const ZcloudBlockAccount = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.black_list'
    }
  }
})

export const ZcloudSiteSetting = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.site-setting'
    }
  }
})

export const ZcloudPadbookBR = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.padbookbr'
    }
  }
})

export const ZcloudInventory = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.inventory'
    }
  }
})

export const ZcloudDefineDamage = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.damage'
    }
  }
})

export const ZcloudError = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.error_alarm'
    }
  }
})

export const ZcloudDepositReport = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.deposit_statistics'
    }
  }
})

export const ZcloudDeposit = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.deposit_manage'
    }
  }
})

export const ZcloudOverdueOrder = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.order_overdue_manage'
    }
  }
})

export const ZcloudOverdueOrderDeposit = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.order_deposit_manage'
    }
  }
})

export const zcloudBorrower = Base.extend({
  data() {
    return {
      name: 'zhuyun_page.borrow_manage'
    }
  }
})
