<template>
  <el-dialog
    title="借过的书"
    :visible.sync="visible"
    width="700px"
    @open="onDialogOpen"
    :append-to-body="true"
  >
    <el-col :span="24" style="margin-bottom: 24px; margin-top: -16px">
      <el-card shadow="never">
        <el-col :span="20">
          <div style="margin-bottom: 8px">
            <h1 style="font-size: 18px; font-weight: bold">
              {{ list.data.user_name }}
            </h1>
          </div>
          <span v-if="list.data.cx_uname" style="margin-right: 2em">
            学习通账号: {{ list.data.cx_uname }}
          </span>
          <span>首次登录时间: {{ list.data.first_login_time | date }}</span>
        </el-col>
        <el-col :span="4" style="text-align: center">
          <h5 style="margin-bottom: 13px">总借书数</h5>
          <h1 style="margin-bottom: 8px">{{ list.data.lend_count }}本</h1>
        </el-col>
      </el-card>
    </el-col>

    <search />

    <el-table :data="list.items" size="small" v-loading="list.data.loading">
      <el-table-column label="书名" :width="300">
        <template slot-scope="scope">
          <div
            class="title"
            style="margin-top: 10px; font-size: 14px; color: #333"
          >
            {{ scope.row.title }}
          </div>
          <small class="desc">{{ scope.row.author | join }}</small>
        </template>
      </el-table-column>
      <el-table-column property="publisher" label="出版社" />
      <el-table-column property="lend_date" label="借阅日期" />
    </el-table>
    <el-pagination
      @current-change="handlePageChange"
      :current-page="list.index"
      :total="list.total"
      :page-size="list.size"
      layout="total, prev, pager, next"
      background
    />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './push-log.store'
import Search from './push-log-search.component.vue'
import { LibraryMember, DeviceUser } from '@/admin-shared-modules/typings'

export default {
  components: { Search },

  data() {
    return {
      list: store.list,
      visible: false
    }
  },

  methods: {
    open(params: LibraryMember | DeviceUser) {
      this.list.params = params
      this.visible = true
    },
    onDialogOpen() {
      store.onPushCountClick(true)
    },
    handlePageChange(index: number) {
      this.list.index = index
      store.onPushCountClick()
    }
  }
}
</script>
