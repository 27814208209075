import _ from 'lodash'
import moment from 'moment'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { dateFormat } from '@/admin-shared-modules/utils/format'

class CateList extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'report/tag/lend/statistic'
  }
  getSearchParams() {
    return this.context.search.getSearchParams()
  }
  parseFetchedItems(data) {
    return _.take(data, 10)
  }
}

class BookList extends AbstractListService<Store> {
  size = 10
  getFetchURL(): string {
    return 'report/book/lend/statistic'
  }
  getSearchParams() {
    return this.context.search.getSearchParams()
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData() {
    return {
      dateRange: [moment().subtract(1, 'months').toDate(), moment().toDate()]
    }
  }
  getSearchParams() {
    return this.omitEmptyString({
      ..._.omit(this.data, ['dateRange', 'tags']),
      tag_id: _.get(this.data, 'tags[0]'),
      begin_date: dateFormat(this.data.dateRange[0]),
      end_date: dateFormat(this.data.dateRange[1])
    })
  }
  onSubmit() {
    this.context.cateList.fetch({ reset: true })
    this.context.bookList.fetch({ reset: true })
  }
}

class BookDetailList extends AbstractListService<BookDetailDialog> {
  size = 30
  bookData = {}
  getFetchURL() {
    return 'report/book/lend/statistic/by_book'
  }
  getSearchParams(): any {
    return {
      ...this.context.search.getSearchParams(),
      book_id: this.context.params.book_id
    }
  }
  parseFetchedItems(data) {
    this.context.book = data.book
    return data.items
  }
}
class BookDetailSearch extends AbstractSearchService<BookDetailDialog> {
  getDefaultFormData() {
    return {
      dateRange: [moment().subtract(6, 'days').toDate(), moment().toDate()]
    }
  }
  getSearchParams() {
    return this.omitEmptyString({
      begin_date: dateFormat(this.data.dateRange[0]),
      end_date: dateFormat(this.data.dateRange[1])
    })
  }
  onSubmit() {
    this.context.list.fetch({ reset: true })
  }
}

class BookDetailDialog {
  visible = false
  params
  book
  list = new BookDetailList(this)
  search = new BookDetailSearch(this)
  open(item, searchParams) {
    this.visible = true
    this.params = item
    this.search.data = _.clone(searchParams)
    this.list.fetch({ reset: true })
  }
}

class Store {
  cateList: CateList
  bookList: BookList
  search: Search
  bookDetailDialog: BookDetailDialog

  constructor() {
    this.cateList = new CateList(this)
    this.bookList = new BookList(this)
    this.search = new Search(this)
    this.bookDetailDialog = new BookDetailDialog()
  }
}

export default new Store()
