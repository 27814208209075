<template>
  <div>
    <page-header :breadcrumb="['学生报名', '报名审核']" />

    <page-main>
      <search />
    </page-main>

    <page-main>
      <template v-if="!list.loading && list.items.length">
        <el-table ref="table" :data="list.items" size="small">
          <el-table-column label="姓名" prop="user_name" />
          <el-table-column label="学号" prop="card_num" />
          <el-table-column label="学院" prop="college" />
          <el-table-column label="专业" prop="profession" />
          <el-table-column label="入学年份" prop="year" />
          <el-table-column label="手机号" prop="phone" />
          <el-table-column label="操作" width="80px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="onEditClick(row)">
                审核
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        暂无报名记录
      </div>
    </page-main>

    <edit />
  </div>
</template>

<script lang="ts">
import { store } from './store'
import Search from './seach.vue'
import Edit from './edit.vue'

export default {
  components: {
    Search,
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search
    }
  },

  async mounted() {
    store.list.fetch({ reset: true })
  },

  methods: {
    onEditClick(item) {
      store.edit.onEdit(item)
    }
  }
}
</script>

<style scoped></style>
