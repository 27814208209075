<template>
  <el-dialog
    :visible.sync="store.visible"
    title="添加图书"
    width="800px"
    destroy-on-close
    :close-on-click-modal="false"
    @close="$emit('cancel')"
  >
    <search />

    <core-table
      v-model="list.checkedRow"
      :service="list"
      size="small"
      :columns="[
        { type: 'checkbox' },
        { label: '封面', slot: 'cover' },
        { label: '书名', slot: 'title' },
        { label: '出版社', dataKey: 'publisher' },
        { label: 'ISBN', dataKey: 'isbn_13' },
        { label: '专业/通识', slot: 'isPro' },
        { label: '阅读类型', slot: 'isPaper' }
      ]"
    >
      <template v-slot:cover="{ row }">
        <img
          :src="row.image"
          alt="封面"
          class="cover-img"
          style="width: 60px"
        />
      </template>
      <template v-slot:title="{ row }">
        <div style="font-size: 14px">
          {{ row.title }}
        </div>
        <div style="color: #777">
          {{ row.author | join }}
        </div>
      </template>
      <template v-slot:isPro="{ row }">
        <el-tag
          :type="row.is_professional ? 'success' : 'warning'"
          size="small"
          disable-transitions
        >
          {{ row.is_professional ? '专业' : '通识' }}
        </el-tag>
      </template>
      <template v-slot:isPaper="{ row }">
        <el-tag
          :type="row.is_paper ? 'success' : 'warning'"
          size="small"
          disable-transitions
        >
          {{ row.is_paper ? '纸本' : '电子' }}
        </el-tag>
      </template>
    </core-table>

    <div slot="footer">
      <el-button @click="store.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Search from './search.vue'
import { store } from './store'

@Component({
  components: { Search }
})
export default class extends Vue {
  store = store
  list = store.list

  onSubmit() {
    this.$emit('confirm', this.list.checkedRow)
  }
}
</script>

<style scoped></style>
