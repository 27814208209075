<template>
  <div class="user-banner">
    <div class="main">
      <div class="left">
        <div class="name">
          {{ data.userName }}
          <el-tag v-if="data.status === '1'" type="success" size="mini">
            在借中
          </el-tag>
          <el-tag v-else-if="data.status === '2'" size="mini"> 已归还 </el-tag>
          <el-tag v-else-if="data.status === '3'" type="danger" size="mini">
            已超期
          </el-tag>
          <el-tag v-else size="mini"> 未借过 </el-tag>
        </div>
        <div class="desc-item" v-if="data.cardNum">
          借阅证: {{ data.cardNum }}
          <el-button
            type="text"
            size="small"
            @click="showMore = !showMore"
            style="margin-left: 2em"
          >
            更多信息
            <i v-if="!showMore" class="el-icon-arrow-down" />
            <i v-else class="el-icon-arrow-up" />
          </el-button>
        </div>
        <div class="desc-item" v-else>暂未办理借阅证</div>
      </div>
      <div class="right">
        <div class="summary-num">
          <div class="title">借阅次数</div>
          <div class="num">
            {{ data.borrowerBrTimes }}
          </div>
        </div>
        <div class="summary-num">
          <div class="title">超期次数</div>
          <div class="num">
            {{ data.borrowerExpireTimes }}
          </div>
        </div>
      </div>
    </div>
    <div class="extend" v-show="showMore">
      <div class="desc-container">
        <div class="desc-item">手机号: {{ data.phone }}</div>
        <div class="desc-item">邮箱: {{ data.email || '-' }}</div>
        <div class="desc-item">导入时间: {{ data.createTime | date }}</div>
        <div class="desc-item">身份证: {{ data.certCode || '-' }}</div>
        <div class="desc-item">地址: {{ data.address || '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => {} }
  },

  data() {
    return {
      showMore: false
    }
  }
}
</script>

<style scoped lang="scss" src="../../style.scss"></style>
<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.user-banner {
  margin: -20px -20px 16px;
  background-color: #f7f7f7;
  .main {
    display: flex;
    padding: 20px 20px 8px;
    .left {
      flex: auto;
      .name {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .right {
      display: flex;
      align-items: flex-start;
    }
  }
  .extend {
    padding: 16px 20px 8px;
    border-top: $--border-base;
  }
}

.summary-num {
  padding: 0 40px;
  text-align: center;
  &:first-child {
    border-right: $--border-base;
  }
  .title {
    margin-bottom: 8px;
    color: $--color-text-secondary;
  }
  .num {
    font-size: 24px;
  }
}
</style>
