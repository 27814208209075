<template>
  <div class="page-header">
    <div class="content">
      <slot>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-if="!isCXApp"> 首页 </el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="(crumb, index) in breadcrumbItems"
            :key="index"
          >
            {{ crumb }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  name: 'PageHeader',
  mixins: [AppStateMixin],
  props: {
    breadcrumb: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    breadcrumbItems() {
      if (!this.isCXApp && ~this.$route.name.indexOf('credit')) {
        return ['阅读学分', ...this.breadcrumb]
      }
      return this.breadcrumb
    }
  }
}
</script>

<style lang="scss">
.page-header {
  position: relative;
  background: #fff;
  z-index: 1;
  .content {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 32px;
    border-bottom: 1px solid #e8e8e8;
    .el-breadcrumb__item:last-child {
      .el-breadcrumb__inner {
        color: #000 !important;
      }
    }
  }
}
</style>
