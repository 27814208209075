<template>
  <el-select
    v-model="currentValue"
    filterable
    clearable
    remote
    :remote-method="onQueryChange"
    :loading="loading"
    popper-class="book-selector-popper"
    placeholder="请输入书名搜索"
    style="width: 100%"
  >
    <el-option
      v-for="item in items"
      :key="item.id"
      :label="item.title"
      :value="item.credit_book_id"
    />
  </el-select>
</template>

<script lang="ts">
import { Book } from '@/admin-shared-modules/typings'
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  props: {
    value: { type: [Number, String], require: true, default: null },
    defaultItem: { type: Object, require: true, default: null }
  },
  data() {
    return {
      loading: false,
      items: [] as Book[],
      currentValue: this.value
    }
  },
  watch: {
    value() {
      if (typeof this.value === 'string') this.currentValue = null
      else this.currentValue = this.value
    },
    currentValue() {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  },

  created() {
    if (this.defaultItem) this.items.push(this.defaultItem)
    else this.onQueryChange()
  },

  methods: {
    async onQueryChange(query = null) {
      this.loading = true

      const res = await GET(`credit/book`, {
        data: {
          pageSize: 30,
          title: query
        }
      })

      this.loading = false
      this.items = res.data.items
    }
  }
}
</script>

<style lang="scss">
.book-selector-popper {
  max-width: 450px;
  .el-select-dropdown__item {
  }
}
</style>
