import numeral from 'numeral'

//@ts-ignore
const G2 = window.G2Plot

export class LendTrendTimeChart {
  static chartInstance: any

  static render(el: HTMLDivElement, data: any) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const scaleXProp = 'day'
    const scaleYProp = 'reading_time'

    this.chartInstance = new G2.Line(el, {
      data,
      xField: scaleXProp,
      yField: scaleYProp,
      meta: {
        [scaleXProp]: {
          type: 'time',
          mask: 'MM-DD'
        },
        [scaleYProp]: {
          min: 0,
          alias: '阅读时间',
          formatter: val => {
            return numeral(val).format('0.00') + '小时'
          }
        }
      },
      smooth: true
    })

    this.chartInstance.render()
  }
}
