var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-dialog',{attrs:{"visible":_vm.store.visible,"title":"添加图书","width":"800px","destroy-on-close":"","close-on-click-modal":false},on:{"update:visible":function($event){return _vm.$set(_vm.store, "visible", $event)},"close":function($event){return _vm.$emit('cancel')}}},[_c('search'),_c('core-table',{attrs:{"service":_vm.list,"size":"small","columns":[
      { type: 'checkbox' },
      { label: '封面', slot: 'cover' },
      { label: '书名', slot: 'title' },
      { label: '出版社', dataKey: 'publisher' },
      { label: 'ISBN', dataKey: 'isbn_13' },
      { label: '专业/通识', slot: 'isPro' },
      { label: '阅读类型', slot: 'isPaper' }
    ]},scopedSlots:_vm._u([{key:"cover",fn:function({ row }){return [_c('img',{staticClass:"cover-img",staticStyle:{"width":"60px"},attrs:{"src":row.image,"alt":"封面"}})]}},{key:"title",fn:function({ row }){return [_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('div',{staticStyle:{"color":"#777"}},[_vm._v(" "+_vm._s(_vm._f("join")(row.author))+" ")])]}},{key:"isPro",fn:function({ row }){return [_c('el-tag',{attrs:{"type":row.is_professional ? 'success' : 'warning',"size":"small","disable-transitions":""}},[_vm._v(" "+_vm._s(row.is_professional ? '专业' : '通识')+" ")])]}},{key:"isPaper",fn:function({ row }){return [_c('el-tag',{attrs:{"type":row.is_paper ? 'success' : 'warning',"size":"small","disable-transitions":""}},[_vm._v(" "+_vm._s(row.is_paper ? '纸本' : '电子')+" ")])]}}]),model:{value:(_vm.list.checkedRow),callback:function ($$v) {_vm.$set(_vm.list, "checkedRow", $$v)},expression:"list.checkedRow"}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.store.visible = false}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }