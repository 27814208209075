<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="600px"
    :close-on-click-modal="false"
  >
    <el-form
      :key="edit.data.type"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="8em"
      size="small"
    >
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="edit.data.type" :disabled="edit.isEdit">
          <el-radio-button label="apk">apk应用</el-radio-button>
          <el-radio-button label="web">链接应用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-upload
          action="/api/v1/admin/upload/image"
          accept="image/*"
          :show-file-list="false"
          style="
            position: relative;
            width: 80px;
            height: 80px;
            border: 1px dashed #aaa;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          v-bind="iconUploadProps"
          v-loading="iconUploadProps.uploading.value"
        >
          <img
            v-if="edit.data.icon"
            :src="edit.data.icon"
            style="width: 80px"
          />
          <div
            style="
              width: 80px;
              height: 80px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              line-height: 1.8;
            "
            v-else
          >
            <i class="el-icon-upload" style="font-size: 20px"></i>
            点击上传
          </div>

          <el-button
            type="primary"
            size="mini"
            style="
              position: absolute;
              right: 0;
              bottom: 0;
              transform: translateX(calc(100% + 10px));
            "
            >上传图标</el-button
          >
        </el-upload>
        <icon-select
          style="position: absolute; left: 90px; top: 0"
          @change="url => (edit.data.icon = url)"
        />
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="edit.data.name" />
      </el-form-item>

      <template v-if="edit.data.type === 'apk'">
        <el-form-item label="apk安装包" prop="url">
          <a
            v-if="edit.data.url"
            :href="edit.data.url"
            target="_blank"
            style="line-height: 1 !important"
          >
            {{ edit.data.url }}</a
          >
          <el-upload
            action="/api/v1/admin/upload/apk"
            :show-file-list="false"
            accept=".apk"
            style="
              height: 100px;
              border: 1px dashed #aaa;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            v-bind="fileUploadProps"
            v-loading="fileUploadProps.uploading.value"
          >
            <i class="el-icon-upload" style="font-size: 30px"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="apk包名" prop="apkName">
          <el-input v-model="edit.data.apkName" />
        </el-form-item>
        <el-form-item label="apk入口类名" prop="apkClassName">
          <el-input v-model="edit.data.apkClassName" />
        </el-form-item>
        <el-form-item label="对比度增强" prop="render_contrast_inc">
          <el-input-number
            v-model="edit.data.render_contrast_inc"
            :min="0"
            :max="100"
            controls-position="right"
          />
        </el-form-item>
      </template>

      <template v-else>
        <el-form-item label="URL" prop="url">
          <el-input v-model="edit.data.url" />
        </el-form-item>
        <el-form-item label="缩放" prop="zoom">
          <el-input-number
            v-model="edit.data.zoom"
            :min="1"
            controls-position="right"
          />
          %
        </el-form-item>
        <el-form-item label="对比度增强" prop="render_contrast_inc">
          <el-input-number
            v-model="edit.data.render_contrast_inc"
            :min="0"
            :max="100"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item label="操作区域" props="tools">
          <el-checkbox-group v-model="edit.data.tools">
            <el-checkbox label="forward">前进</el-checkbox>
            <el-checkbox label="back">后退</el-checkbox>
            <el-checkbox label="home">主页</el-checkbox>
            <el-checkbox label="refresh">刷新</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="同步用户登录" prop="cookies">
          <el-switch v-model="edit.data.cookies" />
        </el-form-item>
        <el-form-item label="底部控制" prop="showControl">
          <el-switch v-model="edit.data.showControl" />
        </el-form-item>
        <el-form-item label="新页面打开新窗口" prop="new_tab">
          <el-switch v-model="edit.data.new_tab" />
        </el-form-item>
      </template>
      <el-form-item label="备注" prop="note">
        <el-input v-model="edit.data.note" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useUpload } from '../useUpload'
import IconSelect from './icon-select.vue'
import { store } from './store'

export default {
  components: { IconSelect },
  data() {
    return {
      edit: store.edit
    }
  },
  setup() {
    const form = ref()
    const rules = {
      icon: { required: true, message: '请填写' },
      name: { required: true, message: '请填写' },
      url: { required: true, message: '请填写' }
    }

    const iconUploadProps = useUpload({
      onUpload(url) {
        store.edit.data.icon = url
      }
    })

    const fileUploadProps = useUpload({
      onUpload(url) {
        store.edit.data.url = url
      }
    })

    async function onSubmit() {
      await form.value.validate()
      store.edit.onEditSubmit()
    }

    return {
      form,
      rules,
      iconUploadProps,
      fileUploadProps,
      onSubmit
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
