import { DELETE, GET, POST } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'

class Store {
  state = {
    list: {
      items: [],
      loading: true,
      tagGroup: {
        currentId: null,
        items: []
      }
    }
  }

  async fetch() {
    this.state.list.loading = true
    this.state.list.items = []
    try {
      if (!this.state.list.tagGroup.items.length) {
        const res0 = await GET('qikan/parent/types', {})
        this.state.list.tagGroup.items = res0.data
        this.state.list.tagGroup.currentId = String(res0.data[0].id)
      }

      const res = await GET('qikan/recommend', {
        data: {
          lib_type: this.state.list.tagGroup.currentId
        }
      })
      this.state.list.items = res.data
    } finally {
      this.state.list.loading = false
    }
  }

  async onRemove(item) {
    await DELETE(`qikan/recommend/${item.id}`, {
      data: {}
    })
    MessageService.open({ message: '删除成功' })
    this.fetch()
  }

  async saveSorting() {
    await POST(`qikan/recommend/set_order`, {
      data: {
        sort: _.map(this.state.list.items, (item, index) => {
          return {
            id: item.id,
            sort: index
          }
        })
      }
    })
    MessageService.open({ message: '保存成功' })
  }

  async onAdd(items) {
    const res = await POST(`qikan/recommend`, {
      data: {
        lib_type: +this.state.list.tagGroup.currentId,
        qks: items
      }
    })
    MessageService.open({ message: '添加成功' })
    this.fetch()
  }
}

export default new Store()
