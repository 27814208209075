<template>
  <el-form class="page-search" :inline="true">
    <!-- <el-form-item label="来源">
      <el-select
        v-model="state.data.scope"
        placeholder="请选择"
        size="small"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item> -->
    <el-form-item label="分类">
      <book-cate-selector
        v-model="state.data.tags"
        :clearable="true"
        size="small"
      />
    </el-form-item>
    <el-form-item label="书名">
      <el-input v-model="state.data.title" size="small" clearable />
    </el-form-item>
    <el-form-item label="ISBN">
      <el-input v-model="state.data.isbn" size="small" clearable />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import BookCateSelector from '../../views/book/shared/book-cate-selector.vue'
import store from './book-selector.store'

export default {
  components: {
    BookCateSelector
  },

  data() {
    return {
      state: store.state.search,
      options: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'public',
          label: '公共图书'
        },
        {
          value: 'private',
          label: '私有图书'
        }
      ]
    }
  },

  methods: {
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
