<template>
  <el-dialog
    :visible.sync="state.visible"
    :title="state.book && state.book.title"
    class="edit-dialog"
    :close-on-click-modal="false"
  >
    <div v-if="state.book" class="book-card">
      <div class="thumb">
        <img :src="state.book.image" alt="" />
      </div>
      <div class="content">
        <div class="title">
          {{ state.book.title }}
        </div>
        <div class="desc">
          {{ state.book.author | join }}
        </div>
        <div class="desc">
          {{ state.book.publisher }}
        </div>
        <div class="desc">分类: {{ state.book.tags | join('name', '-') }}</div>
      </div>
    </div>

    <el-form class="page-search" inline>
      <el-form-item label="借阅日期">
        <el-date-picker
          v-model="search.data.dateRange"
          type="daterange"
          size="small"
          style="width: 260px"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit">
          查询
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="table"
      :data="list.items"
      size="small"
      v-loading="list.loading"
    >
      <el-table-column
        type="index"
        width="50"
        :index="index => list.indexMethod(index)"
      />

      <el-table-column label="读者证号">
        <template slot-scope="{ row }">
          {{ row.lend_card_num || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="姓名">
        <template slot-scope="{ row }">
          {{ row.lend_user_name }}
        </template>
      </el-table-column>
      <el-table-column label="登录账号">
        <template slot-scope="{ row }">
          {{ row.cx_uname || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="借阅时间">
        <template slot-scope="{ row }">
          {{ row.begin_time | date('YYYY.MM.DD HH:mm') }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="i => list.handlePageIndexChange(i)"
      :current-page="list.index"
      :total="list.total"
      :page-size="list.size"
      layout="total, prev, pager, next"
      background
    />
  </el-dialog>
</template>

<script lang="ts">
import store from '@/views/dashboard/book-send-tops/store'

export default {
  data() {
    return {
      state: store.bookDetailDialog,
      search: store.bookDetailDialog.search,
      list: store.bookDetailDialog.list
    }
  },

  methods: {
    onSubmit() {
      this.list.fetch({ reset: true })
    }
  }
}
</script>

<style scoped lang="scss">
.book-card {
  display: flex;
  margin-top: -16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  .thumb {
    flex: 0 0 80px;
    margin-right: 16px;
  }
  .content {
    flex: auto;
  }
  .title {
    margin-bottom: 8px;
    font-size: 16px;
  }
  .desc {
    color: #9da0a4;
  }
}
</style>
