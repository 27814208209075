import G2 from '@/admin-shared-modules/utils/g2'

export class LendTrendChart {
  static chartInstance: any

  static render(el: HTMLDivElement, data: any) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const scaleXProp = 'date'
    const scaleYProp = 'count'

    const chart = (this.chartInstance = new G2.Chart({
      container: el,
      height: el.offsetHeight,
      forceFit: true,
      padding: 'auto'
    }))

    chart.source(data, {
      [scaleXProp]: {
        type: 'time',
        mask: 'MM-DD'
      },
      [scaleYProp]: {
        min: 0
      }
    })

    chart.tooltip({
      crosshairs: {
        type: 'line'
      }
    })

    chart
      // @ts-ignore
      .lineStack()
      .position(`${scaleXProp}*${scaleYProp}`)
      .color('typeName')
      .shape('smooth')
    chart
      .areaStack()
      .position(`${scaleXProp}*${scaleYProp}`)
      .color('typeName')
      .shape('smooth')

    chart.render()
  }
}
