<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑等级' : '添加等级'"
    class="edit-dialog"
    width="450px"
    top="20vh"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
      style="width: 380px"
      @submit="onSubmit"
    >
      <el-form-item label="等级" prop="level">
        <el-input v-model.trim="edit.data.level" />
      </el-form-item>
      <el-form-item label="评分" prop="scoreRange">
        <el-input-number
          v-model="edit.data.scoreRange.min_score"
          :min="0"
          :controls="false"
          style="width: 100px"
        />
        至
        <el-input-number
          v-model="edit.data.scoreRange.max_score"
          :min="0"
          :controls="false"
          style="width: 100px"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import store from './store'
const baseRequireRule = { required: true, message: '请填写' }

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        level: baseRequireRule,
        scoreRange: {
          type: 'object',
          required: true,
          fields: {
            min_score: { ...baseRequireRule, type: 'number' },
            max_score: { ...baseRequireRule, type: 'number' }
          }
        }
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped></style>
