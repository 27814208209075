<template>
  <page-layout>
    <page-header :breadcrumb="['定制书城首页']" />

    <page-main v-loading="list.loading">
      <div class="wrapper">
        <div class="header" style="display: flex; align-items: center">
          <div style="flex: auto; font-size: 20px">
            {{ list.type === 0 ? '精选书城' : '定制书城' }}
          </div>
          <el-button size="small" @click="onSwitchOpen"> 书城切换 </el-button>
        </div>

        <el-alert
          :title="
            list.type === 0
              ? '内容由超星运营, 定期更新'
              : '书城定制后, 需等待约8分钟, 阅读本书城自动更新'
          "
          show-icon
          :closable="false"
          style="margin-bottom: 16px"
        />

        <div class="header" v-if="list.type === 1">
          <div class="left">
            <el-button
              type="primary"
              size="small"
              :disabled="isSorting"
              @click="onAddClick"
            >
              添加模块
            </el-button>
          </div>
          <template v-if="isSorting">
            <el-button size="small" type="primary" @click="onSortSubmit">
              保存排序
            </el-button>
            <el-button size="small" @click="onSortCancel"> 取消 </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              :disabled="!list.items.length"
              @click="isSorting = true"
            >
              <i class="el-icon-sort" /> 模块排序
            </el-button>
          </template>
        </div>
        <div class="body">
          <draggable
            v-model="list.items"
            :disabled="!isSorting"
            :options="{ ghostClass: 'sort-placeholder', sort: isSorting }"
          >
            <section-item
              v-for="(item, index) in list.items"
              :key="index"
              :data="item"
              :disabled-action="isSorting || list.type === 0"
              :readonly="list.type === 0"
              :style="{ cursor: isSorting ? 'pointer' : '' }"
              @edit="onEditClick(item)"
              @remove="onRemoveClick(index)"
            />
          </draggable>
        </div>
      </div>

      <add v-if="add.visible" />
      <edit />

      <el-dialog
        :visible.sync="typeSwitchDialogVisible"
        title="书城切换"
        width="350px"
      >
        <div class="type-switch">
          <el-radio v-model="typeSwitchValue" :label="0" border>
            启用精选书城
            <span>内容由超星运营, 定期更新</span>
          </el-radio>
          <el-radio v-model="typeSwitchValue" :label="1" border>
            启用定制书城
            <span>书城内容自主管理</span>
          </el-radio>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="typeSwitchDialogVisible = false"> 取消 </el-button>
          <el-button type="primary" @click="onSwitchSubmit"> 确定 </el-button>
        </div>
      </el-dialog>
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import Draggable from 'vuedraggable'
import store from './home-section.store'
import Add from './home-section-add.component.vue'
import Edit from './home-section-edit.component.vue'
import SectionItem from './shared/section-item.vue'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { AppStateMixin } from '@/core/appStateMixin'
import { PUT } from '@/admin-shared-modules/utils/ajax'

export default {
  mixins: [AppStateMixin],
  components: { Add, Edit, Draggable, SectionItem },

  data() {
    return {
      list: store.list,
      add: store.add,
      isSorting: false,
      typeSwitchDialogVisible: false,
      typeSwitchValue: store.list.type
    }
  },

  async mounted() {
    await store.detectType()
    store.fetch()
  },

  methods: {
    onAddClick() {
      store.onAdd()
    },

    onEditClick(item) {
      store.onEdit(item)
    },

    async onRemoveClick(index) {
      await MessageBoxService.confirm({
        type: 'warning',
        title: '确定不展示此模块?',
        message: '隐藏后可通过“添加模块”重新添加'
      })
      store.onRemove(index)
    },

    onSortCancel() {
      this.isSorting = false
      store.fetch()
    },
    async onSortSubmit() {
      await store.onSortSubmit()
      this.isSorting = false
    },

    onSwitchOpen() {
      this.typeSwitchDialogVisible = true
      this.typeSwitchValue = store.list.type
    },
    async onSwitchSubmit() {
      await PUT('shop_type', {
        data: { shop_type: this.typeSwitchValue }
      })
      this.typeSwitchDialogVisible = false
      await store.detectType()
      store.fetch()
    }
  }
}
</script>

<style scoped lang="scss" src="./home-section.style.scss"></style>
