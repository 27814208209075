import { GET } from '@/admin-shared-modules/utils/ajax'

class Store {
  summary = ''
  testing = {
    type_config: {}
  }

  async fetchSummary() {
    const res = await GET('credit/rule/intro', {})
    this.summary = res.data
  }

  async fetchTesting() {
    const { data } = await GET('credit/test/config', {})
    this.testing = {
      ...data,
      test_time: data.test_time / 60
    }
  }
}

export const store = new Store()
