<template>
  <el-drawer
    custom-class="lib-switcher"
    title="切换单位"
    :visible="visible"
    append-to-body
    @close="$emit('update:visible', false)"
  >
    <div class="list">
      <el-input
        v-model="keyword"
        style="margin-bottom: 1em"
        placeholder="输入关键字"
      />
      <div v-show="loading" class="mt1">Loading...</div>
      <div v-show="empty" class="mt1">没有匹配的结果</div>
      <el-button
        v-for="item in result"
        :key="item.id"
        class="item"
        :type="item.id === currentLib.id ? 'primary' : ''"
        :disabled="item.id === currentLib.id"
        @click="onClick(item)"
      >
        {{ item.name }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { appStore } from '@/store'
import { Loading } from 'element-ui'

export default {
  props: {
    visible: { type: Boolean }
  },
  data() {
    return {
      items: [],
      currentLib: appStore.state.lib.data,
      loading: true,
      keyword: ''
    }
  },
  watch: {
    visible(v) {
      if (v) this.fetch()
    }
  },
  computed: {
    result: function () {
      if (!this.keyword) return this.items
      return this.items.filter(item => !!~item.name.indexOf(this.keyword))
    },
    empty: function () {
      return this.items.length && !this.result.length
    }
  },
  methods: {
    async fetch() {
      const { data } = await GET('account/library_list')
      this.items = data
      this.loading = false
    },
    async onClick(item) {
      Loading.service({
        lock: true,
        text: '正在切换单位'
      })
      await POST('account/switch_lib', {
        data: { library_id: item.id }
      })
      window.location.reload()
    }
  }
}
</script>

<style>
.lib-switcher {
  overflow-y: auto;
}
</style>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.mt1 {
  margin-top: 1em;
}

.list {
  padding: 0 20px 0;
}

.item {
  margin: 0 0 16px;
  display: block;
  width: 100%;
  font-size: $--font-size-medium;
}
</style>
