import _ from 'lodash'

export abstract class AbstractSearchService<TCT = any, S = any> {
  data = {} as Partial<S>

  constructor(protected context: TCT) {
    this.data = this.getDefaultFormData()
  }

  getDefaultFormData(): Partial<S> {
    return {}
  }

  getSearchParams(options = { noEmptyString: true }) {
    return this.omitEmptyString(this.data)
  }

  reset() {
    this.data = this.getDefaultFormData()
    this.onSubmit()
  }

  protected omitEmptyString(data) {
    return _.omitBy(data, v => {
      return _.isString(v) && !_.size(v)
    })
  }

  abstract onSubmit(): void
}
