import moment from 'moment'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { dateFormat } from '@/admin-shared-modules/utils/format'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'bi/reading/range'
  }
  getSearchParams() {
    return this.context.search.getSearchParams()
  }
  parseFetchedItems(data): any[] {
    return data
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData() {
    return {
      dateRange: [moment().subtract(1, 'months').toDate(), moment().toDate()]
    }
  }
  getSearchParams() {
    return {
      begin_date: dateFormat(this.data.dateRange[0]),
      end_date: dateFormat(this.data.dateRange[1])
    }
  }
  onSubmit() {
    this.context.list.fetch({ reset: true })
  }
}

class Store {
  list: List
  search: Search

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
  }
}

export default new Store()
