import _ from 'lodash'
import moment from 'moment'
import { DeviceUser } from '@/admin-shared-modules/typings'
import { GET } from '@/admin-shared-modules/utils/ajax'

type CombinedMemberUserParams = DeviceUser

class PushLogStore {
  list = {
    data: [],
    items: [],
    index: 1,
    size: 20,
    total: 0,
    loading: true,
    params: null
  }
  search = {
    data: {
      date_created_range: ''
      // createdAtRange: null
    }
  }
  async fetch() {
    this.list.loading = true
    this.list.data = []
    try {
      const params = this.list.params as CombinedMemberUserParams
      const res = await GET(`user/${params.id}`, {})
      this.list.data = res.data
    } catch (e) {
      // defaultErrorHandler(e)
    } finally {
      this.list.loading = false
    }
  }
  async onPushCountClick(reset = false) {
    if (reset) this.list.index = 1
    this.list.items = []
    try {
      const params = this.list.params as CombinedMemberUserParams
      const res = await GET(`user/${params.id}/lend_books`, {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          ...this.getSearchData()
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      // defaultErrorHandler(e)
    } finally {
      this.list.loading = false
    }
  }
  getSearchData() {
    const data: any = _.cloneDeep(this.search.data)
    if (_.size(data.date_created_range)) {
      data.date_begin = moment(data.date_created_range[0]).format('YYYY-MM-DD')
      data.date_end = moment(data.date_created_range[1]).format('YYYY-MM-DD')
      data.date_created_range = null
    }
    return _.omitBy(data, val => val === '')
  }
}

export default new PushLogStore()
