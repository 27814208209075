<template>
  <div class="card">
    <div class="header">
      {{ sectionName }}
    </div>
    <el-form ref="form" :model="data" :rules="rules" label-width="6em">
      <el-form-item label="参与学分" prop="is_open" style="margin-bottom: 8px">
        <el-switch v-model="data.is_open" />
      </el-form-item>
      <el-form-item
        v-if="data.is_open"
        label="考核标准"
        prop="rulesCheck"
        style="margin-bottom: 8px"
      >
        <div style="margin-bottom: 8px">
          <el-checkbox v-model="data.lend.is_check"> 借阅量 </el-checkbox>
          <el-checkbox v-model="data.note.is_check"> 读书笔记 </el-checkbox>
          <el-checkbox v-model="data.read_test.is_check">
            阅读评测
          </el-checkbox>
          <el-checkbox v-model="data.require.is_check"> 附加要求 </el-checkbox>
        </div>

        <el-form-item
          v-if="data.lend.is_check"
          class="group"
          prop="lend"
          size="small"
        >
          <div class="field">
            *借阅量 >=
            <el-input-number
              v-model="data.lend.lend_count"
              :controls="false"
              style="width: 80px"
            />
            本,
          </div>
          <div class="field">
            其中通识图书 >=
            <el-input-number
              v-model="data.lend.nomal_count"
              :controls="false"
              placeholder="不限"
              style="width: 80px"
            />
            本,
          </div>
          <div class="field">
            专业图书 >=
            <el-input-number
              v-model="data.lend.professional_count"
              :controls="false"
              placeholder="不限"
              style="width: 80px"
            />
            本,
          </div>
          <div class="field">
            专业书分类 >=
            <el-input-number
              v-model="data.lend.professional_tag_count"
              :controls="false"
              placeholder="不限"
              style="width: 80px"
            />
            个
          </div>
        </el-form-item>
        <el-form-item
          v-if="data.note.is_check"
          class="group"
          prop="note"
          size="small"
        >
          <div>
            <div class="inner-group">
              <div class="label">读书笔记</div>
              <div class="field">
                *总数 >=
                <el-input-number
                  v-model="data.note.count"
                  :controls="false"
                  style="width: 80px"
                />
                篇,
              </div>
              <div class="field">
                合格笔记数 >=
                <el-input-number
                  v-model="data.note.pass_count"
                  :controls="false"
                  placeholder="不限"
                  style="width: 80px"
                />
                篇
              </div>
            </div>
            <div class="inner-group">
              <div class="label">读书笔记要求</div>
              <el-input
                v-model="data.note.note_require"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                style="width: 500px"
                placeholder="选填，如>1500字"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          v-if="data.read_test.is_check"
          class="group"
          prop="read_test"
          size="small"
        >
          <div class="label">阅读评测</div>
          <div class="field">
            *通过至少
            <el-input-number
              v-model="data.read_test.pass_count"
              :controls="false"
              style="width: 80px"
            />
            本书,
          </div>
          <div class="field">
            其中通识图书 >=
            <el-input-number
              v-model="data.read_test.nomal_count"
              :controls="false"
              placeholder="不限"
              style="width: 80px"
            />
            本,
          </div>
          <div class="field">
            专业图书 >=
            <el-input-number
              v-model="data.read_test.professional_count"
              :controls="false"
              placeholder="不限"
              style="width: 80px"
            />
            本
          </div>
        </el-form-item>
        <el-form-item
          v-if="data.require.is_check"
          class="group"
          prop="appendGroup"
          size="small"
        >
          <div class="label">附加要求</div>
          <el-input
            v-model="data.require.content"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6 }"
            style="width: 500px"
            placeholder="如参加3次线下讲座"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="data.is_open" label="获得学分" prop="score">
        <el-radio-group v-model="scoreType">
          <div class="radio-input">
            <el-radio :label="1" />
            达标后本学年即可获得学分
            <el-input-number
              v-model="data.score"
              :disabled="scoreType === 2"
              size="small"
              :controls="false"
              style="width: 80px"
            />
            分
          </div>
          <el-radio :label="2"> 本学年不获得学分 </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { GradeNames, TermName } from '@/consts'
const BaseRequired = { required: true, message: '带*号的项目必填' }

export default {
  props: {
    type: { type: Number, default: 0 },
    data: { type: Object, default: () => {} }
  },

  data() {
    const checkRulesChecked = (rule, value, callback) => {
      const hasChecked = _.includes(
        [
          this.data.lend.is_check,
          this.data.note.is_check,
          this.data.read_test.is_check,
          this.data.require.is_check
        ],
        true
      )
      if (!hasChecked) {
        callback(new Error('请至少选择一个考核标准'))
      } else {
        callback()
      }
    }

    return {
      rules: {
        rulesCheck: {
          validator: checkRulesChecked
        },
        lend: {
          type: 'object',
          trigger: 'blur',
          fields: {
            lend_count: { type: 'number', ...BaseRequired }
          }
        },
        note: {
          type: 'object',
          required: true,
          trigger: 'blur',
          fields: {
            count: { type: 'number', ...BaseRequired }
          }
        },
        read_test: {
          type: 'object',
          required: true,
          trigger: 'blur',
          fields: {
            pass_count: { type: 'number', ...BaseRequired }
          }
        },
        score: { type: 'number', ...BaseRequired, trigger: 'blur' }
      }
    }
  },

  computed: {
    sectionName() {
      if (this.type === 1) {
        return TermName[this.data.index - 1]
      } else if (this.type === 2) {
        return GradeNames[this.data.index - 1]
      } else {
        return '大一到大四'
      }
    },
    scoreType: {
      get() {
        return this.data.score === 0 ? 2 : 1
      },
      set(val) {
        if (val === 2) {
          this.data.score = 0
        } else {
          this.data.score = undefined
        }
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
.card {
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid $--border-color-base;
  border-radius: $--card-border-radius;
  .header {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }
}
.radio-input {
  display: inline-flex;
  align-items: center;
  margin-right: 40px;
  font-size: 14px !important;
  ::v-deep .el-radio {
    margin-right: 0;
  }
  ::v-deep .el-radio__label {
    font-size: 0;
  }
  ::v-deep .el-input-number {
    margin: 0 6px;
  }
}
.group {
  margin-bottom: 8px;
  padding: 16px 8px 20px;
  background-color: #f5f5f5;
  border-radius: 2px;
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .inner-group {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .label {
    flex: 0 0 auto;
    width: 7em;
    align-self: flex-start;
  }
  .field {
    margin-right: 16px;
  }
}
</style>
