<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '添加'"
    class="edit-dialog"
    width="450px"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
      style="width: 380px"
      @submit="onSubmit"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="edit.data.name" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="edit.data.phone" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" :disabled="edit.saving" @click="onSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { store } from './store'
const baseRequireRule = { required: true, message: '请填写' }

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        name: baseRequireRule,
        phone: baseRequireRule
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped></style>
