<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="书名">
      <el-input v-model="search.data.title" clearable />
    </el-form-item>
    <el-form-item label="作者">
      <el-input v-model="search.data.author" clearable />
    </el-form-item>
    <el-form-item label="出版社">
      <el-input v-model="search.data.publisher" clearable />
    </el-form-item>
    <el-form-item label="ISBN">
      <el-input v-model="search.data.isbn_13" clearable />
    </el-form-item>
    <el-form-item v-if="store.isPro" label="分类">
      <book-cate-selector v-model="search.data.tags" :clearable="true" />
    </el-form-item>
    <el-form-item label="可考核">
      <el-select v-model="search.data.test_open" clearable>
        <el-option label="是" value="1" />
        <el-option label="否" value="0" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { store } from './store'
import BookCateSelector from './shared/book-cate-selector.vue'

export default {
  components: {
    BookCateSelector
  },

  data() {
    return {
      store,
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
