<template>
  <el-dialog
    class="edit-dialog"
    title="借出"
    :visible.sync="edit.visible"
    :close-on-click-modal="false"
    @open="onDialogOpen"
    width="650px"
  >
    <el-steps
      v-if="edit.step !== 3"
      :active="edit.step"
      :align-center="true"
      center
      style="margin-bottom: 50px; margin-top: -20px"
    >
      <el-step title="选择设备" />
      <el-step title="录入用户" />
      <el-step title="确认信息" />
    </el-steps>

    <template v-if="edit.step === 0">
      <el-form
        ref="form"
        label-width="150px"
        style="width: 450px; margin-left: 20px"
      >
        <el-form-item label="阅读本编号">
          <device-selector v-model="edit.data.deviceCode" device-type="store" />
        </el-form-item>
      </el-form>
    </template>

    <template v-if="edit.step === 1">
      <el-form
        ref="form"
        label-width="150px"
        style="width: 450px; margin-left: 20px"
      >
        <el-form-item label="借阅证号">
          <el-input v-model="edit.data.userCardId" />
        </el-form-item>
        <el-form-item label="缴纳押金">
          <el-switch />
        </el-form-item>
      </el-form>
    </template>

    <template v-if="edit.step === 2">
      <borrowing-summary-form :data="edit.data" />
    </template>

    <template v-if="edit.step === 3">
      <div class="message">
        <div class="icon">
          <i class="el-icon-success" />
        </div>
        <div class="text">设备成功借出</div>
      </div>
    </template>

    <div slot="footer" v-if="edit.step === 0">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="!edit.data.deviceCode"
        :loading="loading"
        @click="onSubmitDevice"
      >
        下一步
      </el-button>
    </div>
    <div slot="footer" v-if="edit.step === 1">
      <el-button @click="edit.step = 0"> 上一步 </el-button>
      <el-button
        type="primary"
        :disabled="!edit.data.userCardId"
        :loading="loading"
        @click="onSubmitUser"
      >
        下一步
      </el-button>
    </div>
    <div slot="footer" v-if="edit.step === 2">
      <el-button @click="edit.step = 1"> 上一步 </el-button>
      <el-button type="primary" :loading="loading" @click="onSubmit">
        确认借出
      </el-button>
    </div>
    <div slot="footer" v-if="edit.step === 3">
      <el-button type="primary" @click="edit.visible = false"> 关闭 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from './borrowing.store'
import DeviceSelector from './shared/device-selector.component.vue'
import BorrowingSummaryForm from './shared/borrowing-summary-form.component.vue'
import { AJAXErrorResult } from '../../admin-shared-modules/utils/ajax'

export default {
  components: {
    DeviceSelector,
    BorrowingSummaryForm
  },

  data() {
    return {
      edit: store.borrowingDevice,
      loading: false
    }
  },

  methods: {
    onDialogOpen() {},

    async onSubmitDevice() {
      this.loading = true
      try {
        this.edit.data.device = await store.fetchDevice(
          this.edit.data.deviceCode
        )
        this.edit.step = 1
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async onSubmitUser() {
      this.loading = true
      try {
        this.edit.data.member = await store.fetchMember(
          this.edit.data.userCardId
        )
        this.edit.step = 2
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async onSubmit() {
      this.loading = true
      try {
        await store.onBorrowingSubmit()
        this.edit.step = 3
        store.fetchLog()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./borrowing.style.scss"></style>
