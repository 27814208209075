import Vue from 'vue'

import Element from 'element-ui'
Vue.use(Element)
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import PageLayout from './admin-shared-modules/layout/page-layout.vue'
import PageHeader from './admin-shared-modules/layout/page-header.vue'
import PageMain from './admin-shared-modules/layout/page-main.vue'
import PageActionBar from './admin-shared-modules/layout/page-action-bar.vue'
import InnerTabs from './views/dashboard/inner-tabs.vue'
import CoreTable from './admin-shared-modules/components/table'

Vue.component(PageLayout.name, PageLayout)
Vue.component(PageHeader.name, PageHeader)
Vue.component(PageMain.name, PageMain)
Vue.component(PageActionBar.name, PageActionBar)
Vue.component(InnerTabs.name, InnerTabs)
Vue.component('CoreTable', CoreTable)

import {
  dateFormat,
  join,
  getConstValue,
  splitByLength,
  indexPercentage,
  numberFormat
} from '@/admin-shared-modules/utils/format'
Vue.filter('date', dateFormat)
Vue.filter('join', join)
Vue.filter('number', numberFormat)
Vue.filter('splitByLength', splitByLength)
Vue.filter('getConstValue', getConstValue)
Vue.filter('indexPercentage', indexPercentage)
