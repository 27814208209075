import _ from 'lodash'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'

type ConfigTypeIndex = 0 | 1 | 2 | 3
export interface ConfigType {
  index: ConfigTypeIndex
  is_open: boolean
  lend: object
  note: object
  read_test: object
  require: object
  score: number
}
interface FormData {
  currentConfigType: ConfigTypeIndex
  sections: ConfigType[]
}

class Edit extends AbstractEditService<Store, FormData> {
  currentYearId = null
  originalData: any = {}

  getFetchURL(): string | boolean {
    return `credit/year/config/${this.currentYearId}`
  }
  parseFetchedFormData(data: any): FormData {
    this.originalData = data
    if (data.score_config_type !== 0) {
      this.fillSections(1)
      this.fillSections(2)
      this.fillSections(3)
    }
    return {
      currentConfigType: data.score_config_type,
      sections: this.getCurrentSections(data.score_config_type)
    }
  }
  getRemoveURL(): string {
    return ''
  }
  getSubmitURL(): string {
    return `credit/year/config/${this.currentYearId}`
  }
  getSubmitData(): any {
    return {
      score_config_type: this.data.currentConfigType,
      [`type_${this.data.currentConfigType}_config`]:
        this.originalData[`type_${this.data.currentConfigType}_config`]
    }
  }

  requestListReload(option?: ListFetchOptions): void {}

  private getCurrentSections(index: ConfigTypeIndex): ConfigType[] {
    return this.originalData[`type_${index}_config`]
  }
  private fillSections(index: ConfigTypeIndex) {
    if (index === 1 && this.originalData.type_1_config.length !== 8) {
      this.originalData.type_1_config = _.range(1, 9).map(i => {
        return getDefaultSection(i)
      })
    } else if (index === 2 && this.originalData.type_2_config.length !== 4) {
      this.originalData.type_2_config = _.range(1, 5).map(i => {
        return getDefaultSection(i)
      })
    } else if (index === 3 && this.originalData.type_3_config.length !== 1) {
      this.originalData.type_3_config = [getDefaultSection(1)]
    }
  }
}

class Store {
  edit: Edit
  constructor() {
    this.edit = new Edit(this)
  }
}

export default new Store()

function getDefaultSection(index): ConfigType {
  return {
    index,
    is_open: false,
    lend: {
      is_check: false,
      lend_count: undefined,
      nomal_count: undefined,
      professional_count: undefined,
      professional_tag_count: undefined
    },
    note: {
      is_check: false,
      count: undefined,
      pass_count: undefined,
      note_require: ''
    },
    read_test: {
      is_check: false,
      pass_count: undefined,
      nomal_count: undefined,
      professional_count: undefined
    },
    require: {
      is_check: false,
      content: ''
    },
    score: undefined
  }
}
