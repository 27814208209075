<template>
  <div style="align-self: flex-end">
    <el-tabs v-model="tabName" @tab-click="onClick">
      <el-tab-pane label="阅读本借还统计" name="zcloudPadbookBR" />
      <el-tab-pane label="图书借阅统计" name="bookSendTops" />
      <el-tab-pane label="图书借阅记录" name="bookSendLog" />
      <el-tab-pane label="读者阅读统计" name="readingReport" />
      <el-tab-pane label="读者行为分析" name="user" />
      <el-tab-pane label="读者排行" name="userReadTop" />
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'InnerTabs',
  data() {
    return {
      tabName: this.$route.name
    }
  },
  methods: {
    onClick({ name }) {
      this.$router.push({ name })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-tabs {
  background-color: #fff;
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>
