var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.edit.visible,"title":_vm.edit.isEdit ? '编辑' : '新增',"width":"700px","close-on-click-modal":false},on:{"update:visible":function($event){return _vm.$set(_vm.edit, "visible", $event)}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.edit.data,"rules":_vm.rules,"label-width":"6em"}},[_c('el-form-item',{attrs:{"label":"标题","prop":"title"}},[_c('el-input',{model:{value:(_vm.edit.data.title),callback:function ($$v) {_vm.$set(_vm.edit.data, "title", $$v)},expression:"edit.data.title"}})],1),_c('el-form-item',{attrs:{"label":"内容","prop":"content"}},[_c('quill-editor',{attrs:{"options":{
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', { color: [] }],
              [{ indent: '-1' }, { indent: '+1' }, { align: [] }]
            ]
          }
        }},model:{value:(_vm.edit.data.content),callback:function ($$v) {_vm.$set(_vm.edit.data, "content", $$v)},expression:"edit.data.content"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.edit.visible = false}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.edit.loading || _vm.edit.saving},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }