import { GET } from '@/admin-shared-modules/utils/ajax'
import { DateRangeType } from '@/views/dashboard/kindle/dashboard.store'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

const getDateRangeForward = (val, unit) => {
  return {
    begin_date: moment().subtract(val, unit).format('YYYY-MM-DD'),
    end_date: moment().endOf('day').format('YYYY-MM-DD')
  }
}
const getDateRangeForQuery = (type: DateRangeType) => {
  if (type === 'week') return getDateRangeForward(7, 'day')
  if (type === 'month') return getDateRangeForward(1, 'month')
  if (type === 'three_months') return getDateRangeForward(3, 'month')
}

const getDateRangeTypeForQuery = (type: DateRangeType) => {
  // if (type === 'quarter') return null
  return type
}

class Store {
  summary = {
    loading: true,
    device: {
      count: null,
      lend: null,
      inStore: null,
      lendPercent: 0
    },
    book: {
      count: null,
      systemBookCount: null,
      libBookCount: null,
      systemBookPercent: 0
    },
    bookLendCount: null,
    bookReadTimeCount: null
  }

  topCateData = {
    loading: true,
    items: []
  }

  topBookData = {
    loading: true,
    items: []
  }

  bookCateData = {
    loading: true,
    items: []
  }

  readingTrendData = {
    loading: true,
    dateRange: 'week' as DateRangeType,
    items: [],
    readTimeCountInHours: null,
    readBookCount: null
  }

  lendTrendData = {
    loading: true,
    dateRange: 'week' as DateRangeType,
    items: [],
    lendCount: null,
    returnCount: null,
    total: null
  }

  userCountData = {
    dateRange: 'week' as DateRangeType,
    loading: true,
    items: [],
    count: null,
    weekCount: null
  }

  async fetchSummary() {
    const deviceRes = await GET('reader/statistic', {
      timeout: 30 * 1000
    })
    this.summary.device.count = _.get(deviceRes.data, 'device.total')
    this.summary.device.lend = _.get(deviceRes.data, 'device.lend_count')
    this.summary.device.inStore = _.get(deviceRes.data, 'device.store_count')
    if (this.summary.device.count) {
      const val = (this.summary.device.lend / this.summary.device.count) * 100
      this.summary.device.lendPercent = _.min([val, 96])
    }

    const bookRes = await GET('bi/book/statistics', {})
    this.summary.book.count = _.get(bookRes.data, 'total_count')
    this.summary.book.systemBookCount = _.get(bookRes.data, 'system_book')
    this.summary.book.libBookCount = _.get(bookRes.data, 'private_count')
    if (this.summary.book.count) {
      const val =
        (this.summary.book.systemBookCount / this.summary.book.count) * 100
      this.summary.book.systemBookPercent = _.min([val, 96])
    }

    const countRes = await GET('bi/book/total_reading', {})
    this.summary.bookLendCount = countRes.data.read_count
    this.summary.bookReadTimeCount = countRes.data.read_time / 60

    this.summary.loading = false
  }

  async fetchTopCateData() {
    const res = await GET('bi/book/classification', {})
    this.topCateData.items = _.take(res.data, 10)
    this.topCateData.loading = false
  }

  async fetchTopBookData() {
    const res = await GET('bi/book/popular', {})
    this.topBookData.items = _.take(res.data, 10)
    this.topBookData.loading = false
  }

  async fetchBookCateData() {
    const res = await GET('bi/book/distribution', {})
    const data: any[] = _.take(res.data, 50)
    const bookCount = _.chain(data).map('book_count').sum().value() || 1
    this.bookCateData.items = _.map(data, d => {
      return {
        ...d,
        percent: _.round(d.book_count / bookCount, 2)
      }
    })
    this.bookCateData.loading = false
  }

  async fetchReadingTrendData() {
    const res = await GET('bi/book/readingtime', {
      data: {
        read_time: this.readingTrendData.dateRange
      }
    })
    this.readingTrendData.items = _.map(res.data, item => {
      return {
        ...item,
        reading_time: numeral(item.reading_time).divide(60).value()
      }
    })
    this.readingTrendData.readTimeCountInHours = _.chain(
      this.readingTrendData.items
    )
      .map('reading_time')
      .sum()
      .round()
      .value()
    this.readingTrendData.readBookCount = _.chain(this.readingTrendData.items)
      .map('count')
      .sum()
      .value()
    this.readingTrendData.loading = false
  }

  async fetchLendTrendData() {
    const res = await GET('reader/lend/statistic', {
      data: {
        ...getDateRangeForQuery(this.lendTrendData.dateRange)
      }
    })
    this.lendTrendData.items = _.reduce(
      res.data,
      (result, item) => {
        result.push({
          date: item.time,
          type: 'lend',
          typeName: '借出',
          count: _.parseInt(item.lendnum)
        })
        result.push({
          date: item.time,
          type: 'return',
          typeName: '归还',
          count: _.parseInt(item.returnnum)
        })
        return result
      },
      []
    )

    this.lendTrendData.lendCount = _.chain(this.lendTrendData.items)
      // @ts-ignore
      .filter({ type: 'lend' })
      .map('count')
      .sum()
      .value()
    this.lendTrendData.returnCount = _.chain(this.lendTrendData.items)
      // @ts-ignore
      .filter({ type: 'return' })
      .map('count')
      .sum()
      .value()
    this.lendTrendData.total =
      this.lendTrendData.lendCount + this.lendTrendData.returnCount

    this.lendTrendData.loading = false
  }

  async fetchUserCountData() {
    this.userCountData.loading = true
    const readerRes = await GET('bi/book/readtime', {
      data: {
        read_time: getDateRangeTypeForQuery(this.userCountData.dateRange)
      }
    })
    const countRes = await GET('bi/book/readerstatistics', {
      data: {
        read_time: getDateRangeTypeForQuery(this.userCountData.dateRange)
      }
    })
    this.userCountData.items = _.chain(readerRes.data)
      .take(10)
      .map(item => {
        return {
          ...item,
          reading_time_in_hours: numeral(item.reading_time).divide(60).value()
        }
      })
      .value()
    this.userCountData.count = _.get(countRes.data, '[0].total_people')
    this.userCountData.weekCount = _.get(countRes.data, '[0].new_number')
    this.userCountData.loading = false
  }
}

export default new Store()
