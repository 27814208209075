<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    @open="onDialogOpen"
  >
    <el-form
      :model="edit.data"
      :rules="rules"
      ref="form"
      label-width="140px"
      style="width: 450px"
    >
      <el-form-item label="用户名" prop="name">
        <el-input v-model.trim="edit.data.name" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model.trim="edit.data.phone" />
      </el-form-item>
      <el-form-item label="密码" prop="password" v-show="!edit.isEdit">
        <el-input
          v-model="edit.data.password"
          placeholder="留空则默认为123456"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from './account.store'
import { AJAXErrorResult } from '../../admin-shared-modules/utils/ajax'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'

export default {
  data() {
    return {
      edit: store.edit,

      rules: {
        name: [
          { required: true, message: '请填写' },
          { min: 2, message: '长度需大于 2' }
        ],
        phone: [
          { required: true, message: '请填写' },
          { pattern: /^1\d{10}$/, message: '请填写正确的手机号' }
        ],
        password: [{ min: 6, message: '密码需大于 6 位' }]
      }
    }
  },

  methods: {
    onDialogOpen() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },

    onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        store.onEditSubmit()
      })
    }
  }
}
</script>
