<template>
  <page-layout>
    <page-header
      :breadcrumb="isCXApp ? ['本馆图书分类'] : ['图书', '图书分类']"
    />

    <page-main v-loading="loading">
      <div v-if="!sorting" style="margin-right: 15px; margin-bottom: 13px">
        <el-button type="primary" size="small" @click="onAddClick">
          新增本馆分类
        </el-button>
      </div>
      <div v-if="!isCXApp" class="tab-action-bar">
        <el-tabs
          v-model="list.tagGroup.currentId"
          v-if="list.tagGroup.currentId"
        >
          <el-tab-pane
            v-for="(item, index) in list.tagGroup.items"
            :key="index"
            :label="item.name"
            :name="`${item.id}`"
          />
        </el-tabs>
      </div>
      <page-action-bar v-if="!isCXApp && list.tagGroup.currentId === '0'">
        <template v-if="sorting">
          <el-button type="primary" size="small" @click="onSortingClick">
            <i class="el-icon-edit" /> 保存排序
          </el-button>
          <el-button size="small" @click="cancelSorting"> 取消 </el-button>
        </template>
        <template v-if="!sorting">
          <el-button size="small" @click="onSortingClick">
            <i class="el-icon-sort" /> 分类排序
          </el-button>
        </template>
      </page-action-bar>

      <!-- <div style="margin-bottom: 4px;"></div> -->

      <draggable
        class="tag-grid"
        v-model="list.items"
        :options="{ ghostClass: 'sort-placeholder', sort: sorting }"
      >
        <div
          class="tag-grid__item"
          :class="{ 'is-draggable': sorting }"
          v-for="item in list.items"
          :key="item.id"
        >
          <el-card class="tag-card">
            <div class="title">
              <span class="index" v-show="sorting">#{{ item._index + 1 }}</span>
              {{ item.name }}
            </div>
            <div
              class="actions"
              :style="{
                visibility: item.is_library_private ? 'visible' : 'hidden'
              }"
            >
              <el-button
                type="text"
                size="small"
                @click.stop="onEditHandler(item)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click.stop="onRemoveHandler(item)"
              >
                删除
              </el-button>
            </div>
          </el-card>
        </div>
      </draggable>
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import Draggable from 'vuedraggable'

import { AppStateMixin } from '@/core/appStateMixin'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import Edit from './tag-edit.component.vue'
import store from './tag.store'

export default {
  mixins: [AppStateMixin],
  components: {
    Edit,
    Draggable
  },

  data() {
    return {
      state: store,
      list: store.list,
      loading: false,
      sorting: false
    }
  },

  mounted() {
    // this.fetch()
    store.fetchGroup()
  },

  watch: {
    'list.tagGroup.currentId': {
      handler() {
        this.isSorting = false
        store.fetch()
      },
      immediate: true
    }
  },

  methods: {
    // async fetch() {
    //   this.loading = true
    //   try {
    //     await store.fetchGroup()
    //     await store.fetch(this.isCXApp ? 0 : null)
    //   } catch (e) {
    //     console.log(e)
    //   } finally {
    //     this.loading = false
    //   }
    // },

    onAddClick() {
      store.onAddParent()
    },

    onSortingClick() {
      if (this.sorting) {
        this.sorting = false
        store.updateSort()
      } else {
        this.sorting = true
      }
    },

    cancelSorting() {
      this.sorting = false
      store.fetch()
    },

    onAppendChildHandler(parent) {
      store.onAddChild(parent)
    },

    onEditHandler(item) {
      store.onEdit(item)
    },

    async onRemoveHandler(item) {
      if (this.sorting) {
        MessageService.open({ type: 'error', message: '排序模式禁止删除' })
        return
      }

      await MessageBoxService.confirm({ message: '确认删除?删除后不可被撤销' })
      store.onRemove(item)
    },

    onAppendBindCate(owner) {
      store.onAppendBindCate(owner)
    },
    async onRemoveBindCate({ owner, cate }) {
      await MessageBoxService.confirm({ message: '确认删除该分类绑定' })
      store.onRemoveBindCate(owner, cate)
    }

    // onDragHandler(dragNode,dropNode,dropType){
    //   store.reSortNodeList(dragNode.data,dropNode.data,dropType)
    // }
  }
}
</script>
<style scoped lang="scss" src="./tag-edit.style.scss"></style>
