<template>
  <el-dialog
    :visible.sync="add.visible"
    :title="'添加模块'"
    :close-on-click-modal="false"
    width="750px"
  >
    <el-alert
      title="新增的模块将展示在书城末尾，可通过“模块排序”调整顺序"
      show-icon
      :closable="false"
      style="margin-bottom: 16px"
    />

    <el-form :model="add.data" ref="form" label-width="80px">
      <el-form-item label="类型">
        <el-radio-group v-model="add.data.type">
          <el-radio label="recommend"> 推荐 </el-radio>
          <el-radio label="project"> 书单 </el-radio>
          <el-radio label="project-tag">书单栏目</el-radio>
          <el-radio label="tag"> 图书分类 </el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="add.data.type === 'recommend'">
        <el-form-item label="栏目">
          <div class="recommend-checkbox">
            <div
              v-for="(item, index) in recommendItems"
              :key="index"
              class="item"
              :class="{ checked: item.selected }"
              @click="onRecommendCheckboxChange(item)"
            >
              <div class="title">
                {{ item.name }}
              </div>
              <div class="desc">
                {{ item.desc }}
              </div>
              <div class="state">
                <span v-if="item.selected">已选中</span>
                <span v-if="item.existInList">已添加</span>
              </div>
            </div>
          </div>
        </el-form-item>
      </template>

      <template v-if="add.data.type === 'project'">
        <el-form-item label="书单">
          <el-button type="primary" size="small" @click="openTopicSelector">
            选择书单
          </el-button>
        </el-form-item>
        <div class="topic-list">
          <div
            v-for="(item, index) in currentSelected"
            :key="index"
            class="section-item"
          >
            <div class="label">
              <el-tag>书单</el-tag>
            </div>
            <div class="content">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="desc">
                {{ item.is_private_project ? '本馆' : '公共' }}书单
              </div>
            </div>
            <div class="action">
              <el-button type="text" @click="onSelectedItemsRemoveClick(index)">
                删除
              </el-button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="add.data.type === 'project-tag'">
        <el-form-item label="请选择">
          <project-tag-checkbox
            :disabled-items="currentDisplayedProjectTagItems"
            @change="onProjectTagSelectChange"
          />
        </el-form-item>
      </template>

      <template v-if="add.data.type === 'tag'">
        <el-form-item label="请选择">
          <tag-checkbox
            :disabled-items="currentDisplayedTagItems"
            @change="onTagSelectChange"
          />
        </el-form-item>
      </template>
    </el-form>

    <div slot="footer">
      <el-button @click="add.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>

    <topic-select-dialog
      ref="topicSelectDialog"
      :default-values="defaultTopicSelectedItems"
      @change="onTopicSelectChange"
    />
  </el-dialog>
</template>

<script lang="ts">
import { Tag, Topic, TopicTag } from '@/admin-shared-modules/typings'
import TopicSelectDialog from '@/components/topic-select-dialog/topic-select-dialog.vue'
import { appStore } from '@/store'
import _ from 'lodash'
import store, { ModuleConfig, ModuleDto } from './home-section.store'
import ProjectTagCheckbox from './shared/project-tag-checkbox.vue'
import TagCheckbox from './shared/tag-checkbox.vue'

export default {
  components: {
    TopicSelectDialog,
    TagCheckbox,
    ProjectTagCheckbox
  },

  data() {
    return {
      add: store.add,
      recommendItems: _.chain(ModuleConfig)
        .filter('group')
        .map(c => {
          return {
            ...c,
            selected: false,
            existInList: !!_.find(store.list.items, { type: c.type })
          }
        })
        .filter(item => {
          if ((item as any)?.checkAllow) {
            return (item as any).checkAllow(appStore.state.lib.data)
          }
          return true
        })
        .value(),
      currentSelected: []
    }
  },
  computed: {
    defaultTopicSelectedItems() {
      return this.add.data.type === 'project' ? this.currentSelected : []
    },
    currentDisplayedTagItems() {
      return _.filter(store.list.items, { type: 'tag' })
    },
    currentDisplayedProjectTagItems() {
      return _.filter(store.list.items, { type: 'label' })
    }
  },
  watch: {
    'add.data.type'() {
      this.currentSelected = []
    }
  },

  methods: {
    onRecommendCheckboxChange(item) {
      if (item.existInList) return
      item.selected = !item.selected
    },

    openTopicSelector() {
      this.$refs.topicSelectDialog.visible = true
    },
    onTopicSelectChange(val: Topic[]) {
      this.currentSelected = val
    },

    onTagSelectChange(val: Tag[]) {
      this.currentSelected = val
    },

    onProjectTagSelectChange(val: TopicTag[]) {
      this.currentSelected = val
    },

    onSelectedItemsRemoveClick(index) {
      this.currentSelected.splice(index, 1)
    },

    onSubmit() {
      if (this.add.data.type === 'recommend') {
        this.add.data.selectedItems = _.chain(this.recommendItems)
          .filter('selected')
          .map(item => {
            return { type: item.type } as ModuleDto
          })
          .value()
      }
      // 书单标签
      else if (this.add.data.type === 'project-tag') {
        this.add.data.selectedItems = _.map<TopicTag, ModuleDto>(
          this.currentSelected,
          item => {
            return {
              type: 'label',
              id: item.id,
              name: item.name
            }
          }
        )
      } else if (this.add.data.type === 'project') {
        this.add.data.selectedItems = _.map<Topic, ModuleDto>(
          this.currentSelected,
          item => {
            return {
              type: 'project',
              id: item.id,
              name: item.title,
              is_private: item.is_private_project
            } as ModuleDto
          }
        )
      } else {
        this.add.data.selectedItems = _.map<Tag, ModuleDto>(
          this.currentSelected,
          item => {
            return {
              type: 'tag',
              id: item.id,
              name: item.name,
              is_private: item.is_library_private
            } as ModuleDto
          }
        )
      }
      store.onAddSubmit()
    }
  }
}
</script>

<style scoped lang="scss" src="./home-section.style.scss"></style>
<style scoped>
.topic-list {
  margin-left: 80px;
  margin-bottom: 24px;
}
</style>
