<template>
  <el-form class="page-search" inline>
    <el-form-item>
      <el-date-picker
        v-model="search.data.dateRange"
        type="daterange"
        size="small"
        :clearable="false"
        style="width: 240px"
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './store'
import BookCateSelector from '../../book/shared/book-cate-selector.vue'

export default {
  components: {
    BookCateSelector
  },

  data() {
    return {
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.search.onSubmit()
    }
  }
}
</script>
