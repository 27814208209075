<template>
  <div>
    <page-header :breadcrumb="['学习轨迹']" />

    <page-main>
      <search />

      <template v-if="!list.loading && list.items.length">
        <page-action-bar>
          <el-button slot="right" size="small" @click="onExportClick">
            <i class="el-icon-printer" /> 列表导出
          </el-button>
        </page-action-bar>
        <el-table ref="table" :data="list.items" size="small">
          <el-table-column label="学号" prop="card_num" />
          <el-table-column label="姓名" prop="user_name" />
          <el-table-column label="学院/专业/班级" prop="college">
            <template v-slot="{ row }">
              {{ row.college }}/ {{ row.profession }}/
              {{ row.class_name }}
            </template>
          </el-table-column>
          <el-table-column label="签到次数" prop="sign_count" align="right" />
          <el-table-column label="阅读图书数" prop="read_count" align="right" />
          <el-table-column
            label="阅读图书天数"
            prop="read_days"
            align="right"
          />
          <el-table-column label="笔记数" prop="note_count" align="right" />
          <el-table-column label="写笔记天数" prop="note_days" align="right" />
        </el-table>
        <el-pagination
          @current-change="i => list.handlePageIndexChange(i)"
          :current-page="list.index"
          :total="list.total"
          :page-size="list.size"
          layout="total, prev, pager, next"
          background
        />
      </template>
      <div class="empty-container" v-if="!list.loading && !list.items.length">
        暂无记录
      </div>
    </page-main>

    <el-dialog
      title="导出学习轨迹"
      custom-class="export-dialog"
      :visible.sync="exportVisible"
      width="300px"
    >
      <a
        class="el-button el-button--primary"
        style="width: 100%"
        :href="exportURL"
        target="_blank"
      >
        <i class="el-icon-printer" /> 点击下载</a
      >
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { store } from './store'
import Search from './seach.vue'
import Edit from './edit.vue'
import queryString from 'querystring'
import { API_HOST } from '@/config'

export default {
  components: {
    Search
  },

  data() {
    return {
      store,
      list: store.list,
      search: store.search,

      exportVisible: false,
      exportURL: ''
    }
  },

  methods: {
    onExportClick() {
      const qs = queryString.stringify({
        ...store.search.getSearchParams(),
        _t: +new Date()
      })
      this.exportURL = `${API_HOST}/credit/teacher/statistic/export?${qs}`
      this.exportVisible = true
    }
  }
}
</script>

<style scoped></style>
