import moment from 'moment'
import _ from 'lodash'
import { GET } from '@/admin-shared-modules/utils/ajax'
import {
  EditState,
  ListState,
  LibraryMember
} from '@/admin-shared-modules/typings'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

class Store {
  list: ListState<LibraryMember> = {
    items: [],
    index: 1,
    size: 20,
    total: 0,
    loading: true,
    summary: {}
  }

  search = {
    data: {},
    isMoreShow: false
  }

  detail = {
    visible: false,
    loading: false,
    params: {},
    data: {}
  }

  importer: EditState<any> = {
    isEdit: false,
    visible: false,
    data: {},
    loading: false
  }

  async fetch(reset = false) {
    if (reset) {
      this.list.index = 1
    }
    this.list.loading = true
    this.list.items = []
    this.list.summary = {}
    try {
      const res = await GET('reader/lend_users', {
        data: {
          page: this.list.index,
          limit: this.list.size,
          ...this.getSearchParams()
        },
        timeout: 30 * 1000
      })
      this.list.items = res.data.list
      this.list.total = res.data.totalCount
      this.list.summary = res.data.statisticsMap
    } catch (e) {
      console.log(e)
    } finally {
      this.list.loading = false
    }
  }

  indexMethod(index) {
    return index + 1 + (this.list.index - 1) * this.list.size
  }

  getSearchParams() {
    const data: any = _.cloneDeep(this.search.data)
    if (_.size(data.date_range)) {
      data.beginTime = moment(data.date_range[0]).format('YYYY-MM-DD')
      data.endTime = moment(data.date_range[1]).format('YYYY-MM-DD')
      data.date_range = null
    }
    return _.omitBy(data, v => !v)
  }

  async onDetail(data) {
    this.detail.params = data
    this.detail.data = {}
    this.detail.loading = true
    this.detail.visible = true
    try {
      const res = await GET(`reader/lend_users/${data.userId}`, {})
      this.detail.data = res.data
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.detail.loading = false
    }
  }

  onImport() {
    this.importer.visible = true
  }
}

export default new Store()
