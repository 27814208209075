<template>
  <el-form class="page-search" inline>
    <el-form-item label="类型">
      <el-select v-model="search.data.book_type" size="small" clearable>
        <el-option value="book" label="图书" />
        <el-option value="magazine" label="期刊" />
      </el-select>
    </el-form-item>

    <el-form-item label="书名">
      <el-input v-model="search.data.title" size="small" clearable />
    </el-form-item>

    <el-form-item label="借阅人">
      <el-input v-model="search.data.lend_user" size="small" clearable />
    </el-form-item>

    <el-form-item label="借阅日期">
      <el-date-picker
        v-model="search.data.dateRange"
        type="daterange"
        size="small"
        :clearable="false"
        style="width: 260px"
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './store'

export default {
  data() {
    return {
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.search.onSubmit()
    }
  }
}
</script>
