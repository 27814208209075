<template>
  <el-header class="app-header">
    <div class="left">
      <div class="nav-collapse-trigger" @click="triggerNav">
        <i v-show="isNavOpen" class="el-icon-ant-menu-fold" />
        <i v-show="!isNavOpen" class="el-icon-ant-menu-unfold" />
      </div>
      <div class="title">
        {{ labName }}
      </div>
      <el-button class="switcher" type="text" @click="openLibSwitcher">
        切换单位
      </el-button>
    </div>
    <div class="right">
      <el-menu class="user-menu" mode="horizontal">
        <el-submenu index="1" popper-class="user-submenu">
          <template slot="title">
            {{ userName }}
          </template>
          <el-menu-item index="1-1" @click="signOut"> 注销 </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>

    <lib-switcher :visible.sync="switcherVisible" />
  </el-header>
</template>

<script lang="ts">
import _ from 'lodash'
import { appStore } from '../store'
import { GET } from '../admin-shared-modules/utils/ajax'
import { Scan } from '../views/passport/sign-in.store'
import LibSwitcher from '../components/lib-switcher.vue'

export default {
  components: { LibSwitcher },
  data() {
    return {
      switcherVisible: false
    }
  },
  computed: {
    labName() {
      return _.get(appStore.state.lib.data, 'name')
    },
    userName() {
      return _.get(appStore.state.user.data, 'name')
    },
    isNavOpen() {
      return appStore.state.ui.openNav
    }
  },
  methods: {
    triggerNav() {
      appStore.state.ui.openNav = !appStore.state.ui.openNav
    },
    async signOut() {
      this.scan = new Scan()
      await GET(`account/logout`, {})
      appStore.state.user.data = null
      this.$router.replace({ name: 'signIn' })
    },
    openLibSwitcher() {
      this.switcherVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  height: 64px !important;
  padding: 0;
  background-color: #fff;
  border-bottom: solid 1px #e6e6e6;
  z-index: 1;
  .left {
    flex: 1;
    display: flex;
    align-items: center;

    .switcher {
      margin-left: 10px;
      margin-top: 2px;
    }
  }
  .right {
    display: flex;
    align-items: center;
  }

  .nav-collapse-trigger {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    width: 64px;
    height: 64px;
    cursor: pointer;
    transition: all 0.3s;
    i {
      font-size: 18px !important;
      color: #888;
    }
  }
  .title {
    font-size: 16px;
  }
}
.user-menu {
  border-bottom: 0;
  background-color: #fff;
  .el-submenu__title {
    transition: none;
    background-color: #fff;
  }
}
</style>
<style lang="scss">
.user-submenu {
  .el-menu--popup {
    margin-top: 0;
  }
}
</style>
