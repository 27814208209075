<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    top="10vh"
    width="700px"
    :close-on-click-modal="false"
    @open="onDialogOpen"
  >
    <el-form
      ref="form"
      v-if="edit.visible"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
      v-loading="edit.loading || isUploading"
      style="margin: 0 40px"
    >
      <el-form-item label="书单背景图" prop="banner">
        <el-upload
          class="banner-uploader"
          :class="{ 'banner-uploader--bordered': !edit.data.banner }"
          action="/api/v1/admin/upload/image"
          accept="image/*"
          :show-file-list="false"
          :before-upload="beforeBannerUpload"
          :on-success="onBannerUploadSuccess"
          :on-error="onBannerUploadError"
        >
          <img
            v-if="edit.data.banner"
            :src="edit.data.banner"
            @error="edit.data.banner = null"
          />
          <i v-else class="el-icon-plus uploader-icon" />
        </el-upload>
        <div class="form-helper">
          将文件拖到此处，或点击上传。建议尺寸800*320，且不超过200kb
        </div>
      </el-form-item>
      <el-form-item label="标题" prop="title" style="margin-right: 60px">
        <el-input v-model.trim="edit.data.title" clearable />
      </el-form-item>
      <el-form-item label="介绍" prop="summary" style="margin-right: 60px">
        <el-input
          v-model.trim="edit.data.summary"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
        />
      </el-form-item>
      <el-form-item
        v-if="edit.data.AddBySSID"
        label="SSID"
        prop="ssids"
        style="margin-right: 60px"
      >
        <el-input
          v-model="edit.data.ssids"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
        />
      </el-form-item>

      <div class="book-section-group">
        <div
          class="book-section"
          v-for="(section, index) in edit.data.books"
          :key="index"
        >
          <div class="book-section__header">
            <div class="title">书单{{ index + 1 }}</div>
            <div class="action">
              <el-button size="tiny" type="text" @click="resetBook(index)">
                重选
              </el-button>
              <el-button size="tiny" type="text" @click="moveSectionUp(index)">
                <i class="el-icon-caret-top" />
              </el-button>
              <el-button
                size="tiny"
                type="text"
                @click="moveSectionDown(index)"
              >
                <i class="el-icon-caret-bottom" />
              </el-button>
              <el-button size="tiny" type="text" @click="removeSection(index)">
                删除
              </el-button>
            </div>
          </div>
          <div class="book-section__body">
            <div class="left">
              <div class="img-area">
                <img :src="section.book.image" alt="" />
              </div>
              <div class="title">
                {{ section.book.title }}
              </div>
              <div class="common">
                {{ section.book.author | join }}
              </div>
              <div class="common">
                {{ section.book.publisher }}
              </div>
              <div class="common">
                {{ section.book.pub_date | date }}
              </div>
            </div>
            <div class="right">
              <el-form-item label="标题" :prop="`books.${index}.section_title`">
                <el-input v-model.trim="section.section_title" clearable />
              </el-form-item>
              <el-form-item
                label="介绍"
                :prop="`books.${index}.section_summary`"
              >
                <el-input
                  v-model.trim="section.section_summary"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 10 }"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <div slot="footer" style="display: flex">
      <div style="flex: 1; text-align: left">
        <el-button
          v-if="!edit.data.AddBySSID"
          type="primary"
          :loading="edit.loading"
          @click="appendSection"
        >
          新增图书
        </el-button>
        <el-button
          v-if="!edit.data.AddBySSID"
          :loading="edit.loading"
          @click="onPreview"
        >
          预览
        </el-button>
      </div>
      <div>
        <el-button @click="edit.visible = false"> 取消 </el-button>
        <el-button type="primary" :loading="edit.loading" @click="onSubmit">
          保存
        </el-button>
      </div>
    </div>

    <add-book :visible.sync="bookSelectVisible" @confirm="bookSelectConfirm" />
  </el-dialog>
</template>

<script lang="ts">
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import addBook from '@/components/book-selector/book-selector-dialog.component.vue'
import _ from 'lodash'
import _move from 'lodash-move'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import store from './topic.store'

export default {
  components: { addBook },
  data() {
    return {
      edit: store.edit,
      detail: store.detail,
      rules: {
        title: [{ required: true, message: '请填写' }],
        summary: [{ required: true, message: '请填写' }],
        banner: [{ required: true, message: '请填写' }],
        ssids: [{ required: true, message: '请填写' }]
      },
      isUploading: false,
      bookSelectVisible: false,
      resetIndex: null
    }
  },

  methods: {
    async onDialogOpen() {
      this.$nextTick(() => {
        this.$refs['form'] && this.$refs['form'].clearValidate()
      })
    },

    bookSelectConfirm(books) {
      if (!_.isNull(this.resetIndex)) {
        if (books.length > 1) {
          MessageService.open({ message: '只能选择一本图书', type: 'warning' })
          return
        } else {
          const isExist = _.find(this.edit.data.books, item => {
            return item.book_id == books[0].id
          })
          if (isExist) {
            MessageService.open({
              message: '选择的图书已在书单中, 请重新选择！',
              type: 'warning'
            })
            return
          }
          this.edit.data.books[this.resetIndex].book = books[0]
          this.edit.data.books[this.resetIndex].book_id = books[0].id
        }
      } else {
        let booksDec = _.reduce(
          books,
          (result, item) => {
            result.push({
              section_title: '',
              section_summary: '',
              book_id: item.id,
              book: item
            })
            return result
          },
          []
        )
        this.edit.data.books = _.unionBy(
          this.edit.data.books,
          booksDec,
          'book_id'
        )
      }
      this.bookSelectVisible = false
    },

    onSubmit() {
      this.$refs['form'].validate(async valid => {
        if (!valid) return
        store.onEditSubmit()
      })
    },

    onPreview() {
      this.$refs['form'].validate(async valid => {
        if (!valid) return
        this.detail.visible = true
        this.detail.items = this.edit.data
      })
    },

    //#region section
    resetBook(index: number) {
      this.resetIndex = index
      this.bookSelectVisible = true
    },
    moveSectionUp(index: number) {
      if (index === 0) return
      this.$set(
        this.edit.data,
        'books',
        _move(this.edit.data.books, index, index - 1)
      )
    },
    moveSectionDown(index: number) {
      if (index === this.edit.data.books.length) return
      this.$set(
        this.edit.data,
        'books',
        _move(this.edit.data.books, index, index + 1)
      )
      console.log(this.edit.data.books)
    },
    appendSection() {
      this.resetIndex = null
      this.bookSelectVisible = true
    },
    async removeSection(index: number) {
      await MessageBoxService.confirm({ message: '确认删除' })
      this.edit.data.books.splice(index, 1)
    },
    //#endregion

    //#region banner
    beforeBannerUpload(file: File) {
      if (!/^image\//.test(file.type)) {
        MessageService.open({ type: 'error', message: '图片格式无效' })
        return false
      }
      if (file.size / 1024 > 200) {
        MessageService.open({ type: 'error', message: '图片大小不能超过200kb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onBannerUploadSuccess(res) {
      this.isUploading = false
      this.edit.data.banner = `${_.get(res, 'data.url')}`
    },
    onBannerUploadError(err) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    }
    //#endregion
  }
}
</script>

<style scoped lang="scss" src="./topic.style.scss"></style>
