<template>
  <page-layout>
    <page-header :breadcrumb="['设置', '规则设置']" />

    <page-main
      v-if="termStore.yearList.items.length"
      v-loading="edit.loading || edit.saving"
    >
      <div class="action-bar-right">
        <el-button size="small" @click="onEditTermClick"> 学期配置 </el-button>
      </div>
      <page-action-bar>
        <el-tabs
          :value="`${edit.currentYearId}`"
          type="card"
          @tab-click="onYearChange"
        >
          <el-tab-pane
            v-for="item in termStore.yearList.items"
            :key="item.id"
            :label="`${item.year}入学`"
            :name="`${item.id}`"
          />
          <el-tab-pane label="+" />
        </el-tabs>
      </page-action-bar>

      <template v-if="edit.data.currentConfigType !== 0">
        <page-action-bar>
          <label style="margin-right: 16px">学分考核周期</label>
          <el-radio-group v-model="edit.data.currentConfigType">
            <el-radio :label="1"> 按学期 </el-radio>
            <el-radio :label="2"> 按学年 </el-radio>
            <el-radio :label="3"> 大学期间 </el-radio>
          </el-radio-group>
        </page-action-bar>

        <section-form
          ref="sectionForms"
          v-for="(section, index) in edit.data.sections"
          :key="index"
          :type="edit.data.currentConfigType"
          :data="section"
        />

        <div style="text-align: right">
          <el-button type="primary" @click="onSubmit" style="margin-top: 16px">
            保存
          </el-button>
        </div>
      </template>
      <template v-else-if="edit.data.currentConfigType === 0">
        <div class="initial-guide">
          <div class="title" style="margin-bottom: 8px">
            该学年未配置学分规则，您需要
          </div>
          <el-dropdown @command="handleCopyCommand">
            <el-button type="primary" size="small">
              导入其他年级的规则 <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in yearListHasRules"
                :key="index"
                :command="item.id"
              >
                {{ item.year }}年
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            size="small"
            style="margin-left: 8px"
            @click="onFillRulesSectionClick"
          >
            新建规则
          </el-button>
        </div>
      </template>
    </page-main>

    <credit-term-dialog />
    <edit-year />
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import CreditTermDialog from '../credit-term-dialog/index.vue'
import EditYear from '../credit-term-dialog/edit-year.vue'
import SectionForm from './section-form.vue'
import { termStore } from '@/views/credit-term-dialog/store'
import store from './store'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { POST } from '@/admin-shared-modules/utils/ajax'

export default {
  components: {
    CreditTermDialog,
    EditYear,
    SectionForm
  },

  data() {
    return {
      store,
      termStore,
      edit: store.edit
    }
  },
  computed: {
    yearListHasRules() {
      return _.filter(this.termStore.yearList.items, item => {
        return item.score_config_type !== 0
      })
    }
  },

  watch: {
    'termStore.visible'(visible) {
      if (!visible) {
        this.edit.onEdit({})
      }
    },
    'edit.data.currentConfigType'(type) {
      this.edit.data.sections = this.edit.getCurrentSections(type)
    }
  },

  async mounted() {
    this.init()
  },

  methods: {
    async init() {
      await termStore.yearList.fetch()
      if (!termStore.yearList.items.length) {
        termStore.open({ isInitial: true })
      } else {
        this.edit.currentYearId =
          this.edit.currentYearId || _.get(termStore.yearList.items, '[0].id')
        this.edit.onEdit({})
      }
    },
    onYearChange({ name }) {
      if (!name) {
        termStore.yearEdit.onAdd({
          success: () => {
            this.init()
          }
        })
        return
      }
      this.edit.currentYearId = +name
      this.edit.onEdit({})
    },

    onEditTermClick() {
      termStore.open({ isInitial: false })
    },

    onFillRulesSectionClick() {
      this.edit.data.currentConfigType = 1
      this.edit.fillSections(1)
      this.edit.fillSections(2)
      this.edit.fillSections(3)
    },

    async onSubmit() {
      const promises = _.map(this.$refs.sectionForms, form => {
        return form.validate()
      })
      Promise.all(promises)
        .then(res => {
          this.edit.onEditSubmit()
        })
        .catch(err => {
          MessageService.open({
            type: 'warning',
            message: '请检查表单是否填写完整'
          })
        })
    },

    async handleCopyCommand(fromId) {
      this.edit.saving = true
      try {
        await POST('credit/year/config/copy', {
          data: {
            from_id: fromId,
            to_id: this.edit.currentYearId
          }
        })
        MessageService.open({ type: 'success', message: '规则导入成功' })
        this.edit.onEdit({})
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.edit.saving = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.action-bar-right {
  position: absolute;
  right: 24px;
  margin-top: 16px;
}
</style>
