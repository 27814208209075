import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import { Alert, Table as ElTable, TableColumn, Pagination } from 'element-ui'
import { AbstractListService } from '@/core/services/list.service'

interface CoreTableColumn {
  type?: 'index'
  label: string
  dataKey?: string
  slot?: string
  width?: number
}

@Component({
  name: 'CoreTable'
})
export default class CoreTable extends Vue {
  @Prop()
  readonly service?: AbstractListService
  @Prop()
  readonly data: any[]
  @Prop()
  readonly columns: CoreTableColumn[]
  @Prop()
  readonly size: string
  @Prop()
  readonly loading: boolean
  @Prop()
  readonly defaultSelected: any[]
  @Prop({ default: 'id' })
  readonly rowKey: string

  selectedItems = []

  get showPagination() {
    return !!this.service
  }

  render(h: CreateElement) {
    return (
      <div class="table-wrapper">
        {!!this.selectedItems.length && (
          <Alert
            {...{
              props: {
                type: 'success',
                title: `已选中 ${this.selectedItems.length} 条记录`,
                closeText: '清空选择'
              },
              on: {
                close: this.cleanSelectedItems.bind(this)
              }
            }}
          />
        )}

        <ElTable
          {...{
            ref: 'table',
            props: {
              data: this.service ? this.service.items : this.data,
              size: this.size,
              vLoading: this.service ? this.service.loading : this.loading
            },
            on: {
              'selection-change': this.handleSelectionChange.bind(this)
            }
          }}
        >
          {this.renderColumns()}
        </ElTable>

        {this.showPagination && (
          <Pagination
            {...{
              props: {
                rowKey: this.rowKey,
                currentPage: this.service.index,
                total: this.service.total,
                pageSize: this.service.size,
                layout: 'total, prev, pager, next',
                background: true
              },
              on: {
                'current-change': this.onPageChange.bind(this)
              }
            }}
          />
        )}
      </div>
    )
  }

  renderColumns() {
    return this.columns.map((col, index) => {
      const props = {
        key: index,
        label: col.label,
        prop: col.dataKey
      }
      const scopedSlots = col.slot
        ? {
            default: props => {
              return this.$scopedSlots[col.slot](props)
            }
          }
        : {}

      if (col.type === 'index') {
        return <TableColumn {...{ props: { type: 'index', width: 50 } }} />
      }
      if (col.type === 'checkbox') {
        return <TableColumn {...{ props: { type: 'selection', width: 50 } }} />
      }

      return (
        <TableColumn
          {...{
            props,
            scopedSlots
          }}
        />
      )
    })
  }

  async onPageChange(index) {
    await this.service.handlePageIndexChange(index)
    await this.$nextTick()
    this.setTableCurrentSelection()
  }

  handleSelectionChange(e) {
    const items = _.filter(e, item => {
      return item[this.rowKey]
    })
    console.debug('handleSelectionChange', items)
    const sourceData = this.service ? this.service.items : this.data
    const unSelectedItems = _.differenceBy(sourceData, items, this.rowKey)
    this.selectedItems = _.chain(this.selectedItems)
      .unionBy(items, this.rowKey)
      .differenceBy(unSelectedItems, this.rowKey)
      .value()
    this.emitValue()
  }

  async setTableCurrentSelection() {
    const sourceData = this.service ? this.service.items : this.data
    const selectedRow = _.intersectionBy(
      sourceData,
      this.selectedItems,
      this.rowKey
    )
    const table = this.$refs.table as any
    console.debug('setTableCurrentSelection', selectedRow)
    _.forEach(selectedRow, row => table.toggleRowSelection(row, true))
  }

  cleanSelectedItems() {
    this.selectedItems = []
    const table = this.$refs.table as any
    table.clearSelection()
    this.emitValue()
  }

  emitValue() {
    this.$emit('input', this.selectedItems)
  }
}
