<template>
  <el-dialog
    :visible.sync="edit.visible"
    title="笔记详情"
    width="700px"
    :destroy-on-close="true"
    append-to-body
  >
    <div class="note-list">
      <div v-for="(note, index) in edit.data.notes" :key="index" class="item">
        <div v-if="note.title" class="title">
          {{ note.title }}
        </div>
        <div class="content">
          {{ note.content }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'

export default {
  components: {},

  data() {
    return {
      edit: store.edit
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.note-list {
  .item {
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    line-height: 1.4;
    .title {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .content {
    }
  }
}
</style>
