import { Tag } from '@/admin-shared-modules/typings'
import { GET } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'

class BookCateStore {
  state = {
    flattenTags: [] as Tag[],
    items: [] as Tag[],
    loading: false
  }

  async fetch() {
    if (this.state.items.length) return
    this.state.loading = true

    try {
      // publicTags
      const res1 = await GET(`tag/parent`, {})
      const res2 = await GET(`tag`, {})

      const publicTags = (res1.data as Array<Tag>)?.map(g => {
        return {
          ...g,
          tag_id: g.id,
          children: res2.data?.[g.id]?.map(it => ({ ...it, leaf: true })) || []
        }
      })

      // privateTags
      const privateTags = [
        { id: 0, name: '本馆分类', children: [], is_library_private: true }
      ]
      const res3 = await GET(`tag`, {
        data: {
          public: 0
        }
      })
      privateTags[0].children = res3.data?.map(g => {
        return {
          ...g,
          tag_id: g.id,
          leaf: true
        }
      })
      console.log('privateTags', privateTags)

      this.state.items = [...publicTags, ...privateTags]
    } catch (e) {
      console.error(e)
    } finally {
      this.state.loading = false
    }
  }

  async fetchSubCates(id: number) {
    const { data } = await GET('tag', {
      data: { parent_id: id }
    })
    return data?.map(it => ({ ...it, leaf: true })) || []
  }

  getTagById(id: number) {
    return _.clone(_.find(this.state.flattenTags, { id }))
  }
}

export default new BookCateStore()
