import _ from 'lodash'
import { Book, EditState, ListState } from '@/admin-shared-modules/typings'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export interface Folder {
  _id: string
  name: string
  books: Book[]
}

interface FormData {
  folder: Folder[]
  books: Book[]
  summary: string
}

class Store {
  list: ListState<any> = {
    items: [],
    index: 1,
    size: 20,
    total: 0,
    loading: true
  }

  add: EditState<FormData> = {
    data: {
      folder: [],
      books: []
    } as FormData,
    visible: false,
    loading: false,
    isEdit: false,
    step: 0
  }

  folderEdit: EditState<any> = {
    data: {},
    visible: false,
    loading: false,
    isEdit: false
  }

  edit: EditState<any> = {
    data: {},
    visible: false,
    loading: false,
    isEdit: true
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    try {
      const res = await GET(`shelf_push/logs`, {
        data: {
          pageIndex: this.list.index - 1
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.log(e)
    } finally {
      this.list.loading = false
    }
  }

  onAdd() {
    this.add.data = {
      folder: [],
      books: []
    } as FormData
    this.add.visible = true
  }
  async onAddSubmit() {
    const bookshelf = []
    if (this.add.data.books.length) {
      bookshelf.push({
        book_ids: _.map(this.add.data.books, 'id')
      })
    }
    if (this.add.data.folder.length) {
      bookshelf.push(
        ..._.map(this.add.data.folder, f => {
          return {
            folder_name: f.name,
            book_ids: _.map(f.books, 'id')
          }
        })
      )
    }
    await POST('shelf_push/push', {
      data: {
        bookshelf,
        summary: this.add.data.summary
      }
    })
    this.add.visible = false
    this.fetch()

    MessageBoxService.alert({
      type: 'success',
      title: '推送成功',
      message: '当本馆的阅读本设备联网后，会收到您推送的图书'
    })
  }

  //#region folder
  onFolderAdd() {
    this.folderEdit.data = {
      name: '',
      books: []
    }
    this.folderEdit.isEdit = false
    this.folderEdit.visible = true
  }
  onFolderEdit(folder: Folder) {
    this.folderEdit.data = _.cloneDeep(folder)
    this.folderEdit.isEdit = true
    this.folderEdit.visible = true
  }
  onFolderEditSubmit() {
    if (this.folderEdit.isEdit) {
      const index = _.findIndex(this.add.data.folder, f => {
        return f._id === this.folderEdit.data._id
      })
      if (index > -1) {
        this.add.data.folder.splice(index, 1, this.folderEdit.data)
      }
    } else {
      this.add.data.folder.push({
        _id: _.uniqueId(),
        name: this.folderEdit.data.name,
        books: this.folderEdit.data.books
      })
    }
  }

  isFolderNameUnique() {
    const names = _.map(this.add.data.folder, 'name')
    return _.uniq(names).length === names.length
  }
  //#endregion

  async onEdit(item) {
    this.edit.visible = true
    const res = await GET(`shelf_push/settings/${item.id}`, {})

    const transToBook = item => {
      return {
        id: item.book_id,
        title: item.name,
        image: item.book_thumbnail
      } as Book
    }
    const books = _.chain(res.data.bookshelf)
      .filter(item => {
        return item.type === 'book' && !item.parent_name
      })
      .map(transToBook)
      .value()
    const folders = _.reduce<any, Folder[]>(
      res.data.bookshelf,
      (result, item) => {
        if (item.type === 'folder') {
          const books = _.chain(res.data.bookshelf)
            .filter(d => {
              return d.type === 'book' && d.parent_name === item.name
            })
            .map(transToBook)
            .value()
          result.push({
            _id: '',
            name: item.name,
            books
          })
        }
        return result
      },
      []
    )

    this.edit.data = {
      ...res.data,
      books,
      folder: folders
    }
  }

  async onPush(item) {
    await MessageBoxService.confirm({
      message: '确定再次推送'
    })
    await POST('shelf_push/push_again', {
      data: {
        push_id: item.id
      }
    })
    MessageBoxService.alert({
      type: 'success',
      title: '推送成功',
      message: '当本馆的阅读本设备联网后，会收到您推送的图书'
    })
  }
}

export default new Store()
