<template>
  <canvas ref="canvas" :style="{ width: `${size}px`, height: `${size}px` }" />
</template>

<script>
import QRCode from 'qrcode'
export default {
  props: {
    text: { type: String, required: true },
    size: { type: Number, required: false, default: 128 },
    color: { type: String, required: false, default: '#000' },
    bgColor: { type: String, required: false, default: '#FFF' },
    errorLevel: {
      type: String,
      validator: function (value) {
        return value === 'L' || value === 'M' || value === 'Q' || value === 'H'
      },
      required: false,
      default: 'H'
    }
  },
  watch: {
    text: function () {
      this.makeCode(this.text)
    }
  },
  data() {
    return {
      qrCode: {}
    }
  },
  mounted() {
    if (this.text) this.makeCode(this.text)
  },
  methods: {
    makeCode: function (text) {
      QRCode.toCanvas(this.$refs.canvas, text, {
        errorCorrectionLevel: 'H'
      })
    }
  }
}
</script>
