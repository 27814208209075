<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="700px"
  >
    <div v-if="!edit.isEdit" style="margin-top: -16px; margin-bottom: 24px">
      <el-steps
        :active="edit.step"
        :align-center="true"
        center
        finish-status="success"
      >
        <el-step title="添加图书" />
        <el-step title="信息编辑" />
        <el-step title="完成" />
      </el-steps>
    </div>
    <div v-if="!edit.isEdit && edit.step === 0">
      <div class="intro">
        <el-upload
          action="/api/v1/admin/upload/ebook"
          :before-upload="beforeFileUpdate"
          :on-success="onFileUploadSuccess"
          :on-error="onFileUploadError"
        >
          <div class="intro-card">
            <div>
              <i class="el-icon-upload" style="font-size: 60px; color: #999" />
            </div>
            <div class="title">上传图书</div>
            <div class="help">仅支持epub格式</div>
          </div>
        </el-upload>
      </div>
    </div>

    <el-form
      v-if="edit.visible && (edit.isEdit || edit.step === 1)"
      ref="form"
      :model="edit.data"
      label-width="100px"
      v-loading="isUploading || edit.saving"
    >
      <el-row>
        <el-col :span="16">
          <el-form-item
            label="图书分类"
            prop="tags"
            :rules="{ ...baseRequireRule, type: 'array' }"
          >
            <div v-for="(tag, index) in edit.data.tags" :key="index">
              <book-cate-selector
                :value="[tag.id]"
                @input="val => (tag.id = val[0])"
                size="small"
              />
              <a
                v-if="index"
                style="margin-left: 8px"
                @click="onTagRemoveClick(index)"
                >删除</a
              >
            </div>
            <el-button size="small" type="primary" @click="onTagAddClick">
              新增分类
            </el-button>
          </el-form-item>

          <el-form-item
            label="ISBN"
            prop="isbn_13"
            :rules="getFieldRules([baseRequireRule])"
          >
            <el-input
              v-model="edit.data.isbn_13"
              maxlength="13"
              :disabled="readonly"
            />
          </el-form-item>
          <el-form-item
            label="书名"
            prop="title"
            :rules="getFieldRules([baseRequireRule, nameLengthRule])"
          >
            <el-input v-model="edit.data.title" :disabled="readonly" />
          </el-form-item>
          <el-form-item
            label="作者"
            prop="authorStr"
            :rules="getFieldRules([baseRequireRule, nameLengthRule])"
          >
            <el-input v-model="edit.data.authorStr" :disabled="readonly" />
            <div class="el-upload__tip">多个作者使用逗号分隔</div>
          </el-form-item>
          <el-form-item
            label="出版社"
            prop="publisher"
            :rules="getFieldRules([baseRequireRule, nameLengthRule])"
          >
            <el-input v-model="edit.data.publisher" :disabled="readonly" />
          </el-form-item>

          <el-form-item label="出版时间" prop="pub_date">
            <el-input v-model="edit.data.pub_date" :disabled="readonly" />
          </el-form-item>
          <el-form-item label="页数" prop="pages">
            <el-input v-model="edit.data.pages" :disabled="readonly" />
          </el-form-item>
          <el-form-item label="简介" prop="summary">
            <el-input
              v-model="edit.data.summary"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 10 }"
              :disabled="readonly"
            />
          </el-form-item>

          <el-form-item v-if="edit.data.epub_file" label="电子书文件">
            <el-tag>{{ edit.data.epub_file }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="1">
          <el-upload
            class="cover-uploader"
            action="/api/v1/admin/upload/image"
            accept="image/*"
            :show-file-list="false"
            :before-upload="beforeCoverUpload"
            :on-success="onCoverUploadSuccess"
            :on-error="onCoverUploadError"
            :disabled="readonly"
          >
            <img v-if="edit.data.image" :src="edit.data.image" alt="" />
            <template v-else>
              <i class="el-icon-upload uploader-icon" />
              <div>封面图片</div>
            </template>
          </el-upload>
          <div class="el-upload__tip">
            只能上传图片文件，建议尺寸180*240，且不超过500kb
          </div>
        </el-col>
      </el-row>
    </el-form>

    <template v-if="edit.isEdit" slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </template>
    <template v-else slot="footer">
      <div v-if="edit.step === 1" slot="footer">
        <el-button @click="edit.visible = false"> 取消 </el-button>
        <el-button
          type="primary"
          :disabled="edit.loading || edit.saving"
          @click="onSubmit"
        >
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import moment from 'moment'
import { store } from './store'
import BookCateSelector from './shared/book-cate-selector.vue'
import { UploadResponse } from '@/views/book/book.store'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  components: {
    BookCateSelector
  },

  data() {
    return {
      edit: store.edit,
      baseRequireRule: { required: true, message: '请填写' },
      nameLengthRule: { min: 2, message: '长度需大于 2' },
      isUploading: false
    }
  },
  computed: {
    readonly() {
      return (
        (this.edit.isEdit && !this.edit.data.can_update) || this.edit.isBookRef
      )
    }
  },

  methods: {
    getFieldRules(params) {
      return this.readonly ? [] : params
    },

    onTagAddClick() {
      this.edit.data.tags.push({ id: 0 })
    },
    onTagRemoveClick(index) {
      this.edit.data.tags.splice(index, 1)
    },

    onSubmit() {
      if (!this.edit.data.image) {
        MessageService.open({ type: 'warning', message: '请上传图书封面' })
        return
      }
      this.$refs['form'].validate(v => {
        if (!v) return
        store.edit.onEditSubmit()
      })
    },

    //#region file upload
    beforeFileUpdate(file: File) {
      if (this.isUploading) {
        return false
      }
      if (!/\.epub$/.test(file.name)) {
        MessageService.open({ type: 'error', message: '文件格式无效' })
        return false
      }
      if (file.size / 1024 / 1024 > 200) {
        MessageService.open({ type: 'error', message: '文件大小不能超过200mb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onFileUploadError(err, file: File) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    },
    async onFileUploadSuccess(res, file: File) {
      console.log(res, file)
      this.isUploading = false
      const data: UploadResponse = res.data
      this.edit.data.epub_file = data.file
      if (data.metadata) {
        this.edit.data.authorStr = data.metadata.creator
        this.edit.data.pub_date = moment(data.metadata.date).toDate()
        this.edit.data.publisher = data.metadata.publisher
        this.edit.data.title = data.metadata.title
      }
      this.edit.step = 1
    },
    //#endregion

    //#region cover upload
    beforeCoverUpload(file: File) {
      if (this.isUploading) {
        return false
      }
      if (!/^image\//.test(file.type)) {
        MessageService.open({ type: 'error', message: '图片格式无效' })
        return false
      }
      if (file.size / 1024 > 500) {
        MessageService.open({ type: 'error', message: '图片大小不能超过500kb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onCoverUploadError(err, file: File) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    },
    onCoverUploadSuccess(res, file: File) {
      this.isUploading = false
      this.edit.data.image = res.data.url
    }
    //#endregion
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';

.intro {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  > div {
    flex: 0 0 400px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
}
::v-deep .el-upload {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.intro-card {
  flex: auto;
  padding: 16px 24px;
  border: 1px solid $--border-color-base;
  border-radius: $--border-radius-base;
  cursor: pointer;
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: $--box-shadow-base;
  }
  &:first-child {
    margin-right: 20px;
  }
  .title {
    margin-bottom: 8px;
    font-size: $--font-size-large;
    font-weight: $--font-weight-primary;
    text-align: center;
  }
  .help {
    color: $--color-text-secondary;
    text-align: center;
  }
}
</style>
