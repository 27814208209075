import {
  Device,
  DeviceBorrowingLog,
  EditState,
  ListState,
  LibraryMember
} from '@/admin-shared-modules/typings'
import { AJAXErrorResult, GET, POST } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import moment from 'moment'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

interface BorrowingDeviceFormData {
  deviceCode: string
  userCardId: string
  device: Device
  member: LibraryMember
}
interface ReturnDeviceFormData {
  deviceCode: string
  device: Device
  member: LibraryMember
}

class Store {
  borrowingList: ListState<DeviceBorrowingLog> = {
    index: 1,
    items: [],
    loading: false,
    size: 10,
    total: 0
  }

  borrowingDevice: EditState<BorrowingDeviceFormData> = {
    step: 0,
    data: {} as BorrowingDeviceFormData,
    isEdit: true,
    loading: false,
    visible: false
  }

  returnDevice: EditState<ReturnDeviceFormData> = {
    step: 0,
    data: {} as ReturnDeviceFormData,
    isEdit: true,
    loading: false,
    visible: false
  }

  async fetchLog() {
    this.borrowingList.loading = true
    this.borrowingList.items = []
    try {
      const res = await GET('reader/lend/list', {
        data: {
          pageIndex: this.borrowingList.index - 1,
          pageSize: this.borrowingList.size,
          begin_date: moment().startOf('week').format('YYYY-MM-DD'),
          end_date: moment().endOf('week').format('YYYY-MM-DD')
        }
      })
      this.borrowingList.items = res.data.items
      this.borrowingList.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.borrowingList.loading = false
    }
  }

  onBorrowing() {
    this.borrowingDevice.step = 0
    this.borrowingDevice.data = {
      deviceCode: '',
      userCardId: '',
      device: null,
      member: null
    }
    this.borrowingDevice.visible = true
  }
  async onBorrowingSubmit() {
    try {
      await POST('reader/lend', {
        data: {
          device_id: this.borrowingDevice.data.device.padbookId,
          user_id: this.borrowingDevice.data.member.userId
        }
      })
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    }
  }

  async fetchDevice(deviceCode) {
    try {
      const res = await GET(`reader/device/${deviceCode}`, {})
      return res.data
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    }
  }
  async fetchMember(userCardId) {
    try {
      const res = await GET(`reader/user`, {
        data: {
          card_num: userCardId
        }
      })
      return res.data
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    }
  }

  onReturn() {
    this.returnDevice.step = 0
    this.returnDevice.data = {
      deviceCode: '',
      device: null,
      member: null
    }
    this.returnDevice.visible = true
  }
  async onReturnSubmit() {
    try {
      await POST('reader/return', {
        data: {
          device_id: this.returnDevice.data.device.padbookId,
          user_id: this.returnDevice.data.member.userId
        }
      })
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    }
  }
}

export default new Store()
