<template>
  <page-layout>
    <page-header :breadcrumb="['Kindle设备管理']" />

    <page-main v-loading="list.loading">
      <search />

      <el-table ref="table" :data="list.items">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              label-width="9em"
              inline
              class="device-detail-expand"
            >
              <el-form-item label="设备类型">
                {{ props.row.device_type }}
              </el-form-item>
              <el-form-item label="UPC">
                {{ props.row.upc | splitByLength | join(null, '-') }}
              </el-form-item>
              <el-form-item label="亚马逊账号">
                {{ props.row.amazon_register_mail }}
              </el-form-item>
              <el-form-item label="亚马逊密码">
                {{ props.row.amazon_register_password }}
              </el-form-item>
              <el-form-item label="亚马逊推送邮箱">
                {{ props.row.push_account }}
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column type="index" width="50" :index="indexMethod" />

        <el-table-column label="亚马逊账号">
          <template slot-scope="scope">
            {{
              scope.row.amazon_register_mail.replace('@chaoxingbook.com', '')
            }}
          </template>
        </el-table-column>
        <el-table-column label="DSN">
          <template slot-scope="scope">
            {{ scope.row.dsn | splitByLength | join(null, '-') }}
          </template>
        </el-table-column>
        <el-table-column label="导入时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.user"
              placement="right"
              title="用户信息"
              width="200"
              trigger="hover"
            >
              <div style="text-align: left; margin: 0">
                <div>姓名: {{ getUserName(scope.row.user) }}</div>
                <div>电话: {{ getUserPhone(scope.row.user) }}</div>
              </div>
              <el-tag
                slot="reference"
                type="success"
                size="small"
                disable-transitions
              >
                已绑定用户
              </el-tag>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import store from './device-kindle.store'
import Search from './device-kindle-search.component.vue'

export default {
  components: { Search },

  data() {
    return {
      list: store.list
    }
  },

  mounted() {
    store.fetch(true)
  },

  methods: {
    //#region ui
    async handleCurrentChange(index) {
      this.list.index = index
      await store.fetch()
    },
    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },
    //#endregion ui

    getUserName(user) {
      return _.isNull(user) ? '' : user.user_name
    },

    getUserPhone(user) {
      return _.isNull(user) ? '' : user.phone
    }
  }
}
</script>

<style scoped lang="scss">
.device-detail-expand {
  .el-form-item {
    margin-right: 0;
    margin-bottom: 8px;
    padding-right: 10px;
    width: 50%;
  }
  .el-form-item__label {
    color: #999;
  }
  .el-form-item__content {
    color: #333;
  }
}
</style>
