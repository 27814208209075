<template>
  <page-layout>
    <page-header :breadcrumb="['后台管理员设置']" />

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-button type="primary" size="small" @click="onAddClick">
          新增
        </el-button>
      </page-action-bar>

      <el-table :data="list.items">
        <el-table-column label="用户名">
          <template slot-scope="scope">
            {{ scope.row.name }}
            <el-tag
              type="info"
              size="mini"
              disable-transitions
              style="margin-left: 5px"
            >
              管理员
            </el-tag>
            <el-tag
              v-if="scope.row.phone === currentUser.phone"
              size="mini"
              disable-transitions
              style="margin-left: 5px"
            >
              当前用户
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" />
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180px"
          v-if="currentUser.source === 'los'"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="onEditClick(scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="onRemoveClick(scope.row)">
              删除
            </el-button>
            <el-button type="text" @click="onResetPasswordClick(scope.row)">
              重置密码
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import Edit from './account-edit.component.vue'
import store from './account.store'
import { appStore } from '@/store'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'

export default {
  components: { Edit },

  data() {
    return {
      list: store.list
    }
  },
  computed: {
    currentUser() {
      return appStore.state.user.data
    }
  },

  mounted() {
    store.fetch()
  },

  methods: {
    handleCurrentChange(index: number) {
      this.list.index = index
      store.fetch()
    },

    onAddClick() {
      store.onAdd()
    },
    onEditClick(data) {
      store.onEdit(data)
    },
    async onRemoveClick(data) {
      await MessageBoxService.confirm({ message: '确认删除' })
      await store.onRemove(data)
      MessageService.open({ message: '删除成功' })
      store.fetch()
    },

    async onResetPasswordClick(data) {
      await MessageBoxService.confirm({ message: '确认重置密码' })
      await store.onResetPassword(data)
      MessageService.open({ message: '密码重置成功' })
    }
  }
}
</script>
