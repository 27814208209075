<template>
  <page-layout>
    <page-header :breadcrumb="['设置', '学分介绍']" />

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-button type="primary" size="small" @click="e => store.edit.onAdd()">
          新增
        </el-button>
      </page-action-bar>
      <el-table :data="list.items">
        <el-table-column label="标题" prop="title" />
        <el-table-column label="操作" width="120px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="e => store.edit.onEdit(row)">
              编辑
            </el-button>
            <el-button type="text" @click="e => store.edit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <edit />
  </page-layout>
</template>

<script>
import { store } from './store'
import Edit from './edit'

export default {
  components: {
    Edit
  },

  data() {
    return {
      store,
      list: store.list
    }
  },

  async mounted() {
    store.list.fetch()
  },

  methods: {}
}
</script>

<style scoped lang="scss"></style>
