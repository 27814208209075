<template>
  <el-container class="layout">
    <app-nav v-show="showNav" />

    <el-container direction="vertical" class="main">
      <app-header v-show="showHeader" />
      <el-main class="body">
        <router-view />
      </el-main>
      <el-footer class="footer">
        Copyright &copy; 2018 - {{ year }} 超星 V1.0
      </el-footer>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import AppNav from './app-nav.vue'
import AppHeader from './app-header.vue'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: {
    AppNav,
    AppHeader
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    showNav() {
      if (
        ~[
          'tag',
          'book',
          'topic',
          'courseToEpub',
          'customHomeSection',
          'magazineRecommend',
          'zcloudInventory',
          'zcloudBlockAccount',
          'zcloudDefineDamage',
          'zcloudAdPublish',
          'zcloudDepositReport',
          'zcloudDeposit',
          'zcloudOverdueOrder',
          'zcloudOverdueOrderDeposit',

          'creditRegister',
          'creditBookPro',
          'creditBook',
          'creditTopic',
          'creditNoteReview',
          'creditPointReview',
          'creditReport',
          'creditIntro',
          'creditRules',
          'creditSetting'
        ].indexOf(this.$route.name)
      ) {
        return true
      }
      return !this.isCXApp
    },
    showHeader() {
      return !this.isCXApp
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  overflow-x: hidden;
}
.main {
  display: block;
  background-color: #f0f2f5;
}
.body {
  padding: 0;
  overflow: visible;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
</style>
