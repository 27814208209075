import _ from 'lodash'
import moment from 'moment'
import { GET } from '@/admin-shared-modules/utils/ajax'

const defaultDateRange = [
  moment().startOf('month').subtract(3, 'month').toDate(),
  moment().endOf('day').toDate()
]

class DashboardStore {
  userScope = {
    items: []
  }
  gradeData = {
    items: []
  }
  userType = {
    items: []
  }

  lendBookData = {
    search: {
      userScope: '',
      dateRange: defaultDateRange
    },
    lendCount: 0,
    trendData: [],
    cateData: [],
    loading: false
  }

  orgLendData = {
    search: {
      userScope: '',
      dateRange: defaultDateRange
    },
    lendCount: 0,
    lendList: {
      page: 1,
      total: 0,
      items: []
    },
    loading: false
  }

  orgDetail = {
    visible: false,
    params: {},
    search: {
      yearScope: null
    },
    items: [],
    loading: false
  }

  export = {
    visible: false,
    data: {
      grade: null,
      userType: null
    }
  }

  async fetchInitialData() {
    const resOrg = await GET('org/orgs', {})
    this.userScope.items = resOrg.data

    const resGrade = await GET('org/grades', {})
    this.gradeData.items = resGrade.data

    const resUser = await GET('org/user_types', {})
    this.userType.items = resUser.data
  }

  async fetchLendBookData() {
    this.lendBookData.loading = true

    try {
      const search = parseSearchFromData(this.lendBookData.search)
      const resTrend = await GET('org/push_trend', {
        data: search
      })
      this.lendBookData.trendData = resTrend.data

      const resCate = await GET('org/tag_statistic', {
        data: search
      })
      // const data: any[] = [
      //   {id:1, tag_name: '份A1', count: 101},
      //   {id:2, tag_name: '份A1', count: 102},
      //   {id:3, tag_name: '份A1', count: 103},
      //   {id:4, tag_name: '份A1', count: 104},
      //   {id:5, tag_name: '份A1', count: 105},
      //   {id:6, tag_name: '份A1', count: 106},
      //   {id:7, tag_name: '份A1', count: 107},
      //   {id:8, tag_name: '份A1', count: 108},
      //   {id:9, tag_name: '份A1', count: 109},
      //   {id:10, tag_name: '份A1', count: 210},
      //   {id:11, tag_name: '份A1', count: 210},
      //   {id:12, tag_name: '份A1', count: 310},
      //   {id:13, tag_name: '份A1份A1份A1', count: 400},
      // ]
      const data: any[] = resCate.data
      const max = _.maxBy(data, item => item.count)
      if (max) {
        _.forEach(data, item => {
          item.percent = (item.count / max.count) * 100
        })
      }
      this.lendBookData.cateData = data

      const countRes = await GET('org/push_count', {
        data: search
      })
      this.lendBookData.lendCount = countRes.data
    } catch (e) {
      console.error(e)
    } finally {
      this.lendBookData.loading = false
    }
  }

  async fetchOrgLendData() {
    this.orgLendData.loading = true

    try {
      const search = parseSearchFromData(this.orgLendData.search)
      const res = await GET('org/org_statistic', {
        data: search
      })
      this.orgLendData.lendList.items = res.data

      const countRes = await GET('org/push_count', {
        data: search
      })
      this.orgLendData.lendCount = countRes.data
    } catch (e) {
      console.error(e)
    } finally {
      this.orgLendData.loading = false
    }
  }

  async openOrgDetail(params) {
    this.orgDetail.params = params
    this.orgDetail.search.yearScope = null
    this.orgDetail.items = []
    this.orgDetail.visible = true
    await this.fetchOrgLendDetailData()
  }
  async fetchOrgLendDetailData() {
    this.orgDetail.loading = true
    const res = await GET('org/profession_statistic', {
      data: {
        ...parseSearchFromData(this.orgLendData.search),
        ...this.orgDetail.params,
        grade: this.orgDetail.search.yearScope
      }
    })
    this.orgDetail.items = _.map(res.data, (item, index) => {
      return {
        ...item,
        index
      }
    })
    this.orgDetail.loading = false
  }
}

const parseSearchFromData = data => {
  let result: any = {
    org_name: data.userScope || null
  }
  if (data.dateRange && data.dateRange.length) {
    result = {
      ...result,
      begin_date: format(data.dateRange[0]),
      end_date: format(data.dateRange[1])
    }
  }
  return result
}
const format = (date: Date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const store = new DashboardStore()
