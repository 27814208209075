export const Icons = [
  'Telegram.png',
  '书签.png',
  '书籍-打开.png',
  '书籍.png',
  '书籍1.png',
  '云存储.png',
  '保存.png',
  '公告.png',
  '分享.png',
  '切换.png',
  '协议.png',
  '博物馆.png',
  '名片.png',
  '地球仪.png',
  '工程牌.png',
  '成功列表.png',
  '报纸折叠.png',
  '指示牌.png',
  '数据图.png',
  '数据透视表.png',
  '文档架子.png',
  '日历.png',
  '显示器.png',
  '智能优化.png',
  '更多.png',
  '棋盘.png',
  '盒子.png',
  '笔记本.png',
  '网页.png',
  '订阅.png',
  '评论.png',
  '详情列表.png'
].map(it => `https://chaoxingstatic.oss-cn-beijing.aliyuncs.com/icons/${it}`)
