<template>
  <page-layout>
    <page-header :breadcrumb="['首页管理', typeName]"> </page-header>

    <page-main :key="typeName" v-if="!loading">
      <list
        v-if="$route.name === 'deviceCustomSetupImage'"
        type="boot"
        :data="data"
        @change="onChange"
      />
      <list
        v-if="$route.name === 'deviceCustomGuideImage'"
        type="guide"
        :data="data"
        @change="onChange"
      />
      <one
        v-if="$route.name === 'deviceCustomShutdownImage'"
        type="shutdown"
        :data="data"
        @change="onChange"
      />
      <one
        v-if="$route.name === 'deviceCustomLockImage'"
        type="lock"
        :data="data"
        @change="onChange"
      />
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import List from './list.vue'
import One from './one.vue'

const routerMap = {
  // deviceCustomSetupImage: '开机界面配置',
  deviceCustomGuideImage: '开机界面配置',
  deviceCustomShutdownImage: '关机界面配置',
  deviceCustomLockImage: '锁屏界面配置'
}

export default {
  components: { List, One },
  setup() {
    const route = useRoute()
    const typeName = computed(() => routerMap[route.name])

    const data = ref({
      boot: {
        images: [
          // '//placehold.it/200x200?text=1',
          // '//placehold.it/200x200?text=2',
          // '//placehold.it/200x200?text=3'
        ]
      },
      guide: {
        images: [
          // '//placehold.it/200x200?text=12',
          // '//placehold.it/200x200?text=2',
          // '//placehold.it/200x200?text=3'
        ]
      },
      shutdown: {
        images: []
      },
      lock: {
        images: []
      }
    })

    const loading = ref(true)
    onMounted(async () => {
      const res = await GET('device/config/guide', {})
      if (_.has(res.data.config, 'boot')) {
        data.value = res.data.config
      }
      loading.value = false
    })

    async function submit() {
      await POST('device/config/guide', {
        data: {
          config: data.value
        }
      })
    }

    async function onChange(type: string, images: string[]) {
      data.value[type].images = images
      MessageService.open({
        message: '保存成功',
        type: 'success'
      })

      await submit()
    }

    return {
      routeName: route.name,
      typeName,
      loading,
      data,
      onChange
    }
  }
}
</script>
