import _ from 'lodash'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { GET, POST } from '@/admin-shared-modules/utils/ajax'
import { AbstractEditService } from '@/core/services/edit.service'
import { ListFetchOptions } from '@/core/typing'

class List extends AbstractListService<Store> {
  size = 50
  getFetchURL(): string {
    return 'credit/note'
  }
  parseFetchedItems({ items }) {
    return _.map(items, item => {
      const levelConfig = findLevelConfig(item.note_score)
      return {
        ...item,
        level: _.get(levelConfig, 'level'),
        isPass: item.note_score >= this.context.notePassScore
      }
    })
  }
  getSearchParams(): any {
    return this.context.search.getSearchParams()
  }
}

class Search extends AbstractSearchService<Store> {
  getDefaultFormData(): Partial<any> {
    return {
      note_status: 1
    }
  }
  getSearchParams() {
    let levelRange = {}
    if (_.isNumber(this.data.level) && this.data.note_status === 2) {
      const levelConfig = this.context.scoreLevelConfigs[this.data.level]
      levelRange = {
        note_score_begin: levelConfig.min_score,
        note_score_end: levelConfig.max_score
      }
    }
    return this.omitEmptyString({
      ...this.data,
      ...levelRange
    })
  }
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return `credit/note/${this.params.id}`
  }
  getRemoveURL(): string {
    return ''
  }
  getSubmitURL(): string {
    return 'credit/note/review'
  }
  getSubmitMethod() {
    return POST
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
  parseFetchedFormData(data) {
    return {
      ...data,
      note_score:
        this.context.search.data.note_status === 1 ? undefined : data.note_score
    }
  }
  getSubmitData(): any {
    return {
      user_credit_book_id: this.params.id,
      note_score: this.data.note_score
    }
  }
}

class Store {
  list: List
  search: Search
  edit: Edit

  scoreLevelConfigs = []
  notePassScore = 0

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
    this.edit = new Edit(this)
  }

  async fetchScoreLevel() {
    const { data } = await GET('credit/note/review/config', {})
    this.scoreLevelConfigs = data.review_level
    this.notePassScore = data.note_pass_score
  }
}

export const store = new Store()

export const findLevelConfig = score => {
  return _.find(store.scoreLevelConfigs, config => {
    const { min_score, max_score } = config
    return _.inRange(score, min_score, max_score + 1)
  })
}
