import G2 from '@/admin-shared-modules/utils/g2'
import numeral from 'numeral'

export class BookCateChart {
  static chartInstance: any

  static render(el: HTMLDivElement, data: any) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const itemProp = 'tag_name'
    const valueProp = 'book_count'

    const chart = (this.chartInstance = new G2.Chart({
      container: el,
      height: el.offsetHeight,
      forceFit: true,
      padding: 'auto'
    }))
    chart.source(data, {
      [itemProp]: {},
      [valueProp]: {
        alias: '图书数'
      },
      percent: {
        formatter: val => {
          return `${numeral(val).multiply(100).value()}%`
        }
      }
    })

    chart.coord('theta', {
      radius: 0.5,
      innerRadius: 0.4
    })

    chart.tooltip({
      showTitle: false
    })

    chart
      .intervalStack()
      .position('percent')
      .label('percent', {
        formatter: (val, item) => {
          return `${item.point[itemProp]}: ${val}`
        }
      })
      .color(itemProp)
      .style({
        lineWidth: 1,
        stroke: '#fff'
      })

    chart.legend(false)

    chart.render()
  }
}
