var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(!_vm.isEdit)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.summary)}}):_c('div',[_c('quill-editor',{attrs:{"options":{
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', { color: [] }],
            [{ indent: '-1' }, { indent: '+1' }, { align: [] }]
          ]
        }
      }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticStyle:{"margin-top":"16px"}},[_c('el-button',{on:{"click":function($event){_vm.isEdit = false}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"loading":_vm.saving,"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }