<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="edit.data"
      :rules="rules"
      ref="form"
      label-width="120px"
      style="width: 450px"
    >
      <el-form-item label="设备型号" prop="padbookType">
        <el-input v-model.trim="edit.data.padbookType" />
      </el-form-item>
      <el-form-item label="设备编号" prop="padbookCode">
        <el-input v-model.trim="edit.data.padbookCode" />
      </el-form-item>
      <el-form-item label="MAC地址" prop="padbookMac">
        <el-input v-model.trim="edit.data.padbookMac" />
      </el-form-item>
      <el-form-item label="系统版本" prop="padbookVersion">
        <el-input v-model.trim="edit.data.padbookVersion" />
      </el-form-item>
      <el-form-item label="存储容量" prop="padbookCapacity">
        <el-input v-model.number="edit.data.padbookCapacity">
          <template slot="append"> GB </template>
        </el-input>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import store from './device.store'

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        padbookType: { required: true, message: '请填写' },
        padbookCode: { required: true, message: '请填写' },
        padbookMac: { required: true, message: '请填写' },
        padbookVersion: { required: true, message: '请填写' },
        padbookCapacity: [
          { required: true, message: '请填写' },
          { type: 'number', message: '请填写数字' }
        ]
      }
    }
  },

  methods: {
    async onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        store.onEditSubmit()
      })
    }
  }
}
</script>

<style scoped></style>
