<template>
  <el-dialog
    :visible.sync="edit.visible"
    title="报名信息"
    width="550px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="6em"
      style="margin-top: -10px; margin-left: 40px"
    >
      <el-form-item label="书名">
        {{ edit.data.user_name }}
      </el-form-item>
      <el-form-item label="学号">
        {{ edit.data.card_num }}
      </el-form-item>
      <el-form-item label="学院">
        {{ edit.data.college }}
      </el-form-item>
      <el-form-item label="专业">
        {{ edit.data.profession }}
      </el-form-item>
      <el-form-item label="入学年份">
        {{ edit.data.year }}
      </el-form-item>
      <el-form-item label="手机号">
        {{ edit.data.phone }}
      </el-form-item>
      <el-form-item label="学习通账号">
        {{ edit.data.study_account }}
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="primary" @click="edit.visible = false"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import { appStore } from '@/store'

export default {
  data() {
    return {
      edit: store.edit
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss"></style>
