<template>
  <el-dialog
    title="阅读报告"
    :visible.sync="visible"
    width="700px"
    top="9vh"
    @open="onDialogOpen"
    center
    :append-to-body="true"
  >
    <div v-if="visible && state.params">
      <el-card class="summary" shadow="never">
        <div slot="header">
          <div class="title">综合统计</div>
        </div>
        <div class="user">
          {{ state.params.userName || state.params.user_name }}
        </div>
        <div class="summary__content">
          <div class="item">
            本周读 <b class="num"> {{ state.bookCount.weekRead }}</b> 本
          </div>
          <div class="item">
            本月读 <b class="num"> {{ state.bookCount.monthRead }}</b> 本
          </div>
          <div class="item">
            总共读 <b class="num"> {{ state.bookCount.totalRead }}</b> 本
          </div>
        </div>
        <div class="summary__content">
          <div class="item">
            本周读 <b class="num">{{ state.readTime.weekReadTime }}</b> 分钟
          </div>
          <div class="item">
            本月读 <b class="num">{{ state.readTime.monthReadTime }}</b> 分钟
          </div>
          <div class="item">
            总共读 <b class="num">{{ state.readTime.totalReadTime }}</b> 分钟
          </div>
        </div>
      </el-card>

      <el-card class="chart" shadow="never">
        <div slot="header">阅读趋势</div>
        <div class="chart__content">
          <div ref="chartRef3" />
          <div v-if="!state.trend.length" class="chart-empty-message">
            暂无数据
          </div>
        </div>
      </el-card>

      <el-card class="chart" shadow="never">
        <div slot="header">阅读时长分布图</div>
        <div class="chart__content">
          <div ref="chartRef1" />
          <div v-if="!state.hourData.length" class="chart-empty-message">
            暂无数据
          </div>
        </div>
      </el-card>

      <el-card class="chart" shadow="never">
        <div slot="header">阅读分类图</div>
        <div class="chart__content">
          <div ref="chartRef2" />
          <div v-if="!state.tag.length" class="chart-empty-message">
            暂无数据
          </div>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import numeral from 'numeral'
import store from './user-reporting.store'
import G2 from '@antv/g2'
import { LibraryMember, DeviceUser } from '@/admin-shared-modules/typings'

export default {
  data() {
    return {
      visible: false,
      isBorrower: false,
      state: store.state
    }
  },

  mounted() {},

  methods: {
    open(params: LibraryMember | DeviceUser, option = { isBorrower: false }) {
      this.isBorrower = option.isBorrower
      this.state.params = params
      this.visible = true
    },

    async onDialogOpen() {
      await store.fetch()

      await this.$nextTick()
      this.render()
    },

    render() {
      if (this.chartInstance1) {
        this.chartInstance1.destroy()
      }
      if (this.chartInstance2) {
        this.chartInstance2.destroy()
      }
      if (this.chartInstance3) {
        this.chartInstance3.destroy()
      }

      //region 时长分布
      const c1 = (this.chartInstance1 = new G2.Chart({
        container: this.$refs.chartRef1,
        forceFit: true,
        height: 360,
        padding: 'auto'
      }))
      let scaleXProp = 'hours'
      let scaleYProp = 'minute'
      c1.source(this.state.hourData, {
        [scaleXProp]: {
          ticks: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00']
        },
        [scaleYProp]: {
          min: 0,
          alias: '阅读时间',
          formatter: val => {
            return numeral(val).format(0, 0) + '分钟'
          }
        }
      })
      c1.line().position(`${scaleXProp}*${scaleYProp}`).shape('smooth')
      c1.render()
      //endregion

      //#region 阅读分类
      const c2 = (this.chartInstance2 = new G2.Chart({
        container: this.$refs.chartRef2,
        forceFit: true,
        height: 360,
        padding: 'auto'
      }))
      c2.source(this.state.tag)
      c2.scale('books_num', {
        alias: '阅读数(本)'
      })
      c2.coord('rect').transpose()
      c2.interval().position('tag_name*books_num')
      c2.render()
      //#endregion

      //#region 阅读趋势
      const c3 = (this.chartInstance3 = new G2.Chart({
        container: this.$refs.chartRef3,
        forceFit: true,
        height: 360,
        padding: 'auto'
      }))

      if (this.isBorrower) {
        scaleXProp = 'day'
        scaleYProp = 'count'
        c3.source(this.state.trend, {
          [scaleXProp]: {
            type: 'time',
            mask: 'MM-DD'
          },
          [scaleYProp]: {
            min: 0,
            alias: '阅读数量',
            formatter: val => {
              return numeral(val).format(0, 0) + '本'
            }
          }
        })
      } else {
        scaleXProp = 'day'
        scaleYProp = 'minute'
        c3.source(this.state.trend, {
          [scaleXProp]: {
            type: 'time',
            mask: 'MM-DD'
          },
          [scaleYProp]: {
            min: 0,
            alias: '阅读时间',
            formatter: val => {
              return numeral(val).format(0, 0) + '分钟'
            }
          }
        })
      }

      c3.line().position(`${scaleXProp}*${scaleYProp}`).shape('smooth')
      c3.render()
      //#endregion
    }
  }
}
</script>

<style scoped lang="scss" src="./user-reporting.style.scss"></style>
