<template>
  <page-layout>
    <page-header :breadcrumb="['资源统计']"> </page-header>

    <page-main>
      <el-form class="page-search" inline>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="year"
            format="yyyy年"
            :picker-options="pickerOptions"
            size="small"
            :clearable="false"
            style="width: 240px"
          />
        </el-form-item>
      </el-form>

      <table class="table">
        <thead>
          <tr>
            <th>资源</th>
            <th>数量</th>
            <th>总容量</th>
            <th>年更新量</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>图书</td>
            <td>50000 本</td>
            <td>0.25 T</td>
            <td>1800 本</td>
          </tr>
          <tr>
            <td>期刊</td>
            <td>6500 种</td>
            <td>0.15 T</td>
            <td>6500 册</td>
          </tr>
        </tbody>
      </table>
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import moment from 'moment'

export default {
  data() {
    return {
      date: moment().year(2022).toDate(),
      pickerOptions: {
        disabledDate(val: Date) {
          return ![2018, 2019, 2020, 2021, 2022, 2023].includes(
            moment(val).year()
          )
        }
      }
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.table {
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    text-align: center;
  }
}
</style>
