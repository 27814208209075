<template>
  <div class="section-item">
    <div class="label">
      <el-tag :type="labelType">
        {{ data.config.groupName || data.config.name }}
      </el-tag>
    </div>
    <div class="content">
      <div class="title">
        {{ data.name || data.config.name }}
      </div>
      <div class="desc">
        <template v-if="data.config.type === 'project'">
          {{ data.is_private ? '本馆' : '公共' }}书单:
          {{ data.original_name || data.name }}
        </template>
        <template v-else-if="data.config.type === 'tag'">
          {{ data.is_private ? '本馆' : '公共' }}分类:
          {{ data.original_name || data.name }}
        </template>
        <template v-else-if="data.config.type === 'label'">
          标签: {{ data.original_name || data.name }}
        </template>
        <template v-else-if="data.config.type === 'tag_group'">
          分类({{ data.tags.length }}): {{ data.tags | join('name') }}
        </template>
        <template v-else>
          {{ data.config.desc }}
        </template>
      </div>
    </div>
    <div class="action" v-if="!disabledAction">
      <el-button type="text" @click="onEditClick"> 编辑 </el-button>
      <el-button type="text" @click="onRemoveClick"> 隐藏 </el-button>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    data: { required: true, type: Object },
    disabledAction: { type: Boolean, default: false },
    onEdit: { require: true, type: Function },
    onRemove: { require: true, type: Function }
  },
  computed: {
    labelType() {
      switch (this.data.config.type) {
        case 'project':
        case 'project-tag':
          return ''
        case 'tag':
          return 'success'
        default:
          return 'danger'
      }
    },
    desc() {
      switch (this.data.config.type) {
        case 'project':
        case 'project-tag':
        case 'tag':
          return this.data.name
        default:
          return this.data.config.desc
      }
    }
  },
  methods: {
    onEditClick() {
      this.$emit('edit', this.data)
    },
    onRemoveClick() {
      this.$emit('remove', this.data)
    }
  }
}
</script>

<style scoped lang="scss" src="../home-section.style.scss"></style>
