<template>
  <el-cascader
    v-model="currentValue"
    :options="items"
    :props="{
      label: 'name',
      value: 'id'
    }"
    :change-on-select="false"
    :clearable="clearable"
    :disabled="disabled"
    placeholder="请选择"
    :size="size"
    :style="{ width }"
    @change="onChange"
    popper-class="cascader-tag-style"
  />
</template>
<script lang="ts">
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  props: {
    value: { type: Array },
    filterable: { default: false },
    clearable: { default: false },
    disabled: { default: false },
    size: { default: '' },
    width: { type: [Number, String] },

    formLos: { type: Boolean, default: false },
    isPro: { type: Boolean, default: false }
  },

  data() {
    return {
      currentValue: this.value,
      items: []
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    },
    currentValue() {
      this.$emit('input', this.currentValue)
    },
    isPro() {
      this.fetch()
    }
  },

  async mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      if (this.formLos) {
        const { data } = await GET('los/credit/tag', {
          data: {
            is_professional: this.isPro ? 1 : 0
          }
        })
        this.items = data
      } else {
        const { data } = await GET('credit/tag', {})
        this.items = data
      }
    },
    onChange(val) {
      this.currentValue = val
    }
  }
}
</script>

<style lang="scss"></style>
