<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑文件夹' : '新增文件夹'"
    width="800px"
    append-to-body
  >
    <el-form
      v-if="edit.visible"
      :model="edit.data"
      :rules="rules"
      ref="form"
      style="margin-bottom: 20px"
    >
      <el-form-item label="文件夹名称" prop="name">
        <el-input v-model.trim="edit.data.name" :disabled="readonly" />
      </el-form-item>
    </el-form>

    <div class="book-and-folder-list">
      <div class="col" v-for="item in edit.data.books" :key="item.id">
        <div class="book-list__item">
          <div class="thumb thumb--small">
            <img :src="item.image" alt="" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.author | join }}
            </div>
            <div class="action" v-if="!readonly">
              <el-button type="text" @click="onBookRemove(item)">
                删除
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!readonly"
      slot="footer"
      style="display: flex; align-items: center"
    >
      <div style="flex: auto; text-align: left">
        <el-button type="primary" @click="bookSelectVisible = true">
          添加图书
        </el-button>
      </div>
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>

    <book-select-dialog
      :visible.sync="bookSelectVisible"
      @confirm="onBookSelectConfirm"
    />
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store from '../preset-book-send.store'
import BookSelectDialog from '@/components/book-selector/book-selector-dialog.component.vue'
import { Book } from '../../../admin-shared-modules/typings'

export default {
  components: {
    BookSelectDialog
  },

  props: {
    readonly: { type: Boolean, default: false }
  },

  data() {
    return {
      edit: store.folderEdit,
      rules: {
        name: [
          { required: true, message: '请填写' },
          { min: 2, message: '长度需大于 2' }
        ]
      },

      bookSelectVisible: false
    }
  },

  methods: {
    onBookSelectConfirm(books: Book[]) {
      this.edit.data.books.push(...books)
    },
    onBookRemove(item: Book) {
      this.edit.data.books = _.reject(this.edit.data.books, b => {
        return b.id === item.id
      })
    },

    onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        this.edit.visible = false
        store.onFolderEditSubmit()
      })
    }
  }
}
</script>

<style scoped></style>
