import { GET } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'
import { LibraryMember, DeviceUser } from '@/admin-shared-modules/typings'

type CombinedMemberUserParams = LibraryMember | DeviceUser
function isMember(d: CombinedMemberUserParams): d is LibraryMember {
  return (<LibraryMember>d).userId !== undefined
}

class UserReportingStore {
  state = {
    params: null as CombinedMemberUserParams,
    loading: false,

    bookCount: {
      dayRead: 0,
      weekRead: 0,
      monthRead: 0,
      totalRead: 0
    },
    readTime: {
      dayReadTime: 0,
      weekReadTime: 0,
      monthReadTime: 0,
      totalReadTime: 0
    },
    hourData: [],
    trend: [],
    tag: []
  }

  async fetch() {
    this.state.loading = true
    this.state.bookCount = {
      dayRead: 0,
      weekRead: 0,
      monthRead: 0,
      totalRead: 0
    }
    this.state.readTime = {
      dayReadTime: 0,
      weekReadTime: 0,
      monthReadTime: 0,
      totalReadTime: 0
    }
    this.state.hourData = []
    this.state.tag = []
    this.state.trend = []

    try {
      const params = this.state.params as CombinedMemberUserParams
      const url = isMember(params)
        ? `user/${params.userId}/report_library`
        : `user/${params.id}/report`

      const res = await GET(url, {})

      this.state.bookCount = res.data.bookCount
      this.state.readTime = res.data.readTime
      this.state.hourData = res.data.hourData
      this.state.tag = _.chain(res.data.tag)
        .orderBy(['books_num'], ['desc'])
        .take(15)
        .reverse()
        .value()
      this.state.trend = res.data.trend
    } catch (e) {
    } finally {
      this.state.loading = false
    }
  }
}
export default new UserReportingStore()
