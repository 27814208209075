import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { ref } from 'vue'

export function useUpload(options: { onUpload: (url: string) => void }) {
  const uploading = ref(false)

  function onProgress(event, file) {
    uploading.value = true
  }

  function onSuccess(res, file) {
    uploading.value = false
    const url = res.data?.url || res.data?.file
    options.onUpload(url)
  }

  function onError(err, file) {
    uploading.value = false
    MessageBoxService.alert({
      type: 'error',
      message: '上传失败'
    })
  }

  return {
    uploading,
    'with-credentials': true,
    'on-progress': onProgress,
    'on-success': onSuccess,
    'on-error': onError
  }
}
