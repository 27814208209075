<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="姓名">
      <el-input v-model="search.data.user_name" clearable />
    </el-form-item>
    <el-form-item label="学号">
      <el-input v-model="search.data.card_num" clearable />
    </el-form-item>
    <el-form-item label="专业">
      <el-input v-model="search.data.profession" clearable />
    </el-form-item>
    <el-form-item label="入学年份">
      <year-selector v-model="search.data.year" clearable />
    </el-form-item>
    <el-form-item label="书名">
      <el-input v-model="search.data.title" clearable />
    </el-form-item>
    <el-form-item label="ISBN">
      <el-input v-model="search.data.isbn_13" clearable />
    </el-form-item>
    <el-form-item v-if="search.data.note_status === 2" label="等级">
      <el-select v-model="search.data.level" clearable>
        <el-option
          v-for="item in levelOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import _ from 'lodash'
import { store } from './store'
import YearSelector from '@/components/credit-year-selector.vue'

export default {
  components: {
    YearSelector
  },

  data() {
    return {
      search: store.search
    }
  },

  computed: {
    levelOptions() {
      return _.map(store.scoreLevelConfigs, (c, index) => {
        return {
          label: c.level,
          value: index
        }
      })
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
