<template>
  <page-layout>
    <page-header :breadcrumb="['期刊推荐']" />
    <page-main v-loading="state.list.loading">
      <div class="tab-action-bar">
        <el-tabs v-model="state.list.tagGroup.currentId">
          <el-tab-pane
            v-for="(item, index) in state.list.tagGroup.items"
            :key="index"
            :label="item.name"
            :name="`${item.id}`"
          />
        </el-tabs>
      </div>

      <page-action-bar>
        <el-button
          v-if="!sorting"
          type="primary"
          size="small"
          @click="onAddClick"
        >
          添加期刊
        </el-button>

        <template v-if="!sorting">
          <el-button size="small" @click="onSortingClick">
            <i class="el-icon-sort" /> 推荐排序
          </el-button>
        </template>
        <template v-if="sorting">
          <el-button type="primary" size="small" @click="onSortingConfirm">
            <i class="el-icon-edit" /> 保存排序
          </el-button>
          <el-button size="small" @click="cancelSorting"> 取消 </el-button>
        </template>
      </page-action-bar>

      <div
        class="empty-container"
        v-if="!state.list.loading && !state.list.items.length"
      >
        暂无数据
      </div>

      <draggable
        class="book-list"
        v-model="state.list.items"
        :options="{ ghostClass: 'book-list__item--dragging', sort: sorting }"
      >
        <div
          class="book-list__item"
          v-for="item in state.list.items"
          :key="item.id"
        >
          <div class="drag-handler" v-show="sorting">
            <i class="el-icon-sort" />
          </div>
          <div class="thumb">
            <img :src="item.coverurl" alt="" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.magname }}
            </div>
            <div class="desc">
              {{ item.unit }}
            </div>
          </div>
          <div class="action">
            <el-button type="text" @click.stop="onRemoveClick(item)">
              取消推荐
            </el-button>
          </div>
        </div>
      </draggable>

      <magazine-select-dialog
        :visible.sync="dialogVisible"
        @confirm="onDialogConfirm"
      />
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import Draggable from 'vuedraggable'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import store from './magazine-recommend.store'
import MagazineSelectDialog from './shared/magazine-select-dialog.component.vue'

export default {
  components: {
    Draggable,
    MagazineSelectDialog
  },

  data() {
    return {
      state: store.state,
      sorting: false,
      dialogVisible: false
    }
  },
  watch: {
    'state.list.tagGroup.currentId'() {
      store.fetch()
    }
  },

  mounted() {
    this.dialogVisible = false
    store.fetch()
  },

  methods: {
    onAddClick() {
      this.dialogVisible = true
    },
    async onDialogConfirm(items) {
      await store.onAdd(items)
      MessageService.open({ message: '添加成功' })
    },

    async onRemoveClick(item) {
      await MessageBoxService.confirm({ message: `确认删除该推荐吗` })
      await store.onRemove(item)
      MessageService.open({ message: '删除成功' })
      store.fetch()
    },

    onSortingClick() {
      this.sorting = true
    },
    async onSortingConfirm() {
      await store.saveSorting()
      this.sorting = false
    },
    cancelSorting() {
      this.sorting = false
      store.fetch()
    }
  }
}
</script>
