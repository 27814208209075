<template>
  <div>
    <div class="search">
      <el-radio-group v-model="groupId" size="small">
        <el-radio-button v-for="g in groups" :key="g.id" :label="g.id">
          {{ g.name }}
        </el-radio-button>
        <el-radio-button :label="0"> 本馆分类 </el-radio-button>
      </el-radio-group>
    </div>
    <div class="checkbox-list">
      <div
        v-for="(item, index) in currentItems"
        :key="index"
        class="item"
        :class="{ checked: item.selected, disabled: item.disabled }"
        @click="onCheck(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="!isPublic && !currentItems.length">
      您还未创建本馆分类，可在“图书资源管理/图书分类”中新增
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { GET } from '../../../admin-shared-modules/utils/ajax'

export default {
  props: {
    disabledItems: { type: Array }
  },
  data() {
    return {
      groupId: null,
      groups: [],
      items: {},
      // privateItems: [],
      currentItems: [],
      // currentSelected: [],
      currentDisabledItems: []
    }
  },
  computed: {
    isPublic() {
      return this.groupId !== 0
    },
    currentSelected() {
      return _.flatten(_.values(this.items)).filter(it => it.selected)
    }
  },
  watch: {
    groupId() {
      this.setCurrentItems()
    },
    currentSelected() {
      this.$emit('change', this.currentSelected)
    }
  },

  mounted() {
    this.currentDisabledItems = this.disabledItems
    this.fetchGroups()
    this.fetch()
  },

  methods: {
    setCurrentItems() {
      this.currentItems = this.isPublic
        ? this.items[this.groupId]
        : this.items[0]
    },
    async fetchGroups() {
      const res = await GET('tag/parent')
      this.groups = res.data
    },
    async fetch() {
      const res1 = await GET('tag', {
        data: {
          public: 1
        }
      })
      this.items = _.reduce(
        res1.data,
        (obj, value, key) => {
          obj[key] = _.map(value, item => {
            return {
              ...item,
              selected: false,
              disabled: !!_.find(this.currentDisabledItems, { id: item.id })
            }
          })
          return obj
        },
        {}
      )

      const res2 = await GET('tag', {
        data: {
          public: 0
        }
      })
      this.$set(
        this.items,
        0,
        _.map(res2.data, item => {
          return {
            ...item,
            selected: false,
            disabled: !!_.find(this.currentDisabledItems, { id: item.id })
          }
        })
      )
    },
    onCheck(item) {
      if (item.disabled) return
      item.selected = !item.selected
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
.search {
  margin-bottom: 16px;
}
.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    flex: 0 0 calc(25% - 32px);
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    line-height: 1.4;
    cursor: pointer;
    &.checked {
      border-color: $--color-primary;
      color: $--color-primary;
    }
    &.disabled {
      background-color: #eee;
      border-color: #ccc;
      color: $--color-text-primary;
      cursor: not-allowed;
    }
  }
}
</style>
