import {
  AJAXErrorResult,
  DELETE,
  GET,
  POST
} from '@/admin-shared-modules/utils/ajax'
import { Device, EditState, ListState } from '@/admin-shared-modules/typings'
import LoadingService from '@/admin-shared-modules/utils/loading.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

interface DeviceFormData {
  padbookType: string
  padbookCode: string
  padbookMac: string
  padbookVersion: string
  padbookCapacity: string
}

class Store {
  list: ListState<Device> = {
    items: [],
    index: 1,
    size: 50,
    total: 0,
    loading: true
  }
  search = {
    data: {
      code: null
    }
  }
  edit: EditState<DeviceFormData> = {
    isEdit: false,
    visible: false,
    data: {} as DeviceFormData,
    loading: false
  }
  importer: EditState<any> = {
    isEdit: false,
    visible: false,
    data: {},
    loading: false
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('reader/device', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          ...this.search.data
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  onAdd() {
    this.edit.isEdit = false
    this.edit.visible = true
    this.edit.data = {
      padbookType: '',
      padbookCode: '',
      padbookMac: '',
      padbookVersion: '',
      padbookCapacity: ''
    }
  }
  async onEditSubmit() {
    LoadingService.create('保存中...')
    const data = {
      ...this.edit.data,
      padbookCapacity: `${this.edit.data.padbookCapacity}GB`
    }
    try {
      const res = await POST('reader/device', {
        data
      })
      MessageService.open({ message: '保存成功' })
      this.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
      throw e
    } finally {
      LoadingService.close()
    }
  }

  async onRemove(data: Device) {
    LoadingService.create()
    try {
      await POST(`reader/device/delete`, {
        data: { device_ids: [data.padbookId] }
      })
    } catch (e) {
      const ex: AJAXErrorResult = e
      if (e.handled) return
      if (e.message === '删除失败') e.message = '设备正在使用, 无法删除'
      defaultErrorHandler(e)
      throw e
    } finally {
      LoadingService.close()
    }
  }

  onImport() {
    this.importer.visible = true
  }
}

export default new Store()
