<template>
  <div class="borrowing-summary-form-container">
    <div class="left">
      <div class="panel-header">
        <div class="title">设备信息</div>
      </div>
      <el-form
        ref="form"
        class="borrowing-summary-form"
        label-width="8em"
        style="margin-left: 40px; margin-bottom: 40px"
      >
        <el-form-item label="设备型号">
          {{ data.device.padbookType }}
        </el-form-item>
        <el-form-item label="设备编号">
          {{ data.device.padbookCode }}
        </el-form-item>
        <el-form-item label="系统版本">
          {{ data.device.padbookVersion }}
        </el-form-item>
        <el-form-item label="MAC地址">
          {{ data.device.padbookMac }}
        </el-form-item>
        <el-form-item label="存储容量">
          {{ data.device.padbookCapacity }}
        </el-form-item>
      </el-form>
    </div>
    <div class="right">
      <div class="panel-header">
        <div class="title">用户信息</div>
      </div>
      <el-form
        ref="form"
        class="borrowing-summary-form"
        label-width="8em"
        style="margin-left: 40px"
      >
        <el-form-item label="借阅证号">
          {{ data.member.cardNum }}
        </el-form-item>
        <el-form-item label="姓名">
          {{ data.member.userName }}
        </el-form-item>
        <el-form-item label="身份证号">
          {{ data.member.certCode }}
        </el-form-item>
        <el-form-item label="是否缴纳押金">
          {{ data.member.deposit }}
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return { device: {}, member: {} }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.borrowing-summary-form {
  ::v-deep .el-form-item {
    margin-bottom: 8px;
    > * {
      line-height: 1.6;
    }
  }
}
</style>
