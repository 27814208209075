export const DeviceBorrowingStateNames = {
  all: '全部',
  lend: '借出',
  store: '在馆'
}
export type DeviceBorrowingState = keyof typeof DeviceBorrowingStateNames

export const DeviceBorrowingLogStateNames = {
  1: '借出',
  2: '归还'
}

export const LibDeviceTypeMap = {
  READER: 1,
  KINDLE: 2
}

export const LibLendOutTypeMap = {
  NO_READER: 0, //无阅读本
  BY_SHELF: 1, //借还柜
  BY_MANUAL: 2 //人工借还
}
