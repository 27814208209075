<template>
  <page-layout>
    <page-header :breadcrumb="['机构信息']" />

    <page-main>
      <div class="panel-header">
        <div class="title">基本信息</div>
      </div>

      <el-form
        ref="form"
        :model="lib.data"
        label-width="100px"
        v-loading="lib.loading"
        style="width: 480px; margin-left: 120px"
      >
        <el-form-item label="Logo">
          <div class="cover-uploader">
            <img v-if="lib.data.logo" :src="lib.data.logo" alt="" />
          </div>
          <div class="el-upload__tip">建议尺寸150*150，且不超过100kb</div>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model.trim="lib.data.name" readonly />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model.trim="lib.data.phone" readonly />
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model.trim="lib.data.address" readonly />
        </el-form-item>
        <el-form-item label="学习通邀请码" prop="invite_code">
          <el-input v-model.trim="lib.data.invite_code" readonly />
        </el-form-item>
      </el-form>
    </page-main>

    <page-main v-if="practiceIntro.enable">
      <div class="panel-header">
        <div class="title">文明实践中心</div>
      </div>

      <el-form
        ref="form"
        :model="lib.data"
        :rules="settingRules"
        label-width="100px"
        v-loading="lib.loading"
        style="width: 700px; margin-left: 120px"
      >
        <el-form-item label="简介" prop="practice_intro">
          <el-input
            v-model="lib.data.practice_intro"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="setting.saving"
            @click="onConfigSubmit"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </page-main>

    <page-main v-if="false">
      <div class="panel-header">
        <div class="title">借阅设置</div>
      </div>

      <el-form
        ref="settingForm"
        :model="setting.data"
        :rules="settingRules"
        label-width="140px"
        v-loading="setting.loading"
        style="width: 480px; margin-left: 80px"
      >
        <el-form-item label="借阅周期（天）" prop="period">
          <el-input-number
            v-model.number="setting.data.period"
            controls-position="right"
            :min="1"
            :max="366"
          />
        </el-form-item>
        <el-form-item label="设备超期锁定" prop="lockOnExpire">
          <el-switch v-model="setting.data.lockOnExpire" />
        </el-form-item>
        <el-form-item label="押金二维码" prop="deposit_code">
          <el-input v-model.trim="setting.data.deposit_code" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="setting.saving"
            @click="onSettingSubmit"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import _ from 'lodash'
import { GET, PUT } from '../../admin-shared-modules/utils/ajax'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '../../admin-shared-modules/utils'
import { appStore } from '@/store'

export default {
  data() {
    return {
      lib: {
        data: {},
        loading: false
      },
      setting: {
        data: {
          period: 30,
          lockOnExpire: appStore.state.lib.data.is_lock
        },
        loading: false,
        saving: false
      },
      practiceIntro: {
        enable: appStore.state.lib.data.lib_type === 6
      },

      settingRules: {
        period: [{ require: true, type: 'number', message: '请填写数字' }],
        deposit_code: {}
      }
    }
  },

  async mounted() {
    await this.fetchBaseInfo()
    await this.fetchSetting()
    this.$nextTick(() => {
      this.$refs.settingForm.clearValidate()
    })
  },

  methods: {
    async fetchBaseInfo() {
      this.lib.loading = true
      const res = await GET(`setting`, {})
      this.lib.data = res.data
      this.lib.loading = false
    },
    async fetchSetting() {
      this.setting.loading = true
      const res = await GET(`reader/lend/period`, {})
      this.setting.data.period = _.parseInt(res.data.days) || ''
      this.setting.loading = false
    },

    async onConfigSubmit() {
      this.setting.saving = true
      try {
        await PUT('setting', {
          data: this.lib.data
        })
        MessageService.open({ message: '保存成功' })
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.setting.saving = false
      }
    },

    async onSettingSubmit() {
      this.setting.saving = true
      try {
        await PUT('setting', {
          data: { is_lock: this.setting.data.lockOnExpire }
        })
        appStore.state.lib.data.is_lock = this.setting.data.lockOnExpire

        await PUT(`reader/lend/period`, {
          data: { days: this.setting.data.period }
        })
        MessageService.open({ message: '保存成功' })
      } catch (e) {
        defaultErrorHandler(e)
      } finally {
        this.setting.saving = false
      }
    }
  }
}
</script>

<style scoped>
.cover-uploader {
  align-items: center;
  justify-content: center;
  width: 150px;
  min-height: 150px;
}
</style>
