<template>
  <el-dialog
    :visible.sync="state.visible"
    :title="state.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    top="6vh"
    width="800px"
    :close-on-click-modal="false"
    @open="onDialogOpen"
  >
    <el-steps
      v-if="!state.isEdit"
      :active="state.step"
      :align-center="true"
      center
      finish-status="success"
    >
      <el-step title="上传图书" />
      <el-step title="选择图书分类" />
      <el-step title="填写图书信息" />
      <el-step title="完成" />
    </el-steps>

    <div class="drop-uploader" v-if="state.step === 0" v-loading="isUploading">
      <el-upload
        action="/api/v1/admin/upload/ebook"
        drag
        :show-file-list="false"
        with-credentials
        :before-upload="beforeFileUpdate"
        :on-success="onFileUploadSuccess"
        :on-error="onFileUploadError"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          支持epub/pdf格式文件，大小不超过100M
        </div>
      </el-upload>
    </div>

    <div class="book-sort-step" v-if="state.step === 1">
      <div class="book-sort-step__section1">
        <div v-if="tagIdArray.length == 0">请点击选择此图书所属的分类</div>
        <div class="section1__select-tag" v-else>
          <div>已选中:</div>
          <div class="tag">
            <el-tag
              v-for="tag in tags"
              :key="tag.tag_id"
              closable
              @close="onRemoveTag(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
      </div>
      <div class="book-sort-step__section2">
        <book-sort-selector
          v-model="tagIdArray"
          @sortChange="sortChangeSelect"
        />
      </div>
    </div>

    <el-form
      v-if="state.step === 2"
      ref="form"
      :model="state.data"
      :show-message="false"
      label-width="100px"
      v-loading="isUploading"
    >
      <div class="form-row">
        <div class="left">
          <div class="book-thumb">
            <el-upload
              class="cover-uploader"
              action="/api/v1/admin/upload/image"
              accept="image/*"
              :show-file-list="false"
              :before-upload="beforeCoverUpload"
              :on-success="onCoverUploadSuccess"
              :on-error="onCoverUploadError"
            >
              <img v-if="state.data.image" :src="coverImageUrl" />
              <i v-else class="el-icon-plus uploader-icon" />
            </el-upload>
            <div class="el-upload__tip" slot="tip">
              只能上传图片文件，建议尺寸180*240，且不超过500kb
            </div>
          </div>
        </div>

        <div class="right">
          <div class="form-row__info-tag">基本信息</div>
          <el-form-item label="书名" prop="title" :rules="[{ required: true }]">
            <el-input v-model="state.data.title" />
          </el-form-item>
          <el-form-item
            label="作者"
            prop="authorStr"
            :rules="[{ required: true }]"
          >
            <el-input v-model="state.data.authorStr" />
            <div class="el-upload__tip">多个作者使用逗号分隔</div>
          </el-form-item>

          <div class="form-row__info-tag">出版信息</div>
          <el-form-item
            label="ISBN"
            prop="isbn_13"
            :rules="[{ required: true }]"
          >
            <div style="display: flex">
              <el-input
                v-model="state.data.isbn_13"
                maxlength="13"
                style="flex: 1; margin-right: 10px"
              />
              <el-button
                :disabled="
                  !state.data.isbn_13 || state.data.isbn_13.length !== 13
                "
                @click="fetchBookInfoByISBN"
              >
                获取
              </el-button>
            </div>
            <div class="el-upload__tip">点击[获取]会尝试抓取图书信息</div>
          </el-form-item>

          <el-form-item
            label="出版社"
            prop="publisher"
            :rules="[{ required: true }]"
          >
            <el-input v-model="state.data.publisher" />
          </el-form-item>
          <el-form-item
            label="出版时间"
            prop="pub_date"
            :rules="[{ required: true }]"
          >
            <el-date-picker
              v-model="state.data.pub_date"
              type="date"
              :editable="false"
              :clearable="false"
              style="width: 100%"
            />
          </el-form-item>

          <div class="form-row__info-tag">内容详情</div>
          <el-form-item label="页数" prop="pages">
            <el-input v-model="state.data.pages" />
          </el-form-item>
          <el-form-item
            label="简介"
            prop="summary"
            :rules="[{ required: true }]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 10 }"
              v-model="state.data.summary"
            />
          </el-form-item>
          <el-form-item label="目录" prop="catalog">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 10 }"
              v-model="state.data.catalog"
            />
          </el-form-item>

          <el-form-item
            label="电子书文件"
            prop="epub_file"
            :rules="[{ required: true }]"
            style="margin-top: 40px"
          >
            <el-tag
              v-if="state.data.epub_file"
              :disable-transitions="true"
              :closable="true"
              @close="onFileRemoveClick"
            >
              {{ state.data.epub_file }}
            </el-tag>

            <el-upload
              v-else
              action="/api/v1/admin/upload/ebook"
              drag
              :show-file-list="false"
              with-credentials
              :before-upload="beforeFileUpdate"
              :on-success="onFileUploadSuccess"
              :on-error="onFileUploadError"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传epub/pdf/text文件，且不超过200mb
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            label="图书分类"
            prop="tags.length"
            :rules="[{ required: true }]"
          >
            <el-tag
              style="margin-right: 10px"
              v-for="tag in tags"
              :key="tag.tag_id"
              closable
              @close="onRemoveTag(tag)"
            >
              {{ tag.name }}
            </el-tag>
            <el-button size="small" type="primary" @click="onTagAddClick()">
              新增分类
            </el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <div slot="footer" v-if="state.step === 1">
      <el-button @click="state.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmitStep"> 保存 </el-button>
    </div>
    <div slot="footer" v-if="state.step === 2">
      <el-button @click="state.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>

    <el-dialog
      :visible.sync="sortManageVisible"
      title="分类管理"
      width="600px"
      append-to-body
      :before-close="cancelFix"
      class="sortManage"
    >
      <div class="book-sort-step">
        <div class="book-sort-step__section1">
          <div v-if="tagIdArray.length == 0">请点击选择此图书所属的分类</div>
          <div class="section1__select-tag" v-else>
            <div>已选中:</div>
            <div class="tag">
              <el-tag
                v-for="tag in changeSelectTag"
                :key="tag.tag_id"
                closable
                @close="onRemoveTag(tag)"
              >
                {{ tag.name }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="book-sort-step__section2">
          <book-sort-selector
            v-model="tagIdArray"
            @sortChange="sortChangeSelect"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button @click="cancelFix"> 取消 </el-button>
        <el-button type="primary" @click="onChangeSelectTagSubmit">
          保存
        </el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script lang="ts">
import _ from 'lodash'
import store, { UploadResponse } from './book.store'
import { MessageBoxService } from '../../admin-shared-modules/utils/message-box.service'
import { MessageService } from '../../admin-shared-modules/utils/message.service'
import BookCateSelector from './shared/book-cate-selector.vue'
import LoadingService from '../../admin-shared-modules/utils/loading.service'
import BookSortSelector from './shared/book-sort-selector.vue'

export default {
  components: { BookCateSelector, BookSortSelector },

  data() {
    return {
      state: store.state.edit,
      isUploading: false,
      tagIdArray: [], //选中的分类id
      //tags: store.state.edit.tags,//选中的分类标签所有信息
      changeSelectTag: [], //修改后的分类标签
      sortManageVisible: false
    }
  },
  computed: {
    coverImageUrl() {
      return this.state.data.image
    },
    tags() {
      return this.state.tags
    }
  },

  methods: {
    onDialogOpen() {
      if (this.state.isEdit) {
        this.tagIdArray = this.state.data.tagIdArray
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      } else {
        this.tagIdArray = []
      }
    },
    //#region step0start
    sortChangeSelect(tags) {
      console.log(tags)
      if (this.sortManageVisible) this.changeSelectTag = _.cloneDeep(tags)
      else this.state.tags = _.cloneDeep(tags)
      console.log(this.tags)
    },
    onRemoveTag(tag) {
      if (this.sortManageVisible) {
        this.changeSelectTag.splice(
          this.changeSelectTag.indexOf(
            _.find(this.changeSelectTag, item => {
              return item.tag_id == tag.tag_id
            })
          ),
          1
        )
        this.tagIdArray = _.map(this.changeSelectTag, 'tag_id')
      } else {
        this.state.tags.splice(
          this.state.tags.indexOf(
            _.find(this.state.tags, item => {
              return item.tag_id == tag.tag_id
            })
          ),
          1
        )
        this.tagIdArray = _.map(this.state.tags, 'tag_id')
      }
      console.log(this.state.tags, this.tagIdArray)
    },
    //#region step0end
    onSubmitStep() {
      if (this.tagIdArray.length == 0)
        MessageService.open({ type: 'error', message: '请选择图书分类' })
      else this.state.step = 2
    },
    //#region file upload
    beforeFileUpdate(file: File) {
      if (!/\.epub|pdf|txt$/.test(file.name)) {
        MessageService.open({ type: 'error', message: '文件格式无效' })
        return false
      }
      if (file.size / 1024 / 1024 > 200) {
        MessageService.open({ type: 'error', message: '文件大小不能超过200mb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onFileUploadError(err, file: File) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    },
    async onFileUploadSuccess(res, file: File) {
      console.log(res, file)
      this.isUploading = false

      const uploadResponse: UploadResponse = res.data
      store.setBookInfoFromUploadResponse(uploadResponse)
      const isbn = _.get(uploadResponse, 'metadata.identifier.ISBN')

      this.state.data.file_size = file.size

      if (!this.state.isEdit) {
        if (isbn) {
          this.isUploading = true
          try {
            await store.fetchBookInfo(isbn)
          } catch (e) {
            console.error(e)
          } finally {
            this.isUploading = false
          }
        }
        if (this.state.step == 2) return
        this.state.step = 1
      }
    },
    onFileRemoveClick() {
      this.state.data.epub_file = null
    },
    //#endregion

    //#region cover upload
    beforeCoverUpload(file: File) {
      if (!/^image\//.test(file.type)) {
        MessageService.open({ type: 'error', message: '图片格式无效' })
        return false
      }
      if (file.size / 1024 > 500) {
        MessageService.open({ type: 'error', message: '图片大小不能超过500kb' })
        return false
      }
      this.isUploading = true
      return true
    },
    onCoverUploadError(err, file: File) {
      this.isUploading = false
      MessageBoxService.alert({ type: 'error', message: err.toString() })
    },
    onCoverUploadSuccess(res, file: File) {
      this.isUploading = false
      this.state.data.image = res.data.url //  `/attachments${_.get(res, 'data.file')}`
    },
    //#endregion

    async fetchBookInfoByISBN() {
      LoadingService.create('正在获取...')
      try {
        await store.fetchBookInfo()
      } catch (e) {
        console.error(e)
        MessageService.open({ type: 'warning', message: '无法查找到图书信息' })
      } finally {
        LoadingService.close()
      }
    },

    //#region tags
    isSortManageVisible() {
      this.sortManageVisible = !this.sortManageVisible
    },
    onTagAddClick() {
      this.sortManageVisible = true
      this.changeSelectTag = _.cloneDeep(this.state.tags)
      this.tagIdArray = _.map(this.state.tags, 'tag_id')
    },
    onChangeSelectTagSubmit() {
      this.state.tags = _.cloneDeep(this.changeSelectTag)
      this.tagIdArray = _.map(this.changeSelectTag, 'tag_id')
      this.isSortManageVisible()
      console.log(this.state.tags, this.tagIdArray)
    },
    cancelFix() {
      this.tagIdArray = _.map(this.state.tags, 'tag_id')
      this.isSortManageVisible()
    },
    //#endregion

    onSubmit() {
      this.$refs['form'].validate(async v => {
        if (!v) {
          MessageService.open({ type: 'error', message: '请检查填写内容' })
          return
        }

        //check file
        if (!this.state.data.epub_file) {
          MessageService.open({ type: 'error', message: '请上传电子书文件' })
          return
        }

        if (!this.state.data.image) {
          MessageService.open({ type: 'error', message: '请上传缩略图' })
          return
        }
        //check tag
        if (this.state.tags.length == 0) {
          MessageService.open({ type: 'error', message: '请上选择图书分类' })
          return
        }

        this.state.data.tagIdArray = this.tagIdArray
        store.onEditSubmit()
      })
    }
  }
}
</script>

<style scoped lang="scss" src="./book.style.scss"></style>
