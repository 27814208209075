<template>
  <page-layout>
    <page-header :breadcrumb="['学分认定', '学分评定']" />

    <page-main v-loading="loading">
      <page-action-bar>
        <el-tabs
          :value="`${state.year.currentYearId}`"
          type="card"
          @tab-click="onYearChange"
        >
          <el-tab-pane
            v-for="item in state.year.items"
            :key="item.id"
            :label="`${item.year}入学`"
            :name="`${item.id}`"
          />
        </el-tabs>
      </page-action-bar>

      <search />

      <el-table ref="table" :data="list.items" size="small">
        <el-table-column
          type="index"
          width="50"
          :index="index => list.indexMethod(index)"
        />
        <el-table-column label="学号" prop="card_num" />
        <el-table-column label="学生姓名" prop="user_name" />
        <el-table-column label="入学年份" prop="year" />
        <el-table-column label="考核周期">
          <template v-slot="{ row }">
            {{ list.termName }}
          </template>
        </el-table-column>
        <el-table-column label="附加条件">
          <template v-slot="{ row }">
            <span v-if="row.attach_require === 1" class="blue">已完成</span>
            <span v-else-if="row.attach_require === 2" class="red">未完成</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template v-slot="{ row }">
            <el-button
              v-show="list.showReviewBtn"
              type="text"
              @click="e => edit.onEdit(row)"
            >
              开始审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>
    <edit />
  </page-layout>
</template>

<script lang="ts">
import { store } from './store'
import Search from './search.vue'
import Edit from './edit.vue'

export default {
  components: {
    Search,
    Edit
  },
  data() {
    return {
      loading: false,
      state: store,
      list: store.list,
      edit: store.edit
    }
  },

  async created() {
    this.loading = true
    await store.year.fetch()
    await store.year.fetchCurrentYearConfig()
    await store.year.fetchCurrentYearAuthState()
    await store.list.fetch({ reset: true })
    this.loading = false
  },

  methods: {
    async onYearChange({ name: yearId }) {
      this.state.year.currentYearId = +yearId
      this.loading = true
      try {
        await store.year.fetchCurrentYearConfig()
        await store.year.fetchCurrentYearAuthState()
        await store.list.fetch({ reset: true })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
