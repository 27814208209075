<template>
  <page-layout>
    <page-header :breadcrumb="['统计报表', '图书更新统计']"> </page-header>

    <page-main>
      <el-form class="page-search" inline>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="year"
            format="yyyy年"
            :picker-options="pickerOptions"
            size="small"
            :clearable="false"
            style="width: 180px"
          />
        </el-form-item>
      </el-form>

      <div
        class="empty"
        v-if="!isLoading && !data.length"
        style="text-align: center; padding: 10px 0"
      >
        暂无数据
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>月份</th>
            <th>更新图书数</th>
            <th>更新目录</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.month">
            <td>{{ item.month }}月</td>
            <td>{{ item.count }}</td>
            <td><a :href="item.list_file" target="_blank">查看目录</a></td>
          </tr>
        </tbody>
      </table>
    </page-main>
  </page-layout>
</template>

<script setup lang="ts">
import { GET } from '@/admin-shared-modules/utils/ajax'
import { orderBy } from 'lodash'
import { ref, watch } from 'vue'

const pickerOptions = {
  disabledDate(time) {
    return time.getFullYear() < 2020 || time.getFullYear() > 2030
  }
}

const date = ref(new Date())
const isLoading = ref(false)
watch(
  date,
  () => {
    fetch(date.value.getFullYear())
  },
  {
    immediate: true
  }
)

const data = ref([])

async function fetch(year: number) {
  isLoading.value = true
  const res = await GET('monthly_records/list', {
    data: { year }
  })
  data.value = orderBy(res.data, 'month')
  isLoading.value = false
}
</script>

<style scoped lang="less">
.table {
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    text-align: center;
  }
}
</style>
