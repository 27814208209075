<template>
  <el-form class="page-search" inline>
    <el-form-item label="设备编号">
      <el-input v-model="search.data.code" size="small" clearable />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from './device.store'

export default {
  data() {
    return {
      search: store.search
    }
  },

  methods: {
    onSubmit() {
      store.fetch(true)
    }
  }
}
</script>
