<template>
  <page-layout class="page-user">
    <page-header :breadcrumb="['读者行为分析']">
      <inner-tabs v-if="isCXApp" />
    </page-header>

    <page-main>
      <el-tabs v-model="tabName">
        <el-tab-pane label="借阅读者" name="Borrower" />
        <el-tab-pane label="学习通用户" name="User" />
      </el-tabs>

      <component :is="tabName" />
    </page-main>
  </page-layout>
</template>

<script lang="ts">
import User from './user/user.component.vue'
import Borrower from './borrower/borrower.component.vue'
import { AppStateMixin } from '@/core/appStateMixin'

export default {
  mixins: [AppStateMixin],
  components: { User, Borrower },

  data() {
    return {
      tabName: 'Borrower'
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>
