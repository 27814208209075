<template>
  <div>
    <el-alert
      type="info"
      show-icon
      :closable="false"
      title="可拖拽图片进行排序"
      style="margin-bottom: 16px"
    />

    <div>
      <draggable
        :list="images"
        filter=".non-draggable"
        :move="checkMove"
        style="display: flex; flex-direction: row; flex-wrap: wrap"
      >
        <div
          v-for="(img, i) in images"
          :key="i"
          style="margin-right: 20px; margin-bottom: 20px"
          :style="{
            width: `${size.width}px`
          }"
        >
          <div
            style="background-size: cover; background-position: center"
            :style="{
              width: `${size.width}px`,
              height: `${size.height}px`,
              backgroundImage: `url(${img})`
            }"
          ></div>
          <div
            style="
              text-align: center;
              margin-top: 8px;
              font-size: 14px;
              position: relative;
            "
          >
            <div>第{{ i + 1 }}张</div>
            <div style="position: absolute; right: 0; top: 0">
              <a
                style="color: #f33; margin-left: 0.8em; cursor: pointer"
                @click="
                  () => {
                    images.splice(i, 1)
                  }
                "
                >删除</a
              >
            </div>
          </div>
        </div>

        <el-upload
          class="upload non-draggable"
          action="/api/v1/admin/upload/image"
          :show-file-list="false"
          :style="{
            width: `${size.width}px`,
            height: `${size.height}px`
          }"
          style="
            border: 1px dashed #aaa;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          v-bind="uploadProps"
          v-loading="uploadProps.uploading.value"
        >
          <i class="el-icon-upload" style="font-size: 30px"></i>
          <div>将图片拖到此处，或<em>点击上传</em></div>
          <div style="margin-top: 8px">建议尺寸 1404*1640</div>
        </el-upload>
      </draggable>
    </div>

    <div style="text-align: right">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { clone } from 'lodash'
import { ref } from 'vue'
import draggable from 'vuedraggable'
import { useUpload } from '../useUpload'

export default {
  components: { draggable },
  props: {
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const images = ref<string[]>([])

    images.value = clone(props.data[props.type]?.images || [])

    const uploadProps = useUpload({
      onUpload(url) {
        images.value.push(url)
      }
    })

    function submit() {
      emit('change', props.type, images.value)
    }

    function checkMove(e) {
      if (!e.relatedContext.element) return false
      return true
    }

    return {
      size: {
        width: 240,
        height: 240 * 1.168
      },
      images,
      uploadProps,
      submit,
      checkMove
    }
  }
}
</script>

<style scoped lang="less">
.upload {
  ::v-deep .el-upload {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
