<template>
  <div>
    <el-button type="primary" size="mini" @click="visible = true"
      >图标库</el-button
    >

    <el-dialog title="选择图标" :visible.sync="visible" append-to-body>
      <div
        style="
          display: grid;
          grid-template-columns: repeat(10, 1fr);
          gap: 16px 8px;
        "
      >
        <div
          v-for="(it, i) in Icons"
          :key="i"
          class="item"
          @click="onClick(it)"
        >
          <img :src="it" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { Icons } from './icons'

export default {
  setup(props, { emit }) {
    const visible = ref(false)

    function onClick(icon: string) {
      visible.value = false
      emit('change', icon)
    }

    return {
      visible,
      onClick,
      Icons
    }
  }
}
</script>

<style scoped lang="less">
.item {
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
}
</style>
