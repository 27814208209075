import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import { reactive } from 'vue'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'device/config/link/list'
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return false
  }
  getDefaultFormData() {
    return {
      icon: '',
      type: 'apk',
      zoom: 100,
      tools: [],
      render_contrast_inc: 0,
      new_tab: true
    }
  }
  parseFetchedFormData(data: any) {
    return {
      ...data,
      icon: data.icon ?? '',
      tools: data.tools ?? [],
      render_contrast_inc: data.render_contrast_inc ?? 0,
      new_tab: data.new_tab ?? true
    }
  }
  getRemoveURL(): string {
    return `device/config/link/${this.params.id}`
  }
  getSubmitURL(): string {
    return this.isEdit
      ? `device/config/link/modify/${this.data.id}`
      : 'device/config/link/add'
  }
  requestListReload(option?): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit
  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = reactive(new Store())
