import _ from 'lodash'

type RoleType = 'admin' | 'credit_note_data'

class AppStore {
  state = {
    app: {
      isCXApp: false,
      isCXCreditApp: false,
      isAdmin: true
    },
    data: null,
    user: {
      data: null,
      role: null as RoleType
    },
    lib: {
      data: null as any,
      hasDeviceReader: true,
      hasDeviceKindle: false,
      lendOutWithShelf: false,
      lendOutWithManual: true,
      isDepositOpen: false,
      deviceAppDashboard: false,
      deviceCustomUI: false
    },
    ui: {
      openNav: true,
      appName: ''
    }
  }

  setUser(data, role: RoleType) {
    this.state.user.data = data
    this.state.user.role = role
    this.state.app.isAdmin = role === 'admin'
  }

  setLib(data) {
    this.state.lib.data = data
    const { isDepositOpen, device_type, lend_way } = this.state.lib.data
    this.state.lib.hasDeviceReader = _.includes(device_type, 1)
    this.state.lib.hasDeviceKindle = _.includes(device_type, 2)
    this.state.lib.lendOutWithShelf = _.includes(lend_way, 1)
    this.state.lib.lendOutWithManual = _.includes(lend_way, 2)
    this.state.lib.isDepositOpen = isDepositOpen
    this.state.lib.deviceAppDashboard = data.lms_homepage_enable
    this.state.lib.deviceCustomUI = data.device_guide_enable
  }
}

export const appStore = new AppStore()
