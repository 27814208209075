<template>
  <page-layout>
    <page-header :breadcrumb="['资源管理', '图书推荐']" />

    <page-main v-loading="list.loading">
      <page-action-bar>
        <el-button
          v-show="!isSorting"
          type="primary"
          size="small"
          @click="e => store.edit.onAdd()"
        >
          新增书单
        </el-button>

        <template v-if="!isSorting">
          <el-button
            size="small"
            @click="isSorting = true"
            :disabled="!list.items.length"
          >
            <i class="el-icon-sort" />书单排序
          </el-button>
        </template>
        <template v-if="isSorting">
          <div class="tip blue" style="margin-right: 8px">
            请拖动书单进行排序
          </div>
          <el-button size="small" type="primary" @click="onSortSubmit">
            保存
          </el-button>
          <el-button size="small" @click="onSortCancel"> 取消 </el-button>
        </template>
      </page-action-bar>

      <div class="table">
        <div class="table__header">
          <div class="index">序号</div>
          <div class="title">书单名称</div>
          <div class="bookNum">图书数量</div>
          <div class="create-at">创建时间</div>
          <div class="operate">操作</div>
        </div>
        <div class="table__content">
          <draggable v-model="list.items" :disabled="!isSorting">
            <div
              :class="{ row: true, sorting: isSorting }"
              v-for="(item, index) in list.items"
              :key="index"
            >
              <div class="index">
                {{ index + 1 }}
              </div>
              <div class="title">
                {{ item.name }}
              </div>
              <div class="bookNum">{{ item.lib_credit_book_ids.length }}本</div>
              <div class="create-at">
                {{ item.created_at | date('YYYY/MM/DD') }}
              </div>
              <div class="operate">
                <el-button type="text" @click="e => store.edit.onEdit(item)">
                  编辑
                </el-button>
                <el-button type="text" @click="e => store.edit.onRemove(item)">
                  删除
                </el-button>
              </div>
            </div>
          </draggable>

          <div
            style="text-align: center; margin-top: 10px"
            v-if="!list.items.length"
          >
            暂无数据
          </div>
        </div>
      </div>
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import { store } from './store'
import Edit from './edit.vue'
import Draggable from 'vuedraggable'

export default {
  components: { Edit, Draggable },

  data() {
    return {
      store,
      list: store.list,
      isSorting: false
    }
  },

  mounted() {
    this.list.fetch()
  },

  methods: {
    onSortSubmit() {
      this.isSorting = !this.isSorting
      store.list.onSortSubmit()
    },
    onSortCancel() {
      this.isSorting = false
      this.list.fetch()
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>
