<template>
  <el-select :value="value" :clearable="clearable" @change="onChange">
    <el-option
      v-for="item in items"
      :key="item.id"
      :label="item.year"
      :value="item.year"
    />
  </el-select>
</template>

<script lang="ts">
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: []
    }
  },
  async mounted() {
    const { data } = await GET('credit/year/config', {})
    this.items = data
  },
  methods: {
    onChange(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped></style>
