<template>
  <el-dialog
    title="借还记录"
    :visible.sync="visible"
    width="800px"
    top="8vh"
    append-to-body
  >
    <user-banner :data="params" />

    <div style="padding: 0 0 8px">查询到 {{ total }} 条记录</div>

    <div v-loading="loading">
      <borrow-card v-for="(item, index) in items" :key="index" :data="item" />

      <el-pagination
        @current-change="handlePageChange"
        :current-page="index"
        :total="total"
        :page-size="size"
        layout="total, prev, pager, next"
        background
      />
    </div>
  </el-dialog>
</template>

<script lang="ts">
import UserBanner from '../shared/user-banner.vue'
import BorrowCard from '../shared/borrow-card.vue'
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  components: {
    UserBanner,
    BorrowCard
  },

  data() {
    return {
      visible: false,
      items: [],
      index: 1,
      size: 10,
      total: 0,
      loading: true,
      params: {}
    }
  },

  methods: {
    open(params) {
      this.visible = true
      this.params = params
      this.fetch(true)
    },

    handlePageChange(index: number) {
      this.index = index
      this.fetch()
    },

    async fetch(reset = false) {
      if (reset) {
        this.index = 1
      }
      this.loading = true
      this.items = []
      const res = await GET(`reader/lend_history/${this.params.userId}`, {
        data: {
          page: this.index,
          limit: this.size
        }
      })
      this.items = res.data.list
      this.total = res.data.totalCount
      this.loading = false
    }
  }
}
</script>
