import { GET } from '@/admin-shared-modules/utils/ajax'
import _ from 'lodash'

class Store {
  state = {
    visible: true,
    isPro: false,
    list: {
      items: [],
      index: 1,
      size: 10,
      total: 0,
      loading: false
    },
    search: {
      data: {
        tab: 1,
        tags: [],
        title: '',
        author: '',
        publisher: '',
        isbn_13: ''
      }
    }
  }

  async fetch(reset = false) {
    if (reset) this.state.list.index = 1
    this.state.list.loading = true
    this.state.list.items = []
    const data = {
      pageIndex: this.state.list.index - 1,
      pageSize: this.state.list.size,
      ...this.state.search.data,
      credit_tag_id: this.state.search.data.tags[0],
      is_professional: this.state.isPro ? 1 : 0
    }
    try {
      const res = await GET('los/credit/book', {
        data: _.omitBy(data, v => {
          return _.isString(v) && !_.size(v)
        })
      })
      this.state.list.items = res.data.items
      this.state.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.state.list.loading = false
    }
  }
}

export default new Store()
